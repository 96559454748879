import queryClient from "../../config/queryClient";
import { resetUserStore } from "../../stores/user";
import { removeAdminAccessToken } from "../../helpers/accessToken";
import { reset as resetAddressStore } from "../../stores/address";
import { reset as resetBookingStore, resetPaymentStore } from "../../stores/booking";

const invalidateUserCache = () => {
  queryClient.clear();
};

export const resetUserData = () => {
  resetBookingStore();
  resetAddressStore();
  resetUserStore();
  resetPaymentStore();

  invalidateUserCache();

  localStorage.removeItem("token");
  removeAdminAccessToken();
};
