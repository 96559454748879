import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Each from "../../Each";
import { Box } from "../../v2/Styled";
import RadioButton from "../../RadioButton";
import { DropdownOption } from "../../Dropdown";
import { useMobile } from "../../../hooks/mobile";
import Button, { ButtonType } from "../../Button";
import { Colors } from "../../../constants/colors";
import { debounce } from "../../../utils/debounce";
import { useUserStore } from "../../../stores/user";
import TextButton from "../../TextButton/TextButton";
import { useAlertStore } from "../../../stores/alert";
import ContentModal from "../ContentModal/contentModal";
import recipientIcon from "./../../../images/user-icon.png";
import { PAYMENT_FILTER_STATUS } from "../../../data/enums";
import { AutoCompleteField } from "../../AutoCompleteField";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { useChildAccounts, useSearchProviderByName } from "../../../hooks/search.hook";
import BookingDateFilter from "../../BookingFilter/BookingDateFilter";
import BookingStatusFilter from "../../BookingFilter/BookingStatusFilter";
import { useCachedBookingSort } from "../../../hooks/utility/filter.hooks";
import { PaymentStatusFilter } from "../../BookingFilter/PaymentStatusFilter";
import { useGetRecipientOptions } from "../../../hooks/recipient/recipient.hooks";
import { useRecentProviders } from "../../../hooks/booking/preferredProviders.hooks";
import { FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../../v2/Styled/enum";
import {
  BookingFilterStatus,
  bookingSortOptions,
  pastBookingStatusOptions,
  upcomingBookingStatusOptions,
} from "../../../constants/sortOptions";
import { stringifyParams } from "../../../helpers/string";
import useBookingFilter from "../../../hooks/booking/bookingFilter.hook";
import { getAdminAccessToken } from "../../../helpers/accessToken";

interface Props {
  open: boolean;
  onClose: () => unknown;

  forUpcoming: boolean;
  showRecipientField?: boolean;
}

export interface FilterBookingValue {
  recipientId?: number | undefined | null;
  paymentStatus?: Array<string>;
  providerId?: number | undefined | null;
  sortBy: string;
  status?: Array<string>;
  filterDate?: object;
}

interface BookingFilterState {
  recipientId: number | undefined | null;
  recipientName: string;
  recipientNameToSearch: string;
  isRecipientSelected: boolean;
  providerNameToSearch: string;
  providerName: string;
  isProviderSelected: boolean;
  isUnpaidOnly: boolean;
  providerId: number | undefined | null;
  sortBy: string | undefined | null;
  bookingStatus: Array<string>;
  startDate: Date | string | null;
  endDate: Date | string | null;
  bookingId?: string;
  bookedBy: number | undefined | null;
  bookedByName: string;
  bookedByNameToSearch: string;
  isBookedBySelected: boolean;
  jobId: string | number | undefined | null;
}

export const BookingFilterModal = ({
  open,
  onClose,
  forUpcoming = false,
  showRecipientField = true,
}: Props) => {
  const isMobile = useMobile();
  const { setErrorMessage } = useAlertStore();
  const adminLoggedInAsClient = getAdminAccessToken();
  const { allBookingQueries: bookingParams } = useBookingFilter({ forUpcoming });

  const { cachedSortBy, setSortBy, removeSortBy } = useCachedBookingSort();
  const defaultSortBy = forUpcoming ? "asc" : "desc";

  const bookingSortBy = cachedSortBy || bookingParams.sortBy;

  const bookingStatusOptions = forUpcoming
    ? upcomingBookingStatusOptions
    : pastBookingStatusOptions;

  const defaultFilter = {
    recipientId: null,
    recipientName: "",
    recipientNameToSearch: "",
    isRecipientSelected: false,
    providerNameToSearch: "",
    providerName: "",
    providerId: null,
    isProviderSelected: false,
    sortBy: defaultSortBy,
    isUnpaidOnly: false,
    bookingStatus: Object.values(BookingFilterStatus),
    startDate: "",
    endDate: "",
    bookingId: "",
    bookedBy: null,
    bookedByName: "",
    bookedByNameToSearch: "",
    isBookedBySelected: false,
    jobId: null,
  } as const;

  const filterFromParams = {
    recipientId: bookingParams.recipientId,
    recipientName: "",
    recipientNameToSearch: "",
    isRecipientSelected: bookingParams.recipientId ? true : false,
    providerNameToSearch: "",
    providerName: bookingParams.providerName || "",
    providerId: bookingParams.providerId,
    isProviderSelected: bookingParams.providerId ? true : false,
    sortBy: bookingParams.sortBy || bookingSortBy,
    isUnpaidOnly: bookingParams.paymentStatus === PAYMENT_FILTER_STATUS.IS_UNPAID,
    bookingStatus: bookingParams.status || Object.values(BookingFilterStatus),
    startDate: bookingParams.filterDate?.startDate || "",
    endDate: bookingParams.filterDate?.endDate || "",
    bookingId: bookingParams.bookingId,
    bookedBy: bookingParams.bookedBy,
    bookedByName: bookingParams.bookedByName || "",
    bookedByNameToSearch: "",
    isBookedBySelected: bookingParams.bookedBy ? true : false,
    jobId: bookingParams.jobId,
  } as const;

  const [filter, setFilter] = useState<BookingFilterState>(filterFromParams);

  const [recipientName, setRecipientName] = useState("");
  const [providerName, setProviderName] = useState(bookingParams.providerName || "");
  const [bookedByName, setBookedByName] = useState(bookingParams.bookedByName || "");

  const [listOfProvider, setListOfProvider] = useState([]);
  const [bookingId, setBookingId] = useState(bookingParams.bookingId ?? "");
  const [jobId, setJobId] = useState(bookingParams.jobId ?? "");

  const { isInitialLoading: isRecipientOptionsInitiallyLoading, data: recipientOptions } =
    useGetRecipientOptions({
      name: filter.recipientNameToSearch,
    });

  const { data: providerOptions, isLoading } = useSearchProviderByName({
    name: filter.providerNameToSearch,
  });

  const { data: recentPros } = useRecentProviders({
    currentPage: 1,
    perPage: 20,
    options: { allProviders: true },
  });

  const { isInitialLoading: isBookedByOptionsInitiallyLoading, data: supportCoordinatorOptions } =
    useChildAccounts({
      name: filter.bookedByNameToSearch,
    });

  const { user } = useUserStore();

  let recipientWithMyself: DropdownOption[] | [] = [];
  let supportWithMyself: DropdownOption[] | [] = [];
  let providers: DropdownOption[] | [] = [];

  if (recipientOptions) {
    recipientWithMyself = [...recipientWithMyself, ...recipientOptions];
    if (!recipientName) {
      const myself = {
        value: user?.id,
        title: "myself",
      };
      recipientWithMyself.unshift(myself);
    }
  }

  if (supportCoordinatorOptions) {
    supportWithMyself = [...supportWithMyself, ...supportCoordinatorOptions];
    if (!bookedByName) {
      const myself = {
        value: user?.id,
        title: "myself",
      };
      supportWithMyself.unshift(myself);
    }
  }

  if (providerOptions && !checkIfEmpty(providerOptions)) {
    providers = [...providerOptions];
  }
  useEffect(() => {
    if (filter.recipientId) {
      let selectedRecipient = null;
      const recipientId = filter.recipientId;
      if (recipientId === user?.id) {
        selectedRecipient = {
          title: "myself",
          value: `${user?.id}`,
        };
      } else {
        selectedRecipient = (recipientWithMyself || []).find(({ value }) => recipientId === value);
      }
      if (selectedRecipient) {
        setRecipientName(selectedRecipient.title);
        setFilter({
          ...filter,
          isRecipientSelected: true,
          recipientName: selectedRecipient?.title,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecipientOptionsInitiallyLoading]);

  useEffect(() => {
    if (filter.bookedBy) {
      let selectedBookedBy = null;
      const bookedBy = filter.bookedBy;
      if (bookedBy === user?.id) {
        selectedBookedBy = {
          title: "myself",
          value: `${user?.id}`,
        };
      } else {
        selectedBookedBy = (supportWithMyself || []).find(({ value }) => bookedBy === value);
      }
      if (selectedBookedBy) {
        setBookedByName(selectedBookedBy.title);
        setFilter({
          ...filter,
          isBookedBySelected: true,
          bookedByName: selectedBookedBy?.title,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBookedByOptionsInitiallyLoading]);

  useEffect(() => {
    if (!checkIfEmpty(recentPros) && !filter.providerNameToSearch) {
      const therapistList = getValue(recentPros, "therapistList", []);
      const transformRecentPros = therapistList.map((data: any) => ({
        value: data.id,
        title: `${data.firstName} ${data.lastName}`,
      }));
      setListOfProvider(transformRecentPros);
    }
  }, [recentPros, filter.providerNameToSearch]);

  useEffect(() => {
    if (filter.providerNameToSearch) {
      setListOfProvider(providerOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerOptions, isLoading]);

  useEffect(() => {
    const debounceSearchProvider = debounce(
      () => setFilter({ ...filter, providerNameToSearch: providerName }),
      1000
    );

    // Call the debounced function
    debounceSearchProvider();

    // Clean up the debounced function on component unmount
    return () => {
      debounceSearchProvider.cancel();
    };
  }, [providerName]);

  const handleRecipientNameToSearchChange = (value: any) => {
    setFilter({ ...filter, recipientNameToSearch: value });
  };

  const handleBookedByNameToSearchChange = (value: any) => {
    setFilter({ ...filter, bookedByNameToSearch: value });
  };

  const handleProviderChange = (value: any) => {
    if (value.length >= 2) {
      setFilter({ ...filter, providerNameToSearch: value });
    }
  };

  const debounceRecipientChangeHandler = debounce(handleRecipientNameToSearchChange, 500);
  const debounceBookedByChangeHandler = debounce(handleBookedByNameToSearchChange, 500);
  const debounceProviderChangeHandler = debounce(handleProviderChange, 500);

  const onProviderSelect = (option: DropdownOption) => {
    setProviderName(option.title);
    setFilter({
      ...filter,
      providerId: option.value,
      providerName: option.title,
      isProviderSelected: true,
    });
  };

  const history = useHistory();

  const handleFilterApply = () => {
    if (recipientName && !filter.isRecipientSelected) {
      return setErrorMessage("The recipient name you have entered could not be found.");
    }
    if (providerName && !filter.isProviderSelected) {
      return setErrorMessage("The provider name you have entered could not be found.");
    }
    if (bookedByName && !filter.isBookedBySelected) {
      return setErrorMessage("The user name you have entered could not be found.");
    }

    if (filter.sortBy && filter.sortBy !== bookingParams.sortBy) {
      setSortBy(filter.sortBy);
    }
    if (filter.startDate && !filter.endDate) {
      return setErrorMessage("Please select 'To' date first.");
    }
    if (filter.endDate && !filter.startDate) {
      return setErrorMessage("Please select 'From' date first.");
    }

    const paymentStatus = [];

    const searchParams: any = {
      sortBy: filter.sortBy ? filter.sortBy : null,
      status: filter.bookingStatus,
    };

    if (filter.isUnpaidOnly) {
      paymentStatus.push("isUnpaid");
      searchParams.paymentStatus = paymentStatus;
    }

    if (recipientName && filter.recipientId) {
      searchParams.recipientId = filter.recipientId;
    }

    if (providerName && filter.providerId) {
      searchParams.providerId = filter.providerId;
      searchParams.providerName = filter.providerName;
    }

    if (bookedByName && filter.bookedBy) {
      searchParams.bookedBy = filter.bookedBy;
    }

    if (!forUpcoming) {
      searchParams.page = bookingParams.page;
      searchParams.section = bookingParams.section;
    }

    if (filter.startDate && filter.endDate) {
      searchParams.filterDate = JSON.stringify({
        startDate: filter.startDate,
        endDate: filter.endDate,
      });
    }

    if (bookingId) {
      searchParams.bookingId = bookingId;
    }
    if (jobId) {
      searchParams.jobId = jobId;
    }

    if (!showRecipientField) {
      history.push("?" + stringifyParams(searchParams));
    } else {
      history.push("/my-bookings?" + stringifyParams(searchParams));
    }

    onClose();
  };

  const handleClearAll = () => {
    removeSortBy();
    setFilter(defaultFilter);
    setProviderName("");
    setRecipientName("");
    setBookingId("");
    setBookedByName("");
    setJobId("");
    // debounceProviderChangeHandler("");
    // debounceRecipientChangeHandler("");
  };

  const shouldShowClearAll = () =>
    recipientName || filter.isUnpaidOnly || providerName || filter.sortBy;

  const statusToggleChanged = (statusToggle: boolean, status: string) => {
    const stateBookingStatus = getValue(filter, "bookingStatus");
    let updatedStatus = [...stateBookingStatus];
    if (stateBookingStatus.includes(status)) {
      var index = updatedStatus.indexOf(status);
      if (index !== -1) {
        updatedStatus.splice(index, 1);
      }
    } else {
      updatedStatus.push(status);
    }
    setFilter({
      ...filter,
      bookingStatus: updatedStatus,
    });
  };

  const handleDateRangeFilterApplied = (field: string, value: Date | string | null) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const handleBookingIdChange = (value: string) => {
    // discarding non number chars
    let id = "";
    for (let ch of value) {
      if (!isNaN(+ch)) {
        id += ch;
      }
    }

    setBookingId(id);
  };

  const handleJobIdChange = (value: string) => {
    let id = "";
    for (let ch of value) {
      if (!isNaN(+ch)) {
        id += ch;
      }
    }

    setJobId(id);
  };

  return (
    <ContentModal
      visible={open}
      onClose={onClose}
      actions={[
        <TextButton
          text="Clear all"
          type={shouldShowClearAll() ? "danger" : "disabled"}
          onClick={handleClearAll}
        />,
        <Button
          title="Apply"
          type={ButtonType.secondary}
          onClick={handleFilterApply}
          width={"161px"}
        />,
      ]}
      title="Filter and sort"
      divider={false}
      maxWidth="md"
      PaperProps={{
        style: {
          minWidth: !isMobile ? "438px" : "100%",
          minHeight: !isMobile ? "default" : "100%",
        },
      }}
      actionStyle={{
        paddingLeft: Spacing.S6,
        paddingRight: Spacing.S6,
        justifyContent: "space-between",
      }}
      titleStyle={{ fontSize: "16px" }}
      fullWidth={isMobile}
      childrenStyle={{ marginTop: 0 }}
      fixButtonsWithDivider={true}
      showCrossIcon={true}
    >
      <Box direction={FlexDirection.Column} gap={Spacing.S3} marginY={Spacing.S6}>
        <Box fontWeight={700} fontSize={"22px"} color={Colors.NightBlue} fontFamily="Museo">
          Sort by
        </Box>
        {bookingSortOptions.map((sortOpt) => (
          <Box alignItems="center" gap={Spacing.S4}>
            <RadioButton
              style={{ height: 16, width: 16 }}
              selected={sortOpt.value === filter.sortBy}
              onSelected={() => setFilter({ ...filter, sortBy: sortOpt.value })}
            />
            <Box
              color={Colors.Dusk}
              fontSize="16px"
              fontFamily="Museo"
              className="cursor-pointer"
              onClick={() => setFilter({ ...filter, sortBy: sortOpt.value })}
            >
              {sortOpt.title}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        fontWeight={700}
        fontSize={"22px"}
        color={Colors.NightBlue}
        fontFamily="Museo"
        marginY={Spacing.S3}
      >
        Filter by
      </Box>
      <Box direction={FlexDirection.Column} gap={Spacing.S6} marginBottom={Spacing.S6}>
        <Box direction={FlexDirection.Column} gap={Spacing.S4}>
          <Box
            fontWeight={FontWeight.Bold}
            fontSize={FontSize.F18}
            lineHeight={"27px"}
            fontFamily={FontFamily.Museo}
            color={Colors.Dusk}
          >
            Date
          </Box>
          <BookingDateFilter
            startingDate={filter.startDate}
            endingDate={filter.endDate}
            handleDateSelected={handleDateRangeFilterApplied}
          />
        </Box>
        <Box direction={FlexDirection.Column} gap={Spacing.S4}>
          <Box
            fontWeight={FontWeight.Bold}
            fontSize={FontSize.F18}
            lineHeight={"27px"}
            fontFamily={FontFamily.Museo}
            color={Colors.Dusk}
          >
            Booking status
          </Box>
          <Each
            of={bookingStatusOptions}
            render={(status) => (
              <BookingStatusFilter
                key={status.title}
                onToggle={(statusToggle, status) => statusToggleChanged(statusToggle, status)}
                active={filter.bookingStatus.includes(status.title)}
                status={status.title}
                subtitle={status.description}
              />
            )}
          />
        </Box>

        <PaymentStatusFilter
          onUnpaidToggled={(isUnpaid: boolean) => setFilter({ ...filter, isUnpaidOnly: isUnpaid })}
          unPaidToggle={filter.isUnpaidOnly}
        />
        {showRecipientField && (
          <AutoCompleteField
            title="Recipient name"
            options={recipientWithMyself as Array<DropdownOption>}
            placeholder="Enter recipient name"
            onOptionClick={(option) => {
              setRecipientName(option.title);
              setFilter({
                ...filter,
                recipientName: option.title,
                recipientId: option.value,
                isRecipientSelected: true,
              });
            }}
            onValueChange={(value) => {
              setRecipientName(value);
              setFilter({
                ...filter,
                recipientName: value,
                isRecipientSelected: false,
              });
              debounceRecipientChangeHandler(value);
            }}
            value={recipientName}
            dropDownOptionIcon={recipientIcon}
          />
        )}

        <AutoCompleteField
          title="Provider name"
          options={listOfProvider as Array<DropdownOption>}
          placeholder="Enter provider name"
          onOptionClick={(option) => onProviderSelect(option)}
          onValueChange={(value) => {
            setProviderName(value);
            setFilter({
              ...filter,
              providerName: value,
              isProviderSelected: false,
            });
            debounceProviderChangeHandler(value);
          }}
          value={providerName}
          dropDownOptionIcon={recipientIcon}
        />

        <AutoCompleteField
          title="Booking no."
          options={[]}
          placeholder="Enter booking no."
          onOptionClick={(option) => {}}
          onValueChange={handleBookingIdChange}
          value={bookingId}
        />

        <AutoCompleteField
          title="Booked by"
          options={supportWithMyself as Array<DropdownOption>}
          placeholder="Enter booker name"
          onOptionClick={(option) => {
            setBookedByName(option.title);
            setFilter({
              ...filter,
              bookedByName: option.title,
              bookedBy: option.value,
              isBookedBySelected: true,
            });
          }}
          onValueChange={(value) => {
            setBookedByName(value);
            setFilter({
              ...filter,
              recipientName: value,
              isBookedBySelected: false,
            });
            debounceBookedByChangeHandler(value);
          }}
          value={bookedByName}
          dropDownOptionIcon={recipientIcon}
        />

        {adminLoggedInAsClient && (
          <AutoCompleteField
            title="Job no."
            options={[]}
            placeholder="Enter job no."
            onOptionClick={(option) => {}}
            onValueChange={handleJobIdChange}
            value={jobId}
          />
        )}

        <Box marginY={Spacing.S3} />
      </Box>
    </ContentModal>
  );
};
