import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";
import { useAlertStore } from "../../stores/alert";

const INDUSTRY_QUERY_KEYS = {
  industryOptions: "industryOptions",
};

export function useIndustryOptions() {
  const { setErrorMessage } = useAlertStore();

  return useQuery({
    queryKey: [INDUSTRY_QUERY_KEYS.industryOptions],
    queryFn: async () => {
      const response = await get("api/v2/industry/options", {}, true);
      return response;
    },
    onError(err) {
      console.log(err);
      setErrorMessage("Failed to fetch industry options");
    },
  });
}
