import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, Token } from "@stripe/stripe-js";
import { Colors } from "../../../constants/colors";
import { useStripeStore } from "../../../stores/stripe";
import { useStripePromise } from "../../../hooks/stripe/stripe.hooks";
import StripeAddPaymentMethodFooter from "./StripeAddPaymentMethodFooter";
import StripeCardElement, { StripeCardFormHandle } from "./StripeCardElement";

interface Props {
  onSuccess: (
    e: MouseEvent,
    token: Token | string | undefined,
    cb: (e: MouseEvent, d: any) => unknown
  ) => unknown;
}

const StripeCardAddPayment = React.forwardRef(
  ({ onSuccess }: Props, ref: React.Ref<StripeCardFormHandle>) => {
    const { stripeCountry } = useStripeStore();
    const stripePromise = useStripePromise(stripeCountry);

    const options: StripeElementsOptions = {
      appearance: {
        theme: "stripe",
        variables: {
          colorPrimary: Colors.NightBlue,
        },
      },
    };

    const onTokenCreated = (
      e: MouseEvent,
      token: Token | undefined,
      cb = (e: MouseEvent, d: any): any => {}
    ) => {
      onSuccess(e, token, cb);
    };

    return (
      <Elements options={options} stripe={stripePromise}>
        <StripeCardElement
          ref={ref}
          forPayment
          onTokenCreated={onTokenCreated}
          FooterComponent={<StripeAddPaymentMethodFooter />}
        />
      </Elements>
    );
  }
);

export default StripeCardAddPayment;
