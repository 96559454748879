import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import LocationFilter from "./LocationFilter";
import ServicesFilter from "./ServicesFilter";
import DateRangeFilter from "./DateRangeFilter";
import SortComponent from "./SortComponent";
import { Colors } from "../../../constants/colors";
import { useMobile } from "../../../hooks/mobile";
import { FilterComponentResponsive } from "./FilterComponentResponsive";
import { Display, FontFamily, FontSize, JustifyContent, Spacing } from "../../v2/Styled/enum";

const FiltersContainer = ({
  filter,
  handleFilterApplied,
  clearFilter,
}: {
  filter: any;
  handleFilterApplied: any;
  clearFilter?: () => void;
}) => {
  const isMobile = useMobile();
  const [closeOthers, setCloseOthers] = useState(false);
  const [showFilterAndSortModal, setShowFilterAndSortModal] = useState(false);

  const onFilterClick = () => {
    setShowFilterAndSortModal(true);
  };
  const filterCount = Object.keys(filter).filter((key) => {
    if (filter[key] || filter[key] !== null) return key;
  }).length;

  return (
    <>
      {isMobile ? (
        <>
          <Box width="100%" display={Display.Flex} justifyContent={JustifyContent.end}>
            <Box marginRight={Spacing.S6} position={"relative"}>
              <Button
                variant="outlined"
                startIcon={<FilterList />}
                style={{
                  fontFamily: FontFamily.Museo,
                  fontSize: FontSize.F14,
                  color: Colors.Grey,
                  borderColor: Colors.LightBlueGrey,
                  borderRadius: "100px",
                  textTransform: "none",
                }}
                onClick={onFilterClick}
              >
                Filter and sort
              </Button>
              {filterCount ? (
                <Box
                  position="absolute"
                  top="-12px"
                  right={"0px"}
                  width={"14px"}
                  height={"14px"}
                  fontFamily={FontFamily.Museo}
                  fontSize={"12px"}
                  style={{ borderRadius: "50%", backgroundColor: `${Colors.Danger}` }}
                  color={Colors.White}
                  textAlign={"center"}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  padding={Spacing.S1}
                >
                  {filterCount}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <FilterComponentResponsive
            open={showFilterAndSortModal}
            onClose={() => setShowFilterAndSortModal(false)}
            filter={filter}
            handleFilter={handleFilterApplied}
            clearFilter={clearFilter}
          />
        </>
      ) : (
        <Box display="flex" flexDirection="row" gap={Spacing.S2} width={"80%"} margin="0 auto">
          {/* Suburb Input */}
          <Box flex={1}>
            <LocationFilter
              filter={filter}
              handleFilterApplied={handleFilterApplied}
              setCloseOthers={setCloseOthers}
              closeOthers={closeOthers}
            />
          </Box>
          {/* Services Dropdown */}
          <Box flex={1}>
            <ServicesFilter
              filter={filter}
              handleFilterApplied={handleFilterApplied}
              setCloseOthers={setCloseOthers}
              closeOthers={closeOthers}
            />
          </Box>

          {/* Date Picker */}
          <DateRangeFilter
            filter={filter}
            handleFilterApplied={handleFilterApplied}
            setCloseOthers={setCloseOthers}
            closeOthers={closeOthers}
          />

          {/* Filter and Sort Button */}
          <SortComponent
            filter={filter}
            handleFilterApplied={handleFilterApplied}
            setCloseOthers={setCloseOthers}
            closeOthers={closeOthers}
          />
        </Box>
      )}
    </>
  );
};

export default FiltersContainer;
