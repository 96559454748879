import axios from "axios";
import { deleteApi, post, get } from "../../api/client";
import { ProFileOwnership } from "../../constants/prodashboard";
import { getAccessToken } from "../../helpers/accessToken";
import { getProvidersDomain } from "../../constants/common";
import { toLowerCase } from "../../helpers/string";

const getPractitioners = (
  lat: number,
  long: number,
  serviceId?: string,
  serviceName?: string,
  currentPage?: number,
  treatmentId?: string,
  languageSpoken?: string | null,
  gender?: string | null,
  healthFund?: string | null,
  name?: string | null,
  sortBy?: string | null,
  startTime?: Date | string | null,
  endTime?: Date | string | null,
  perPage = 8,
  industry?: string | null
) => {
  const accessToken = getAccessToken();
  let url = `api/v2/therapists/providers?longitude=${long}&latitude=${lat}&perPage=${perPage}`;

  if (currentPage != null || currentPage != undefined) url += `&currentPage=${currentPage}`;

  if (serviceId) url += `&serviceId=${serviceId}`;
  if (serviceName) url += `&serviceName=${serviceName}`;
  if (treatmentId) url += `&treatmentId=${treatmentId}`;
  if (languageSpoken) url += `&languageSpoken=${languageSpoken}`;
  if (gender) url += `&gender=${gender}`;
  if (healthFund) url += `&healthFund=${healthFund}`;
  if (name) url += `&name=${name}`;
  if (sortBy) url += `&sortBy=${sortBy}`;
  if (startTime) url += `&startTime=${startTime}`;
  if (endTime) url += `&endTime=${endTime}`;
  if (industry) url += `&industry=${industry}`;

  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => response.data);
};

const uploadFile = (
  userId: number,
  accessToken: string | null,
  form: any,
  ownership: string = ProFileOwnership.portfolio
) => {
  return axios.post(
    `/therapists/${userId}/file?accessToken=${accessToken}&fileOwnership=${ownership}`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const uploadPublicImage = (form: any) => {
  return axios.post("/api/v2/uploads/images", form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const removeFile = (fileId: number, userId: number, accessToken: string | null) => {
  return axios.delete(
    `/therapists/${fileId}/file?accessToken=${accessToken}&therapistId=${userId}`
  );
};

const removeTherapistFile = (uploadedFileID: number, userID: number) => {
  return deleteApi(`therapists/${uploadedFileID}/file?therapistId=${userID}`);
};

const getPublicProfile = (userId: number, accessToken: string | null) => {
  return axios.get(`/therapists/${userId}/publicProfile?accessToken=${accessToken}`);
};

const getProSchedules = (
  accessToken: string | null,
  date: { startDate: string; endDate: string }
) => {
  return axios.get(
    `/api/v2/proschedules/availability?accessToken=${accessToken}&startDate=${date.startDate}&endDate=${date.endDate}`
  );
};

const addToFavourites = (therapistId: Number, accessToken: string | null) =>
  post(`favorite/${therapistId}`, { accessToken });

const removeFromFavorites = (therapistId: Number) => deleteApi(`favorite/${therapistId}`);

const blockTherapist = (therapistId: Number, blockReason: string, accessToken: string | null) =>
  post(`blockTherapist/${therapistId}`, { accessToken, blockReason });

const unBlockTherapist = (therapistId: Number) => deleteApi(`blockTherapist/${therapistId}`);

export const getProfileLink = ({ id, firstName }: { id: number | string; firstName: string }) => {
  return `${getProvidersDomain()}/practitioner/${toLowerCase(firstName)}-${id}`;
};

export const getPublicProfileForGuest = (userId: number | string) => {
  return axios.get(`/therapists/${userId}/guestPublicProfile`);
};

const getBeautyProfessionFromCountry = (countryCode: string) => {
  //v1
  if (countryCode === "US") {
    return "cosmetologist";
  }
  return "beauty therapist";
};

const getProPublicProfile = ({ therapistId }: { therapistId: number }) =>
  get(`therapists/${therapistId}/publicProfile`);

export {
  uploadFile,
  removeFile,
  removeTherapistFile,
  getPublicProfile,
  getPractitioners,
  getProSchedules,
  addToFavourites,
  removeFromFavorites,
  unBlockTherapist,
  blockTherapist,
  uploadPublicImage,
  getBeautyProfessionFromCountry,
  getProPublicProfile,
};
