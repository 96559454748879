import axios from "axios";
import create from "zustand";

type State = {
  clientSecret: string | null;
  stripeCountry: string | null;
  createSetupIntent: (t?: string | null) => unknown;
  resetClientSecret: () => unknown;
};

export const useStripeStore = create<State>((set) => ({
  clientSecret: null,
  stripeCountry: null,
  createSetupIntent: (token) => {
    const accessToken = localStorage.getItem("token") || token;

    if (accessToken) {
      axios
        .post("/api/v2/stripe/setup-intent", {
          accessToken,
        })
        .then((response) => {
          set({
            clientSecret: response.data.clientSecret,
            stripeCountry: response.data.stripeCountry,
          });
        })
        .catch((error) => {
          console.debug("fetch client secret error: ", error);
        });
    }
  },
  resetClientSecret: () => {
    set({ clientSecret: null });
  },
}));
