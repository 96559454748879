import { useEffect, useState } from "react";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "../../stores/V2";
import { useMobile } from "../../hooks/mobile";
import ReviewWrapper from "./Wrapper/ReviewWrapper";
import Button, { ButtonType } from "../../components/Button";
import { trackEvent } from "../../services/segment/track.service";
import { STEP_PROGRESS } from "../../constants/booking";
import {
  RECIPIENT_KEYS,
  useDeleteRecipient,
  useGetAllRecipients,
} from "../../hooks/recipient.hook";
import PaginationControl from "../../components/PaginationControl";
import RecipientItem from "../../components/Recipients/RecipientItem";
import { getValue } from "../../utils/object";
import { Paths } from "../../constants/paths";
import { Recipient } from "../../models";
import TextLink from "../../components/TextLink";
import { Box } from "../../components/v2/Styled";
import {
  AlignItems,
  Display,
  FlexDirection,
  JustifyContent,
  Spacing,
} from "../../components/v2/Styled/enum";
import RecipientModal from "../../components/Recipients/RecipientModal";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import queryClient from "../../config/queryClient";

interface Params {
  id?: string;
}

const ReviewBookingRecipient = () => {
  const { id } = useParams<Params>();

  const history = useHistory();
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [selectedRecipientForEdit, setSelectedRecipientForEdit] = useState<Recipient | null>(null);
  const [showRecipientModal, setShowRecipientModal] = useState(false);

  const state = useSelector(({ booking }: RootState) => booking);
  const selectedRecipient = state.selectedRecipient;

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { data: recipientsData } = useGetAllRecipients({ currentPage: page });

  const { deleteRecipient } = useDeleteRecipient();

  useEffect(() => {
    trackEvent("Review treatment type", {
      step: 4,
      version: 2,
    });
  }, []);

  const handleBack = () => history.replace(`/my-bookings/review/${id}`, { initialize: false });

  const handleContinue = () => {
    history.replace(`/my-bookings/review/${id}`, { initialize: false });
  };

  const recipientLists = getValue(recipientsData, "data", []) as Recipient[];

  const onRecipientUpdated = (recipient: Recipient | null) => {
    dispatch(bookingActions.updateBookingRecipient({ recipient }));
  };

  const refetchRecipientData = () => {
    queryClient.invalidateQueries({ queryKey: [RECIPIENT_KEYS.recipients] });
  };

  const onRecipientDelete = async (id: number) => {
    try {
      await deleteRecipient(id);
      refetchRecipientData();
      setSuccessMessage("Recipient deleted");
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  return (
    <ReviewWrapper
      buttons={[
        <Button title={"Back"} onClick={handleBack} type={ButtonType.outlined} />,
        <Button title={"Continue"} type={ButtonType.secondary} onClick={handleContinue} />,
      ]}
      progress={STEP_PROGRESS[3]}
      title="Recipient details"
      label="Step 3 of 7"
      onClose={handleBack}
    >
      <Box marginY={Spacing.S2} display={Display.Flex} direction={FlexDirection.Column}>
        <RecipientItem
          selected={!getValue(selectedRecipient, "id")}
          onSelected={() => {
            onRecipientUpdated(null);
          }}
          myself
          onEditClicked={() => {
            history.push(Paths.Profile);
          }}
        />

        {recipientLists.map((thisRecipient: Recipient) => (
          <RecipientItem
            recipient={thisRecipient}
            selected={thisRecipient.id === getValue(selectedRecipient, "id")}
            onSelected={() => {
              onRecipientUpdated(thisRecipient);
            }}
            onEditClicked={() => {
              setSelectedRecipientForEdit(thisRecipient);
              setShowRecipientModal(true);
            }}
            onDeleteClicked={() => onRecipientDelete(thisRecipient.id)}
          />
        ))}
      </Box>
      <Box
        display={Display.Flex}
        direction={isMobile ? FlexDirection.ColumnReverse : FlexDirection.Row}
        marginY={Spacing.S4}
        justifyContent={isMobile ? JustifyContent.center : JustifyContent.spaceBetween}
        alignItems={AlignItems.center}
        marginBottom={Spacing.S6}
        maxWidth={"100%"}
      >
        <Box mt={isMobile ? Spacing.S5 : Spacing.S0}>
          <TextLink
            title="Add new recipient"
            onClick={() => {
              setSelectedRecipientForEdit(null);
              setShowRecipientModal(true);
            }}
          />
        </Box>
        <PaginationControl
          currentPage={recipientsData?.page || 1}
          pages={recipientsData?.totalPages || 1}
          onPrevious={() => setPage(page - 1)}
          onNext={() => setPage(page + 1)}
          startFromOne={true}
        />
      </Box>

      <RecipientModal
        existingRecipient={selectedRecipientForEdit}
        open={showRecipientModal}
        onClose={() => setShowRecipientModal(false)}
        onSaved={(recipient: Recipient) => {
          refetchRecipientData();
          setShowRecipientModal(false);
          onRecipientUpdated(recipient);
        }}
      />
    </ReviewWrapper>
  );
};

export default ReviewBookingRecipient;
