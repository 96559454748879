import { Moment } from "moment";
import { useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { actions as bookingActions } from "../../../stores/V2/booking/booking";

import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import UnpaidBookingInfo from "./UnpaidBookingInfo";
import AddressSection from "../Address/AddressSection";
import { BOOKING_STATUS, DELIVERY_METHOD, JobStatus } from "../../../constants/booking";
import { cardWrapperStyles } from "../../../components/BookingDetails/BookingDetailCard";
import DayDateTimeSection from "../../../components/DayDateTime";
import { getRangedBookingFormattedDateTime } from "../../../services/bookings/bookingTime.service";
import SectionHeader from "../../../components/Headers/SectionHeader/SectionHeader";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontSize,
  FontWeight,
  JustifyContent,
} from "../../../components/v2/Styled/enum";
import Divider from "../../../components/Divider";
import TextButton from "../../../components/TextButton/TextButton";
import { BOOKING_SESSION_TYPE } from "../../../helpers/booking";
import DateAndTimeModal from "../Modals/DateAndTimeModal/DateAndTimeModal";
import { useMobile, useTab } from "../../../hooks/mobile";
import { calculateMinMaxMinutes } from "../utils/utils";

import DateTimeIcon from "../../../images/review-calendar-time.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/V2";

const BookingDetails = ({
  job,
  booking,
  address,
  hideSensativeData,
  openMapModal,
  isPaidBooking,
  onBookingUpdate,
  style,
}: any) => {
  const isTab = useTab();
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const bookingData = useSelector(({ booking }: RootState) => booking);

  const [showArrivalTimeModal, setShowArrivalTimeModal] = useState(false);

  const { status: jobStatus } = job || {};
  const bookingStatus = getValue(booking, "status");
  const isHairAndMakeup = getValue(booking, "isHairAndMakeup");
  const { userId } = booking || {};

  const showLocationDetails =
    (getValue(job, "bookingdetail.deliveryMethod") || DELIVERY_METHOD.inperson) ===
      DELIVERY_METHOD.inperson || hideSensativeData;

  const pendingBookingDeadline = getValue(
    job,
    "bookingdetail.booking.bookingPayment.declinedPaymentExpiryTime"
  );
  const hasBackupDate = !!booking?.backup;

  const getFormattedDateTime = ({ booking }: any) => {
    if (!booking) return;

    const { earliestTime, latestTime, timezone } = booking;
    const { datetime } = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });
    return datetime;
  };

  const getFormattedBackupDate = ({ booking }: any) => {
    if (!booking || !booking?.backup) return "";
    const { timezone } = booking;
    const { earliestTime, latestTime } = booking?.backup;

    const { datetime } = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });
    return datetime;
  };

  const isDetailsUpdateAllowed = () => {
    const { sessionType } = booking || {};

    const numberOfTherapist = (getValue(booking, "bookingdetails") || []).length;
    if (
      [BOOKING_SESSION_TYPE.COUPLES, BOOKING_SESSION_TYPE.CORPORATE].includes(sessionType) ||
      numberOfTherapist > 1
    ) {
      return false;
    }

    if ([JobStatus.accepted, JobStatus.onway].includes(jobStatus)) return true;
    if (
      jobStatus === JobStatus.rebooking &&
      [BOOKING_STATUS.NEW, BOOKING_STATUS.COMPLETED].includes(bookingStatus)
    )
      return true;

    return false;
  };

  const toggleArrivalTimeModal = (visible: boolean) => setShowArrivalTimeModal(visible);

  const onArrivalTimeSave = ({ selectedDateTime }: { selectedDateTime: Moment }) => {
    toggleArrivalTimeModal(false);
    dispatch(bookingActions.updateBookingArrivalTime({ timeOfArrival: selectedDateTime }));
    if (jobStatus === JobStatus.rebooking && bookingStatus === BOOKING_STATUS.NEW) {
      onBookingUpdate({ updated: { timeOfArrival: selectedDateTime } });
    }
  };

  const getTimeOfArrival = () => {
    const bookingArrivalTime = getValue(bookingData, "timeOfArrival");
    return bookingArrivalTime;
  };

  const { max: maxMinutes, min: minMinutes } = calculateMinMaxMinutes(booking);
  const allowDetailUpdate = isDetailsUpdateAllowed();
  const headerTextStyle = { fontWeight: FontWeight.Medium, color: Colors.BlueyGrey };

  const showSectionHeaders = [BOOKING_STATUS.ARRANGED, BOOKING_STATUS.COMPLETED].includes(
    bookingStatus
  );

  return (
    <Box style={style ? style : styles.bookingDetailContainer}>
      <Box>
        {showSectionHeaders && (
          <Box
            display={Display.Flex}
            alignItems={AlignItems.start}
            flexDirection={FlexDirection.Row}
            justifyContent={JustifyContent.spaceBetween}
          >
            <SectionHeader
              title="Booking date and time"
              textStyle={headerTextStyle}
              wrapperStyle={{ marginBottom: "12px" }}
            />

            {allowDetailUpdate && (
              <TextButton
                text="Change"
                onClick={() => setShowArrivalTimeModal(true)}
                type="primary"
                textStyle={{ padding: "0px", fontSize: FontSize.F16 }}
              />
            )}
          </Box>
        )}

        {[BOOKING_STATUS.ARRANGED, BOOKING_STATUS.COMPLETED].includes(bookingStatus) && (
          <>
            <DayDateTimeSection
              time={getTimeOfArrival()}
              timezone={getValue(booking, "timezone")}
              variant={bookingStatus === BOOKING_STATUS.COMPLETED ? "offline" : "active"}
            />
          </>
        )}

        {bookingStatus === BOOKING_STATUS.NEW && (
          <Box style={{ ...styles.flex, flexDirection: "row", marginBottom: 8 }}>
            <Box
              width={"24px"}
              height={"24px"}
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <img src={DateTimeIcon} alt="Treatment icon" />
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <Box>
                <Box style={styles.label}>Preferred date and time</Box>
                <Box style={styles.treatmentLabel}>{getFormattedDateTime({ booking })}</Box>
              </Box>
              {hasBackupDate ? (
                <Box>
                  <Box style={styles.label}>Backup date and time</Box>
                  <Box style={styles.treatmentLabel}>{getFormattedBackupDate({ booking })}</Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            {allowDetailUpdate && (
              <TextButton
                text="Change"
                onClick={() => setShowArrivalTimeModal(true)}
                type="primary"
                textStyle={{ padding: "0px", fontSize: FontSize.F16 }}
              />
            )}
          </Box>
        )}
      </Box>

      {pendingBookingDeadline && !isPaidBooking && (
        <UnpaidBookingInfo deadline={pendingBookingDeadline} />
      )}
      <Divider />

      <AddressSection
        userId={userId}
        address={address}
        status={bookingStatus}
        jobStatus={jobStatus}
        hideSensativeData={hideSensativeData}
        showLocationDetails={showLocationDetails}
        openMapModal={openMapModal}
        isHairAndMakeup={isHairAndMakeup}
        allowAddressUpdate={allowDetailUpdate}
      />

      <DateAndTimeModal
        visible={showArrivalTimeModal}
        isMobile={isMobile}
        isTab={isTab}
        onClose={() => toggleArrivalTimeModal(false)}
        jobId={job.id?.toString()}
        offeredTime={booking?.timeOfArrival}
        timezone={getValue(booking, "timezone")}
        label="Time of arrival"
        actionName="Save"
        onConfirm={onArrivalTimeSave}
        maxMinutes={maxMinutes}
        minMinutes={minMinutes}
        restrictTimeOptions={false}
        initialSelectedDay={booking?.timeOfArrival}
      />
    </Box>
  );
};

const styles = {
  flex: {
    display: "flex",
    gap: "16px",
  },
  person: {
    lineHeight: "24px",
    color: Colors.Indigo,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 600,
  },
  treatmentLabel: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: 8,
  },
  label: {
    fontFamily: "Museo",
    fontWeight: 400,
    fontSize: "16px",
    color: Colors.Grey,
  },
  bookingDetailContainer: {
    ...cardWrapperStyles.card,
    padding: "24px",
    flexDirection: "column",
    gap: "16px",
  },
};

export default BookingDetails;
