import { Box } from "@material-ui/core";
import { styles } from "./singleSlot.styles";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { getTimeTitle } from "../../../utils/date.util";
import Button, { ButtonSize, ButtonType } from "../../Button";
import { BookingSlot } from "../../../stores/V2/corporate/interface";

type Props = {
  slot: BookingSlot;
  onPress: any;
  isAdmin?: boolean;
  onPressConfirm: any;
  onPressRemove?: any;
  isSelected?: boolean;
  allowRemove?: boolean;
  bookedBy?: string | null;
  timeZone: string;
  isDisabled?: boolean;
};

const SingleSlot = ({
  slot,
  isAdmin = false,
  isSelected = false,
  onPress = () => {},
  allowRemove = false,
  onPressRemove = () => {},
  onPressConfirm = () => {},
  timeZone,
  isDisabled = false,
}: Props) => {
  const isMobile = useMobile();
  if (!slot) return <></>;

  if (slot.type !== null) return <></>;

  const shouldShowConfirm = !!isSelected && !slot.isReserved;
  const isSelectedByAdmin = isAdmin && !!isSelected;

  let timeTitle = getTimeTitle(slot.start || "", timeZone, false) || "";
  let timeSubtitle = "";

  const recipient = slot.recipient
    ? `${slot.recipient?.firstName} ${slot.recipient?.lastName}`
    : `${slot.user?.firstName} ${slot.user?.lastName}`;

  if (slot?.isReserved) {
    timeSubtitle = (isAdmin && recipient) || "Booked";
  }

  if (isSelectedByAdmin) {
    timeTitle = "Cancel";
    timeSubtitle = "";
  }

  return (
    <Box
      style={{
        display: "flex",
        marginBottom: "16px",
        width: isMobile ? "100vw" : "245px",
        justifyContent: "space-between",
        gap: "16px",
      }}
    >
      <Button
        fontStyle={styles.fontStyle}
        style={{
          ...styles.buttonBase,
          backgroundColor: slot.isReserved ? Colors.CornflowerBlue : Colors.White,
        }}
        subTitleStyle={styles.subTitle}
        type={ButtonType.white}
        size={ButtonSize.small}
        title={timeTitle}
        subTitle={timeSubtitle}
        onClick={() => onPress(slot)}
      />
      {!!isSelected && (
        <>
          {shouldShowConfirm && (
            <Button
              type={isDisabled ? ButtonType.lightSteel : ButtonType.secondary}
              size={ButtonSize.large}
              title="Confirm"
              onClick={() => onPressConfirm(slot)}
              disabled={isDisabled}
            />
          )}

          {!shouldShowConfirm && (isAdmin || allowRemove) && (
            <Button
              style={styles.removeBtn}
              size={ButtonSize.large}
              title="Remove"
              onClick={() => onPressRemove(slot)}
              disabled={isDisabled}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default SingleSlot;
