import { isNil } from "lodash";
import React from "react";
import { Booking, Recipient } from "../../../../models";
import RecipientPreferenceModals from "./RecipientPreferenceModal";
import { useAddRecipientPreference } from "../../../../hooks/recipient/recipient.hooks";
import { useAlertStore } from "../../../../stores/alert";
import { parseApiV2Error } from "../../../../helpers/error";

interface BookingReviewRecipientPreferenceModalProps {
  booking?: Booking;
  recipient: Recipient;
  open: boolean;
  onClose: () => void;
  onUpdate: (recipientPreference: any) => void;
  serviceId: number | null;
  preference?: any;
}

const BookingReviewRecipientPreferenceModal: React.FC<
  BookingReviewRecipientPreferenceModalProps
> = ({ booking, recipient, open, onClose, serviceId, preference, onUpdate }) => {
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  // add and updates are the same
  const { mutate: addPreferences, isLoading: addingPreference } = useAddRecipientPreference({
    onSuccess: (res: any) => {
      onUpdate(res.data);
      onClose();
      setSuccessMessage(
        preference ? "Updated preference for the recipient2" : "Added preference for the recipient"
      );
    },
    onError: (err: any) => {
      setErrorMessage(parseApiV2Error(err) || "Failed to add preference.");
    },
  });

  const handleSave = (data: any) => {
    addPreferences(data);
  };

  return (
    <RecipientPreferenceModals
      onClose={onClose}
      open={open}
      onSave={handleSave}
      recipient={recipient}
      setSelectedInstruction={() => {}}
      serviceSelectionDisabled={!!serviceId}
      immediateUpdate={isNil(booking)}
      selectedInstruction={preference}
      selectedServiceId={serviceId}
      resetOnClose={false}
      isLoading={addingPreference}
    />
  );
};

export default BookingReviewRecipientPreferenceModal;
