import React from "react";
import { Box } from "@mui/material";
import AvailableBookingCard from "./AvailableBookingCard";
import { Job } from "../../../models";
import "../../../components/ScrollBar.css";
import { useMobile, useTab } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { FontSize, FontWeight, Spacing } from "../../../components/v2/Styled/enum";
import { Link, Typography } from "@material-ui/core";
import { Paths } from "../../../constants/paths";

interface Props {
  jobs?: Job[];
  onClick: Function;
  selectedJobId: number;
}

const AvailableBookingCardHolder = (props: Props) => {
  const { jobs, onClick, selectedJobId } = props;
  const isMobile = useMobile();
  const isTab = useTab();
  const boxProps = !(isMobile || isTab) ? { borderLeft: 1, borderRight: 1 } : {};

  return (
    <Box
      {...boxProps}
      borderColor={Colors.Mischka}
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
      rowGap={isMobile || isTab ? "16px" : "0px"}
      marginY={isMobile || isTab ? "24px" : "0px"}
    >
      {jobs &&
        jobs.map((job: Job) => (
          <Box>
            <AvailableBookingCard
              key={job.id}
              job={job}
              onClick={onClick}
              selectedJobId={selectedJobId}
            />
          </Box>
        ))}
      {jobs.length <= 3 && (
        <Box padding={Spacing.S6}>
          <Typography
            style={{ color: Colors.Dusk, fontSize: FontSize.F14, fontWeight: FontWeight.Regular }}
          >
            <Link href={Paths.Login} style={{ color: Colors.TurquoiseBlue }} underline="none">
              Log in
            </Link>{" "}
            to get instant access to all new bookings with detailed information. Dont have an
            account?
            <Link href={Paths.ProSignup} style={{ color: Colors.TurquoiseBlue }} underline="none">
              {" "}
              Create account{" "}
            </Link>{" "}
            now!
          </Typography>
        </Box>
      )}
      {/* <AvailableBookingCard/> */}
    </Box>
  );
};

export default AvailableBookingCardHolder;
