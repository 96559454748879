import { Box } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useRef } from "react";
import { Colors } from "../../../constants/colors";
import Dropdown from "../../../components/Dropdown";
import { useAlertStore } from "../../../stores/alert";
import TextField from "../../../components/TextField";
import ActionModal from "../../../components/ActionModal";
import { parseApiV2Error } from "../../../helpers/error";
import Button, { ButtonType } from "../../../components/Button";
import UploadedFileRow from "../../../components/ProDashboard/UploadedFileRow";
import {
  invalidateMembership,
  validateIndustryMembership,
} from "../../../services/industry/industryMembership.service";
import {
  useAddMembership,
  useDeleteMembership,
  useGetMembershipOptions,
  useGetMemberships,
} from "../../../hooks/industrymembership.hooks";
import { allowUploadDocumentExtension, filterImageAndApplicationExtension } from "../../../helpers/services";
interface IndustryMembershipProps {
  renderOldMemberships?: () => any;
}

type OptionType = {
  title: string;
  value: string;
};

type MembershipType = {
  title: string;
  value: string;
  options: OptionType[];
};

const IndustryMembership: React.FC<IndustryMembershipProps> = ({ renderOldMemberships }) => {
  const [expiryDate, setExpireDate] = React.useState<string | null>(null);
  const [options, setOptions] = React.useState<OptionType[]>([]);
  const [membershipNumber, setMembershipNumber] = React.useState<string>("");
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const [selectedIndustry, setSelectedIndustry] = React.useState<MembershipType | null>(null);
  const [selectedMember, setSelectedMember] = React.useState<OptionType | null>(null);
  const [files, setFiles] = React.useState<File | null>(null);
  const [membershipToRemove, setMembershipToRemove] = React.useState<any>(null);
  const { addMembership, isLoading } = useAddMembership({
    onSuccess: () => {
      setSuccessMessage("Membership added successfully");
      invalidateMembership();
      setFiles(null);
      setExpireDate(null);
      setMembershipNumber("");
      setSelectedIndustry(null);
      setSelectedMember(null);
    },
    onError: (error) => {
      setErrorMessage(parseApiV2Error(error) || "Failed to add membership");
    },
  });
  const { data: memberships } = useGetMemberships();
  const { deleteMembership, isLoading: isDeleteLoading } = useDeleteMembership({
    onSuccess: () => {
      setSuccessMessage("Membership removed successfully");
      invalidateMembership();
    },
    onError: (error) => {
      setErrorMessage(parseApiV2Error(error) || "Failed to remove membership");
    },
  });
  const { data: membershipOptions, isLoading: isloadingOptions } = useGetMembershipOptions();
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleMembershipChange = (val: any) => {
    setSelectedIndustry(val);
    setSelectedMember(null);
  };

  useEffect(() => {
    if (membershipOptions) {
      const opt: MembershipType[] = [];
      Object.entries(membershipOptions).forEach(([key, value]) => {
        opt.push({
          title: key,
          value: key,
          options: Array.isArray(value)
            ? value.map((option: any) => ({ title: option.name, value: option.id }))
            : [],
        });
      });
      setOptions(opt);
    }
  }, [membershipOptions]);

  const handleSubmit = () => {
    const message = validateIndustryMembership({
      files,
      selectedIndustry,
      selectedMember,
      membershipNumber,
      expiryDate,
    });

    if (message) {
      setErrorMessage(message);
      return;
    }

    const form = new FormData();
    form.append("file", files as Blob);
    form.append("industry", selectedIndustry?.value as string);
    form.append("qualificationId", selectedMember?.value as string);
    form.append("membershipId", membershipNumber);
    form.append("expirationDate", expiryDate as string);

    addMembership(form);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event?.target?.files && event.target.files[0];
    if(!filterImageAndApplicationExtension(file)){
      setErrorMessage("Unsupported file type. Please upload a PNG, JPG, JPEG or PDF file.");
      return;
    }
    if (file) {
      setFiles(file);
    }
  };

  const oldMemberships = renderOldMemberships && renderOldMemberships();

  return (
    <>
      <Box fontFamily="Museo" fontSize="24px" fontWeight={600} color={Colors.NightBlue} mt="56px">
        Industry membership (optional)
      </Box>

      <Box
        fontFamily="Museo"
        fontSize="16px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="24px"
        lineHeight="24px"
      >
        If you are a member of an industry organisation, please upload all valid documentation.
        Scanned or photos of documents are accepted.
      </Box>
      {(memberships?.length || oldMemberships) && (
        <Box
          fontFamily="Museo"
          fontWeight={600}
          fontSize="18px"
          lineHeight="27px"
          color={Colors.Dusk}
          mt="32px"
          // mb="32px"
        >
          Added memberships
        </Box>
      )}
      {/* List of files */}

      {memberships?.map((membership: any) => (
        <UploadedFileRow
          file={{ filePath: membership.membershipDocument } as any}
          containsFullUrl
          onRemove={() => {
            setMembershipToRemove(membership);
          }}
        />
      ))}
      {oldMemberships}
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="18px"
        lineHeight="27px"
        color={Colors.Dusk}
        mt="32px"
        // mb="32px"
      >
        Add new membership
      </Box>

      <>
        <Dropdown
          title={"Industry"}
          isDisabled={isloadingOptions}
          options={options}
          onSelectedOption={handleMembershipChange}
          selectedOption={selectedIndustry as MembershipType}
        />
        {selectedIndustry && (
          <Dropdown
            title={"Membership type"}
            options={selectedIndustry.options}
            onSelectedOption={(option) => setSelectedMember(option)}
            selectedOption={selectedMember}
          />
        )}

        {selectedIndustry && (
          <>
            {" "}
            <TextField
              title="Membership number"
              value={membershipNumber}
              onChange={(text) => setMembershipNumber(text)}
            />
            <TextField
              title="Expiry date"
              type="date"
              value={expiryDate}
              onChange={(date) => setExpireDate(date)}
            />
          </>
        )}
      </>

      {files && (
        <UploadedFileRow
          file={{ filePath: URL.createObjectURL(files) } as any}
          onRemove={() => setFiles(null)}
          containsFullUrl
        />
      )}

      <Box mt="20px" mb="32px">
        <Button
          title="Upload file"
          type={ButtonType.outlined}
          width="132px"
          onClick={() => {
            if (uploadRef.current) {
              uploadRef.current.click();
            }
          }}
        />
      </Box>
      <input
        type="file"
        id="industry-membership-upload-file"
        ref={uploadRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept={allowUploadDocumentExtension()}
      />

      <Button title="Add membership" loading={isLoading} onClick={handleSubmit} />

      <ActionModal
        open={!!membershipToRemove}
        title={"Remove file"}
        description={
          "Please confirm if you want to remove this file. This action can’t be undone. If you want to upload it again, you’ll need to reselect the file."
        }
        onCancel={() => {
          setMembershipToRemove(null);
        }}
        onConfirm={() => {
          deleteMembership(membershipToRemove.id);
          setMembershipToRemove(null);
        }}
        confirmText="Confirm"
        cancelText="Back"
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default IndustryMembership;
