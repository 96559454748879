import { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "react-day-picker/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import "../../styles/DayPicker.css";
import { RootState } from "../../stores/V2";
import MassageDetails from "./MassageDetails";
import { useMobile } from "../../hooks/mobile";
import { useAlertStore } from "../../stores/alert";
import ReviewWrapper from "./Wrapper/ReviewWrapper";
import { STEP_PROGRESS } from "../../constants/booking";
import HairAndMakeupDetails from "./HairAndMakeupDetails";
import Button, { ButtonType } from "../../components/Button";
import { trackEvent } from "../../services/segment/track.service";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import { getBookingPrice } from "../../stores/V2/booking/booking.transformer";
import { QUESTION_TYPE } from "../../constants/questionType";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import { checkIfEmpty, getValue } from "../../utils/object";
import { DEFAULT_COUNTRY } from "../../utils/country";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import { ServiceRate } from "../../models";
import AlertModal from "../../components/AlertModal";
import { INFO_MESSAGES } from "../../constants/message";

interface Params {
  id?: string;
}

function ReviewTreatment() {
  const { id } = useParams<Params>();

  const history = useHistory();
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const [infoModalText, setInfoModalText] = useState("");
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const { setErrorMessage } = useAlertStore();
  const state = useSelector(({ booking }: RootState) => booking);
  const { selectedService, selectedSessionType } = state;

  const bookingCountryCode = getValue(state, "address.countryCode") || DEFAULT_COUNTRY;
  const { services } = useServiceRates(bookingCountryCode);

  useEffect(() => {
    trackEvent("Review treatment type", {
      step: 4,
      version: 2,
    });
  }, []);

  useEffect(() => {
    updatePrice();
  }, [state.bookingDetails]);

  const updatePrice = async () => {
    const price = await getBookingPrice(state);
    if (price) {
      dispatch(bookingActions.updateBookingPrice({ price }));
    } else {
      setErrorMessage("Unable to load booking price. Please Try again.");
    }
  };

  const handleBack = () => history.replace(`/my-bookings/review/${id}`, { initialize: false });

  const verifyFields = () => {
    const { bookingDetails } = state;

    let isValid = true;
    if (selectedService && bookingDetails) {
      const { isMassage } = selectedService;
      if (!isMassage) {
        // verify questions
        for (let i = 0; i < bookingDetails.length; i++) {
          const jobTreatments = bookingDetails[i].treatmentDetails;
          for (let j = 0; j < jobTreatments.length; j++) {
            const questions = jobTreatments[j].questions;
            const validAnswers = verifyQuestionAnswers(questions);
            if (!validAnswers) {
              isValid = false;
              break;
            }
          }
          if (!isValid) break;
        }
      }
    }
    return isValid;
  };

  const verifyQuestionAnswers = (questions: any) => {
    let isValid = true;
    for (let i = 0; i < questions.length; i++) {
      const { type, answer: selectedAnswer, required, question } = questions[i];
      if (!required) continue;

      const { answer, answers } = selectedAnswer;
      if ([QUESTION_TYPE.SELECT, QUESTION_TYPE.DURATION].includes(type) && !answer) {
        setErrorMessage(`Please select answer for ${question}`);
        isValid = false;
        break;
      } else if (type === QUESTION_TYPE.IMAGE && (!answers || answers.length <= 0)) {
        setErrorMessage(`Please add image for ${question}`);
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const handleContinue = () => {
    // verify fields here
    const isValid = verifyFields();
    if (!isValid) return;
    history.replace(`/my-bookings/review/${id}`, { initialize: false });
  };

  const getServiceOptions = () => {
    if (checkIfEmpty(services)) return [];

    return services.map(({ alias, id }) => ({ value: id, title: alias }));
  };

  const serviceOptions = getServiceOptions();

  const getSelectedServiceOption = () =>
    serviceOptions.find(({ value }) => value === selectedService.id);

  const validateServiceChange = (service: ServiceRate) => {
    let isValid = true;
    let message;

    const isCorporate = getValue(service, "prices[0].isCorporate");
    if (isCorporate) {
      isValid = false;
      message = INFO_MESSAGES.corporateServiceUpdate;
    }

    if (selectedService.isMassage && selectedSessionType.isCouples) {
      isValid = false;
      message = INFO_MESSAGES.couplesServiceUpdate;
    }

    return { isValid, message };
  };

  const onServiceSelected = (service: DropdownOption) => {
    const serviceId = service.value;
    if (serviceId === selectedService.id) return;

    const newService = services.find(({ id }) => id === serviceId);
    if (!newService || checkIfEmpty(newService.prices)) return;

    const { isValid, message } = validateServiceChange(newService);
    if (!isValid) {
      if (message) {
        setInfoModalText(message);
        setInfoModalVisible(true);
      }
      return;
    }

    dispatch(bookingActions.updateService({ serviceId }));
  };

  return (
    <ReviewWrapper
      buttons={[
        <Button title={"Back"} onClick={handleBack} type={ButtonType.outlined} />,
        <Button title={"Continue"} type={ButtonType.secondary} onClick={handleContinue} />,
      ]}
      progress={STEP_PROGRESS[2]}
      title="Booking details"
      label="Step 2 of 7"
      onClose={handleBack}
    >
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} marginTop="30px">
        <Box
          width="100%"
          mt="16px"
          display={isMobile ? "flex" : undefined}
          alignItems={isMobile ? "center" : undefined}
          flexDirection={isMobile ? "column" : undefined}
        >
          <Box width={isMobile ? "100%" : "50%"}>
            <Dropdown
              title="Service type"
              options={serviceOptions}
              selectedOption={getSelectedServiceOption()}
              onSelectedOption={onServiceSelected}
            />
          </Box>
          {state.selectedService.isMassage ? <MassageDetails /> : <HairAndMakeupDetails />}
        </Box>
      </Box>
      <AlertModal
        title="Service Type"
        open={infoModalVisible}
        message={infoModalText}
        onClose={() => setInfoModalVisible(false)}
      />
    </ReviewWrapper>
  );
}

export default ReviewTreatment;
