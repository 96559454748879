import { Box, ButtonBase } from "@material-ui/core";
import { isEmpty, isNil, get } from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { NavbarElementProps } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonType } from "../../components/Button";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import { BUFFER_TIME_HOURS, STEP_PROGRESS } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { parseApiError } from "../../helpers/error";
import { minutesToTime } from "../../helpers/time";
import dateConfig from "../../config/dateAndTime.json";
import CalendarNext from "../../images/calendar-next.png";
import CalendarPrevious from "../../images/calendar-previous.png";
import { trackEvent } from "../../services/segment/track.service";
import { useAlertStore } from "../../stores/alert";
import DateAndTime from "./DateAndTime";

import {
  generateHandleNext,
  isCorporateTreatmentType,
  updateBooking,
  useBookingStore,
  useDateTimeStore,
} from "../../stores/booking";
import "../../styles/DayPicker.css";
import {
  getCurrentTimeStamp,
  convertDatetoTimezoneMoment,
  isDateObjectToday,
  getCurrentDayInTimezone,
  isSameDate,
} from "../../utils/date.util";
import pixelsService from "../../services/pixels/pixels.service";

export function DayPickerNavbar({
  month,
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
}: NavbarElementProps) {
  const buttonStyle = {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
  };

  return (
    <div className={className}>
      <Box display="flex" justifyContent="space-between" pl="22px" pr="22px">
        <Box fontFamily="Museo" fontSize="20px" fontWeight={700} color={Colors.Dusk}>
          {moment(month).format("MMMM YYYY")}
        </Box>

        <Box display="flex" flexDirection="row">
          <ButtonBase style={buttonStyle} onClick={() => onPreviousClick()}>
            <img src={CalendarPrevious} />
          </ButtonBase>
          <ButtonBase style={buttonStyle} onClick={() => onNextClick()}>
            <img src={CalendarNext} />
          </ButtonBase>
        </Box>
      </Box>
    </div>
  );
}

export default function DateAndTimeWrapper(): JSX.Element {
  const history = useHistory();
  const { state } = useLocation();
  const next = get(state, "next");

  useEffect(() => {
    pixelsService.trackPageView();
  }, []);

  const {
    selectedDay,
    earliestStartInMinutes,
    latestStartInMinutes,
    backupSelectedDay,
    backupEarliestStartInMinutes,
    backupLatestStartInMinutes,
    selectedDayRange,
  } = useDateTimeStore();

  const { setErrorMessage } = useAlertStore();

  const { updatingForBooking, address } = useBookingStore();

  const addressTimezone = address?.timezone;

  const isCorporateType = isCorporateTreatmentType();

  const labels = isCorporateType ? dateConfig["corporate"] : dateConfig["*"];

  const [saving, setSaving] = useState(false);
  const [showBackUpDate, setShowBackUpDate] = useState(false);

  const oneYearFromNow = getCurrentDayInTimezone(addressTimezone);
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const getSelectedDateTime = (selectedDay: Date, timeInMinutes: number, timezone?: string) => {
    const { hour, mins } = minutesToTime(timeInMinutes);
    const timeMoment = convertDatetoTimezoneMoment(selectedDay, timezone);
    return timeMoment.set({
      hours: hour,
      minutes: mins,
      seconds: 0,
      milliseconds: 0,
    });
  };

  const verifyFields = () => {
    if (isNil(selectedDay)) {
      setErrorMessage("Please select a date.");
      return false;
    }

    if (isNil(earliestStartInMinutes)) {
      setErrorMessage("Please select earliest start time.");
      return false;
    }

    if (isNil(latestStartInMinutes) || isNaN(latestStartInMinutes)) {
      setErrorMessage("Please select latest start time.");
      return false;
    }

    const currentTime = addressTimezone ? getCurrentTimeStamp(addressTimezone) : moment();

    const earliestDate = selectedDayRange.from;
    const selectedTimeMoment = getSelectedDateTime(
      earliestDate,
      earliestStartInMinutes,
      addressTimezone
    );
    const selectedTimeMomentLatest = getSelectedDateTime(
      earliestDate,
      latestStartInMinutes,
      addressTimezone
    );

    const isSameDay = isDateObjectToday(earliestDate, addressTimezone, "date");
    const isRangeDate = !isSameDate(selectedDayRange.from, selectedDayRange.to, addressTimezone);

    // skip for ranged bookings
    // no buffer in earliestTime and cannot be less than current time
    if (!isRangeDate && isSameDay && selectedTimeMoment.isBefore(currentTime)) {
      setErrorMessage("Earliest time is too early.");
      return false;
    }

    // 1 hour buffer in latest time wrt current time
    if (
      !updatingForBooking?.isBookedByPro &&
      isSameDay &&
      selectedTimeMomentLatest.isBefore(currentTime.add(BUFFER_TIME_HOURS, "hours"))
    ) {
      setErrorMessage("Latest time is too early.");
      return false;
    }

    if (latestStartInMinutes < earliestStartInMinutes) {
      setErrorMessage("Latest start cannot be earlier than earliest start.");
      return false;
    }

    if (showBackUpDate) {
      if (isNil(backupSelectedDay)) {
        setErrorMessage("Please select backup a date.");
        return false;
      }

      if (isNil(backupEarliestStartInMinutes)) {
        setErrorMessage("Please select backup earliest start time.");
        return false;
      }

      if (isNil(backupLatestStartInMinutes) || isNaN(backupLatestStartInMinutes)) {
        setErrorMessage("Please select backup latest start time.");
        return false;
      }

      if (backupLatestStartInMinutes < backupEarliestStartInMinutes) {
        setErrorMessage("Backup Latest start cannot be earlier than backup earliest start time.");
        return false;
      }
    }

    return true;
  };

  const handleBack = () => {
    const accessToken = localStorage.getItem("token");
    if (!isEmpty(accessToken)) {
      history.push(Paths.SavedRecipients);
    } else {
      history.push(Paths.RecipientDetails);
    }
  };

  const handleNext = () => {
    if (!verifyFields()) {
      return;
    }

    trackEvent("Date Time Completed", {
      step: 4,
      version: 2,
    });

    if (updatingForBooking) {
      setSaving(true);
      updateBooking()
        .then(() => {
          setSaving(false);

          history.replace(`/my-bookings/${updatingForBooking?.id}`);
        })
        .catch((error) => {
          setSaving(false);
          setErrorMessage(parseApiError(error));
        });
    } else {
      const nextPath = next || Paths.BookingFrequency;
      const isEditing = get(state, "editing");
      history.replace(nextPath, { preFetchPaymentMethod: false, isEditing });
    }
  };

  const handleNextObject = generateHandleNext(handleNext);

  return (
    <NewBookingWrapper
      overline="Step 4 of 7"
      progress={STEP_PROGRESS[4]}
      title={labels.title}
      footerLeftButton={
        <Button
          type={ButtonType.outlined}
          title={updatingForBooking ? "Back" : "Previous"}
          onClick={() => handleBack()}
          style={{ padding: "12px 24px" }}
        />
      }
      nextGeneratorObject={handleNextObject}
      footerRightButton={
        <Button
          loading={saving}
          type={updatingForBooking ? ButtonType.secondary : ButtonType.indigo}
          title={updatingForBooking ? "Save & apply" : "Continue"}
          onClick={() => handleNextObject.next()}
          style={{ padding: "12px 24px" }}
        />
      }
    >
      <DateAndTime />
    </NewBookingWrapper>
  );
}
