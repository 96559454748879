import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Box } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import { RecipientWithSummary } from "../../../models";
import TextField from "../../../components/TextField";
import { useMobile } from "../../../hooks/mobile";
import Dropdown from "../../../components/Dropdown";
import { useAccessToken } from "../../../hooks/common";
import { put } from "../../../api/client";
import ProfileImage from "./ProfileImage";
import RecipientSummary from "./RecipientSummary";
import { isArray } from "../../../utils/array";
import { useUserRole } from "../../../hooks/user.hooks";
import { invalidateQueries } from "../../../services/query";
import { useGetAllowDirectProChangeStatus } from "../../../hooks/settings/bookings.hooks";
import { SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST } from "../../../constants/common";
import MobileTextField from "../../../components/MobileTextField";
import { AllRelationships, MassageFor, Relationship } from "../../../constants/booking";
import { getCountryCallingCode, getCountryCode } from "../../../helpers/mobile";
import { useAlertStore } from "../../../stores/alert";
import { parseApiError } from "../../../helpers/error";
import CovidVaccinationInfo, { MessageType } from "../../../components/CovidVaccinationInfo";
import { removeSpaces } from "../../../helpers/string";
import { useBookingStore } from "../../../stores/booking";
import Gender from "../../../components/Gender";
import isValidEmail from "../../../helpers/validation";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import GuardianForm from "../../../components/Recipients/Guardian";
import { getValue, checkIfEmpty } from "../../../utils/object";
import { NOTE_FOR_THERAPIST_PLACHOLDER } from "../../../constants/placeholder";
import BillingIdentiferItem from "../../../components/AdditionalBillingIdentifiers/BillingIdentifierItem";
import { getBillingIdentifierConfig } from "../../../services/additionalBillingIdentifier/additionalBillingIdentifier.service";
import Contraindications from "../../../components/Contraindications";
import { getCountryCode as getCountryCodeFromIpAddress } from "../../../utils/country";
import { PointOfContactInfo } from "../../../components/PointOfContactInfo/PointOfContactInfo";
import AutoUpdateSetting from "../../../components/BookingSettings/AutoUpdateSetting";
import { FontSize, Spacing } from "../../../components/v2/Styled/enum";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import TextButton from "../../../components/TextButton/TextButton";
import RemoveRecipient from "./RemoveRecipient";
import { isEmptyOrWhitespace } from "../../../helpers/services";

interface RecipientFormProps {
  recipient: RecipientWithSummary | null;
  recipientId: number;
}

const relationshipOptions = AllRelationships.map((relationship) => {
  return {
    value: relationship,
    title: Relationship.toString(relationship),
  };
});

export const RecipientForm: React.FC<RecipientFormProps> = ({
  recipient: existingRecipient,
  recipientId,
}) => {
  const isMobile = useMobile();
  const accessToken = useAccessToken();
  const { isHomeCareClient } = useUserRole();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [relationship, setRelationship] = useState<Relationship | null>(null);
  const [gender, setGender] = useState("");
  const [notes, setNotes] = useState("");
  const [hasContraindications, setHasContraindications] = useState<null | boolean>(null);
  const [contraindications, setContraindications] = useState<null | string>(null);
  const [isCovidVaccinated, setIsCovidVaccinated] = useState(false);
  const [customerId, setCustomerId] = useState<number | string>("");
  const [orderNumber, setOrderNumber] = useState("");
  const [lineItemCode, setLineItemCode] = useState("");
  const [saving, setSaving] = useState(false);

  // guradian related data
  const [guardianFirstName, setGuardianFirstName] = useState("");
  const [guardianLastName, setGuardianLastName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [guardianMobile, setGuardianMobile] = useState("");
  const [guardianCountryCode, setGuardianCountryCode] = useState("");
  const [isRecipientPOC, setIsRecipientPOC] = useState(false);
  const [isGuardianPOC, setIsGuardianPOC] = useState(false);
  const [autoUpdateEnabled, setAutoUpdate] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    if (!isEmpty(acceptedFiles)) {
      setSelectedImageFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });
  const history = useHistory();
  const { user } = useUserStore();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { setRecipient } = useBookingStore();
  const { data: userBookingSettings } = useGetAllowDirectProChangeStatus();

  const canAddRecipientsContactPerson = getValue(user, "permission.canAddRecipientsContactPerson");

  const { recipientDetailConfig } = getBillingIdentifierConfig(user);

  const setCountryDialingCode = async () => {
    const countryCode = await getCountryCodeFromIpAddress();
    const dailingCode = getCountryCallingCode(countryCode);
    setCountryCode(`+${dailingCode}`);
    setGuardianCountryCode(`+${dailingCode}`);
  };

  useEffect(() => {
    const userUpdateSetting = getValue(userBookingSettings, "allowDirectProChange");
    const defaultAutoUpdateSetting = isNil(userUpdateSetting) ? false : userUpdateSetting;

    if (!isEmpty(existingRecipient) && !!existingRecipient) {
      setFirstName(existingRecipient.firstName);
      setLastName(existingRecipient.lastName);
      setEmail(existingRecipient.email);

      const autoUpdate = isNil(existingRecipient.autoUpdateBooking)
        ? defaultAutoUpdateSetting
        : existingRecipient.autoUpdateBooking;
      setAutoUpdate(autoUpdate);

      const mobileCountryCodeData = getCountryCode(existingRecipient.mobile);

      setCountryCode(mobileCountryCodeData?.countryCode || "");
      setMobile(mobileCountryCodeData?.withoutCountryCode || "");
      setRelationship(existingRecipient.relationship);
      setGender(existingRecipient.gender);
      setNotes(existingRecipient.specialInstructions);
      setIsCovidVaccinated(existingRecipient.isCovidVaccinated || false);
      setCustomerId(existingRecipient?.customerId || "");
      setOrderNumber(existingRecipient?.orderNumber || "");
      setLineItemCode(existingRecipient?.lineItemCode || "");
      setContraindications(existingRecipient?.contraindications || null);
      setHasContraindications(existingRecipient?.hasContraindications);
      setIsGuardianPOC(existingRecipient?.primaryContactUserId !== existingRecipient?.aliasUserId);

      if (existingRecipient.aliasUserId) {
        setIsRecipientPOC(
          existingRecipient?.aliasUserId === existingRecipient?.primaryContactUserId
        );
      }

      const aliasUserId = getValue(existingRecipient, "aliasUserId", null);
      const primaryContactUserId = getValue(existingRecipient, "primaryContactUserId", null);
      const isRecipientPOC =
        (aliasUserId && primaryContactUserId && aliasUserId === primaryContactUserId) || false;
      setIsRecipientPOC(isRecipientPOC);
      if (existingRecipient?.contactPerson) {
        let contactPersonList = getValue(existingRecipient, "contactPerson", []);
        if (contactPersonList) {
          contactPersonList = isArray(contactPersonList) ? contactPersonList : [contactPersonList];
        }

        const guardianOrNextOfKin = contactPersonList.find(
          (contact: any) => !!getValue(contact, "firstName", "") && !getValue(contact, "invitedBy")
        );

        const { firstName, lastName, email, countryCode, isPrimaryPointOfContact } =
          guardianOrNextOfKin || {};

        let { mobile: guardianMobile } = guardianOrNextOfKin || {};

        if (countryCode && guardianMobile) {
          const formattedGuardianMobile = getCountryCode(`${countryCode}${guardianMobile}`);
          if (formattedGuardianMobile) guardianMobile = formattedGuardianMobile.withoutCountryCode;
        }

        setGuardianFirstName(firstName);
        setGuardianLastName(lastName);
        setGuardianEmail(email);
        setGuardianCountryCode(countryCode);
        setGuardianMobile(guardianMobile);
        setIsGuardianPOC(isPrimaryPointOfContact || false);
      } else {
        setGuardianFirstName("");
        setGuardianEmail("");
        setGuardianLastName("");
        setGuardianCountryCode("");
        setGuardianMobile("");
        setIsGuardianPOC(false);
      }
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setMobile("");
      setRelationship(null);
      setGender("");
      setNotes("");
      setIsCovidVaccinated(false);
      setCustomerId("");
      setOrderNumber("");
      setLineItemCode("");
      setGuardianFirstName("");
      setGuardianEmail("");
      setGuardianLastName("");
      setGuardianMobile("");
      setCountryDialingCode();

      setAutoUpdate(defaultAutoUpdateSetting);
    }
  }, [existingRecipient]);

  useEffect(() => {
    const isUpdating = !checkIfEmpty(existingRecipient);
    if (isUpdating) return;

    const userUpdateSetting = getValue(userBookingSettings, "allowDirectProChange");
    const defaultAutoUpdateSetting = isNil(userUpdateSetting) ? false : userUpdateSetting;

    setAutoUpdate(defaultAutoUpdateSetting);
  }, [existingRecipient]);

  const verifyFields = () => {
    if (isEmptyOrWhitespace(firstName)) {
      setErrorMessage("Please enter their first name");
      setFirstName("");
      return false;
    }
    if (isEmptyOrWhitespace(lastName)) {
      setErrorMessage("Please enter their last name");
      setLastName("");
      return false;
    }

    if (((isHomeCareClient && isRecipientPOC) || !isHomeCareClient) && isEmpty(email)) {
      setErrorMessage("Please enter their email address");
      return false;
    }

    if (email && !isValidEmail(email)) {
      setErrorMessage("Please enter valid email");
      return false;
    }
    if (isEmpty(countryCode)) {
      setErrorMessage("Please enter their country code");
      return false;
    }
    if (isEmpty(mobile)) {
      setErrorMessage("Please enter their mobile");
      return false;
    }
    if (isNil(relationship)) {
      setErrorMessage("Please specify your relationship with them");
      return false;
    }
    if (isEmpty(gender)) {
      setErrorMessage("Please specify their gender");
      return false;
    }

    if (isGuardianPOC) {
      if (!guardianFirstName) {
        setErrorMessage("Please enter the first name of their guardian.");
        return false;
      }
      if (!guardianLastName) {
        setErrorMessage("Please enter the last name of their guardian.");
        return false;
      }
      if (!guardianEmail) {
        setErrorMessage("Please enter the email of their guardian.");
        return false;
      }
      if (guardianEmail && !isValidEmail(guardianEmail)) {
        setErrorMessage("Please enter the valid email of their guardian.");
        return false;
      }
      if (!guardianMobile) {
        setErrorMessage("Please enter the mobile of their guardian.");
        return false;
      }
    }

    return true;
  };

  const uploadRecipientProfilePic = (id: number, file: File) => {
    const form = new FormData();
    form.append("image", file);

    put(`api/v2/recipient/${id}/profileImage`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {})
      .catch((error) => {
        setSaving(false);
        setErrorMessage(parseApiError(error));
      });
  };

  const handleUpdateRecipient = (data: any) => {
    if (!existingRecipient) return;

    const path = `/users/me/recipients/${existingRecipient.id}`;
    axios
      .put(path, data)
      .then((response) => {
        setSaving(false);
        let toastMessage = "Recipient updated.";
        const updatedRecipient = getValue(response, "data");
        const updatedPrimaryContactUserId = getValue(updatedRecipient, "primaryContactUserId");
        const previousPrimaryContactUserId = getValue(existingRecipient, "primaryContactUserId");

        if (
          updatedPrimaryContactUserId &&
          updatedPrimaryContactUserId !== previousPrimaryContactUserId
        ) {
          let firstName = updatedRecipient.firstName;
          const isGuardianPOC = getValue(
            updatedRecipient,
            "contactPerson.isPrimaryPointOfContact",
            ""
          );
          if (isGuardianPOC) {
            firstName = getValue(updatedRecipient, "contactPerson.firstName", "");
          }
          toastMessage = `${toastMessage} We notified ${firstName} that they’re the primary contact.`;
        }
        setSuccessMessage(toastMessage);
        setRecipient(response.data);
        invalidateQueries(["SINGLE_RECIPIENT", existingRecipient.id]);
      })
      .catch((error) => {
        setSaving(false);
        setErrorMessage(parseApiError(error));
      });
  };

  const createNewRecipient = (data: any) => {
    const path = "/users/me/recipients";
    return axios
      .post(path, data)
      .then((response) => {
        setSaving(false);
        const hasPrimaryContact = getValue(response, "data.primaryContactUserId");
        const recipient = getValue(response, "data");
        let firstName = getValue(recipient, "firstName", "");
        const isGuardianPOC = getValue(recipient, "contactPerson.isPrimaryPointOfContact");
        let toastMessage = "Added new recipient.";
        if (isGuardianPOC) {
          firstName = getValue(recipient, "contactPerson.firstName");
        }
        if (hasPrimaryContact) {
          toastMessage = `${toastMessage} We notified ${firstName} that they’re the primary contact.`;
        }
        setSuccessMessage(toastMessage);
        return recipient.id;
      })
      .catch((error) => {
        setSaving(false);

        setErrorMessage(parseApiError(error));
      });
  };

  const handleSave = () => {
    if (!verifyFields()) return;

    const contactNumber = removeSpaces(`${countryCode}${mobile}`);
    const data: any = {
      accessToken,
      firstName,
      lastName,
      mobile: contactNumber,
      email,
      gender,
      relationship,
      specialInstructions: notes,
      isCovidVaccinated,
      contraindications,
      hasContraindications: hasContraindications || false,
      isPrimaryPointOfContact: isRecipientPOC,
      allowAutoUpdate: autoUpdateEnabled,
    };

    if (user?.role) {
      data.orderNumber = orderNumber || null;
      data.customerId = customerId || null;
      data.lineItemCode = lineItemCode || null;
    }

    const guardianInfo = {
      firstName: guardianFirstName || null,
      lastName: guardianLastName || null,
      email: guardianEmail || null,
      countryCode: guardianCountryCode || null,
      mobile: guardianMobile || null,
      relation: "Guardian / Carer / Next of kin",
      isPrimaryPointOfContact: isGuardianPOC,
    };

    data.contactPerson = guardianInfo;

    setSaving(true);

    if (existingRecipient) {
      handleUpdateRecipient(data);
      selectedImageFile && uploadRecipientProfilePic(existingRecipient?.id, selectedImageFile);
    } else {
      createNewRecipient(data).then((recipientId) => {
        selectedImageFile && uploadRecipientProfilePic(recipientId, selectedImageFile);
        history.push(`/recipients/${recipientId}/details`);
      });
    }
  };

  const onAutoUpdateChange = (checked: boolean) => {
    setAutoUpdate(checked);
  };

  return (
    <Box
      marginTop="43px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
      maxWidth="630px"
    >
      <Box width={isMobile ? "100%" : "640px"} borderRadius="11px">
        {existingRecipient ? (
          <RecipientSummary
            recipient={existingRecipient}
            ProfileImageComponent={
              <ProfileImage
                selectedImageFile={selectedImageFile}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                profileImage={existingRecipient.profileImage}
              />
            }
          />
        ) : (
          <ProfileImage
            selectedImageFile={selectedImageFile}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />
        )}
        <Box display="flex" marginTop="40px" flexDirection={isMobile ? "column" : "row"}>
          <TextField
            title="First name"
            value={firstName}
            onChange={(text) => setFirstName(text)}
            placeholder="First name"
          />
          <Box width="60px" />
          <TextField
            title="Last name"
            value={lastName}
            onChange={(text) => setLastName(text)}
            placeholder="Last name"
          />
        </Box>
        <TextField
          title="Email"
          placeholder="recipient@email.com"
          value={email}
          onChange={(text) => setEmail(text)}
          type="email"
        />
        <MobileTextField
          countryCode={countryCode}
          mobile={mobile}
          mobileFieldTitle="Mobile number"
          onCountryCodeChange={(code) => setCountryCode(code)}
          onMobileChange={(text) => setMobile(text)}
        />
        <PointOfContactInfo
          isPointOfContact={isRecipientPOC}
          onCheckBoxChange={(isChecked) => {
            setIsRecipientPOC(isChecked);
            if (isGuardianPOC) {
              setIsGuardianPOC(false);
            }
          }}
          firstName={firstName}
        />
        {/* Allow provider auto update */}
        <AutoUpdateSetting
          checked={autoUpdateEnabled}
          onChange={(checked) => onAutoUpdateChange(checked)}
          text="Allow providers to change this recipient's booking details without confirmation"
          textColor={Colors.Dusk}
          textSize={FontSize.F14}
        />
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <Dropdown
            paddingTop={Spacing.S4}
            paddingBottom={Spacing.S4}
            title="Relationship"
            options={relationshipOptions}
            selectedOption={relationshipOptions.find((option) => option.value === relationship)}
            onSelectedOption={(option) => setRelationship(option.value)}
          />
        </Box>

        <Box>
          <Gender onChange={(text: any) => setGender(text)} value={gender} />
        </Box>
        <Contraindications
          hasContraindications={hasContraindications}
          setHasContraindications={setHasContraindications}
          contraindications={contraindications}
          setContraindications={setContraindications}
          gender={gender}
        />
        <TextField
          title="Notes for therapist"
          subtitle="Your note is visible to providers."
          value={notes}
          onChange={(text) => {
            setNotes(text);
          }}
          multiline
          maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
          placeholder={NOTE_FOR_THERAPIST_PLACHOLDER}
          paddingBottom={Spacing.S4}
          paddingTop={Spacing.S4}
        />

        <CovidVaccinationInfo
          massageFor={MassageFor.someoneElse}
          isCovidVaccinated={isCovidVaccinated}
          type={MessageType.client}
          onCheckBoxChange={(status) => setIsCovidVaccinated(status)}
        />

        {canAddRecipientsContactPerson && (
          <GuardianForm
            guardianCountryCode={guardianCountryCode}
            setGuardianCountryCode={setGuardianCountryCode}
            guardianFirstName={guardianFirstName}
            setGuardianFirstName={setGuardianFirstName}
            isGuardianPOC={isGuardianPOC}
            setIsGuardianPOC={setIsGuardianPOC}
            setIsRecipientPOC={setIsRecipientPOC}
            guardianLastName={guardianLastName}
            setGuardianLastName={setGuardianLastName}
            guardianEmail={guardianEmail}
            setGuardianEmail={setGuardianEmail}
            guardianMobile={guardianMobile}
            setGuardianMobile={setGuardianMobile}
            isMobile={isMobile}
          />
        )}
        {user?.role ? (
          <>
            <Box
              fontFamily="Museo"
              fontSize="24px"
              color={Colors.Dusk}
              fontWeight={600}
              lineHeight="26px"
              mt={Spacing.S8}
              mb={Spacing.S6}
            >
              Additional billing identifiers
            </Box>
            <BillingIdentiferItem
              customerId={String(customerId)}
              orderNumber={orderNumber}
              lineItemCode={lineItemCode}
              setCustomerId={setCustomerId}
              setOrderNo={setOrderNumber}
              setItemCode={setLineItemCode}
              additionalBillConfig={recipientDetailConfig()}
            />
            <Box mb={"16px"} />
          </>
        ) : (
          <></>
        )}
        {/* </Box> */}
        {!!recipientId && (
          <Box
            style={{
              marginTop: "24px",
              marginBottom: "40px",
            }}
          >
            <RemoveRecipient recipientId={recipientId} />
          </Box>
        )}

        <Button
          title="Save"
          type={ButtonType.primary}
          width="128px"
          onClick={() => handleSave()}
          size={ButtonSize.small}
          loading={saving}
        />
      </Box>
    </Box>
  );
};
