import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { PaymentType } from "../constants/payment";
import { BRAINTREE_COUNTRIES } from "../data/enums";
import AddNewPaypalModal from "./Payment/AddNewPaypalModal";
import AddNewCardModelStripe from "./Payment/AddNewCardModelStripe";

export default function MobileAppNewPaymentMethod(): JSX.Element {
  const accessToken = new URLSearchParams(window.location.search).get("accessToken");
  const countryCode = new URLSearchParams(window.location.search).get("countryCode");
  const paymentType = new URLSearchParams(window.location.search).get("paymentType");

  localStorage.setItem("token", accessToken as string);

  const postWebviewMessage = () => {
    window.location.replace("/new-payment-successful");
  };

  return (
    <ErrorBoundary domain={"CLIENT_APP_PAYMENT_METHOD_ADD"}>
      {paymentType === PaymentType.paypal ? (
        <AddNewPaypalModal
          open={true}
          onClose={() => {}}
          onAddedNewCard={() => postWebviewMessage()}
          canAddPaypal={BRAINTREE_COUNTRIES.includes(countryCode || "")}
        />
      ) : (
        <AddNewCardModelStripe
          open={true}
          onClose={() => {}}
          onAddedNewCard={() => postWebviewMessage()}
        />
      )}
    </ErrorBoundary>
  );

  // return (
  //   <AddNewCardModal
  //     open={true}
  //     onClose={() => {}}
  //     onAddedNewCard={() => postWebviewMessage()}
  //     canAddPaypal={BRAINTREE_COUNTRIES.includes(countryCode || "")}
  //   />
  // );
}
