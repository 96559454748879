import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";

import { get } from "../../../../api/client";
import UpdateRequestActions from "../../../../components/Bookings/UpdateRequestActions";
import ChatButton from "../../../../components/Chat/ChatButton";
import TermsAndPoliciesDocuments from "../../../../components/TermsAndPoliciesDocuments/TermsAndPoliciesDocuments";
import { Spacing } from "../../../../components/v2/Styled/enum";
import { JobStatus } from "../../../../constants/booking";
import { Colors } from "../../../../constants/colors";
import { isChatEnableForBooking } from "../../../../hooks/chat/chat.hooks";
import { useMobile } from "../../../../hooks/mobile";
import { useUserRole } from "../../../../hooks/user.hooks";
import { JobAgreement } from "../../../../models";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";
import { ProDocs } from "./ProvidersDocument";
import { isEmpty } from "lodash";

interface Props {
  index: number;
  therapist: any;
  status: any;
  address: {
    latitude: number;
    longitude: number;
  };
  detail: any;
  timeDetail: string;
  onProfileTap: any;
  booking: any;
  bookingMutate: any;
  bookingStatus?: string;
  agreements?: JobAgreement[];
  providersDocuments?: ProDocs[];
}

const BookingDetailCard = ({
  index,
  agreements,
  onProfileTap,
  status,
  therapist,
  detail,
  address,
  timeDetail,
  booking,
  bookingMutate,
  bookingStatus,
  providersDocuments,
}: Props) => {
  const isMobile = useMobile();
  const { isHomeCareClient } = useUserRole();

  const [publicProfile, setPublicProfile] = useState(null);
  const isJobCancelled = status === JobStatus.cancelled;

  const jobId = getValue(detail, "jobId");

  useEffect(() => {
    fetchTherapistProfile();
  }, [therapist]);

  const showAddAdditionalDocuments =
    ![JobStatus.pending, JobStatus.rebooking].includes(status) && isHomeCareClient;

  const showAgreements = showAddAdditionalDocuments && agreements && agreements.length > 0;
  const hasProviderDocument = showAddAdditionalDocuments && !checkIfEmpty(providersDocuments);

  const fetchTherapistProfile = async () => {
    try {
      if (!therapist || !therapist.id) return;

      const { id: therapistId } = therapist;

      const url = `therapists/${therapistId}/publicProfile`;
      const response = await get(url);
      setPublicProfile(response);
    } catch (err) {
      console.debug(err);
    }
  };

  const handleProfileOpen = () => {
    if (!therapist || !therapist.id) return;

    const { id } = therapist;
    onProfileTap(id);
  };

  const shouldAllowTerms = () => {
    const allowedJobStatus = [JobStatus.accepted, JobStatus.pendingPayment];
    return allowedJobStatus.includes(status);
  };

  const onDocumentUploaded = () => {
    bookingMutate();
  };

  const allowNewTerms = shouldAllowTerms();
  const profession = getValue(booking, "service.profession", "therapist");
  const { canClientChat } = isChatEnableForBooking(booking, detail.status);
  const shouldShowLicenseBadge = () => {
    const proFromUs = therapist?.country === "US";
    const licenseNumber = getValue(therapist, "therapistprofile.abn", null);
    const isMassage = getValue(booking, "selectedService.isMassage", false);

    return proFromUs && !isEmpty(licenseNumber) && isMassage;
  };

  return (
    <Box
      bgcolor="#ffffff"
      borderRadius="6px"
      border={`solid 1px ${Colors.PaleLilac}`}
      mb="16px"
      width={isMobile ? "100%" : "468px"}
    >
      <Box padding={"24px"}>
        {!isJobCancelled && (
          <Box style={{ width: "100%" }}>
            <CardHeader
              therapist={therapist}
              detail={detail}
              bookingTitle={booking.title}
              publicProfile={publicProfile}
              refreshTherapist={fetchTherapistProfile}
              handleProfileOpen={handleProfileOpen}
              profession={profession}
              showLicenseBade={shouldShowLicenseBadge()}
            />
          </Box>
        )}
        <CardBody
          mapId={`map-bookingdetail-${index}`}
          address={address}
          status={status}
          timeDetail={timeDetail}
          bookingStatus={bookingStatus}
        />

        <TermsAndPoliciesDocuments
          showAddAdditionalDocuments={showAddAdditionalDocuments}
          showAgreements={showAgreements as boolean}
          hasProsDocuments={hasProviderDocument}
          agreements={agreements || []}
          proDocuments={providersDocuments || []}
          bookingId={getValue(booking, "id")}
          therapistId={getValue(therapist, "id")}
          alignItems="start"
          allowUpload={allowNewTerms}
          onSuccess={onDocumentUploaded}
        />

        {canClientChat && (
          <Box marginTop={Spacing.S4}>
            <ChatButton
              text={`Message ${therapist?.firstName}`}
              bookingId={booking.id}
              conversationId={booking.conversationId}
            />
          </Box>
        )}

        <UpdateRequestActions
          showTitle={false}
          booking={booking}
          bookingMutate={bookingMutate}
          jobId={jobId}
        />
      </Box>
    </Box>
  );
};

export default BookingDetailCard;
