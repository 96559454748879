/* eslint-disable indent */
import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { isNil } from "lodash";

import { getValue } from "../../../../utils/object";
import { Colors } from "../../../../constants/colors";
import InfoModal from "../../../../components/Modals/InfoModal/InfoModal";
import { BOOKING_STATUS, PRICE_BREAKDOWN } from "../../../../constants/booking";
import { formatToPrice } from "../../../../helpers/number";
import { useUserStore } from "../../../../stores/user";
import CurrencyConversionInfo from "../../../../components/CurrencyConversionInfo";
import TextButton from "../../../../components/TextButton/TextButton";
import { SingleProTippingModal } from "../../../../components/Modals/TippingModal/SingleProTippingModal";
import { MultipleProTippingModal } from "../../../../components/Modals/TippingModal/MultipleProTippingModal";
import { AfterBookingTipSuccessModal } from "../../../../components/Modals/TippingModal/AfterBookingTipSuccessModal";
import { useTipInfoQuery, useUpdateTipMutation } from "../../../../hooks/tip.hook";
import { PartialJobForTip, TipPerJob } from "../../../../types/tip.types";
import TippingConfirmModal from "../../../../components/Modals/TippingModal/TippingConfirmModal";
import { PaymentType } from "../../../../constants/payment";
import { useDispatch } from "react-redux";
import { bookingInitializer } from "../../../../stores/V2/booking/booking.initializers";

export enum BookingSummaryItemType {
  massage,
  peakHourSurcharge,
  discount,
  coupon,
  credit,
  cancellationFee,
  publicHolidaySurcharge,
  clientPlatformFee,
  parkingAndTravel,
  tipping,
}

interface Props {
  type: BookingSummaryItemType;
  booking: any;
}

export default function BookingDetailsSummaryItem({ type, booking }: Props): JSX.Element {
  const { bookingPrice, platformFeeData, peakHourInfo, parkingAndTravelInfo } = booking;
  const [infoModal, setInfoModal] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
      data: [],
    },
  });
  const [showTipModal, setShowTipModal] = useState(false);
  const [showTipSuccessModal, setShowTipSuccessModal] = useState(false);
  const [tipPerJob, setTipPerJob] = useState<TipPerJob[]>([]);
  const [confirmModalData, setConfirmModalData] = useState<{
    tipPerJob: TipPerJob[];
    isEvenlySplit: boolean;
    isSingle?: boolean;
  } | null>(null);

  const dispatch = useDispatch();

  const bookingIsCancelled = (booking: any) => {
    return booking.status === BOOKING_STATUS.CANCELLED;
  };
  const bookingCurrency = getValue(booking, "selectedService.selectedCountry.currencySymbol", "A$");
  const bookingCurrencyCode = getValue(booking, "selectedService.selectedCountry.currency", "AUD");

  const originalCouponAmount = getValue(booking, "coupon.originalAmount");
  const originalCouponCurrency = getValue(booking, "coupon.originalCurrency");
  const originalCouponSymbol = getValue(booking, "coupon.originalCouponCurrencySymbol");
  const couponType = getValue(booking, "coupon.type");

  const cancelled = bookingIsCancelled(booking);

  const { currencyCode: userCurrencyCode } = useUserStore();
  const { tipInfo } = useTipInfoQuery();
  const { mutateAsync: updateTip, isLoading } = useUpdateTipMutation();

  const clickableSummaryItems = [
    BookingSummaryItemType.clientPlatformFee,
    BookingSummaryItemType.peakHourSurcharge,
    BookingSummaryItemType.parkingAndTravel,
    BookingSummaryItemType.tipping,
  ];

  const onAddTipConfirmed = async () => {
    // make api calls
    await updateTip(
      {
        bookingId: booking.id,
        tipPerJobs: confirmModalData?.tipPerJob ?? [],
        isEvenlySplit: confirmModalData?.isEvenlySplit ?? false,
      },
      {
        onError: () => {
          setShowTipSuccessModal(false);
        },
      }
    )
      .then(() => {
        bookingInitializer(booking.id, dispatch);
      })
      .catch(() => {});

    // set tipPerJob state
    setTipPerJob([...(confirmModalData?.tipPerJob ?? [])]);

    // close modal
    setConfirmModalData(null);
    setShowTipModal(false);

    // show success modal
    setShowTipSuccessModal(true);
  };

  const getPartialJob = (): PartialJobForTip[] => {
    return (
      booking.bookingDetails?.map(
        (details: any): PartialJobForTip => ({
          id: details.jobId,
          user: {
            id: details.therapist.id,
            firstName: details.therapist?.preferredFirstName || details.therapist.firstName,
            profileImage: details.therapist?.profileImage,
          },
        })
      ) ?? []
    );
  };

  const getBookingBaseRate = () => {
    return cancelled ? 0 : bookingPrice.originalPrice - bookingPrice.addressSurcharge || 0;
  };

  const getBaseRateWithSurcharges = () => {
    return (
      booking.bookingPrice.priceWithoutDiscount -
      booking.bookingPrice.clientPlatformFee -
      (booking.tipAmount || 0)
    );
  };

  const enabled = () => {
    switch (type) {
      case BookingSummaryItemType.massage:
        return true;
      case BookingSummaryItemType.parkingAndTravel:
        const parkingAndTravelSurcharge =
          (bookingPrice?.addressSurcharge ?? 0) + (bookingPrice?.hotelSurcharge ?? 0);
        return parkingAndTravelSurcharge > 0;
      case BookingSummaryItemType.peakHourSurcharge:
        return (
          ((bookingPrice?.lateNightSurcharge ?? 0) || (bookingPrice?.publicHolidaySurcharge ?? 0)) >
          0
        );
      case BookingSummaryItemType.discount:
        return false;
      case BookingSummaryItemType.coupon:
        return !isNil(booking.coupon);
      case BookingSummaryItemType.credit:
        return bookingPrice.credit ?? 0 > 0;
      case BookingSummaryItemType.cancellationFee:
        return cancelled;
      case BookingSummaryItemType.publicHolidaySurcharge:
        return false;
      case BookingSummaryItemType.clientPlatformFee:
        return bookingPrice.clientPlatformFee ?? 0 > 0;
      case BookingSummaryItemType.tipping:
        return (
          booking.paymentType !== PaymentType.payWithInvoice &&
          booking.paymentType !== PaymentType.payWithNDISFunding
        );
    }
  };

  const title = () => {
    switch (type) {
      case BookingSummaryItemType.massage:
        return PRICE_BREAKDOWN.BASERATE;
      case BookingSummaryItemType.parkingAndTravel:
        return PRICE_BREAKDOWN.PARKINGTRAVEL;
      case BookingSummaryItemType.peakHourSurcharge:
        return PRICE_BREAKDOWN.PEAK_PERIOD;
      case BookingSummaryItemType.discount:
        return "Discount";
      case BookingSummaryItemType.coupon:
        return `Coupon (${booking.coupon.code})`;
      case BookingSummaryItemType.credit:
        return PRICE_BREAKDOWN.CREDIT;
      case BookingSummaryItemType.cancellationFee:
        return "Cancellation fee";
      case BookingSummaryItemType.publicHolidaySurcharge:
        return PRICE_BREAKDOWN.PUBLIC_HOLIDAY;
      case BookingSummaryItemType.clientPlatformFee:
        return getValue(booking, "platformFeeData.title", "Platform Fee");
      case BookingSummaryItemType.tipping:
        return PRICE_BREAKDOWN.TIP_FOR_THE_PROVIDER;
    }
  };

  const value = () => {
    let price = 0;
    switch (type) {
      case BookingSummaryItemType.massage:
        price = getBookingBaseRate();
        return formatToPrice(price, bookingCurrency);
      case BookingSummaryItemType.parkingAndTravel:
        const parkingAndTravelSurcharge =
          (bookingPrice?.addressSurcharge ?? 0) + (bookingPrice?.hotelSurcharge ?? 0);
        price = cancelled ? price : parkingAndTravelSurcharge;
        return formatToPrice(price, bookingCurrency);
      case BookingSummaryItemType.peakHourSurcharge:
        price = cancelled
          ? price
          : bookingPrice.lateNightSurcharge + bookingPrice.publicHolidaySurcharge;
        return formatToPrice(price, bookingCurrency);
      case BookingSummaryItemType.discount:
        return formatToPrice(0, bookingCurrency); // TODO
      case BookingSummaryItemType.coupon:
        if (booking.coupon.type === "percent") {
          const couponPrice =
            (bookingPrice.priceWithoutDiscount - bookingPrice.clientPlatformFee) *
            (booking.coupon.value / 100);
          return `-${formatToPrice(couponPrice, bookingCurrency)}`;
        }

        if (booking.coupon.type === "referral") {
          return `-${formatToPrice(booking.coupon.value, bookingCurrency)}`;
        }

        if (booking.coupon.type === "value") {
          return `-${formatToPrice(booking.coupon.value, bookingCurrency)}`;
        }

        return null;

      case BookingSummaryItemType.credit:
        return formatToPrice(bookingPrice.credit, bookingCurrency);
      case BookingSummaryItemType.cancellationFee:
        return formatToPrice(booking.cancellationFee, bookingCurrency);

      case BookingSummaryItemType.publicHolidaySurcharge:
        return `${formatToPrice(bookingPrice.publicHolidaySurcharge, bookingCurrency)}`;
      case BookingSummaryItemType.clientPlatformFee:
        return `${formatToPrice(bookingPrice.clientPlatformFee, bookingCurrency)}`;
      case BookingSummaryItemType.tipping:
        return formatToPrice(booking.tipAmount || 0, bookingCurrency);
    }
  };

  const isClickable = () => clickableSummaryItems.includes(type);

  const handleInfoIconClicked = (type: BookingSummaryItemType) => {
    const modalContent = {
      title: "",
      description: "",
      data: [],
    };
    switch (type) {
      case BookingSummaryItemType.clientPlatformFee:
        modalContent.title = getValue(platformFeeData, "title", "");
        modalContent.description = getValue(platformFeeData, "remarks", "");
        break;
      case BookingSummaryItemType.peakHourSurcharge:
        modalContent.title = getValue(peakHourInfo, "title", "");
        modalContent.description = getValue(peakHourInfo, "subtitle", "");
        modalContent.data = getValue(peakHourInfo, "data", []);
        break;
      case BookingSummaryItemType.parkingAndTravel:
        modalContent.title = getValue(parkingAndTravelInfo, "title", "");
        modalContent.description = getValue(parkingAndTravelInfo, "subtitle", "");
        modalContent.data = getValue(parkingAndTravelInfo, "data", []);
        break;
      case BookingSummaryItemType.tipping:
        modalContent.title = tipInfo.title;
        modalContent.description = tipInfo.description;
        modalContent.data = [];
        break;
    }
    setInfoModal({
      visible: true,
      content: modalContent,
    });
  };

  const handleInfoDismiss = () => {
    setInfoModal({ ...infoModal, visible: false });
  };

  const actionLink = () => {
    switch (type) {
      case BookingSummaryItemType.tipping: {
        return (
          <TextButton
            textStyle={{ color: Colors.TurquoiseBlue, fontSize: "14px" }}
            onClick={() => {
              setShowTipModal(() => true);
            }}
            text={tipPerJob.length > 0 || booking.bookingTips.length > 0 ? "Change" : "Add"}
          />
        );
      }
      default:
        return null;
    }
  };

  return enabled() ? (
    <Box mt={2} mb={2}>
      <Box display="flex" flexDirection="row" alignItems={"center"}>
        <Box
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gridColumnGap={"10px"}
        >
          <Typography
            style={isClickable() ? styles.clickable : {}}
            onClick={() => handleInfoIconClicked(type)}
          >
            {title()}
          </Typography>
          {actionLink()}
        </Box>
        <Box flex={1}>
          <Box
            fontFamily="Museo"
            fontSize="14px"
            color={Colors.Dusk}
            textAlign="right"
            // flex={1}
          >
            {value()}
          </Box>
        </Box>
      </Box>
      {type === BookingSummaryItemType.coupon && couponType === "value" ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={originalCouponCurrency}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(originalCouponAmount, originalCouponSymbol)}
        />
      ) : (
        <></>
      )}
      {type === BookingSummaryItemType.credit ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={userCurrencyCode}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(bookingPrice.creditBeforeConversion)}
        />
      ) : (
        <></>
      )}
      {isClickable() && (
        <InfoModal
          visible={infoModal.visible}
          title={infoModal.content.title}
          description={infoModal.content.description}
          data={infoModal.content.data}
          divider={false}
          handleClose={handleInfoDismiss}
        />
      )}
      {booking?.bookingDetails?.length === 1 && showTipModal && (
        <SingleProTippingModal
          baseRate={getBaseRateWithSurcharges()}
          currency={bookingCurrency}
          onAdd={(tipAmount, cardId) => {
            setConfirmModalData({
              isEvenlySplit: true,
              tipPerJob: [
                {
                  jobId: booking.bookingDetails[0].jobId,
                  tipAmount: tipAmount,
                  tipId: cardId,
                },
              ],
              isSingle: true,
            });
            setShowTipModal(false);
          }}
          onClose={() => {
            setShowTipModal(false);
          }}
          visible={showTipModal}
          therapistCount={booking.bookingDetails.length}
          bookingType={booking.status}
          proName={
            booking.bookingDetails?.[0]?.therapist?.preferredFirstName ||
            booking.bookingDetails?.[0]?.therapist?.firstName
          }
          proImage={booking.bookingDetails?.[0]?.therapist.therapistprofile.profileImage}
          selectedCardId={booking.bookingTips[0]?.type}
          tipAmount={booking.bookingTips[0]?.amount}
          countryCode={
            booking.selectedService.selectedCountry.code ?? booking.selectedService.country
          }
        />
      )}
      {booking?.bookingDetails?.length > 1 && showTipModal && (
        <MultipleProTippingModal
          baseRate={getBaseRateWithSurcharges()}
          currency={bookingCurrency}
          onAdd={(tipPerJob, splitType) => {
            setShowTipModal(false);
            setConfirmModalData({
              isEvenlySplit: splitType === "even",
              tipPerJob: tipPerJob,
              isSingle: false,
            });
            setShowTipModal(false);
          }}
          onClose={() => {
            setShowTipModal(false);
          }}
          visible={showTipModal}
          jobs={getPartialJob()}
          bookingType={booking.status}
          bookingTips={booking.bookingTips}
          countryCode={
            booking.selectedService.selectedCountry.code ?? booking.selectedService.country
          }
        />
      )}

      {!!confirmModalData && (
        <TippingConfirmModal
          currency={bookingCurrency}
          jobs={getPartialJob()}
          tipPerJobs={confirmModalData.tipPerJob}
          onClose={() => setConfirmModalData(null)}
          onConfirm={onAddTipConfirmed}
          isLoading={isLoading}
        />
      )}

      {showTipSuccessModal && (
        <AfterBookingTipSuccessModal
          currency={bookingCurrency}
          onClose={() => {
            setShowTipSuccessModal(false);
          }}
          jobs={getPartialJob()}
          tipByJob={tipPerJob}
          visible={showTipSuccessModal}
        />
      )}
    </Box>
  ) : (
    <></>
  );
}

const styles = {
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};
