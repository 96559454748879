import { Box } from "@material-ui/core";
import React from "react";

interface LicenseBadgeProps {
  licenseNumber: string;
}

const LicenseBadge: React.FC<LicenseBadgeProps> = ({ licenseNumber }) => {
  return (
    <Box
      style={{
        // display: "inline-block",
        padding: "4px",
        borderRadius: "4px",
        color: "#41506F",
        backgroundColor: "#EBF8FA",
        fontFamily: "SF UI Text",
        fontWeight: 400,
        fontSize: "12px",
        width: "fit-content",
      }}
    >
      License number: <span style={{ fontWeight: 500, fontSize: "12px" }}>{licenseNumber}</span>
    </Box>
  );
};

export default LicenseBadge;
