import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import {
  AlignItems,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
} from "../../v2/Styled/enum";
import PopularStar from "../../../images/star-line.svg";

type Props = {
  id: string;
  isSelected: boolean;
  title: string;
  secondaryTitle: string;
  isSecondaryTitle: boolean;
  onSelect: (key: string) => void;
  size: CardSize;
  recommended: boolean;
};

export type CardSize = "md" | "sm";

const cardSize: Record<CardSize, Record<any, any>> = {
  md: {
    width: "98px",
    height: "98px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  sm: {
    width: "53px",
    height: "54px",
    fontSize: "14px",
    lineHeight: "18px",
  },
};

export function TippingCard(props: Props) {
  return (
    <Box
      minWidth={cardSize[props.size].width}
      minHeight={cardSize[props.size].height}
      width={cardSize[props.size].width}
      height={cardSize[props.size].height}
      borderRadius="8px"
      border="1px solid #C5CBD6"
      bgcolor={props.isSelected ? Colors.CornflowerBlue : ""}
      display={"flex"}
      flexDirection={FlexDirection.Column}
      justifyContent={props.recommended ? JustifyContent.end : JustifyContent.center}
      alignItems={"center"}
      style={{
        cursor: "pointer",
      }}
      onClick={() => props.onSelect(props.id)}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        fontFamily={"Museo"}
        fontWeight={700}
        fontSize={cardSize[props.size].fontSize}
        lineHeight={cardSize[props.size].lineHeight}
        color={props.isSelected ? Colors.TurquoiseBlue : Colors.DarkSteelGrey}
      >
        <Box>{props.title}</Box>
        <Box
          style={{
            ...(props.isSecondaryTitle
              ? {
                  fontSize: "12px",
                  fontFamily: "SF UI Text",
                  fontWeight: 500,
                  lineHeight: "15.6px",
                }
              : {}),
            color: props.isSelected
              ? Colors.TurquoiseBlue
              : props.isSecondaryTitle
                ? Colors.Grey
                : Colors.DarkSteelGrey,
          }}
        >
          {props.secondaryTitle}
        </Box>
      </Box>
      {props.recommended && (
        <Box
          style={{
            display: "flex",
            flexDirection: FlexDirection.Row,
            backgroundColor: Colors.GoldenYellow,
            width: "100%",
            height: "21.6px",
            justifyContent: JustifyContent.center,
            alignItems: AlignItems.center,
            gap: Spacing.S1,
            borderBottomLeftRadius: "7px",
            borderBottomRightRadius: "7px",
            marginTop: "8px",
          }}
        >
          <img src={PopularStar} alt="popular-tip" />
          <Typography
            style={{
              fontFamily: FontFamily.SFPro,
              fontSize: FontSize.F12,
              fontWeight: FontWeight.Medium,
              lineHeight: LineHeight.L16,
              color: Colors.White,
            }}
          >
            Popular
          </Typography>
        </Box>
      )}
    </Box>
  );
}
