export enum Roles {
  ACCOUNT_OWNER = "accountOwner",
  ACCOUNT_MANAGER = "accountManager",
  RECIPIENT = "recipient",
  HOME_CARE_PROVIDER = "homeCareProvider",
  AGED_CARE_SUPPORT_RECIPIENT = "agedCareSupportRecipient",
  DISABILITY_SUPPORT_RECIPIENT = "disabilitySupportRecipient",
  DISABILITY_SUPPORT_COORDINATOR = "disabilitySupportCoordinator",
  AGED_CARE_SUPPORT_COORDINATOR = "agedCareSupportCoordinator",
}

export const RolesOptionsForOwner = [
  {
    title: "Account Manager",
    value: Roles.ACCOUNT_MANAGER,
  },
  {
    title: "Recipient",
    value: Roles.RECIPIENT,
  },
];

export const RolesOptionsForManager = [
  {
    title: "Recipient",
    value: Roles.RECIPIENT,
  },
];
export const RolesOptionsForDisabilityCoordinator = [
  {
    title: "Disability Support Recipient",
    value: Roles.DISABILITY_SUPPORT_RECIPIENT,
  },
];

export const RolesOptionsForAgedCareCoordinator = [
  {
    title: "Aged Care Support Recipient",
    value: Roles.AGED_CARE_SUPPORT_RECIPIENT,
  },
];

export const canInviteRolesByHomeCare = [
  Roles.HOME_CARE_PROVIDER,
  Roles.AGED_CARE_SUPPORT_COORDINATOR,
  Roles.DISABILITY_SUPPORT_COORDINATOR,
];
