import { Box } from "@material-ui/core";
import { useState } from "react";
import { BOOKING_STATUS } from "../../../constants/booking";
import { Colors } from "../../../constants/colors";
import { BOOKING_TIP_TYPE } from "../../../constants/tip";
import { formatToPrice } from "../../../helpers/number";
import { useMobile } from "../../../hooks/mobile";
import TextField from "../../TextField";
import { CardSize, TippingCard } from "./TippingCard";
import { useDefaultTipOptionsQuery } from "../../../hooks/tip.hook";
import { COUNTRY } from "../../../constants/countries";

type Props = {
  therapistCount: number;
  bookingType: BOOKING_STATUS;
  baseRate: number;
  currency: string;
  onTipUpdate: (tipAmount: number, cardId: string) => void;
  cardSize?: CardSize;
  cardSpacing?: "compact" | "normal";
  selectedCardId?: string;
  customTipAmount?: number;
  countryCode: string;
  textFieldError: string | null;
};

export function TippingAmounts({
  cardSize = "md",
  cardSpacing = "normal",
  selectedCardId = BOOKING_TIP_TYPE.NOT_NOW,
  ...props
}: Props) {
  const [selectedCard, setSelectedCard] = useState(selectedCardId);
  const [customTip, setCustomTip] = useState(props.customTipAmount ?? 0);

  const { defaultTipOptions } = useDefaultTipOptionsQuery();
  const isMobile = useMobile();

  const recommendedTipPercentage = props.countryCode === COUNTRY.US ? "20%" : "10%";

  const getTipAmount = (key = selectedCard, customTipAmount = customTip) => {
    if (key === BOOKING_TIP_TYPE.NOT_NOW) {
      return 0;
    } else if (key === BOOKING_TIP_TYPE.CUSTOM) {
      return customTipAmount;
    } else {
      const percentage = defaultTipOptions.find((tip) => tip.id === key)?.amount!;

      return (percentage / 100) * props.baseRate;
    }
  };

  const showTippingCards = () => {
    return defaultTipOptions.map((tip) => {
      let title = tip.label!;
      let secondaryTitle = tip.secondaryLabel;

      if (tip.amount) {
        secondaryTitle = `${formatToPrice((tip.amount / 100) * props.baseRate, props.currency)}`;
      }

      return (
        <TippingCard
          id={tip.id.toString()}
          isSelected={selectedCard === tip.id.toString()}
          title={title}
          secondaryTitle={secondaryTitle || ""}
          isSecondaryTitle={!tip.secondaryLabel}
          onSelect={(key) => {
            setSelectedCard(key);
            props.onTipUpdate(getTipAmount(key), key);
          }}
          size={cardSize}
          recommended={tip.label === recommendedTipPercentage}
        />
      );
    });
  };

  return (
    <Box flex={1} display={"flex"} flexDirection={"column"} gridGap={"16px"}>
      <Box
        display={"flex"}
        gridGap={"8px"}
        style={{
          overflowX: "auto",
          paddingBottom: isMobile ? "8px" : "auto",
        }}
        justifyContent={cardSpacing === "normal" ? "space-between" : "start"}
      >
        {showTippingCards()}
      </Box>
      {selectedCard === BOOKING_TIP_TYPE.CUSTOM && (
        <Box padding={"0px 2px"} style={{ background: Colors.White }}>
          <TextField
            title="Tip amount"
            placeholder="Enter the amount you want to tip"
            startAdornment={customTip > 0 && `${props.currency}`}
            style={{ padding: 0 }}
            onChange={(value) => {
              if (isNaN(Number(value))) return;
              setCustomTip(Number(value));
              props.onTipUpdate(getTipAmount(selectedCard, Number(value)), selectedCard);
            }}
            value={customTip > 0 ? customTip.toString() : undefined}
            showErrorImage={props.textFieldError !== null}
            isError={props.textFieldError !== null}
            errorMessage={props.textFieldError || undefined}
          />
        </Box>
      )}
    </Box>
  );
}
