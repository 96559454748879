import * as httpClient from "../../../api/client";
import { useMutation } from "@tanstack/react-query";

const useAddPaymentMethod = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: any) => unknown;
  onError: (e: any) => unknown;
}) =>
  useMutation(
    (paymentMethodId: string) =>
      httpClient.post("api/v2/stripe/payment-method", { paymentMethodId }),
    {
      onSuccess,
      onError,
    }
  );

export { useAddPaymentMethod };
