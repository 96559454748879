import { Box, Button } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import Dialog from "../../Dialog";
import GiftVoucherPriceBreakdownSummary from "../../GiftVoucher/GiftVoucherPriceBreakdownSummary";
import InfoModal from "../InfoModal/InfoModal";
import { useState } from "react";
import { useMobile } from "../../../hooks/mobile";

interface Props {
    open: boolean;
    onToggle: (boolean: boolean) => unknown;
    actionName?: string;
}

const VoucherPriceBreakdownModal = ({open, onToggle, actionName = "Ok"}: Props) => {
  const isMobile = useMobile();
  const [infoModalContent, setInfoContent] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
    },
  });
  
  const closePriceBreakDownModal = () => {
    onToggle(false);
  };

  const handleInfoClose = () => {
    onToggle(true);
    setInfoContent({ ...infoModalContent, visible: false });
  };

  const showInfoModal = (title: string, description: string) => {
    onToggle(false);
    setInfoContent({
      visible: true,
      content: { title, description },
    });
  };

  return(
    <>
      <Dialog open={open} onClose={closePriceBreakDownModal}>
        <Box marginY={"16px"} marginX={"30px"} width={isMobile ? "auto" : "406px"}>
          <GiftVoucherPriceBreakdownSummary showInfoModal={showInfoModal} forDialog={true}/>
          <Button
            onClick={() => onToggle(false)}
            style={{
              width: "100%",
              marginBottom: "16px",
              marginTop: "16px",
              padding: "12px",
              fontSize: "16px",
              fontWeight: "600",
              color: Colors.Dusk,
              textTransform: "none",
              border: `1px solid ${Colors.LightPeriwinkle}`,
            }}
          >
            {actionName}
          </Button>
        </Box>
      </Dialog>
      <InfoModal
        handleClose={handleInfoClose}
        visible={infoModalContent.visible}
        title={infoModalContent.content.title}
        description={infoModalContent.content.description}
        divider={false}
      />
    </>
  );
};

export default VoucherPriceBreakdownModal;