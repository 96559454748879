import React from "react";
import { Link } from "@material-ui/core";
import { Box, Button } from "@mui/material";
import UserIconLink from "./UserIconLink";
import { useMobile } from "../../../hooks/mobile";
import HeaderLink, { headerLinkTypes } from "./HeaderLink";
import logo from "../../../images/logo.svg";
import { Colors } from "../../../constants/colors";
import { FontFamily, FontWeight } from "../../v2/Styled/enum";

export default function Navigation() {
  const isMobile = useMobile();
  const constants = {
    apiUrl: process.env.NEXT_PUBLIC_API_URL,
    storageUrl: process.env.NEXT_PUBLIC_STORAGE_URL,
    webAppUrl: process.env.NEXT_PUBLIC_WEB_APP_URL as string,
    mapAPIKey: "AIzaSyDBoA7VrJ7ifNDaBagVG3AMJy4UoIH-RbE",
    practitionerProfileBG: "/therapist-profile-background.svg",
    hostName: process.env.NEXT_PUBLIC_APP_URL || "https://providers.getblys.com.au",
    homePageUrl: "https://getblys.com.au",
    canonicalURL: "https://providers.getblys.com",
    webAppUrls: {
      production: {
        AU: "https://app.getblys.com.au",
        default: "https://app.getblys.com",
      },
      staging: {
        AU: "https://app-staging.getblys.com.au",
        default: "https://app-staging.getblys.com",
      },
    },
  };

  return (
    <Box
      height="82px"
      pl={isMobile ? "28px" : "52px"}
      pr={isMobile ? "28px" : "52px"}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box sx={{ cursor: "pointer" }}>
        <Link href={constants.homePageUrl} passHref>
          <a>
            <img className="header-logo__img" alt="Blys" src={logo} height="34px" width="60px" />
          </a>
        </Link>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        {!isMobile && headerLinkTypes.map((type, i) => <HeaderLink key={i} type={type} />)}
        {!isMobile && (
          <Box ml="20px">
            <Button
              variant="contained"
              sx={{
                backgroundColor: Colors.TurquoiseBlue,
                fontFamily: "Nunito",
                color: Colors.White,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
                "&:hover": {
                  backgroundColor: Colors.TurquoiseBlue,
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
              href="https://app.getblys.com.au/new-booking"
            >
              Book a Session
            </Button>
          </Box>
        )}

        <Box ml="49px">
          <UserIconLink />
        </Box>
      </Box>
    </Box>
  );
}
