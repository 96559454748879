import { Box } from "@material-ui/core";

import Divider from "../Divider";
import TextLink from "../TextLink";
import { Recipient } from "../../models";
import RadioButton from "../RadioButton";
import { Spacing } from "../v2/Styled/enum";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { checkIfNil } from "../../utils/object";
import { useUserStore } from "../../stores/user";
import { capitalizeFirstLetter } from "../../helpers/string";

interface Props {
  recipient?: Recipient;
  selected?: boolean;
  onEditClicked?: () => unknown;
  onDeleteClicked?: () => unknown;
  onSelected?: () => unknown;
  myself?: boolean;
  showMyself?: boolean;
}

export default function AddressItem({
  recipient,
  selected,
  onEditClicked,
  onDeleteClicked,
  onSelected,
  myself = false,
  showMyself = false,
}: Props): JSX.Element {
  const { user } = useUserStore();
  const isMobile = useMobile();

  const relationShip =
    recipient?.relationship === "guest" ? "client" : recipient?.relationship || "";
  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {!checkIfNil(selected) && (
          <Box ml={isMobile ? undefined : Spacing.S0} mr={Spacing.S1}>
            <RadioButton selected={selected} onSelected={onSelected} />
          </Box>
        )}

        <Box display="flex" flexDirection="column" flex={1}>
          <Box fontFamily="Museo" fontSize="14px" fontWeight={700} color={Colors.Dusk} mt={2}>
            {showMyself && "Myself"}
            {recipient && capitalizeFirstLetter(relationShip || "")}
          </Box>
          <Box display="flex" flexDirection="row" mt={0.5}>
            <Box
              fontFamily="Museo"
              fontSize="18px"
              fontWeight={500}
              color={Colors.Dusk}
              style={{ cursor: "pointer", textDecoration: "underline", textUnderlineOffset: 3 }}
              mr={4}
              flex={1}
              onClick={onEditClicked}
            >
              {myself
                ? `${user?.firstName} ${user?.lastName}`
                : `${recipient?.firstName} ${recipient?.lastName}`}
            </Box>
            {onEditClicked && (
              <Box>
                <TextLink title="Edit" onClick={onEditClicked} />
              </Box>
            )}

            {onDeleteClicked && (
              <>
                <Box width="16px" />
                <Box>
                  <TextLink title="Delete" destructive onClick={onDeleteClicked} />
                </Box>
              </>
            )}
          </Box>
          <Box
            fontFamily="SF UI Text"
            fontSize="13px"
            fontWeight={400}
            color={Colors.BlueyGrey}
            mt={0.5}
            mb={1}
          >
            {myself ? user?.email : recipient?.email}
          </Box>
        </Box>
      </Box>

      <Divider />
    </>
  );
}
