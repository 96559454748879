import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { Box, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import PayoutWrapper from "./PayoutWrapper";
import OnboardingForm from "./OnboardingForm";
import { getValue } from "../../../../utils/object";
import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
import SetupPayoutTimeline from "./SetupPayoutTimeline";
import { useAlertStore } from "../../../../stores/alert";
import * as Styled from "../../../../components/v2/Styled/enum";
import { parseApiErrorFromResponse } from "../../../../helpers/error";
import { useNameLockedForUser } from "../../../../hooks/userProfileLock.hooks";
import StepProgressIndicator from "../../../../components/StepProgressIndicator";
import { FormDataModal, FormStepIndex, FormStepsName, IFormData } from "./Forms/model";
import { StripeSubmitSuccessModal } from "../../../../components/Stripe/Connect/StripeSuccessModal";
import {
  useStripePayoutDetails,
  useUpdateStripeAccount,
  useStripeOnboardingOptions,
} from "../../../../hooks/stripe/connect/stripeConnect.hooks";
import {
  getAllOnboardingFormSteps,
  getPayloadForAccountUpdate,
  transformStepIndexToStepName,
  transformRequirementsToFormName,
} from "../../../../services/proDashboard/transformer/stripeTransformer.service";
import {
  invalidateAccountStatus,
  invalidateOnboardingData,
} from "../../../../services/proDashboard/stripe.service";

interface Props {}

const PayoutInformation = ({}: Props): JSX.Element => {
  const history = useHistory();
  const { state } = useLocation();

  const isInitialOnboardingCompleted = getValue(
    state,
    "accountStatus.isInitialOnboardingCompleted",
    false
  );
  const accountRequirements = getValue(state, "accountRequirementsData") || {};

  const [selectedStep, setSelectedStep] = useState(1);
  const [earlyExit, setEarlyExit] = useState<boolean>(false);
  const [isForUpdate, setIsForUpdate] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormData>(FormDataModal);
  const [highlights, setHighlights] = useState<Array<FormStepsName>>([]);
  const [showSubmittedModal, setShowSubmittedModal] = useState<boolean>(false);

  const isMobile = useMobile();
  const isNameLocked = useNameLockedForUser();
  const { data: options, isLoading: isOptionsLoading } = useStripeOnboardingOptions();
  const { data: payoutDetails, isLoading: isPayoutDetailsLoading } = useStripePayoutDetails();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { allSteps, steps } = getAllOnboardingFormSteps(options);

  const onUpdateSuccess = ({ success, message }: { success: boolean; message: string }) => {
    if (!success) {
      setErrorMessage(message || "Unable to update payout details at the moment.");
      return;
    }
    invalidateAccountStatus();
    invalidateOnboardingData();
    if (earlyExit) {
      setSuccessMessage("Saved.");
    } else {
      setShowSubmittedModal(true);
    }
  };

  const onUpdateError = (e: AxiosError) => {
    setErrorMessage(parseApiErrorFromResponse({ error: e }));
  };

  const updateHighlights = (requirements: any) => {
    const requirementToCollect = getValue(requirements, "toCollect") || [];
    const requirementsToHighlights = transformRequirementsToFormName(requirementToCollect);

    setHighlights(requirementsToHighlights);
  };

  const { mutate: updateAccount, isLoading: isAccountUpdating } = useUpdateStripeAccount({
    onError: onUpdateError,
    onSuccess: onUpdateSuccess,
  });

  useEffect(() => {
    if (earlyExit) {
      handleFormSubmit(true);
    }
  }, [formData, earlyExit]);

  useEffect(() => {
    if (!isInitialOnboardingCompleted) return;
    updateHighlights(accountRequirements);
  }, [accountRequirements]);

  useEffect(() => {
    if (isInitialOnboardingCompleted) {
      setSelectedStep((allSteps || []).length);
    }
  }, [isInitialOnboardingCompleted, isOptionsLoading]);

  useEffect(() => {
    if (isPayoutDetailsLoading) return;
    setFormData(payoutDetails);
  }, [payoutDetails, isPayoutDetailsLoading]);

  const handlePreviousClicked = (e: MouseEvent, to: FormStepsName) => {
    const idx = FormStepIndex[to];
    const lastStep = (allSteps || []).length;
    const step = selectedStep - 1 === 0 ? 1 : idx;
    setSelectedStep(isForUpdate ? lastStep : step);
  };

  const handleContinueClicked = (e: MouseEvent, to: FormStepsName) => {
    const idx = FormStepIndex[to];
    const lastStep = (allSteps || []).length;
    const step = selectedStep + 1 > lastStep ? lastStep : idx;

    setHighlights(highlights.filter((step) => step !== transformStepIndexToStepName(selectedStep)));
    setSelectedStep(isForUpdate ? lastStep : step);
  };

  const handleChangeClicked = (stepName: FormStepsName) => {
    const step = allSteps.find((step) => step.name === stepName);
    if (!step) return;

    setIsForUpdate(true);
    setSelectedStep(step.step);
  };

  const handleFormUpdate = (name: keyof IFormData, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (overrideStep = false) => {
    let payload = {};
    const options = { isNameUnLocked: !isNameLocked };

    if (overrideStep) {
      payload = getPayloadForAccountUpdate(formData, selectedStep, options);
    } else {
      payload = getPayloadForAccountUpdate(formData, undefined, options);
    }

    updateAccount(payload);
  };

  const handleSaveAndExitClicked = (e: MouseEvent) => {
    // Save
    setEarlyExit(true);
    invalidateAccountStatus();
    invalidateOnboardingData();
    // Exit
    history.goBack();
  };

  const handleStepSelect = (step: number) => {
    if (step < selectedStep) {
      setIsForUpdate(true);
      setSelectedStep(step);
    }
  };

  if (isOptionsLoading || isPayoutDetailsLoading) {
    return (
      <PayoutWrapper newOnboarding={!isInitialOnboardingCompleted}>
        <Box maxWidth={isMobile ? "100%" : "632px"} margin={"40px 80px"}>
          <Box display="flex" justifyContent={"center"}>
            <CircularProgress variant={"indeterminate"} style={{ color: Colors.Indigo }} />
          </Box>
        </Box>
      </PayoutWrapper>
    );
  }

  return (
    <PayoutWrapper newOnboarding={!isInitialOnboardingCompleted}>
      <Box margin={isMobile ? "16px 24px" : "40px 80px"} maxWidth={isMobile ? "100%" : "1000px"}>
        <SetupPayoutTimeline
          steps={steps}
          highlights={highlights}
          selectedStep={selectedStep}
          onSelect={handleStepSelect}
        />
      </Box>
      <Box
        marginBottom={"40px"}
        gridGap={Styled.Spacing.S4}
        display={Styled.Display.Flex}
        marginX={isMobile ? "24px" : "80px"}
        maxWidth={isMobile ? "100%" : "632px"}
        flexDirection={Styled.FlexDirection.Column}
      >
        <StepProgressIndicator totalSteps={(allSteps || []).length} currentStep={selectedStep} />
        <OnboardingForm
          data={formData}
          options={allSteps}
          highlights={highlights}
          isForUpdate={isForUpdate}
          selectedStep={selectedStep}
          payoutDetails={payoutDetails}
          isUpdating={isAccountUpdating}
          onFormSubmit={handleFormSubmit}
          onContinue={handleContinueClicked}
          onPrevious={handlePreviousClicked}
          onChangeClick={handleChangeClicked}
          onFormValueUpdate={handleFormUpdate}
          invalidate={invalidateOnboardingData}
          onSaveAndExit={handleSaveAndExitClicked}
          isInitialOnboardingCompleted={isInitialOnboardingCompleted}
        />
      </Box>
      <StripeSubmitSuccessModal
        open={showSubmittedModal}
        onClose={() => {
          setShowSubmittedModal(false);
          history.goBack();
        }}
      />
    </PayoutWrapper>
  );
};

export default PayoutInformation;
