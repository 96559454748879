import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { formatToPrice } from "../../../helpers/number";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";
import { PartialJobForTip, TipPerJob } from "../../../types/tip.types";
import Button, { ButtonType } from "../../Button";
import { FontFamily } from "../../v2/Styled/enum";
import { SlideUpOverrideAnimationModal } from "../AnimationModal/SlideUpOverrideAnimationModal";
import Dialog from "../../Dialog";
import { useMobile } from "../../../hooks/mobile";

type Props = {
  visible: boolean;
  onClose: () => void;
  tipByJob: TipPerJob[];
  jobs: PartialJobForTip[];
  currency: string;
};

export function AfterBookingTipSuccessModal(props: Props) {
  const isMobile = useMobile();

  const getDescription = () => {
    if (props.jobs.length === 1) {
      const tipAmount = props.tipByJob.find((tip) => tip.jobId === props.jobs[0].id)?.tipAmount;
      const formattedPrice = formatToPrice(tipAmount, props.currency);
      return `You have successfully added ${formattedPrice} tip for ${props.jobs[0].user.firstName}.`;
    }

    let description = "You have successfully added a";
    props.tipByJob.forEach((tip, index) => {
      const job = props.jobs.find((job) => job.id === tip.jobId);
      const formattedPrice = formatToPrice(tip.tipAmount, props.currency);
      description += ` ${formattedPrice} tip for ${job?.user?.firstName}`;
      if (index < props.tipByJob.length - 1) {
        description += " and";
      }
    });

    return description + ".";
  };

  return (
    <Dialog open={props.visible} fullWidth={isMobile} maxWidth={"xs"} onClose={props.onClose}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"40px"}
        paddingBottom={"45px"}
        gridGap={"24px"}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <img
            src={ProfilePicturePlaceholder}
            alt={"profile-pic"}
            style={{
              ...Styles.profileImageStyle,
            }}
          />
        </Box>
        <Box gridGap={"16px"} display={"flex"} flexDirection={"column"}>
          <Typography>
            <Box
              sx={{
                fontSize: "22px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Thanks for the tip!
            </Box>
          </Typography>
          <Typography>
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: FontFamily.OpenSans,
                textAlign: "center",
                color: Colors.BlueyGrey,
                boxSizing: "border-box",
              }}
            >
              {getDescription()}
            </Box>
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() => {
              props.onClose();
            }}
            title={"Sweet, thanks"}
            type={ButtonType.outlined}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

const Styles = {
  profileImageStyle: {
    width: "96px",
    height: "96px",
    borderRadius: "50px",
    "object-fit": "cover",
  },
};
