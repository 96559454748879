import React from "react";
import { Box, BoxProps, Link } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props extends BoxProps {
  title: string;
  onClick?: () => unknown;
  fontSize?: string;
  fontWeight?: number;
  destructive?: boolean;
  color?: string;
  disabled?: boolean;
}

export default function TextLink({
  title,
  onClick,
  fontSize,
  fontWeight = 500,
  destructive = false,
  color = Colors.TurquoiseBlue,
  disabled = false,
  ...props
}: Props): JSX.Element {
  const getTextColor = () => {
    if (disabled) return Colors.Grey;
    return destructive ? Colors.Tomato : color;
  };

  return (
    <Box
      fontFamily="Museo"
      fontWeight={fontWeight}
      fontSize={fontSize}
      style={{ cursor: "pointer" }}
      {...props}
    >
      <Link style={{ color: getTextColor() }} onClick={disabled ? () => {} : onClick}>
        {title}
      </Link>
    </Box>
  );
}
