import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import axios, { AxiosError } from "axios";
import { isEmpty, isNil } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import AddressAutocomplete, { SelectedAddress } from "../../components/AddressAutocomplete";
import Dialog from "../../components/Dialog";
import { getAddressOptions } from "../../components/Addresses/apiCall";
import Button, { ButtonType } from "../../components/Button";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import TextField from "../../components/TextField";
import { LOCATION_NOTES_LIMIT, environment } from "../../constants/common";
import { Paths } from "../../constants/paths";
import { LOCATION_NOTES_PLACEHOLDER } from "../../constants/placeholder";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { Address, AddressOptions, ErrorResponse, LocationType } from "../../models";
import { getPostalPlaceholder, getPostalTitle } from "../../services/address/address.service";
import { useAlertStore } from "../../stores/alert";
import {
  getProductsForSegmentTracking,
  getServicesRates,
  getSubtotal,
  getTotal,
  getTreatmentId,
  isMassageType,
  NewAddress,
  setServiceRates,
  updateBooking,
  useBookingStore,
  useLocationDetailsStore,
  usePaymentStore,
} from "../../stores/booking";
import { trackEvent } from "../../services/segment/track.service";
import { checkIfEmpty, getValue } from "../../utils/object";
import {
  DEFAULT_COUNTRY,
  getCountryCode,
  setCountryCode as setCountryCodeLocalStorage,
} from "../../utils/country";
import { STEP_PROGRESS } from "../../constants/booking";
import { createUserAddress } from "../../services/users/users.service";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import { COUNTRIES_NAME, SERVICE_COUNTRIES } from "../../constants/countries";
import { useUserStore } from "../../stores/user";
import { useAddressOptions } from "../../hooks/address/addressOptions.hooks";
import { Colors } from "../../constants/colors";
import SelectLocationMap from "../../components/Addresses/SelectLocationMap";
import pixelsService from "../../services/pixels/pixels.service";
// interface AddressOptions {
//   parkingOptions: [];
//   petOptions: [];
//   stairOptions: [];
// }

export default function LocationDetails(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const { state } = useLocation();
  const accessToken = useAccessToken();
  const { user } = useUserStore();

  const [typeOptions, setTypeOptions] = useState<Array<DropdownOption>>([]);

  const [stairOption, setStairOption] = useState<DropdownOption | null>(null);
  const [petOption, setPetOption] = useState<DropdownOption | null>(null);
  const [parkingOption, setParkingOption] = useState<DropdownOption | null>(null);
  const [tableOption, setTableOption] = useState<DropdownOption | null>(null);

  const [disableOtherFields, setDisableOtherFields] = useState(false);

  const [isMapSelected, setIsMapSelected] = useState(false);

  const [saving, setSaving] = useState(false);
  const [postalTitle, setPostalTitle] = useState("Postcode");
  const [postalPlaceholder, setPostalPlaceholder] = useState("0000");

  const [defaultCountry, setDefaultCountry] = useState("");

  const { bookingPrice } = usePaymentStore();
  const { setErrorMessage } = useAlertStore();

  const {
    address: bookingAddress,
    setAddress: setSelectedAddress,
    updatingForBooking,
    sessionType,
    recipient,
  } = useBookingStore();

  const {
    address,
    setAddress,
    fullAddress,
    setFullAddress,
    suburb,
    setSuburb,
    postcode,
    setPostcode,
    country,
    setCountry,
    specialInstructions,
    setSpecialInstructions,
    type,
    setType,
    newAddress,
    countryCode,
    setCountryCode,
    setNewAddress,
    latitude,
    longitude,
    setAddressCordinates,
    state: addressState,
    setAddressState,
  } = useLocationDetailsStore();

  const { services: filteredServices } = useServiceRates(countryCode || DEFAULT_COUNTRY);
  const { addressOptions: allAddressOptions } = useAddressOptions(countryCode || DEFAULT_COUNTRY);

  const showSuburbPostalCountryFields = false; // Set to true when you want to enable suburb postal country

  const getUserDefaultCountry = async () => {
    const res = await getCountryCode();
    const countryName = COUNTRIES_NAME[res] || COUNTRIES_NAME[DEFAULT_COUNTRY];
    setDefaultCountry(countryName);
  };

  useEffect(() => {
    trackInitEvent();
    getPostalCodeTitle();
    getUserDefaultCountry();

    const accessToken = localStorage.getItem("token");
    if (!accessToken && newAddress) {
      setAddress(newAddress.address);
      setCountry(newAddress.country);
      setFullAddress(newAddress.fullAddress);
      setSpecialInstructions(newAddress.instructions);
      setSuburb(newAddress.suburb);
      setType(newAddress.type);
      setPostcode(newAddress.postcode);
      setCountryCode(newAddress.countryCode);
      setCountryCodeLocalStorage(newAddress.countryCode);
      setAddressCordinates(newAddress.latitude, newAddress.longitude);
      setAddressState(newAddress.state || null);
    }
    axios
      .get("/locationTypes?global=yes")
      .then((response) => {
        const types = response.data as LocationType[];

        setTypeOptions(
          types.map((type) => {
            return {
              value: type.slug,
              title: type.label,
              id: type.id,
              countryCode: type.countryCode,
              helperText: type.helperText,
            };
          })
        );
      })
      .catch((error) => {});
    setServiceRates(filteredServices);
  }, []);

  useEffect(() => {
    resetSelectedAddressOptions();
  }, [countryCode]);

  const resetSelectedAddressOptions = () => {
    setPetOption(null);
    setStairOption(null);
    setParkingOption(null);
    setTableOption(null);
  };

  const getPostalCodeTitle = async () => {
    let countryCodeForTitle = "";
    if (user) {
      countryCodeForTitle = getValue(user, "country", DEFAULT_COUNTRY);
    } else {
      countryCodeForTitle = await getCountryCode(true);
    }
    const postalTitle = getPostalTitle(countryCodeForTitle);
    const postalPlaceholder = getPostalPlaceholder(countryCodeForTitle);
    setPostalTitle(postalTitle);
    setPostalPlaceholder(postalPlaceholder);
  };

  const analyticsValues = () => {
    const isMassage = isMassageType();
    return {
      version: 2,
      currency: "AUD",
      total: getTotal(),
      affiliation: "web",
      subtotal: getSubtotal(),
      product_id: getTreatmentId(),
      session_type: isMassage ? sessionType : undefined,
      recipient_role: recipient?.relationship,
      recipient_gender: recipient?.gender,
      peak_hour: bookingPrice?.lateNightSurcharge,
      location_type: bookingAddress?.type,
      location_parking: bookingAddress?.parking,
      location_stairs: bookingAddress?.stairs,
      products: getProductsForSegmentTracking(),
    };
  };

  const trackInitEvent = () => {
    const data = analyticsValues();
    trackEvent("Checkout Started", data);
    trackEvent("Location Viewed", {
      step: 1,
      version: 2,
    });
    pixelsService.trackPageView();
  };

  const verifyFields = () => {
    if (isEmpty(address)) {
      setErrorMessage("Please enter your address");
      return false;
    }

    if (showSuburbPostalCountryFields) {
      if (isEmpty(suburb)) {
        setErrorMessage("Please provide your suburb");
        return false;
      }

      if (isEmpty(postcode)) {
        setErrorMessage(`Please provide your ${postalTitle.toLowerCase()}`);
        return false;
      }

      if (isEmpty(country)) {
        setErrorMessage("Please provide your country");
        return false;
      }
    }

    if (isNil(parkingOption)) {
      setErrorMessage("Please let us know if there is parking");
      return false;
    }

    if (isNil(stairOption)) {
      setErrorMessage("Please let us know if you have stairs");
      return false;
    }

    if (isNil(petOption)) {
      setErrorMessage("Please let us know if you have pets");
      return false;
    }

    if (isNil(type)) {
      setErrorMessage("Please provide the type of location");
      return false;
    }

    // if (isNil(tableOption)) {
    //   setErrorMessage("Please let us know if you have massage table");
    //   return false;
    // }

    return true;
  };

  const createNewAddress = () => {
    const data = {
      accessToken,
      type,
      address,
      fullAddress,
      suburb,
      postcode,
      instructions: specialInstructions,
      country,
      countryCode,
      latitude,
      longitude,
      stairOptionId: stairOption?.id,
      parkingOptionId: parkingOption?.id,
      petOptionId: petOption?.id,
      state: addressState,
      tableOptionId: tableOption?.id,
    };
    setSaving(true);

    createUserAddress({ data })
      .then((response) => {
        setSaving(false);

        const savedAddress = response as Address;
        setSelectedAddress(savedAddress);

        trackEvent("Location Completed", {
          step: 1,
          location_type: savedAddress.type,
          location_parking: savedAddress.parking,
          location_stairs: savedAddress.stairs,
          version: 2,
        });

        if (updatingForBooking) {
          setSaving(true);

          updateBooking()
            .then(() => {
              setSaving(false);

              history.push(`/my-bookings/${updatingForBooking.id}`);
            })
            .catch((error) => {
              setSaving(false);
              setErrorMessage("Unable to update booking");
            });
        } else {
          const nextPath = getValue(state, "next") || Paths.ServiceDetails;
          history.push(nextPath);
        }
      })
      .catch((error: AxiosError) => {
        setSaving(false);

        const errorResponse = error?.response?.data as ErrorResponse;

        if (errorResponse && errorResponse.errorMessage) {
          setErrorMessage(errorResponse.errorMessage);
        } else {
          console.log("Post Error: /users/me/addresses", error);
        }
      });
  };

  const handleSave = () => {
    if (!verifyFields()) return;
    setServiceRates(filteredServices);

    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      const data = {
        type,
        address,
        fullAddress,
        suburb,
        postcode,
        country,
        countryCode,
        instructions: specialInstructions,
        stairOptionId: stairOption?.id,
        parkingOptionId: parkingOption?.id,
        petOptionId: petOption?.id,
        // tableOptionId: tableOption?.id,
        latitude,
        longitude,
        state: addressState,
      };

      setNewAddress(data as NewAddress);
      history.push(Paths.ServiceDetails);
    } else {
      createNewAddress();
    }
  };

  const filterOptionCode = (options: any, id: any) => {
    return options.filter((option: DropdownOption) => {
      if (option.locationTypeId === null || option.locationTypeId === id) {
        return option;
      }
    });
  };

  const getFilteredOptions = (addressOptions: AddressOptions, type: string | null) => {
    const locationOption = typeOptions.find((option) => option.value == type);
    const filteredPetOption = filterOptionCode(addressOptions.petOptions, locationOption?.id);
    const filteredParkingOption = filterOptionCode(
      addressOptions.parkingOptions,
      locationOption?.id
    );
    const filteredStairOption = filterOptionCode(addressOptions.stairOptions, locationOption?.id);
    const filteredTableOption = filterOptionCode(addressOptions.tableOptions, locationOption?.id);
    return {
      filteredPetOption: filteredPetOption,
      filteredParkingOption: filteredParkingOption,
      filteredStairOption: filteredStairOption,
      filteredTableOption: filteredTableOption,
    };
  };

  const handleAddressSelected = ({ address }: { address: SelectedAddress }) => {
    if (checkIfEmpty(address)) return;

    const countryChanged = hasCountryChanged(address.countryCode, countryCode);
    setAddress(address.address);
    setFullAddress(address.fullAddress);
    setSuburb(address.suburb);
    setPostcode(address.postcode);
    setCountry(address.country);
    setCountryCode(address.countryCode || "AU");
    setAddressCordinates(address.lat, address.long);
    setCountryCodeLocalStorage(address.countryCode || "AU");
    setAddressState(address.state || null);
    setDisableOtherFields(true);
    if (countryChanged) {
      setType("");
      setPostalTitle(getPostalTitle(address.countryCode || DEFAULT_COUNTRY));
      setPostalPlaceholder(getPostalPlaceholder(address.countryCode || DEFAULT_COUNTRY));
    } else {
      address.type && setType(address.type);
    }
  };

  const handleGoBack = () => {
    const accessToken = localStorage.getItem("token");
    const nextPath = !isNil(accessToken) ? "/my-bookings" : "/";
    history.push(nextPath);
  };

  const getFilteredLocationTypes = (code: string) => {
    const countryCode = SERVICE_COUNTRIES.includes(code) ? code : "AU";
    return typeOptions.filter((l: any) => l.countryCode === countryCode);
  };

  const hasCountryChanged = (currentCountryCode: any, previousCountryCode: any) =>
    currentCountryCode !== previousCountryCode;

  const locationTypes = getFilteredLocationTypes(countryCode || "AU");
  const filteredAddressOptions = getFilteredOptions(allAddressOptions, type);

  const onMapSelected = () => {
    setIsMapSelected(true);
  };

  return (
    <NewBookingWrapper
      overline="Step 1 of 7"
      title="Booking location"
      // subtitle="Allow up to 1 hr for your therapist to reach you"
      footerLeftButton={
        <Button
          type={ButtonType.outlined}
          title={updatingForBooking ? "Back" : "Previous"}
          onClick={handleGoBack}
          style={{ padding: "12px 24px" }}
        />
      }
      footerRightButton={
        <Button
          type={updatingForBooking ? ButtonType.secondary : ButtonType.indigo}
          title={updatingForBooking ? "Save & apply" : "Continue"}
          onClick={handleSave}
          loading={saving}
          style={{ padding: "12px 24px" }}
        />
      }
      progress={STEP_PROGRESS[1]}
    >
      <Box mt={4} />

      <Dialog
        open={isMapSelected}
        maxWidth={false}
        fullScreen={isMobile}
        onClose={() => setIsMapSelected(false)}
      >
        <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
          <Box paddingLeft="40px" paddingRight="40px" paddingBottom="40px" paddingTop="32px">
            <Box
              fontFamily="Museo"
              fontSize="22px"
              fontWeight="600"
              color={Colors.Indigo}
              textAlign="center"
              mb={2}
            >
              Set your location
            </Box>
            <SelectLocationMap
              setIsMapSelected={setIsMapSelected}
              onSelectedAddress={(address) => handleAddressSelected({ address })}
            />
          </Box>
        </Box>
      </Dialog>

      <AddressAutocomplete
        locationType={type}
        showSavedAddresses
        value={fullAddress || ""}
        onChange={(text) => setFullAddress(text)}
        onSelectedAddress={(address) => handleAddressSelected({ address })}
        onMapSelected={onMapSelected}
      />
      {showSuburbPostalCountryFields && (
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <TextField
            title="Suburb"
            placeholder="Enter suburb"
            value={suburb}
            onChange={(text) => setSuburb(text)}
            disabled={disableOtherFields}
            useInputTextColor={true}
          />
          <Box width="60px" />
          <TextField
            title={postalTitle}
            placeholder={postalPlaceholder}
            value={postcode}
            onChange={(text) => setPostcode(text)}
            disabled={disableOtherFields}
            useInputTextColor={true}
          />
          <Box width="60px" />
          <TextField
            title="Country"
            placeholder={defaultCountry}
            value={country}
            onChange={(text) => setCountry(text)}
            disabled={disableOtherFields}
            useInputTextColor={true}
          />
        </Box>
      )}
      <Dropdown
        title="Location type"
        options={locationTypes}
        selectedOption={locationTypes.find((option) => option.value === type)}
        onSelectedOption={(option) => {
          setType(option.value);
        }}
      />
      <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Dropdown
          title="Parking"
          options={filteredAddressOptions.filteredParkingOption}
          selectedOption={parkingOption}
          onSelectedOption={(option: DropdownOption) => {
            setParkingOption(option);
          }}
        />
        <Box width="60px" />

        <Dropdown
          title="Do you have stairs at this location?"
          options={filteredAddressOptions.filteredStairOption}
          selectedOption={stairOption}
          onSelectedOption={(option) => {
            setStairOption(option);
          }}
        />
        <Box width="60px" />

        <Dropdown
          title="Do you have pets at this location?"
          options={filteredAddressOptions.filteredPetOption}
          selectedOption={petOption}
          onSelectedOption={(option: DropdownOption) => {
            setPetOption(option);
          }}
        />
      </Box>
      {/* <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Dropdown
          title="Do you have pets at this location?"
          options={filteredAddressOptions.filteredPetOption}
          selectedOption={petOption}
          onSelectedOption={(option: DropdownOption) => {
            setPetOption(option);
          }}
        />
        <Box width="60px" />
        <Dropdown
          title="Do you have your own professional massage table in this location?"
          options={filteredAddressOptions.filteredTableOption}
          selectedOption={tableOption}
          onSelectedOption={(option: DropdownOption) => {
            setTableOption(option);
          }}
        />
      </Box> */}
      <TextField
        title="Location notes"
        placeholder={LOCATION_NOTES_PLACEHOLDER}
        value={specialInstructions}
        onChange={(text) => setSpecialInstructions(text)}
        multiline
        maxLength={LOCATION_NOTES_LIMIT}
      />
    </NewBookingWrapper>
  );
}
