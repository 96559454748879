import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import { Moment } from "moment";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import { User } from "../../../models";
import { RootState } from "../../../stores/V2";
import LoginModal from "../../Login/LoginModal";
import { checkIfEmpty, getValue } from "../../../utils/object";
import Divider from "../../../components/Divider";
import { Colors } from "../../../constants/colors";
import SignUpModal from "../../SignUp/SignUpModal";
import { useUserStore } from "../../../stores/user";
import RecurringInfo from "../../Job/RecurringInfo";
import { KEYS } from "../../../hooks/userTerms.hook";
import { useAlertStore } from "../../../stores/alert";
import { useAccessToken } from "../../../hooks/common";
import { getCurrentMoment } from "../../../helpers/time";
import TherapistIcon from "../../../images/pro-icon.png";
import ShareIcon from "../../../images/share-icon.svg";
import { parseApiV2Error } from "../../../helpers/error";
import { BOOKING_TIME, TOTAL_DUE_INFO } from "../../../constants/booking";
import { useMobile, useTab } from "../../../hooks/mobile";
import { invalidateQueries } from "../../../services/query";
import { isCoupleMassageType } from "../../../stores/booking";
import { BOOKING_SESSION_TYPE } from "../../../helpers/booking";
import { PRO_ACCOUNT_STATUS } from "../../../constants/profile";
import Button, { ButtonType } from "../../../components/Button";
import { useJobDetailPublic } from "../../../hooks/job/job.hooks";
import { LoadingSpinner } from "../../../components/loadingSpinner";
import { getJobStatus } from "../../../helpers/proDashPastbookings";
import BookingDetails from "../../Job/BookingDetails/BookingDetails";
import InfoModal from "../../../components/Modals/InfoModal/InfoModal";
import CustomTimePicker from "../../../components/TimePicker/TimePicker";
import AcceptJobModal from "../../Job/Modals/AcceptModal/AcceptJobModal";
import ForgotPasswordModal from "../../ForgotPassword/ForgotPasswordModal";
import {
  acceptJob,
  getFormattedJob,
  shouldHideSensitiveData,
  validateTimeOfArrival,
} from "../../../services/proDashboard/job.service";
import { actions as bookingActions } from "../../../stores/V2/booking/booking";
import { hasRangedDates } from "../../../services/bookings/bookingTime.service";
import PayoutExpectedSummary from "../../Job/PayoutSummary/PayoutExpectedSummary";
import DateAndTimeModal from "../../Job/Modals/DateAndTimeModal/DateAndTimeModal";
import BookingStatusPill from "../../../components/Bookings/V2/BookingStatusPill";
import TreatmentDetailSection from "../../Job/TreatmentDetails/TreatmentDetailsSection";
import { calculateMinMaxMinutes, isOldBooking as isPastBooking } from "../../Job/utils/utils";
import { FontFamily, FontSize, FontWeight, Spacing } from "../../../components/v2/Styled/enum";
import PastBookingWarningModal from "../../Job/Modals/PastBookingWarningModal/pastBookingWarningModal";
import ClientTermsAndPoliciesModal from "../../Job/Modals/ClientTermsAndPoliciesModal/clientTermsAndPoliciesModal";
import {
  bookingInitializerPublic,
  bookingInitializerUsingJobId,
} from "../../../stores/V2/booking/booking.initializers";
import {
  addMinutes,
  convertToMoment,
  isFutureMoment,
  isSameDay,
  isSameMoment,
  isSelectedDateBeforeEndDate,
} from "../../../utils/date.util";
import useShareSheet from "../../../hooks/shareSheet";

interface Props {
  jobId: number;
  handleJobOpen: ({ jobId }: { jobId: number }) => unknown;
  handleJobShare?: () => unknown;
}

const JobDetailPublicV3 = (props: Props) => {
  const { jobId, handleJobOpen } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadbooking, setLoadBooking] = useState(true);

  const { user, fetchMe, isFetchingUser } = useUserStore();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const [selectedArrivalType, setArrivalType] = useState(BOOKING_TIME.PREFERRED);

  const bookingData = useSelector(({ booking }: RootState) => booking);

  const accessToken = useAccessToken();

  const isMobile = useMobile();
  const isTab = useTab();

  const [showOldBookingWarning, setShowOldBookingWarning] = useState(false);
  const [forgotPasswordPrefillEmail, setForgotPasswordPrefillEmail] = useState("");
  const [showClientAgreementModal, setShowClientAgreementModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [showAcceptRangeModal, setShowAcceptRangeModal] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [showDateAndTime, setShowDateAndTime] = useState(false);
  const [showTotalDueUpToModal, setShowTotalDueUpToModal] = useState(false);

  const [callBackFunction, setCallBackFunction] = useState<string | null>(null);
  const [selectedArrivalTime, setSelectedArrivalTime] = useState(getCurrentMoment());
  const shareSheet = useShareSheet();

  const {
    job,
    isLoading,
    refetch: refreshJobDetails,
    isRefetching,
  } = useJobDetailPublic({ jobId, accessToken });

  const [data, setData] = useState<any>(null);

  const shouldRestrictData = (user: User | null) => {
    const accountStatus = getValue(user, "therapistprofile.status") || PRO_ACCOUNT_STATUS.PENDING;
    return shouldHideSensitiveData({ accountStatus });
  };

  const hideSensitiveData = shouldRestrictData(user);
  const handleUpdate = () => {};
  const booking = getValue(job, "bookingdetail.booking");

  const sessionType = getValue(booking, "sessionType");
  const isCoupleBooking = isCoupleMassageType(sessionType);
  const bookingDetailId = getValue(job, "bookingdetail.id");

  const getTreatmentDetails = () => {
    const bookingdetails =
      getValue(bookingData, "bookingDetails") || getValue(bookingData, "bookingdetails");

    let bookingDetails = bookingdetails;

    if (isCoupleBooking) {
      bookingDetails = bookingdetails.filter(
        (data: any) => data.bookingDetailId === bookingDetailId
      );
    }

    let treatments = bookingDetails.map((details: any) => {
      let { treatmentDetails } = details;
      if (!checkIfEmpty(treatmentDetails)) {
        const updatedDetails = treatmentDetails.map((detail: any) => ({
          ...detail,
          treatmentId: detail.id || detail.treatmentId,
        }));
        treatmentDetails = updatedDetails;
      }
      return treatmentDetails;
    });
    return treatments.flat();
  };

  const treatmentDetails = getTreatmentDetails();
  const therapistCount = getValue(booking, "bookingdetails", []).length || 1;

  const jobStatus = getJobStatus({
    job,
  });

  const setDefaultArrivalTime = ({ arrivalType }: any) => {
    const booking = getValue(job, "bookingdetail.booking");
    if (!booking) return;

    let { earliestTime } = booking;
    if (arrivalType === BOOKING_TIME.BACKUP && booking?.backup) {
      const backup = booking.backup;
      earliestTime = backup?.earliestTime;
    }

    const selectedMoment = convertToMoment(earliestTime, booking.timezone);
    setSelectedArrivalTime(selectedMoment);
  };

  const initBooking = () => {
    if (loadbooking) {
      initialize();
    }
  };

  const initialize = async () => {
    try {
      if (!jobId) return;
      dispatch(bookingActions.toggleFetching({ isFetching: true }));
      if (accessToken) {
        bookingInitializerUsingJobId(jobId.toString(), dispatch, (message) => {
          setErrorMessage(message);
        });
      } else {
        bookingInitializerPublic(job, dispatch, (message) => {
          setErrorMessage(message);
        });
      }
    } catch (err) {
      console.log("Error encountered!", err);
    }
  };

  useEffect(() => {
    if (job && job.id) {
      initBooking();
    }
  }, [isLoading, job]);

  useEffect(() => {
    const formattedData = getFormattedJob(job);
    setDefaultArrivalTime({ arrivalType: selectedArrivalType });
    setData(formattedData);
  }, [job]);

  useEffect(() => {
    //console.log(`Is fetching user: ${isFetchingUser}`);
    if (!isFetchingUser && callBackFunction && accessToken) {
      // refreshJobDetails().then(() => {
      // eslint-disable-next-line no-eval
      eval(callBackFunction + "()");
      setCallBackFunction(null);
      // });
    }
  }, [user, isFetchingUser, accessToken]);

  if (isLoading) {
    return (
      <Box>
        <LoadingSpinner></LoadingSpinner>
      </Box>
    );
  }

  const onAcceptRangedBooking = ({
    jobId,
    selectedDateTime,
  }: {
    jobId: string;
    selectedDateTime: Moment;
  }) => {
    acceptBooking({ timeOfArrival: selectedDateTime });
  };

  const onAcceptConfirmed = () => {
    setShowAcceptModal(false);
    setShowClientAgreementModal(false);

    const booking = getValue(job, "bookingdetail.booking");
    if (checkIfEmpty(booking)) return;

    let { earliestTime, latestTime, timezone } = booking;
    const hasDateRange = hasRangedDates({ booking, timezone });

    let datePickerRequired = hasDateRange;
    if (booking.backup && !datePickerRequired) {
      const { earliestTime: backupEarliest } = booking.backup;
      datePickerRequired = !isSameDay(earliestTime, backupEarliest, timezone);
    }

    if (datePickerRequired) {
      setShowAcceptRangeModal(true);
      return;
    }

    let isPickerRequired =
      booking.sessionType !== BOOKING_SESSION_TYPE.COUPLES &&
      !isSameMoment(earliestTime, latestTime);

    if (booking.backup && !isSameMoment(earliestTime, booking.backup?.earliestTime)) {
      isPickerRequired = true;
    }

    if (isPickerRequired) {
      setShowTimePicker(true);
      return;
    }

    acceptBooking({ timeOfArrival: convertToMoment(earliestTime, booking.timezone) });
  };

  const acceptBooking = async ({ timeOfArrival }: any) => {
    try {
      toggleDisableActions(true);

      // validate timeOfArrival and arrival type

      const booking = getValue(job, "bookingdetail.booking");
      if (!booking) return;

      const { timezone } = booking;
      const { isValid, arrivalType } = validateTimeOfArrival(
        timeOfArrival,
        timezone,
        booking,
        setErrorMessage
      );
      if (!isValid) {
        toggleDisableActions(false);
        return;
      }

      await acceptJob({
        jobId: job.id,
        status: arrivalType,
        timeOfArrival,
      });
      invalidateQueries([`PUBLIC-job-${job.id}`]);
      invalidateQueries([KEYS.JOB_AGREEMENTS]);
      // history.push(Paths.ProDashboardBookings, { section: BOOKINGS_SECTION_VALUES.UPCOMING });
      setSuccessMessage("The job has been accepted");
      handleJobOpen({ jobId: jobId });
    } catch (err: any) {
      setErrorMessage(err.message || "Sorry something went wrong. Please try again.");
    }

    toggleDisableActions(false);
  };

  const toggleDisableActions = (value: boolean) => {
    setDisableActions(value);
  };

  const isOldBooking = isPastBooking(booking);

  const onAcceptClicked = async () => {
    if (user && user.therapistprofile && !shouldRestrictData(user)) {
      const result = await refreshJobDetails();
      if (isOldBooking) {
        history.push({
          pathname: `pro/bookings/${jobId}`,
          state: { openOldBookingWarningModal: true },
        });
      } else if (result?.data?.job?.isAgreementRequired) {
        history.push({
          pathname: `pro/bookings/${jobId}`,
          state: { openShowClientAgreementModal: true },
        });
      } else {
        history.push({
          pathname: `pro/bookings/${jobId}`,
          state: { openAcceptModal: true },
        });
      }
    }
    if (user && !user.therapistprofile) {
      setLoginModalOpen(false);
      history.push("pro/dashboard");
    } else {
      // if (!accessToken) {
      setCallBackFunction("onAcceptClicked");
      setLoginModalOpen(true);
      /*} else {
        fetchMe();
      }*/
      // history.push(Paths.Login, { jobId: job.id });un
    }
  };

  const addressSuburb = getValue(data, "address.suburb");

  const getTimeLimits = ({ booking }: any) => {
    let { earliestTime, latestTime, backup: backupDate } = booking;
    if (!isFutureMoment(addMinutes(earliestTime, 5).toISOString(), booking.timezone)) {
      // add now +5 minutes to earliest time
      earliestTime = addMinutes(new Date().toUTCString(), 5);
    }
    if (!backupDate) return { min: earliestTime, max: latestTime };

    let { earliestTime: backupEarliest, latestTime: backupLatest } = backupDate;
    if (!isFutureMoment(addMinutes(backupEarliest, 5).toISOString(), booking.timezone)) {
      // add now +5 minutes to earliest time
      backupEarliest = addMinutes(new Date().toUTCString(), 5);
    }

    const min = isSelectedDateBeforeEndDate(earliestTime, backupEarliest)
      ? earliestTime
      : backupEarliest;
    const max = isSelectedDateBeforeEndDate(latestTime, backupLatest) ? backupLatest : latestTime;
    return { min, max };
  };

  const { min: minTime, max: maxTime } = getTimeLimits({ booking });
  const { max: maxMinutes, min: minMinutes } = calculateMinMaxMinutes(booking);

  const dismissTimePicker = () => {
    setShowTimePicker(false);
    setDefaultArrivalTime({ arrivalType: selectedArrivalType });
  };

  const handleAccept = (date: any, timezone: string) => {
    try {
      setShowTimePicker(false);
      setSelectedArrivalTime(date);

      acceptBooking({ timeOfArrival: date });
    } catch (err: any) {
      parseApiV2Error(err);
    }
  };

  const onAlternateOffer = () => {
    if (user && user.therapistprofile && !shouldRestrictData(user)) {
      history.push({
        pathname: `pro/bookings/${jobId}`,
        state: { openOfferAlternativeModal: true },
      });
    } else {
      setCallBackFunction("onAlternateOffer");
      setLoginModalOpen(true);
      // history.push(Paths.Login, { jobId: job.id });
    }
  };

  const handleOfferSuccess = () => {
    refreshJobDetails();
    setShowDateAndTime(false);
  };

  const handleShowTotalDueUpToModalOpen = () => {
    setShowTotalDueUpToModal(true);
  };

  const handleShowTotalDueUpToModalClose = () => {
    setShowTotalDueUpToModal(false);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        paddingTop: Spacing.S6,
        paddingBottom: Spacing.S6,
        paddingLeft: Spacing.S6,
        paddingRight: Spacing.S6,
      }}
      borderRight={"1px solid #e0e2e7"}
      borderLeft={"1px solid #e0e2e7"}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              style={{
                fontFamily: FontFamily.Museo,
                fontSize: FontSize.F24,
                fontWeight: FontWeight.Bold,
                lineHeight: Spacing.S6,
                color: Colors.NightBlue,
              }}
            >
              {getValue(booking, "title", "")}
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexWrap: "wrap", gap: 12, alignItems: "center" }}>
            <Typography
              style={{
                fontFamily: FontFamily.Museo,
                fontSize: FontSize.F16,
                fontWeight: FontWeight.Medium,
                lineHeight: Spacing.S6,
                color: Colors.Dusk,
              }}
            >
              Booking #{getValue(job, "id", "NNNNNN")}
            </Typography>
            <BookingStatusPill status={jobStatus} />
          </Box>
        </Box>
        <Box>
          {!isMobile && !isTab && shareSheet.isSupported && (
            <Box
              width={Spacing.S5}
              height={Spacing.S5}
              padding={Spacing.S2}
              border={1}
              borderColor={Colors.LightPeriwinkle}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={Spacing.S2}
            >
              <img
                src={ShareIcon}
                style={{ cursor: "pointer" }}
                onClick={props.handleJobShare}
                alt={"share"}
              />
            </Box>
          )}
        </Box>
      </Box>
      {job && (
        <Box style={{ display: "flex", flexDirection: "column", gap: Spacing.S6 }}>
          <BookingDetails
            job={job}
            address={getValue(booking, "address")}
            hideSensativeData={false}
            booking={booking}
            openMapModal={() => {}}
            isPaidBooking={false}
            onBookingUpdate={handleUpdate}
            style={{ marginTop: Spacing.S6, marginBotton: Spacing.S6 }}
          />
          <Divider />
          <Box style={{ display: "flex", flexDirection: "column", gap: Spacing.S3 }}>
            <Typography
              style={{
                fontFamily: FontFamily.Museo,
                fontWeight: FontWeight.Bold,
                fontSize: FontSize.F16,
                lineHeight: Spacing.S6,
                color: Colors.NightBlue,
              }}
            >
              Service Details
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: Spacing.S4,
              }}
            >
              <TreatmentDetailSection
                job={job}
                booking={booking}
                sessionType={getValue(booking, "sessionType")}
                corporateInfo={getValue(booking, "corporateInfo")}
                treatmentDetails={treatmentDetails}
              />
              <RecurringInfo booking={booking} />
              <Box display="flex" alignItems="center" gridGap={16}>
                <img src={TherapistIcon} alt="therapist" style={{ width: 24, height: 24 }} />
                <Box
                  style={{
                    color: Colors.Dusk,
                    fontFamily: "Museo",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {therapistCount} {therapistCount > 1 ? "Therapists" : "Therapist"}
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <PayoutExpectedSummary
            booking={booking}
            job={job}
            onClick={handleShowTotalDueUpToModalOpen}
          />
          <Divider />
          <Box
            style={{
              display: "flex",
              flexDirection: !isMobile ? "row" : "column",
              gap: Spacing.S4,
            }}
          >
            <Button
              title="Make an offer "
              type={ButtonType.outlined}
              onClick={onAlternateOffer}
              disabled={disableActions}
            />
            <Button
              title="Accept"
              type={ButtonType.secondary}
              onClick={onAcceptClicked}
              disabled={disableActions}
            />
          </Box>
        </Box>
      )}
      {isNil(user) && (
        <LoginModal
          open={loginModalOpen}
          onClose={() => {
            setCallBackFunction(null);
            setLoginModalOpen(false);
          }}
          onLoggedIn={() => {
            fetchMe();
            setLoginModalOpen(false);
          }}
          onForgotPassword={(email) => {
            setForgotPasswordPrefillEmail(email);
            setLoginModalOpen(false);
            setForgotPasswordModalOpen(true);
          }}
          onCreateAccount={() => {
            setLoginModalOpen(false);
            setSignUpModalOpen(true);
          }}
          redirect={false}
        />
      )}
      {isNil(user) && (
        <SignUpModal
          open={signUpModalOpen}
          onLogin={() => {
            setSignUpModalOpen(false);
            setLoginModalOpen(true);
          }}
          onCreatedAccount={() => {
            fetchMe();
            setSignUpModalOpen(false);
          }}
          onClose={() => setSignUpModalOpen(false)}
        />
      )}
      {isNil(user) && (
        <ForgotPasswordModal
          open={forgotPasswordModalOpen}
          onClose={() => setForgotPasswordModalOpen(false)}
          onBackToLogin={() => {
            setForgotPasswordModalOpen(false);
            setLoginModalOpen(true);
          }}
          onSentInstructions={(email: any) => {
            setForgotPasswordModalOpen(false);
            setSuccessMessage(`Password reset instructions have been sent to ${email}`);
          }}
          prefillEmail={forgotPasswordPrefillEmail}
        />
      )}
      <DateAndTimeModal
        visible={showAcceptRangeModal}
        isMobile={isMobile}
        isTab={isTab}
        onClose={() => setShowAcceptRangeModal(false)}
        jobId={job.id?.toString()}
        offeredTime={booking?.earliestTime}
        timezone={getValue(booking, "timezone")}
        label="Time of arrival"
        actionName="Accept"
        onConfirm={onAcceptRangedBooking}
        maxMinutes={maxMinutes}
        minMinutes={minMinutes}
        restrictTimeOptions={true}
      />
      <CustomTimePicker
        onOpen={() => {}}
        onChange={() => {}}
        open={showTimePicker}
        onClose={dismissTimePicker}
        maxTime={maxTime}
        minTime={minTime}
        timezone={getValue(booking, "timezone")}
        onAccept={(date) => handleAccept(date, booking.timezone)}
      />
      <InfoModal
        visible={showTotalDueUpToModal}
        title={TOTAL_DUE_INFO.title}
        description={TOTAL_DUE_INFO.description}
        handleClose={handleShowTotalDueUpToModalClose}
        divider={false}
        actionName={"Close"}
      />
    </Box>
  );
};

export default JobDetailPublicV3;
