import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Each from "../../../components/Each";
import { Paths } from "../../../constants/paths";
import { Booking, Recipient } from "../../../models";
import { checkIfEmpty } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import Plus from "../../../images/plus-white.svg";
import { Colors } from "../../../constants/colors";
import { Box, Text } from "../../../components/v2/Styled";
import { useBookingStore, useRecipientDetailsStore } from "../../../stores/booking";
import {
  BOOKING_STATUS,
  MassageFor,
  RECIPIENT_UPCOMING_BOOKINGS_PER_PAGE,
} from "../../../constants/booking";
import BookingItem from "../../../components/Bookings/BookingItem";
import { LoadingSpinner } from "../../../components/loadingSpinner";
import PaginationControl from "../../../components/PaginationControl";
import InfoModal from "../../../components/Modals/InfoModal/InfoModal";
import { useUpcomingBookings } from "../../../hooks/booking/booking.hooks";
import { FilterButton } from "../../../components/FilterButton/FilterButton";
import useBookingFilter from "../../../hooks/booking/bookingFilter.hook";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { NoFilteredBookings } from "../../../components/Bookings/NoFilteredBookings";
import { useBookingFilterCount, useCachedBookingSort } from "../../../hooks/utility/filter.hooks";
import { actions as bookingActions } from "../../../stores/V2/booking/booking";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../../components/v2/Styled/enum";
import {
  BookingFilterModal,
  FilterBookingValue,
} from "../../../components/Modals/FilterModal/BookingFilterModal";
import NoBookingsPlaceholder, {
  NoBookingsPlaceholderType,
} from "../../../components/Bookings/NoBookingsPlaceholder";
import { useDispatch } from "react-redux";
interface Props {
  recipient: Recipient;
}

const perPage = RECIPIENT_UPCOMING_BOOKINGS_PER_PAGE;

const RecipientUpcomingBookings = ({ recipient }: Props): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showAlternativeOfferModal, setShowAlternativeOfferModal] = React.useState(false);
  const [showFilterAndSortModal, setShowFilterAndSortModal] = React.useState(false);
  const { cachedSortBy } = useCachedBookingSort();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const bookingSortBy = cachedSortBy || "asc";
  const [searchQuery, setSearchQuery] = useState<FilterBookingValue>({ sortBy: bookingSortBy });
  const [numOfFilter, setNumOfFilter] = useState(bookingSortBy !== "asc" ? 1 : 0);

  const { getFilterCount } = useBookingFilterCount();
  const handleBookingClicked = (booking: Booking, status: string) => {
    const { id: bookingId } = booking;

    const url =
      status === BOOKING_STATUS.ARRANGED || status === BOOKING_STATUS.DECLINED
        ? `/my-bookings/review/${bookingId}`
        : `/my-bookings/${bookingId}`;
    history.push(url, {
      back: `${location.pathname}${location.search}`,
    });
  };

  const history = useHistory();
  const location = useLocation();
  const { setRecipient } = useBookingStore();

  const { safeBookingQueries } = useBookingFilter({ forUpcoming: true });

  const { isLoading, upcomingBookings, invalidateUpcomingBookings, pageCount } =
    useUpcomingBookings({
      currentPage,
      perPage: perPage,
      query: { ...searchQuery, ...safeBookingQueries, recipientId: recipient.id },
    });

  const handleNewBookingClicked = () => {
    dispatch(bookingActions.updateBookingRecipient({ recipient }));
    setRecipient(recipient);
    useRecipientDetailsStore.setState({
      massageFor: MassageFor.someoneElse,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
    });
    // setExitBookingFlowPath(location.pathname);
    const searchParams = new URLSearchParams();
    searchParams.set("recipientId", recipient.id.toString());
    history.push({
      pathname: Paths.ReviewAndBook,
      search: searchParams.toString(),
    });
  };

  const onAlternativeTimePressed = (booking: Booking) => {
    const { id: bookingId, totalAlternativeDateOffered: offers, timezone } = booking;

    const url = `bookings/${bookingId}/alternative-offer`;

    offers === 0
      ? setShowAlternativeOfferModal(true)
      : history.push(url, {
          bookingId,
          timezone,
        });
  };

  useEffect(() => {
    setNumOfFilter(getFilterCount(safeBookingQueries, "asc"));
  }, [safeBookingQueries, getFilterCount]);

  const buildRow = (booking: Booking) => {
    return (
      <BookingItem
        key={booking.id}
        fullwidth
        booking={booking}
        onClick={() => handleBookingClicked(booking, booking.status)}
        handleAlternativeTimes={() => onAlternativeTimePressed(booking)}
        bookingMutate={invalidateUpcomingBookings}
      />
    );
  };

  return (
    <Box gap={Spacing.S6} marginY={Spacing.S11} direction={FlexDirection.Column}>
      {/* Label */}
      <Box flex={1} alignItems={"center"} justifyContent={isMobile ? "flex-end" : "space-between"}>
        {isMobile ? (
          <></>
        ) : (
          <Text
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
            size={FontSize.F24}
            color={Colors.NightBlue}
          >
            {recipient.firstName}'s upcoming bookings
          </Text>
        )}
        <div
          style={{
            display: "flex",
            gap: isMobile ? "8px" : "16px",
            alignItems: "center",
            width: isMobile ? "100%" : "",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={() => {
              handleNewBookingClicked();
            }}
            style={{
              borderRadius: "100px",
              maxWidth: "179px",
            }}
            icon={Plus}
            title="Add new booking"
          />
          <div style={{ gap: 0, width: "200px" }}>
            <FilterButton
              title={isMobile ? "" : "Filter and sort"}
              onClick={() => setShowFilterAndSortModal(true)}
              filterCount={numOfFilter}
            />
          </div>
        </div>
      </Box>

      {isLoading ? <LoadingSpinner /> : <></>}

      {checkIfEmpty(upcomingBookings) && !isLoading ? (
        numOfFilter ? (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <NoFilteredBookings sortOrEditFilter={setShowFilterAndSortModal} />
          </Box>
        ) : (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <div
              style={{
                marginTop: isMobile ? 0 : "8vh",
              }}
            >
              <NoBookingsPlaceholder
                type={NoBookingsPlaceholderType.upcoming}
                onNewBookingClicked={() => {
                  handleNewBookingClicked();
                }}
                onPastBookingsClicked={() => {
                  history.push(Paths.PastBookings);
                }}
              />
            </div>
          </Box>
        )
      ) : (
        <Box direction={FlexDirection.Column}>
          <Each of={upcomingBookings} render={buildRow} />
        </Box>
      )}

      <InfoModal
        visible={showAlternativeOfferModal}
        title={"Offers from providers"}
        description={
          "While you're waiting to be connected with a provider, if a provider offers their availability and/or preferred rate, you'll see them here. You'll have the option to accept one of the offers and get your booking confirmed instantly."
        }
        handleClose={() => setShowAlternativeOfferModal(false)}
        divider={false}
      />
      {pageCount > 0 ? (
        <Box justifyContent={JustifyContent.end}>
          <PaginationControl
            currentPage={currentPage}
            pages={pageCount}
            onNext={() => setCurrentPage(currentPage + 1)}
            onPrevious={() => setCurrentPage(currentPage - 1)}
            startFromOne
          />
        </Box>
      ) : (
        <></>
      )}

      <BookingFilterModal
        open={showFilterAndSortModal}
        onClose={() => setShowFilterAndSortModal(false)}
        forUpcoming
        showRecipientField={false}
      />
    </Box>
  );
};

export default RecipientUpcomingBookings;
