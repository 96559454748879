import { Box, Typography } from "@material-ui/core";
import { useState } from "react";
import Dialog from "../../Dialog";
import { Spacing } from "../../v2/Styled/enum";
import { useMobile } from "../../../hooks/mobile";
import { TippingAmounts } from "./TippingAmounts";
import Button, { ButtonType } from "../../Button";
import { BOOKING_STATUS } from "../../../constants/booking";
import { BOOKING_TIP_TYPE } from "../../../constants/tip";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";

type Props = {
  visible: boolean;
  onClose: () => void;
  onAdd: (tipAmount: number, cardId: string) => void;
  baseRate: number;
  currency: string;
  therapistCount?: number;
  bookingType?: BOOKING_STATUS;
  proName?: string;
  proImage?: string;
  selectedCardId?: string;
  tipAmount?: number;
  countryCode: string;
};

export const SingleProTippingModal = ({
  therapistCount = 1,
  bookingType = BOOKING_STATUS.NEW,
  ...props
}: Props) => {
  const [tipAmount, setTipAmount] = useState(props.tipAmount ?? 0);
  const [tipCard, setTipCard] = useState(props.selectedCardId ?? BOOKING_TIP_TYPE.NOT_NOW);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isMobile = useMobile();

  const onAddClicked = () => {
    props.onAdd(tipAmount, tipCard);
  };

  return (
    <>
      <Dialog
        open={props.visible}
        fullWidth={isMobile}
        maxWidth={"md"}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: !isMobile ? "618px" : undefined,
            padding: !isMobile ? "40px" : "24px",
            gap: "20px",
            boxSizing: "border-box",
          },
        }}
      >
        {/* Dialog Title */}
        <Box>
          <Box display={"flex"} gridGap={"8px"} flexDirection={"column"}>
            {bookingType !== BOOKING_STATUS.NEW && (
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <img
                  src={
                    props.proImage
                      ? `${BASE_UPLOADS_URL}/${props.proImage}`
                      : ProfilePicturePlaceholder
                  }
                  alt="Profile"
                  style={Styles.profileImageStyle}
                />
              </Box>
            )}
            <Typography>
              <Box sx={Styles.title}>
                {bookingType !== BOOKING_STATUS.NEW ? `Tip for ${props.proName}` : "Add a tip"}
              </Box>
            </Typography>
          </Box>
        </Box>

        {/* Dialog content */}
        <Box display={"flex"} flexDirection={"column"}>
          <TippingAmounts
            baseRate={props.baseRate}
            bookingType={bookingType}
            currency={props.currency}
            therapistCount={therapistCount}
            onTipUpdate={(tipAmount, cardId) => {
              if (cardId === BOOKING_TIP_TYPE.CUSTOM && tipAmount === 0) {
                setErrorMessage("Please enter a valid tip amount");
                return;
              }

              setTipAmount(tipAmount);
              setTipCard(cardId);
              setErrorMessage(null);
            }}
            selectedCardId={tipCard}
            customTipAmount={tipAmount}
            countryCode={props.countryCode}
            textFieldError={errorMessage}
          />
        </Box>

        {/*  Dialog Actions */}
        <Box display={"flex"} gridGap={Spacing.S4} width={"100%"}>
          <Button type={ButtonType.outlined} onClick={props.onClose} title="Cancel" />
          <Button
            type={ButtonType.secondary}
            onClick={onAddClicked}
            title="Add"
            disabled={errorMessage !== null}
          />
        </Box>
      </Dialog>
    </>
  );
};

const Styles = {
  title: { fontWeight: 600, fontSize: "22px", color: "#100249", textAlign: "center" },
  profileImageStyle: {
    width: "96px",
    height: "96px",
    borderRadius: "48px",
    "object-fit": "cover",
  },
};
