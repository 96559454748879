import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";

type Props = {
  text: string;
  imageSrc: string;
};

export function ProfileBadge(props: Props) {
  return (
    <Box
      bgcolor="#D5F1CD"
      height="38px"
      paddingLeft="10px"
      paddingRight="10px"
      borderRadius="40px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt="4px"
      mb="4px"
    >
      <img
        alt={props.text}
        src={props.imageSrc}
        style={{
          height: "22px",
          width: "22px",
          borderRadius: "50%",
        }}
      />
      <Box fontFamily="Museo" fontWeight={600} fontSize="14px" color={Colors.Dusk} ml="8px">
        {props.text}
      </Box>
    </Box>
  );
}
