import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { BookingSlot } from "../../../stores/V2/corporate/interface";
import ClockTimerImg from "../../../images/clock-timer.png";
import LockImg from "../../../images/lock.png";
import { getTimeTitle } from "../../../utils/date.util";

interface Props {
  slot: BookingSlot;
  timezone: string;
  allowBreakTimeUpdate: boolean;
  breakSlotWidth: number;
  breakSlotLeftPosition: number;
  isMobile: boolean;
  onClick: (slot: BookingSlot) => unknown;
  isDisabled?: boolean;
}

const BreakSlot = (props: Props) => {
  const {
    slot,
    timezone,
    onClick,
    allowBreakTimeUpdate,
    breakSlotLeftPosition,
    breakSlotWidth,
    isMobile,
    isDisabled,
  } = props;

  const breakStartTime = getTimeTitle(slot.start || "", timezone, false) || "";
  const breakEndtime = getTimeTitle(slot.end || "", timezone, false) || "";

  const onSlotClicked = () => {
    if (isDisabled) return;
    onClick(slot);
  };

  return (
    <Box
      style={{
        backgroundColor: allowBreakTimeUpdate && !isDisabled ? Colors.LightCyan : "#E7E7E7",
        height: "48px",
        width: `${breakSlotWidth}%`,
        marginBottom: "16px",
        borderRadius: "5px",
        cursor: "pointer",
        left: `${breakSlotLeftPosition}%`,
        position: "relative",
        zIndex: 1000,
      }}
      onClick={onSlotClicked}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isMobile ? "flex-start" : "center"}
        gridGap="10px"
        height="48px"
        pl={isMobile ? "40px" : "0px"}
      >
        <Box
          color={Colors.Dusk}
          fontSize="14px"
          fontFamily="Museo"
          fontWeight={700}
          textAlign="center"
        >
          Break: {breakStartTime} - {breakEndtime}
        </Box>
        {allowBreakTimeUpdate ? (
          <img src={ClockTimerImg} alt="Timer" style={{ width: "26px", height: "26px" }} />
        ) : (
          <img src={LockImg} alt="Lock" style={{ width: "12px", height: "15px" }} />
        )}
      </Box>
    </Box>
  );
};

export default BreakSlot;
