import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import "../../../styles/DayPicker.css";
import { RootState } from "../../../stores/V2";
import { Paths } from "../../../constants/paths";
import { useMobile } from "../../../hooks/mobile";
import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import LogoLink from "../../../components/LogoLink";
import { useAlertStore } from "../../../stores/alert";
import { getTimeTitle } from "../../../utils/date.util";
import BackButton from "../../../components/BackButton";
import DownloadIcon from "../../../images/download.svg";
import { actions } from "../../../stores/V2/corporate/booking";
import LandingWrapper from "../../../components/LandingWrapper";
import { saveDivToPdf } from "../../../services/pdf/pdf.service";
import SlotsList from "../../../components/BlysTeam/Slots/SlotsList";
import { BookingSlot, SlotParams } from "../../../stores/V2/corporate/interface";
import ReleaseSlotModal from "../../../components/BlysTeam/Slots/ReleaseSlotModal";
import SelectRecipientModal from "../../../components/BlysTeam/Recipients/SelectRecipientModal";
import {
  useCorproateBooking,
  useReleaseSlot,
  useReserveSlot,
} from "../../../hooks/corporateBooking/corporateBooking.hooks";
import { Roles } from "../../../constants/blysTeam";
import { BOOKING_STATUS } from "../../../constants/booking";


const Schedules = () => {
  const [showRecipientModal, setShowRecipientModal] = useState<boolean>(false);
  const [showReleaseModal, setShowReleaseModal] = useState<boolean>(false);
  const [isDownloadingSchedule, setIsDownloadingSchedule] = useState<boolean>(false);

  const isMobile = useMobile();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const state = useSelector((state: RootState) => state.coroprateBooking);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<SlotParams>();

  const onReserveSuccess = () => {
    setSuccessMessage("Booking slot reserved.");
    dispatch(actions.resetSelectedSlot());
    setShowRecipientModal(false);
  };

  const onReleaseSuccess = () => {
    setSuccessMessage("Booking slot released.");
    dispatch(actions.resetSelectedSlot());
    setShowReleaseModal(false);
  };

  const { booking } = useCorproateBooking(params.uuid, "schedules");
  const bookingStatus = getValue(booking, "status", null);

  const { mutate: assignRecipient } = useReserveSlot({
    onSuccess: onReserveSuccess,
    onError: () => setErrorMessage("Something went wrong"),
  });

  const { mutate: releaseSlot } = useReleaseSlot({
    onSuccess: onReleaseSuccess,
    onError: () => setErrorMessage("Something went wrong"),
  });

  useEffect(() => {
    if (booking) dispatch(actions.initialize(booking));
  }, [booking, dispatch]);

  const handSlotPressed = (slot: BookingSlot) => {
    dispatch(actions.setSelectedSlot(slot));
  };

  const handleAssignRecipient = (recipientId: number, role: string | null) => {
    if (!recipientId) return;
    const data = {
      uuid: params.uuid,
      slotId: state.selectedSlot.id,
      data: role === Roles.RECIPIENT ? { recipientId } : { userId: recipientId },
    };

    assignRecipient(data as any);
  };

  const handleReleaseSlot = (slotId: string | null) => {
    if (!slotId) return;
    releaseSlot({ uuid: params.uuid, slotId: slotId });
  };

  const handleDownloadSchedule = () => {
    setIsDownloadingSchedule(true);
    setTimeout(() => {
      saveDivToPdf("schedules-to-print");
      setIsDownloadingSchedule(false);
    });
  };

  return (
    <LandingWrapper marginTop="40px" showPromoImages={false}>
      <Box position="relative" width={isMobile ? "100%" : "1024px"} marginBottom="80px">
        <Box style={isMobile ? { display: "flex", justifyContent: "center" } : {}}>
          <LogoLink style={{ width: "70px" }} />
        </Box>
        <Box
          position="absolute"
          top="0px"
          left={isMobile ? "24px" : "-82px"}
          display="flex"
          justifyContent="center"
        >
          <BackButton
            onClick={() => {
              history.push(`${Paths.Bookings}/${booking.id}`);
            }}
          />
        </Box>

        <Box marginTop="48px" paddingX={isMobile ? "24px" : "0px"}>
          <Typography
            variant="h1"
            style={{
              color: Colors.NightBlue,
              fontSize: "36px",
              lineHeight: "39px",
            }}
          >
            Schedules
          </Typography>
          <Typography
            style={{
              color: Colors.Dusk,
              fontSize: "16px",
              lineHeight: "24px",
              marginTop: "8px",
            }}
          >
            {bookingStatus === BOOKING_STATUS.CANCELLED ? "View upcoming bookings and schedule." : "View and edit upcoming bookings and schedule."}
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={"4px"}
            justifyContent={"flex-end"}
            onClick={handleDownloadSchedule}
          >
            <Box style={{ cursor: "pointer" }} display="flex" alignItems="center">
              <Box width={"24px"} height={"24px"}>
                <img src={DownloadIcon} width={"100%"} height={"100%"} />
              </Box>
              <Typography
                style={{
                  color: Colors.TurquoiseBlue,
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontWeight: 600,
                }}
              >
                Download schedule
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box marginTop="60px" id="schedules-to-print" paddingX={isMobile ? "24px" : "0px"}>
          <SlotsList
            isAdmin
            jobs={state.booking.jobs}
            selectedSlot={state.selectedSlot}
            onPress={ handSlotPressed }
            onPressRemove={() => setShowReleaseModal(true)}
            onPressConfirm={() => setShowRecipientModal(true)}
            isDownloading={isDownloadingSchedule}
            timeZone={state.booking.timezone}
            uuid={params.uuid}
            earliestTime={booking.earliestTime}
            isMobile={isMobile}
            isDisabled = {bookingStatus === BOOKING_STATUS.CANCELLED ?  true : false}
          />
        </Box>
      </Box>
      <SelectRecipientModal
        open={showRecipientModal}
        onClose={() => {
          setShowRecipientModal(false);
        }}
        onConfirm={(recipientId, role) => {
          handleAssignRecipient(recipientId, role);
        }}
      />
      <ReleaseSlotModal
        open={showReleaseModal}
        onClose={() => setShowReleaseModal(false)}
        timeSlotLabel={getTimeTitle(getValue(state, "selectedSlot.start", ""))}
        onConfirm={() => {
          handleReleaseSlot(state.selectedSlot.uuid);
        }}
        recipientName={getValue(state, "selectedSlot.recipient.firstName", "")}
      />
    </LandingWrapper>
  );
};

export default Schedules;
