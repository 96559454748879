import { Box } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import PaymentPending from "../../../../components/Bookings/PaymentPending";
import { CorporateLink } from "../../../../components/Bookings/V2/Corporate";
import Divider from "../../../../components/Divider";
import ContentHeader from "../../../../components/Headers/ContentHeader/ContentHeader";
import { BOOKING_STATUS, JobStatus } from "../../../../constants/booking";
import { Paths } from "../../../../constants/paths";
import {
  bookingIsCancelled,
  bookingIsComplete,
  bookingIsConfirmed,
} from "../../../../helpers/booking";
import { isCorporateSession } from "../../../../helpers/massage";
import { useMobile } from "../../../../hooks/mobile";
import { isAfterPayUnpaidBooking } from "../../../../services/bookings/bookings.service";
import { useAlertStore } from "../../../../stores/alert";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import { copyStringToClipboard } from "../../../../utils/string";
import InvoiceLinks from "../InvoiceLinks/InvoiceLinks";
import PriceSummary from "./PriceSummary";
import RecieptSummary from "./RecieptSummary";
import TreatmentDetailsBlock from "./TreatmentDetailsBlock";
import styles from "./styles";

interface Props {
  booking: any;
  id?: string;
}

const OrderSummary = ({ booking, id }: Props) => {
  const isMobile = useMobile();
  const history = useHistory();
  const { setSuccessMessage } = useAlertStore();

  if (!booking || !booking.status) return <></>;

  const formattedBookingForInvoiceLinks = (booking: any) => {
    return {
      cancellationFee: booking.cancellationFee,
      ...booking.bookingPrice,
      id: booking.id,
      bookingdetails: booking.bookingDetails,
      userId: booking.userId,
      bookingUpdates: booking.bookingUpdates,
    };
  };

  const bookingComplete = booking && bookingIsComplete(formattedBookingForInvoiceLinks(booking));
  const bookingConfirmed = booking && bookingIsConfirmed(formattedBookingForInvoiceLinks(booking));
  const bookingCancelled = booking && bookingIsCancelled(formattedBookingForInvoiceLinks(booking));

  const handleCopyToClipboard = (value: string | null) => {
    if (!value) return;
    copyStringToClipboard(value);
    setSuccessMessage("Booking link copied to clipboard");
  };

  const handleViewSchedule = () => {
    const path = `${Paths.CorporateSlots}/${booking.uuid}/schedules`;
    history.push(path);
  };

  const sessionType = getValue(booking, "sessionType", null);
  const isCorporateType = isCorporateSession(sessionType);
  const showReciept = [BOOKING_STATUS.COMPLETED, BOOKING_STATUS.CANCELLED].includes(booking.status);
  const isBookingConfirmed = getValue(booking, "status", "") === BOOKING_STATUS.ARRANGED;

  const corporateLink = isCorporateType ? getValue(booking, "bookingLink", null) : null;

  // disable update for corporate + group bookings
  let allowUpdate = true;
  const isMassage = getValue(booking, "selectedService.isMassage");

  if (isMassage && !getValue(booking, "selectedSessionType.value")) {
    allowUpdate = false;
  }
  if (isCorporateType) {
    allowUpdate = false;
  }

  const corporateInfo = getValue(booking, "corporateInfo") || null;

  // filter corporate bookings from NAP
  const hasCorporateSchedule = !checkIfEmpty(corporateInfo) && isCorporateType && !showReciept;

  const bookingDetails = getValue(booking, "bookingDetails", []);

  const allowLocationUpdate = bookingDetails.every(
    (data: any) => data.status === JobStatus.accepted || data.status === JobStatus.onway
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      ml={isMobile ? undefined : "29px"}
      maxWidth={"468px"}
    >
      <Box style={styles.contentWrapper}>
        {isAfterPayUnpaidBooking(booking) && (
          <Box paddingTop={"32px"} textAlign={"center"}>
            <Box
              fontFamily={"Museo"}
              fontWeight={600}
              color="#DC3545"
              fontSize={isMobile ? "16px" : "20px"}
            >
              Complete booking payment
            </Box>
          </Box>
        )}

        {!showReciept && (
          <Box style={!isMobile ? { overflow: "auto" } : {}}>
            <TreatmentDetailsBlock
              booking={booking}
              id={id}
              allowChange={allowUpdate}
              allowLocationUpdate={allowLocationUpdate}
            />
            <PriceSummary booking={booking} id={id} />
          </Box>
        )}
        {showReciept && <RecieptSummary booking={booking} />}
      </Box>

      {!booking.hasInvoices && !isAfterPayUnpaidBooking(booking) && (
        <Box style={styles.contentWrapper} padding="24px" mt="16px" fontFamily="Museo">
          {booking.totalAmountDue > 0 && (
            <>
              <PaymentPending
                booking={booking}
                isMobile={isMobile}
                bookingMutate={() => {
                  history.push(`/my-bookings/${id}`);
                }}
                divider={false}
              />
              <Divider marginTop={"24px"} marginBottom={"16px"} />
            </>
          )}
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <InvoiceLinks booking={formattedBookingForInvoiceLinks(booking)} />
          </Box>
        </Box>
      )}

      {hasCorporateSchedule && (
        <CorporateLink
          title="Booking link"
          description="Share this link with your team so they can choose their preferred timeslot."
          onCopyLink={() => handleCopyToClipboard(corporateLink)}
          onViewSchedule={handleViewSchedule}
          isDisabled={!isBookingConfirmed}
        />
      )}
    </Box>
  );
};

export default OrderSummary;
