import React, { ChangeEvent, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { AxiosError } from "axios";
import Each from "../../../../Each";
import Dialog from "../../../../Dialog";
import Divider from "../../../../Divider";
import TextLink from "../../../../TextLink";
import { Text } from "../../../../v2/Styled";
import * as Styled from "../../../../v2/Styled/enum";
import { getValue } from "../../../../../utils/object";
import Button, { ButtonType } from "../../../../Button";
import { useMobile } from "../../../../../hooks/mobile";
import { Colors } from "../../../../../constants/colors";
import { useAlertStore } from "../../../../../stores/alert";
import { parseApiError } from "../../../../../helpers/error";
import CloseButton from "../../../../CloseButton/CloseButton";
import { invalidateAccountStatus } from "../../../../../services/proDashboard/stripe.service";
import { useStripeSubmitAdditionalRequirements } from "../../../../../hooks/stripe/connect/stripeConnect.hooks";
import { allowUploadDocumentExtension } from "../../../../../helpers/services";

interface Props {
  open: boolean;
  data: {
    key: string;
    name: string;
    title: string;
    isVisible: boolean;
    description: string;
  };
  onClose: () => unknown;
}

const AdditionalInfoModal = ({ open, data, onClose }: Props): JSX.Element => {
  const isMobile = useMobile();
  const [files, setFiles] = useState<File[]>([]);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const onUploadSuccess = () => {
    invalidateAccountStatus();
    setSuccessMessage("Information submitted.");
    onClose();
  };

  const onUploadError = (e: AxiosError) => {
    setErrorMessage(parseApiError(e));
  };

  const { mutate: uploadFile, isLoading: isUploadingFile } = useStripeSubmitAdditionalRequirements({
    onSuccess: onUploadSuccess,
    onError: onUploadError,
  });

  const title = getValue(data, "title", "");
  const description = getValue(data, "description", "");
  const key = getValue(data, "key", "");
  const name = getValue(data, "name", "");

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const file = getValue(event, "target.files[0]");
    if (!file) return;

    // setFiles([...files, file]);
    setFiles([file]); // restricting to one file upload
  };

  const handleUploadClicked = () => {
    if (fileUploadRef && fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const handleFileRemove = (i: number) =>
    setFiles(files.filter((file: File, idx: number) => idx !== i));

  const validate = () => {
    // if (files.length === 0) {
    //   setErrorMessage("Please select file to upload.");
    //   return false;
    // }
    return true;
  };

  const onSubmit = () => {
    const isValid = validate();
    if (!isValid) return;

    const form = new FormData();
    files.forEach((file) => {
      form.append("files", file);
    });
    form.append("key", key);
    form.append("name", name);

    uploadFile(form);
  };

  const renderFileRow = (file: File, idx: number) => {
    return (
      <Box display={Styled.Display.Flex} justifyContent={Styled.JustifyContent.spaceBetween}>
        <Box>
          <Text color={Colors.Dusk} size={Styled.FontSize.F14}>
            {file.name}
          </Text>
        </Box>
        <Box>
          <TextLink title={"Remove"} destructive onClick={() => handleFileRemove(idx)} />
        </Box>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile} maxWidth="sm">
      <Box>
        <Box style={{ position: "absolute", top: "8px", right: "16px" }}>
          <CloseButton onClick={onClose} size={"lg"} color={Colors.Indigo} />
        </Box>
        <Box
          padding={Styled.Spacing.S8}
          gridGap={Styled.Spacing.S6}
          display={Styled.Display.Flex}
          flexDirection={Styled.FlexDirection.Column}
        >
          <Box width={"90%"}>
            <Text
              color={Colors.Indigo}
              size={Styled.FontSize.F30}
              font={Styled.FontFamily.Museo}
              weight={Styled.FontWeight.Bold}
              lineHeight={Styled.LineHeight.L33}
            >
              {title}
            </Text>
          </Box>
          <Box>
            <Text
              color={Colors.Dusk}
              font={Styled.FontFamily.Museo}
              weight={Styled.FontWeight.Medium}
              lineHeight={Styled.LineHeight.L24}
            >
              {description}
            </Text>
          </Box>
          {files.length > 0 ? (
            <Box
              gridGap={Styled.Spacing.S3}
              display={Styled.Display.Flex}
              flexDirection={Styled.FlexDirection.Column}
            >
              <Each of={files} render={renderFileRow} />
            </Box>
          ) : (
            <></>
          )}
          <Box>
            <Button
              title={"Upload file"}
              type={ButtonType.outlined}
              width={isMobile ? "100%" : "auto"}
              style={{
                padding: "12px 24px",
              }}
              onClick={handleUploadClicked}
            />
          </Box>
        </Box>

        <Divider bgcolor={Colors.LightPeriwinkle} />

        <Box padding={Styled.Spacing.S6}>
          <Button
            title={"Submit"}
            onClick={onSubmit}
            loading={isUploadingFile}
            type={ButtonType.secondary}
          />
        </Box>
      </Box>
      <Box style={{ display: "none" }}>
        <form encType="multipart/form-data">
          <input
            type="file"
            ref={fileUploadRef}
            id={"stripe-upload-file"}
            style={{ display: "none" }}
            onChange={handleFileSelect}
            accept={allowUploadDocumentExtension()}
          />
        </form>
      </Box>
    </Dialog>
  );
};

export default AdditionalInfoModal;
