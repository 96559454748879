import React, { useEffect, useRef, useState } from "react";
import { Token } from "@stripe/stripe-js";
import { Box } from "@material-ui/core";
import "../../fonts/Museo.css";
import Dialog from "../Dialog";
import "../../styles/ApplePay.css";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import * as Styled from "../v2/Styled/enum";
import { PaymentMethod } from "../../models";
import { getValue } from "../../utils/object";
import { useQuery } from "../../hooks/common";
import ModalHeaderApp from "../ModalHeaderApp";
import { useMobile } from "../../hooks/mobile";
import { debounce } from "../../utils/debounce";
import { useAlertStore } from "../../stores/alert";
import Snackbar, { SnackbarType } from "../Snackbar";
import { useStripeStore } from "../../stores/stripe";
import Button, { ButtonSize, ButtonType } from "../Button";
import StripeCardAddPayment from "../Stripe/Card/StripeCardAddPayment";
import { StripeCardFormHandle } from "../Stripe/Card/StripeCardElement";
import { LOG_CHANNEL, sendErrorLog } from "../../services/log/log.service";
import { useAddPaymentMethod } from "../../hooks/stripe/customer/stripeCard.hooks";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onAddedNewCard: (method: PaymentMethod) => unknown;
  cardOnly?: boolean;
}

export default function AddNewCardModelStripe({
  open,
  onClose,
  onAddedNewCard,
}: Props): JSX.Element {
  const cardFormRef = useRef<StripeCardFormHandle | null>(null);

  const [isPaymentMethodAdding, setIsPaymentMethodAdding] = useState<boolean>(false); // UI: Handling the loading state manually along with loading state from hook

  const isMobile = useMobile();
  const { setErrorMessage, errorMessage } = useAlertStore();
  const { clientSecret, createSetupIntent, resetClientSecret, stripeCountry } = useStripeStore();
  const search = useQuery();

  useEffect(() => {
    if (!clientSecret || !stripeCountry) {
      createSetupIntent(search.get("accessToken"));
    }
  }, [clientSecret]);

  const { mutate: addNewPaymentMethod, isLoading: isAddingNewPaymentMethod } = useAddPaymentMethod({
    onSuccess: (res) => {
      if (!res.status) {
        setErrorMessage("Please check your card details.");
        return;
      }
      onAddedNewCard(res.payment);
      resetClientSecret();
    },
    onError: () => {
      setErrorMessage("Please check your card details.");
    },
  });

  const handleAddCardClick = debounce(async (e: MouseEvent) => {
    try {
      setIsPaymentMethodAdding(true);
      if (cardFormRef.current) {
        await cardFormRef.current?.onSubmit(e, () => {});
      }
    } catch (err) {
      sendErrorLog({
        domain: "STRIPE_CARD_CONNECT",
        log: {
          title: "Error on handle submit",
          message: getValue(err, "message"),
          data: err,
        },
        channel: LOG_CHANNEL.LOGGER,
      });
    } finally {
      setIsPaymentMethodAdding(false);
    }
  }, 500);

  const onAddCard = (e: MouseEvent, paymentMethodId: Token | string | undefined, cb: any) =>
    addNewPaymentMethod(paymentMethodId as string);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullScreen={isMobile}
      onClose={onClose}
      disableEnforceFocus
      PaperProps={{ style: { overflowY: "visible" } }}
    >
      <Box bgcolor="white" width={isMobile ? "100%" : "700px"} borderRadius="12px">
        {isMobile ? (
          <ModalHeaderApp
            title="Add a card"
            subtitle="Payment methods will be stored to streamline future bookings"
          />
        ) : (
          <ModalHeader
            title="Add a card"
            subtitle="Payment methods will be stored to streamline future bookings"
            onClose={onClose}
          />
        )}

        <Box paddingX={Styled.Spacing.S10} paddingY={Styled.Spacing.S8}>
          <StripeCardAddPayment ref={cardFormRef} onSuccess={onAddCard} />
        </Box>
      </Box>

      <ModalFooter>
        <Button
          title="Back"
          width="130px"
          onClick={onClose}
          size={ButtonSize.small}
          type={ButtonType.outlined}
          style={{ display: isMobile ? "none" : "block", marginRight: Styled.Spacing.S4 }}
        />
        <Button
          width="130px"
          title="Add card"
          size={ButtonSize.small}
          onClick={handleAddCardClick}
          loading={isPaymentMethodAdding || isAddingNewPaymentMethod}
        />
      </ModalFooter>

      <Snackbar
        message={errorMessage}
        type={SnackbarType.error}
        open={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />
    </Dialog>
  );
}
