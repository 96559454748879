import { useState } from "react";
import { Box } from "@material-ui/core";

import ProImage from "./ProImage";
import SingleSlot from "./SingleSlot";
import { checkIfEmpty, getValue } from "../../../utils/object";
import ProModal from "../../TherapistProfileDrawer";
import { BookingSlot, Job } from "../../../stores/V2/corporate/interface";
import BreakTimeUpdateModal from "./BreakTimeUpdateModal";
import { DropdownOption } from "../../Dropdown";
import {
  addTime,
  checkIfSameTime,
  convertToUtc,
  getTimeDiffBetween,
  getTimeInRange,
  getTimeTitle
} from "../../../utils/date.util";
import InfoModal from "../../Modals/InfoModal/InfoModal";
import { useAlertStore } from "../../../stores/alert";
import BreakSlot from "./BreakSlot";
import { useUpdateBreakTimeSlot } from "../../../hooks/corporateBooking/corporateBooking.hooks";
import { BREAK_SLOT_LOCKED_INFO, SLOT_TYPES } from "../../../constants/booking";

type Props = {
  jobs: Job[];
  isAdmin?: boolean;
  selectedSlot: BookingSlot;
  onPress: (slot: BookingSlot) => void;
  onPressRemove?: (slot: BookingSlot) => void;
  onPressConfirm: (slot: BookingSlot) => void;
  isDownloading?: boolean;
  allowRemoveSlots?: string[];
  allowSlotRemoveOption?: boolean;
  timeZone: string;
  uuid: string;
  earliestTime: string;
  isMobile: boolean;
  isDisabled?: boolean;
};

export default function SlotsList({
  jobs,
  onPress,
  isAdmin,
  selectedSlot,
  onPressRemove,
  onPressConfirm,
  allowRemoveSlots,
  allowSlotRemoveOption = false,
  isDownloading = false,
  timeZone,
  uuid,
  earliestTime,
  isMobile,
  isDisabled,
}: Props) {
  const [therapistId, setTherapistId] = useState<number | null>(null);
  const [showProInfo, setShowProInfo] = useState<boolean>(false);
  const [showBreakUpdateModal, setShowBreakUpdateModal] = useState(false);

  const [originalBreakStart, setOriginalBreakStart] = useState("");
  const [breakStartTime, setBreakStartTime] = useState("");
  const [breakEndTime, setBreakEndTime] = useState("");
  
  const [selectedBreakSlot, setSelectedBreakSlot] = useState<BookingSlot | null>(null);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const onBreakTimeUpdateSuccess = () => {
    setSuccessMessage("Break time updated successfully.");
    setSelectedBreakSlot(null);
    setShowBreakUpdateModal(false);
  };

  const { mutateAsync: updateBreakSlotTime, isLoading: breakUpdateLoading } =
    useUpdateBreakTimeSlot({
      onSuccess: onBreakTimeUpdateSuccess,
      onError: () => setErrorMessage("Something went wrong."),
    });

  const handleClick = (therapistId: number) => {
    setTherapistId(therapistId);
    setShowProInfo(true);
  };

  const shouldAllowRemove = (slot: BookingSlot) => {
    const slotId = getValue(slot, "id") || null;
    if (!slotId) return false;

    if (allowRemoveSlots && slot && allowRemoveSlots.includes(slotId)) return true;
    return false;
  };

  const handleBreakTimeChange = (option: DropdownOption, slot: BookingSlot) => {
    if (!slot || !slot.start || !slot.end) return;

    const { start, end } = slot;
    const breakDuration = getTimeDiffBetween(start, end);
    const newBreakEndTime = addTime(option.value, breakDuration);

    setBreakEndTime(newBreakEndTime);
    setBreakStartTime(option.value);
  };
  
  const getFormattedSlotTime = (slot: BookingSlot) => {
    const breakStart = getTimeTitle(slot.start || "", timeZone, false) || "";
    const breakEnd = getTimeTitle(slot.end || "", timeZone, false) || "";
    return { startTime: breakStart, endTime: breakEnd };
  };

  const onBreakSlotClick = (slot: BookingSlot) => {
    const { startTime, endTime } = getFormattedSlotTime(slot);
    setOriginalBreakStart(startTime);
    setBreakStartTime(startTime);
    setBreakEndTime(endTime);
    setSelectedBreakSlot(slot);
    setShowBreakUpdateModal(true);
  };

  const allBookingSlots = jobs?.flatMap((job: Job) => getValue(job, "user.bookingSlots", [])) ?? [];

  const isAnySlotsReserved = allBookingSlots?.some((slot: BookingSlot) => slot?.isReserved);

  const updateBreakTime = async () => {
    try {

      const breakStartTimeUtc = convertToUtc({
        date: earliestTime,
        time: breakStartTime,
        timezone: timeZone,
      });

      const breakEndTimeUtc = convertToUtc({
        date: earliestTime,
        time: breakEndTime,
        timezone: timeZone,
      });

      const isSelectedBreakTimeSame = checkIfSameTime({
        time1: breakStartTime,
        time2: originalBreakStart,
      });
      if (isSelectedBreakTimeSame) {
        setErrorMessage("Please select a new time.");
        return;
      }

      const payload = {
        uuid,
        slotId: selectedBreakSlot ? selectedBreakSlot.id : null,
        breakEndTime: breakEndTimeUtc,
        breakStartTime: breakStartTimeUtc,
      };
      await updateBreakSlotTime(payload);
    } catch (error) {
      setErrorMessage("Something went wrong.");
    }
  };

  const getBreakOptions = () => {
    if (checkIfEmpty(allBookingSlots)) return [];
    const firstSlot = allBookingSlots[0];
    const lastSlot = allBookingSlots[allBookingSlots.length - 1];

    const options =  getTimeInRange({
      startTime: firstSlot.start,
      endTime: lastSlot.end,
      timezone: timeZone,
      breakTime: selectedBreakSlot?.start || null,
    });
    return options.map((time) => ({ title: time, value: time }));
  };

  const totalJobs = jobs?.length || 0;
  const breakSlotWidth = totalJobs * 100 + (totalJobs - 1) * 10;
  const breakSlotLeftPosition = ((jobs?.length || 0) - 1) * 50 + (totalJobs - 1) * 5;

  const breakOptions = getBreakOptions();
  const isEditBreakOpen = showBreakUpdateModal && !isAnySlotsReserved;

  return (
    <>
      <Box
        style={{
          gap: "24px",
          display: "flex",
          overflowX: "auto",
          maxWidth: "100vw",
          justifyContent: "flex-start",
          flexWrap: isDownloading ? "wrap" : undefined,
        }}
      >
        {jobs?.map((job: Job, index: number) => {
          if (!job.user) return <></>;

          const proId = getValue(job, "user.id", "");
          const proName = getValue(job, "user.preferredFirstName", "") || getValue(job, "user.firstName", "");
          const proImage = getValue(job, "user.therapistprofile.profileImage", "");
          const bookingSlots = getValue(job, "user.bookingSlots", []);

          const key = `schedule-list-${job.id}`;
          return (
            <Box key={key} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <ProImage firstName={proName} image={proImage} onClick={() => handleClick(proId)} />
              <Box style={{ marginTop: "48px" }} />
              {bookingSlots.map((slot: BookingSlot) => {
                const allowRemove = shouldAllowRemove(slot);
                const slotType = getValue(slot, "type", "");

                return (
                  <>
                    {slotType === SLOT_TYPES.BREAK_SLOT ? (
                      index === 0 ? (
                        <BreakSlot
                          key={`BreakSlot-${slot.id}`}
                          slot={slot}
                          timezone={timeZone}
                          onClick={onBreakSlotClick}
                          allowBreakTimeUpdate={!isAnySlotsReserved}
                          breakSlotWidth={breakSlotWidth}
                          breakSlotLeftPosition={breakSlotLeftPosition}
                          isMobile={isMobile}
                          isDisabled = {isDisabled}
                        />
                      ) : (
                        <Box height="48px" mb="16px"></Box>
                      )
                    ) : (
                      <SingleSlot
                        key={`Slot-${slot.id}`}
                        slot={slot}
                        timeZone={timeZone}
                        isAdmin={isAdmin}
                        onPress={onPress}
                        allowRemove={allowRemove && allowSlotRemoveOption}
                        onPressRemove={onPressRemove}
                        onPressConfirm={onPressConfirm}
                        isSelected={selectedSlot.id === slot.id}
                        isDisabled = {isDisabled}
                      />
                    )}
                  </>
                );
              })}
            </Box>
          );
        })}
        <ProModal
          open={showProInfo}
          therapistId={therapistId}
          onClose={() => setShowProInfo(false)}
          type="popup"
        />
        <BreakTimeUpdateModal
          isMobile={isMobile}
          timezone={timeZone}
          open={isEditBreakOpen}
          slot={selectedBreakSlot}
          breakEndTime={breakEndTime}
          breakStartTime={breakStartTime}
          isLoading={breakUpdateLoading}
          timeOptions={breakOptions}
          onConfirm={updateBreakTime}
          onClose={() => setShowBreakUpdateModal(false)}
          handleBreakTimeChange={handleBreakTimeChange}
        />
        <InfoModal
          visible={showBreakUpdateModal && isAnySlotsReserved}
          title={BREAK_SLOT_LOCKED_INFO.title}
          description={BREAK_SLOT_LOCKED_INFO.description}
          handleClose={() => setShowBreakUpdateModal(false)}
          divider={false}
        />
      </Box>
    </>
  );
}
