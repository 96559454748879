import { Box, BoxProps, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Colors } from "../../../constants/colors";
import { Accordion } from "../../Accordion/Accordion";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../v2/Styled/enum";
import BookingStatusPill from "../../Bookings/V2/BookingStatusPill";
import { JobStatus } from "../../../constants/booking";

import { useMobile } from "../../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../../Button";

import { PayoutTimeline } from "./PayoutTimeline";
import { isArray } from "../../../utils/array";
import { getValue } from "../../../utils/object";
import { compileDescriptionForPayoutTimeline } from "./PayoutTimeline.transformer";

import {
  DEFAULT_BOOKING_DATETIME_FORMAT,
  DEFAULT_BOOKING_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "../../../constants/date";
import { formatDateWithTimezone } from "../../../utils/date.util";
import { getMassageDescriptionForBooking } from "../../../helpers/booking";

import { useUnblockClient } from "../../../hooks/client/blockClient.hooks";
import { useAlertStore } from "../../../stores/alert";
import BlockClientModal from "../../Client/BlockClientModal";
import queryClient from "../../../config/queryClient";
import { PayoutKeys } from "../../../hooks/payout/payout";
import DownloadInvoice from "../DownloadInvoice";
import {
  getAmountAndTitleForPayout,
  getBookingDateTitle,
  getJobStatus,
} from "../../../helpers/proDashPastbookings";

const Title = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="14px"
      lineHeight="21px"
      fontWeight={600}
      color={Colors.BlueyGrey}
      {...props}
    />
  );
};

const Value = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Museo"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      mt="4px"
      {...props}
    />
  );
};

const InfoBox = ({
  title,
  value,
  style = {},
}: {
  title: string;
  value: string;
  style?: object;
}) => {
  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      style={{ gap: Spacing.S1, ...style }}
      flexWrap={"wrap"}
    >
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Box>
  );
};
interface Props {
  job: any;
}
export const BookingAccordionItem = ({ job }: Props) => {
  const isMobile = useMobile();

  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { mutate: unblockClient, isLoading: isUnBlocking } = useUnblockClient();

  const [showBlockModal, setShowBlockModal] = useState(false);
  const status = getValue(job, "status");
  const jobId = getValue(job, "jobId");
  const bookingId = getValue(job, "booking.id");
  const isClientBlockedByPro = !!getValue(job, "userBlock", false);
  const [showDesc, setShowDesc] = useState(true);

  const _date = () => {
    // const date = getValue(job, "booking.bookingDate");
    const date = getValue(job, "date");
    const timezone = getValue(job, "booking.timezone");
    return moment.tz(date, timezone).format(DEFAULT_BOOKING_DATE_FORMAT);
  };

  const getPayoutTitleAndDesc = ({ payouts }: { payouts: [] }) => {
    let title = <Typography>-</Typography>;
    let description = <Typography />;
    if (!payouts || !isArray(payouts)) return { title, description };
    const activePayoutStatus = payouts.find(({ isActive }) => !!isActive);
    const backupPayoutStatus =
      payouts[payouts.findIndex(({ isFutureStatus }) => !!isFutureStatus) - 1];
    if (!activePayoutStatus && !backupPayoutStatus) return { title, description };

    const label = getValue(activePayoutStatus || backupPayoutStatus, "label");
    const content = getValue(activePayoutStatus || backupPayoutStatus, "content");
    return {
      title: label ? label : "-",
      description: content ? (
        compileDescriptionForPayoutTimeline({
          contents: content,
        })
      ) : (
        <Box />
      ),
    };
  };

  const handleClientBlockOrUnblock = (event: MouseEvent) => {
    event.stopPropagation();
    if (isClientBlockedByPro) {
      unblockClient(
        {
          jobId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([PayoutKeys.PAYOUTS]);
            setSuccessMessage("Client unblocked.");
          },
          onError: () => {
            setErrorMessage("Something went wrong! Please try again later.");
          },
        }
      );
    } else {
      setShowBlockModal(true);
    }
  };

  const jobStatus = getJobStatus({
    job,
  });

  const bookedBy = getValue(job, "userDetails.firstName", "");
  const bookedFor = getValue(job, "recipientDetails.firstName") || bookedBy;
  const address = getValue(job, "booking.address.suburb", "");

  const serviceDescription = getMassageDescriptionForBooking(job.booking);
  const payoutTitle = getPayoutTitleAndDesc({ payouts: job?.proPayouts }).title;
  const payoutDesc = getPayoutTitleAndDesc({ payouts: job?.proPayouts }).description;
  const date = _date();
  const { payouTitle, amountText } = getAmountAndTitleForPayout({ job });
  const bookingDateTitle = getBookingDateTitle({
    status: jobStatus,
    isDebit: job?.isDebit,
  });

  return (
    <Box bgcolor={Colors.White} borderRadius="8px" overflow={"hidden"} border="1px solid #DCDFE6">
      <Box
        padding="24px"
        display={Display.Flex}
        flexDirection={!isMobile ? FlexDirection.Row : FlexDirection.Column}
        style={{
          gap: Spacing.S6,
        }}
      >
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{
            gap: Spacing.S4,
            width: !isMobile ? "232px" : "100%",
          }}
        >
          <Box minWidth={"100px"} maxWidth={"100px"}>
            <BookingStatusPill
              status={jobStatus}
              textOverride={job.isDebit ? "Abandoned" : undefined}
              overrideStyle={{
                maxHeight: job?.isDebit ? "6px" : "14px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </Box>
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
            justifyContent={isMobile ? JustifyContent.spaceBetween : undefined}
            marginTop={Spacing.S4}
            style={{
              gap: Spacing.S6,
            }}
          >
            <InfoBox title="Job Id" value={job.jobId} />
            <InfoBox title={payouTitle} value={amountText} />
          </Box>
          <Box>
            <InfoBox title={bookingDateTitle} value={`${date}`} />
          </Box>
        </Box>
        {!isMobile && <Box width="1px" height="100v" bgcolor={Colors.LightPeriwinkle} />}

        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{
            gap: Spacing.S4,
            flex: 1,
          }}
        >
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
            style={{ gap: Spacing.S6 }}
            justifyContent={isMobile ? JustifyContent.spaceBetween : undefined}
          >
            <InfoBox
              title="Booked by"
              value={bookedBy}
              style={{ width: !isMobile ? "207.5px" : undefined }}
            />
            <InfoBox
              title="Booked for"
              value={bookedFor}
              style={{ width: !isMobile ? "207.5px" : undefined }}
            />
          </Box>
          <Box>
            <InfoBox title="Service" value={serviceDescription} />
          </Box>
          <Box>
            <InfoBox title="Location" value={address} />
          </Box>
        </Box>

        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          justifyContent={JustifyContent.spaceBetween}
          style={{
            gap: isMobile ? Spacing.S6 : undefined,
          }}
        >
          <Box>
            <Button
              height={40}
              width={160}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title={isClientBlockedByPro ? `Unblock ${bookedFor}` : `Block ${bookedFor}`}
              onClick={handleClientBlockOrUnblock}
              loading={isUnBlocking}
            />
          </Box>

          <Box
            style={{
              textAlign: "right",
            }}
          >
            {!job.isDebit ? (
              <Box
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <DownloadInvoice bookingId={bookingId} jobId={jobId} />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>

      {job?.proPayouts && job?.proPayouts?.length ? (
        <Accordion
          containerStyle={{}}
          header={
            <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
              <Box fontSize={FontSize.F16}>{payoutTitle}</Box>
              {showDesc ? (
                <Box
                  fontFamily={FontFamily.OpenSans}
                  fontSize={FontSize.F14}
                  fontWeight={FontWeight.Regular}
                  style={{
                    color: Colors.AppGrey,
                  }}
                >
                  {payoutDesc}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          }
          showDescription={(showDescription: boolean) => {
            setShowDesc(!showDescription);
          }}
        >
          <PayoutTimeline proPayouts={job?.proPayouts || []} />
        </Accordion>
      ) : (
        <Box />
      )}
      <Box
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {jobId && showBlockModal && (
          <BlockClientModal
            jobId={jobId}
            open={showBlockModal}
            onBlockSuccess={() => {
              queryClient.invalidateQueries([PayoutKeys.PAYOUTS]);
            }}
            onClose={() => {
              setShowBlockModal(false);
            }}
          />
        )}
      </Box>
    </Box>
  );
};
