import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { COUNTRIES_CURRENCY } from "../../constants/countries";
import { formatToPrice } from "../../helpers/number";
import SummaryRow from "../../pages/Voucher/PurchaseSummaryRow";
import CurrencyConversionInfo from "../CurrencyConversionInfo";
import { useUserStore } from "../../stores/user";
import { useVoucherStore } from "../../stores/voucher";
import { checkIfEmpty, checkIfEqual, checkIfNil, getValue } from "../../utils/object";
import { usePaymentStore } from "../../stores/booking";
import { useMobile } from "../../hooks/mobile";

interface GiftVoucherPriceBreakdownSummaryProps {
  showInfoModal: (title: string, description: string) => unknown;
  forDialog?: boolean;
}

const GiftVoucherPriceBreakdownSummary = ({
  showInfoModal,
  forDialog = false,
}: GiftVoucherPriceBreakdownSummaryProps) => {
  const { voucher, voucherPrice, selectedCountry } = useVoucherStore();

  const { currency: userCurrency, currencyCode: userCurrencyCode } = useUserStore();

  const { couponCode: appliedCouponCode } = usePaymentStore();
  const isMobile = useMobile();

  const subTotal = getValue(voucherPrice, "subTotal", 0);
  const convertedSubTotal = getValue(voucherPrice, "convertedSubTotal", 0);
  const platformFeeTitle = voucherPrice?.clientPlatformFee?.title || "Processing Fee";
  const platformFeeDesc = voucherPrice?.clientPlatformFee?.remarks || "";

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        style={{ padding: forDialog ? "8px 16px" : "16px" }}
        gridGap={"12px"}
      >
        <Box textAlign={forDialog ? "center" : "left"}>
          <Typography style={{ color: Colors.Dusk, fontWeight: 700 }}>Summary</Typography>
        </Box>
        <Box>
          <SummaryRow title="Subtotal" value={`${voucher?.currency}${subTotal}`} />
          <Box style={{ marginTop: "5px" }} />
          <CurrencyConversionInfo
            convertedToCurrencyCode={userCurrencyCode}
            convertedFromCurrencyCode={COUNTRIES_CURRENCY[selectedCountry]}
            convertedCurrency={formatToPrice(convertedSubTotal, userCurrency)}
          />
        </Box>

        {(voucherPrice?.credit || 0) > 0 && (
          <SummaryRow
            title="Credit"
            value={`-${voucher?.currency}${voucherPrice?.credit.toFixed(2)}`}
          />
        )}

        {!checkIfNil(voucherPrice?.discount) && !checkIfEmpty(appliedCouponCode) && (
          <SummaryRow
            title={`Coupon (${appliedCouponCode})`}
            value={`-${voucher?.currency}${voucherPrice?.discount.toFixed(2)}`}
          />
        )}

        {!checkIfEqual(voucherPrice?.clientPlatformFee?.amount, 0) &&
          !checkIfNil(voucherPrice?.clientPlatformFee) && (
            <Box>
              <SummaryRow
                handleOnClick={() => showInfoModal(platformFeeTitle, platformFeeDesc)}
                title={platformFeeTitle}
                value={`${voucher?.currency}${
                  voucherPrice?.clientPlatformFee?.amount.toFixed(2) || `${voucher?.currency}0.0`
                }`}
              />
            </Box>
          )}
      </Box>
      <Box
        fontFamily="Museo"
        fontSize="18px"
        fontWeight={700}
        color={Colors.Dusk}
        bgcolor={Colors.PaleGrey}
        borderRadius="6px"
        width={isMobile ? "90%" : "100%"}
        padding={"0 16px"}
        boxSizing={!isMobile && forDialog ? "border-box" : "content-box"}
        mt={"8px"}
      >
        <Box display="flex" flexDirection="row" alignItems="center" height="54px">
          <Box flex={1}>Total</Box>
          <Box>
            {voucher?.currency}
            {voucherPrice?.price.toFixed(2)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GiftVoucherPriceBreakdownSummary;
