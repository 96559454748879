import { Box } from "@material-ui/core";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import { Colors } from "../../../../constants/colors";
import { formatDateWithTimezone } from "../../../../utils/date.util";
import SectionHeader from "../../../../components/Headers/SectionHeader/SectionHeader";
import { toJSON } from "../../../../utils/string";

const SpecialInstructionSection = ({ booking }: any) => {
  if (checkIfEmpty(booking)) return <></>;

  const instructions = toJSON(getValue(booking, "instructions"));
  const getSpecialNotes = ({ booking }: any) => {
    let notes = instructions?.note || booking.specialInstructions;
    if (booking.recipient && !notes) {
      notes = booking.recipient.specialInstructions || "";
    }
    return notes || "";
  };

  const getContraindication = ({ booking }: any) => {
    let notes = instructions?.contraindications;
    if (booking.recipient && !notes) {
      notes = getValue(booking, "recipient.contraindications", "");
    }
    return notes || "";
  };

  const getSpecialInstructions = ({ booking }: any) => {
    const specialInstruction = instructions?.preference || "";

    let modifiedInstruction = specialInstruction as string;

    if (booking.isHairAndMakeup && booking.toBeReadyBy) {
      modifiedInstruction += `${
        modifiedInstruction ? "\n" : ""
      }To be ready by ${formatDateWithTimezone(booking.toBeReadyBy, booking.timezone, "hh:mma")}`;
    }
    return modifiedInstruction || "";
  };

  const specialInstruction = getSpecialNotes({ booking });
  const preferences = getSpecialInstructions({ booking });
  const contraindications = getContraindication({ booking });

  return (
    <Box>
      <Box style={{ ...styles.wrapper, flexDirection: "column" }}>
        {specialInstruction && specialInstruction.length > 0 ? (
          <Box style={{ ...styles.sectionWrapper, flexDirection: "column" }}>
            <SectionHeader title={"Note"} />
            <Box style={styles.detail}>{specialInstruction}</Box>
          </Box>
        ) : (
          <></>
        )}
        {contraindications && contraindications.length > 0 ? (
          <Box style={{ ...styles.sectionWrapper, flexDirection: "column" }}>
            <SectionHeader title={"Contraindications"} />
            <Box style={styles.detail}>{contraindications}</Box>
          </Box>
        ) : (
          <></>
        )}
        {preferences && preferences.length > 0 ? (
          <Box style={{ ...styles.sectionWrapper, flexDirection: "column" }}>
            <SectionHeader title={"Preferences"} />
            <Box style={{ ...styles.preferencesWrapper, flexDirection: "column" }}>
              {preferences.split("\n").map((preference) => (
                <Box style={styles.detail}>{preference}</Box>
              ))}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    gap: "16px",
  },
  sectionWrapper: {
    display: "flex",
    gap: "10px",
  },
  preferencesWrapper: {
    display: "flex",
    gap: "4px",
  },
  detail: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  detailText: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
};

export default SpecialInstructionSection;
