import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import AddressItem from "../../components/Addresses/AddressItem";
import AddressModal from "../../components/Addresses/AddressModal";
import Button, { ButtonType } from "../../components/Button";
import TextLink from "../../components/TextLink";
import { Address } from "../../models";
import { fetchAddresses, useAddressStore } from "../../stores/address";
import { useAlertStore } from "../../stores/alert";
import { useBookingStore, useLocationDetailsStore } from "../../stores/booking";
import { fetchLocationTypes } from "../../stores/types";
import { setCountryCode } from "../../utils/country";
import { getValue, checkIfNil } from "../../utils/object";
import { removeUserAddress } from "../../services/users/users.service";
import { trackEvent } from "../../services/segment/track.service";
import { PaginatedResponse3 } from "../../models";
import PaginationControl from "../../components/PaginationControl";
import ReviewWrapper from "./Wrapper/ReviewWrapper";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/V2";
import { STEP_PROGRESS } from "../../constants/booking";
import { getBookingPrice } from "../../stores/V2/booking/booking.transformer";
import { actions as bookingActions } from "../../stores/V2/booking/booking";

interface Params {
  id?: string;
}

export default function ReviewLocation(): JSX.Element {
  const { id } = useParams<Params>();

  const history = useHistory();
  const dispatch = useDispatch();

  const state = useSelector(({ booking }: RootState) => booking);

  const { address: bookingAddress } = state;

  const { setAddress: setSelectedAddress, address: selectedAddress } = useBookingStore();

  const { setCountryCode: setNewAddressCountryCode } = useLocationDetailsStore();

  const { addresses } = useAddressStore();

  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [selectedAddressForEdit, setSelectedAddressForEdit] = useState<Address | null>(null);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const [page, setPage] = React.useState(1);
  const [paginateResponse, setpaginateResponse] = useState<PaginatedResponse3>();

  useEffect(() => {
    fetchLocationTypes();

    trackEvent("Review location", {
      step: 1,
      version: 2,
    });
  }, []);

  useEffect(() => {
    const getAddresses = async () => {
      const res = await fetchAddresses(page);
      setpaginateResponse({
        page: res?.page,
        total: res?.totalPage,
        perPage: res?.perPage,
      });
    };
    getAddresses();
  }, [page]);

  useEffect(() => {
    updatePrice();
  }, [state.bookingDetails, state.address]);

  const updatePrice = async () => {
    const price = await getBookingPrice(state);
    if (price) {
      dispatch(bookingActions.updateBookingPrice({ price }));
    } else {
      setErrorMessage("Unable to load booking price. Please Try again.");
    }
  };

  const handleAddressSelected = ({ address }: { address: Address }) => {
    const country = getValue(bookingAddress, "country", "AU");
    const selectedCountry = getValue(address, "country", "AU");

    if (country !== selectedCountry) {
      setErrorMessage("Address cannot be updated to a new country");
      return;
    }

    dispatch(bookingActions.updateBookingLocation({ address }));

    setCountryCode(selectedCountry);
    setNewAddressCountryCode(selectedCountry);
  };

  const handleAddressEdit = ({ address }: { address: Address }) => {
    setSelectedAddressForEdit(address);
    setAddressModalOpen(true);
  };

  const handleAddressRemove = ({ addressId }: { addressId: number }) => {
    if (selectedAddress?.id === addressId) {
      setSelectedAddress(null);
    }
    removeUserAddress({ addressId })
      .then(() => {
        fetchAddresses();
        setSuccessMessage("Address successfully deleted.");
      })
      .catch((err) => setErrorMessage(err));
  };

  const handleContinue = () => {
    if (checkIfNil(bookingAddress)) {
      setErrorMessage("Please select an address");
      return;
    }

    history.replace(`/my-bookings/review/${id}`, { initialize: false });
  };

  const handleAddLocation = () => {
    setSelectedAddressForEdit(null);
    setSelectedAddress(null);
    setAddressModalOpen(true);
    setPage(1);
  };

  const handleSaveAddress = ({ address }: { address: Address }) => {
    const countryCode = address.countryCode || "AU";
    setSelectedAddress(address);
    setCountryCode(countryCode);
    setNewAddressCountryCode(countryCode);

    setAddressModalOpen(false);
    fetchAddresses();

    window.scrollTo(0, 0);
  };

  const handleGoBack = () => history.replace(`/my-bookings/review/${id}`, { initialize: false });

  return (
    <>
      <ReviewWrapper
        buttons={[
          <Button title={"Back"} onClick={handleGoBack} type={ButtonType.outlined} />,
          <Button title={"Continue"} type={ButtonType.secondary} onClick={handleContinue} />,
        ]}
        progress={STEP_PROGRESS[1]}
        title="Booking location"
        label="Step 1 of 7"
        onClose={handleGoBack}
      >
        <Box mt={4}>
          {addresses &&
            addresses.map((address: Address) => (
              <AddressItem
                address={address}
                selected={bookingAddress?.id === address.id}
                onSelected={() => handleAddressSelected({ address })}
                onEditClicked={() => handleAddressEdit({ address })}
                onDeleteClicked={() => handleAddressRemove({ addressId: address.id })}
              />
            ))}
        </Box>

        <Box
          mt={4}
          mb={4}
          display="flex"
          flexWrap={true}
          alignItems="center"
          justifyContent="space-between"
        >
          <TextLink title="Add new location" onClick={handleAddLocation} />

          <PaginationControl
            currentPage={paginateResponse?.page!}
            pages={paginateResponse?.total!}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            startFromOne={true}
          />
        </Box>
      </ReviewWrapper>

      <AddressModal
        open={addressModalOpen}
        existingAddress={selectedAddressForEdit}
        onClose={() => setAddressModalOpen(false)}
        onSaved={(address) => handleSaveAddress({ address })}
      />
    </>
  );
}
