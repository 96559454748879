export const VOUCHER_DELIVERY_STATUS = {
  DECLINED: "Declined",
  DELIVERED: "Delivered",
  SCHEDULE_FOR_DELIVERY: "Scheduled For Delivery",
  CANCELLED: "Cancelled",
  FAILED: "Failed",
};

export const VOUCHER_DELIVERY_DISPLAY_STATUS = {
  [VOUCHER_DELIVERY_STATUS.DECLINED]: VOUCHER_DELIVERY_STATUS.DECLINED,
  [VOUCHER_DELIVERY_STATUS.DELIVERED]: VOUCHER_DELIVERY_STATUS.DELIVERED,
  [VOUCHER_DELIVERY_STATUS.SCHEDULE_FOR_DELIVERY]: "Scheduled for delivery",
  [VOUCHER_DELIVERY_STATUS.CANCELLED]: VOUCHER_DELIVERY_STATUS.CANCELLED,
  [VOUCHER_DELIVERY_STATUS.FAILED]: VOUCHER_DELIVERY_STATUS.FAILED,
};

export const MAX_VOUCHER_PURCHASE = 10;

export const VOUCHER_PURCHASE_STEP_PROGRESS = {
  // have to update these values if in future number of steps in voucher purchase flow changes
  1: 0.5,
  2: 1.0,
  LAST: 1.0,
};

export const VOUCHER_RECIPIENT = {
  client: "client",
  recipient: "recipient",
};
