import { checkIfEqual, getValue } from "../../utils/object";
import { IFormData } from "../../pages/ProDashboard/Business/Payouts/Forms/model";
import { getPayloadForAccountUpdate } from "./transformer/stripeTransformer.service";

const checkIfDetailsUpdated = ({
  data,
  payoutDetails,
  checkExplicitlyForIDDocument = false,
  options = {},
}: {
  data: IFormData;
  payoutDetails: IFormData;
  checkExplicitlyForIDDocument?: boolean;
  options: Record<string, any>;
}) => {
  const dataPayload = getPayloadForAccountUpdate(data, undefined, options);
  const payoutDetailsPayload = getPayloadForAccountUpdate(payoutDetails, undefined, options);

  const hasDataChanged = !checkIfEqual(dataPayload, payoutDetailsPayload);

  return checkExplicitlyForIDDocument
    ? hasDataChanged || !!getValue(data, "identityVerification.documentPath")
    : hasDataChanged;
};
export { checkIfDetailsUpdated };
