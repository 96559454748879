import { useMutation } from "@tanstack/react-query";
import * as httpClient from "../api/client";
import firebaseApp from "../services/firebaseApp";

interface VerifyEmailRequest {
  email: string;
  forProSignUp?: boolean;
}

interface VerifyEmail {
  email: string;
  code: string | number;
}

export const useVerifyEmailRequest = (includeToken = true) => {
  const { isLoading, mutate } = useMutation(async (body: VerifyEmailRequest) => {
    const token = await firebaseApp.getToken();
    return httpClient.post("api/v2/verify/reqjrth", body, includeToken, {
      "X-SESSION": token,
    });
  });

  return { isLoading, requestEmailVerification: mutate };
};

export const useVerifyEmail = (includeToken = true) => {
  const { isLoading, mutate } = useMutation(async (body: VerifyEmail) => {
    const token = await firebaseApp.getToken();
    return httpClient.post("api/v2/verify/reqjrth-rrhjiff", body, includeToken, {
      "X-SESSION": token,
    });
  });

  return { isLoading, verifyEmail: mutate };
};
