import React, { useState } from "react";
import { Box } from "@material-ui/core";
import ReviewCard from "./ReviewCard";
import { Recipient } from "../../../models";
import { Colors } from "../../../constants/colors";
import { Text } from "../../../components/v2/Styled";
import { useMobile } from "../../../hooks/mobile";
import { LoadingSpinner } from "../../../components/loadingSpinner";
import PaginationControl from "../../../components/PaginationControl";
import { RECIPIENT_REVEIWS_PER_PAGE } from "../../../constants/review";
import { useGetRecipientReceivedReviews } from "../../../hooks/recipient/recipient.hooks";
import { FontFamily, FontSize, FontWeight, Spacing } from "../../../components/v2/Styled/enum";
import HeroImage from "../../../images/no-bookings-placeholder.svg";

interface Props {
  recipient: Recipient;
}

const RecipientReceivedReviews = ({ recipient }: Props): JSX.Element => {
  const isMobile = useMobile();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isLoading } = useGetRecipientReceivedReviews(recipient.id, {
    perPage: RECIPIENT_REVEIWS_PER_PAGE,
    currentPage,
  });

  const pageCount = data ? Math.ceil(data.count / RECIPIENT_REVEIWS_PER_PAGE) : 0;
  const checkIfEmpty = () => {
    return data?.data.length ?? 0;
  };

  return (
    <Box style={{ gap: "24px", display: "flex", flexDirection: "column" }} marginY={Spacing.S11}>
      {/* Label */}
      <Box flex={1} alignItems="center" justifyContent={isMobile ? "flex-end" : "space-between"}>
        {!isMobile && (
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Text
              font={FontFamily.Museo}
              weight={FontWeight.Bold}
              size={FontSize.F24}
              color={Colors.NightBlue}
            >
              Reviews received
            </Text>
            <Text
              font={FontFamily.Museo}
              weight={FontWeight.Medium}
              size={FontSize.F16}
              color={Colors.Dusk}
            >
              Providers share feedback on their experience with clients after each booking.
            </Text>
          </div>
        )}
      </Box>

      {isLoading && <LoadingSpinner />}

      <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
        {data?.data.map((item: any) => (
          <ReviewCard
            key={item.job.bookingId}
            userName={item.job.user.firstName}
            bookingId={item.job.bookingId}
            createdAt={item.createdAt}
            profileImage={item.job.user.profileImage}
            rating={item.rating}
            review={item.review}
            serviceType={item.job.booking.serviceType}
            reviewProvided={false}
          />
        ))}

        {pageCount > 0 && (
          <Box alignSelf="flex-end" justifySelf="flex-end">
            <PaginationControl
              currentPage={currentPage}
              pages={pageCount}
              onNext={() => setCurrentPage(currentPage + 1)}
              onPrevious={() => setCurrentPage(currentPage - 1)}
              startFromOne
            />
          </Box>
        )}
      </Box>

      {/* Centered Empty State */}
      {!checkIfEmpty() && !isLoading && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            marginTop: "15vh",
          }}
        >
          <EmptyState />
        </Box>
      )}
    </Box>
  );
};

const EmptyState = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="300px">
      <img src={HeroImage} alt="No reviews placeholder" />
      <Box
        fontFamily="Museo"
        fontSize="20px"
        textAlign="center"
        fontWeight={700}
        color={Colors.Dusk}
        mt="19px"
      >
        No reviews received
      </Box>
      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        color={Colors.Dusk}
        letterSpacing="0.39px"
        textAlign="center"
        mt="10px"
        width="354px"
      >
        All ratings and reviews from the providers for this client will appear here.
      </Box>
    </Box>
  );
};

export default RecipientReceivedReviews;
