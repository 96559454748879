import React from "react";
import { Box, BoxProps } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Colors } from "../../../constants/colors";
import { Link } from "@material-ui/core";

const Elements = styled(Box)({
  "&:hover": {
    textDecoration: "underline",
  },
});

export enum HeaderLinkType {
  giftVouchers,
  atWork,
  locations,
  massage,
  fitness,
  mind,
  beauty,
  help,
}

export const headerLinkTypes = [
  HeaderLinkType.giftVouchers,
  HeaderLinkType.atWork,
  HeaderLinkType.locations,
  HeaderLinkType.massage,
  HeaderLinkType.fitness,
  HeaderLinkType.mind,
  HeaderLinkType.beauty,
  HeaderLinkType.help,
];

interface Props extends BoxProps {
  type: HeaderLinkType;
}

export default function HeaderLink({ type, ...props }: Props): JSX.Element {
  const _title = () => {
    switch (type) {
      case HeaderLinkType.giftVouchers:
        return "Gift Vouchers";
      case HeaderLinkType.atWork:
        return "At Work";
      case HeaderLinkType.locations:
        return "Locations";
      case HeaderLinkType.massage:
        return "Massage";
      case HeaderLinkType.fitness:
        return "Fitness";
      case HeaderLinkType.mind:
        return "Mind";
      case HeaderLinkType.beauty:
        return "Beauty";
      case HeaderLinkType.help:
        return "Help";
    }
  };

  const _link = () => {
    switch (type) {
      case HeaderLinkType.giftVouchers:
        return "https://getblys.com.au/massage-gift-voucher/";
      case HeaderLinkType.atWork:
        return "https://getblys.com.au/our-massages/corporate-massage/";
      case HeaderLinkType.locations:
        return "https://getblys.com.au/locations/";
      case HeaderLinkType.massage:
        return "https://getblys.com.au/services/";
      case HeaderLinkType.fitness:
        return "https://getblys.com.au/services/blys-telehealth/digital-pt/";
      case HeaderLinkType.mind:
        return "https://getblys.com.au/services/mental-wellness/";
      case HeaderLinkType.beauty:
        return "https://getblys.com.au/services/beauty/";
      case HeaderLinkType.help:
        return "https://help.getblys.com.au/";
    }
  };

  const title = _title();
  const link = _link();

  return (
    <Elements>
      <Box
        fontFamily="Nunito"
        fontWeight={600}
        fontSize="16px"
        lineHeight="17.6px"
        style={{ cursor: "pointer" }}
        ml="20px"
        mr="20px"
        {...props}
        sx={{ textDecoration: "none", color: Colors.SlateGrey }}
      >
        <Link style={{ color: Colors.SlateGrey }} href={link}>
          {title}
        </Link>
      </Box>
    </Elements>
  );
}
