import Badge from "@material-ui/core/Badge";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Colors } from "../../constants/colors";
import { useUnreadMessages } from "../../hooks/chat/chat.hooks";
import Button, { ButtonType } from "../Button";

type Props = {
  text: string;
  conversationId: string;
  bookingId: string | number;
  forClient?: boolean;
};

const StyledBadge = withStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
    },
    colorSecondary: {
      backgroundColor: Colors.LightPeriwinkle,
    },
    colorPrimary: {
      backgroundColor: Colors.Danger,
    },
  });
})(Badge);

const ChatButton = ({ text, bookingId, conversationId, forClient = true }: Props) => {
  const history = useHistory();
  const { unreadCount } = useUnreadMessages(conversationId);

  const redirectToChatScreen = () => {
    if (forClient) {
      return history.push(`/my-bookings/${bookingId}/chat`);
    }
    return history.push(`/pro/bookings/${bookingId}/chat`);
  };

  return (
    <StyledBadge
      badgeContent={unreadCount}
      color={unreadCount > 0 ? "primary" : "secondary"}
      showZero
    >
      <Button title={text} type={ButtonType.outlined} onClick={redirectToChatScreen} />
    </StyledBadge>
  );
};

export default ChatButton;
