import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { formatToPrice } from "../../../helpers/number";
import { useMobile } from "../../../hooks/mobile";
import SuccessImg from "../../../images/success-icon.png";
import Button, { ButtonType } from "../../Button";
import Dialog from "../../Dialog";
import { Text } from "../../v2/Styled";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
} from "../../v2/Styled/enum";

type Props = {
  visible: boolean;
  onClose: () => void;
  price: number;
  therapistCount?: number;
  currency: string;
};
export function BeforeBookingTipSuccessModal({ therapistCount = 1, ...props }: Props) {
  const isMobile = useMobile();

  const getDescription = () => {
    const formattedPrice = formatToPrice(props.price, props.currency);

    if (therapistCount > 1) {
      const splittedPrice = formatToPrice(props.price / therapistCount, props.currency);
      return `You have successfully added ${formattedPrice} tip. For this booking with ${therapistCount} providers, the tip will be split evenly by default, with each receiving ${splittedPrice}. After your booking is confirmed, you can easily adjust the tip amount for each provider individually.`;
    }
    return `You have successfully added ${formattedPrice} tip for your provider.`;
  };

  return (
    <Dialog open={props.visible} fullWidth={isMobile} maxWidth={"xs"} onClose={props.onClose}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "30px 40px",
        }}
      >
        <Box style={{ textAlign: "center", paddingBottom: "20px" }}>
          <img src={SuccessImg} alt="success-icon" style={{ height: " 100px", width: "100px" }} />
        </Box>
        <Box
          gridGap={"16px"}
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          paddingBottom={"18px"}
        >
          <Box style={{ textAlign: "center" }}>
            <Text
              color={Colors.Indigo}
              size={FontSize.F24}
              font={FontFamily.Museo}
              weight={FontWeight.Bold}
            >
              Tip added!
            </Text>
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Text color={Colors.Grey} lineHeight={LineHeight.L24}>
              {getDescription()}
            </Text>
          </Box>
        </Box>
        <Box>
          <Button
            onClick={() => {
              props.onClose();
            }}
            title={"Sweet, thanks"}
            type={ButtonType.outlined}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
