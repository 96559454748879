import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import TextField from "../../TextField";

interface Props {
  standardRate: string;
  rateToOffer: string;
  onRateToOfferChange: (value: any) => unknown;
  currency: string | undefined;
  maximumOfferRate: string;
  shouldFocusToInput?: boolean;
}

export const OfferRate = ({
  standardRate,
  rateToOffer,
  currency,
  onRateToOfferChange,
  maximumOfferRate,
  shouldFocusToInput,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (shouldFocusToInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [shouldFocusToInput, inputRef]);

  return (
    <Box
      style={{
        display: Display.Flex,
        flexDirection: FlexDirection.Column,
        gap: Spacing.S6,
      }}
    >
      <Box
        style={{
          fontSize: FontSize.F24,
          fontFamily: FontFamily.Museo,
          color: Colors.NightBlue,
          fontWeight: FontWeight.Bold,
        }}
      >
        {" "}
        Offer rate
      </Box>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        style={{
          gap: Spacing.S1,
        }}
      >
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          justifyContent={JustifyContent.spaceBetween}
          style={{
            fontFamily: FontFamily.Museo,
            color: Colors.Dusk,
            fontSize: FontSize.F16,
            fontWeight: FontWeight.Bold,
            border: `1.2px solid ${Colors.LightPeriwinkle}`,
            padding: Spacing.S4,
            borderRadius: Spacing.S2,
          }}
        >
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
            style={{ gap: Spacing.S2 }}
          >
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              justifyContent={JustifyContent.spaceBetween}
            >
              <Box>Standard rate</Box>
              <Box>{`${currency}${standardRate}`}</Box>
            </Box>

            <Box
              style={{
                fontWeight: FontWeight.Regular,
                fontFamily: FontFamily.OpenSans,
                fontSize: FontSize.F14,
                color: Colors.AppGrey,
              }}
            >
              The standard payout amount set by the platform for this booking.
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            marginTop: Spacing.S2,
          }}
        >
          <TextField
            title="Offer preferred rate"
            subtitle={`The amount you prefer to receive for this booking.
                 The rate offered cannot be below the standard rate set by the platform. The maximum amount you can enter for this booking is ${currency}${maximumOfferRate}.
            `}
            placeholder="Enter your preferred rate"
            onChange={(value) => onRateToOfferChange(value)}
            value={`${rateToOffer}`}
            startAdornment={rateToOffer ? currency : undefined}
            inputRef={inputRef}
          />
        </Box>
      </Box>
    </Box>
  );
};
