import { ServiceRate, ServiceRatePrice } from "../models";
import { capitalizeFirstLetter } from "./string";
import { checkIfEmpty } from "../utils/object";
import { ReactComponentElement } from "react";

const formatServicesData = (rates: ServiceRate[]) => {
  return rates.map((rate) => {
    return {
      value: rate.id,
      title: capitalizeFirstLetter(rate.alias || rate.name),
      profession: rate.profession || "therapist",
    };
  });
};

const formatTreatmentsData = (treatments: ServiceRatePrice[]) => {
  return treatments.map((treatment: ServiceRatePrice) => {
    return {
      value: treatment.id,
      title: treatment.label,
    };
  });
};
const isEmptyOrWhitespace = (str: string) => {
  return checkIfEmpty(str) || str.trim().length === 0;
};

const allowUploadImageExtension = () => {
  return "image/*";
};

const allowUploadDocumentExtension = () => {
  return "image/*,application/pdf";
};

const filterImageAndApplicationExtension = (file: any) => {
  const fileType = file?.type;
  if (fileType?.startsWith("image") || fileType === "application/pdf") {
    return true;
  }
  return false;
};
export {
  formatServicesData,
  formatTreatmentsData,
  isEmptyOrWhitespace,
  allowUploadImageExtension,
  allowUploadDocumentExtension,
  filterImageAndApplicationExtension,
};
