import { ConfirmCardSetupData, Token } from "@stripe/stripe-js";
import { User } from "../../../models";
import { getValue } from "../../../utils/object";

const getConfirmCardSetupData = ({
  user,
  token,
}: {
  user: User | null;
  token: Token | undefined;
}): ConfirmCardSetupData => {
  const tokenId = getValue(token, "id") || "";
  const name = `${getValue(user, "legalFirstName") || getValue(user, "firstName")} ${getValue(
    user,
    "lastName"
  )}`;

  return {
    payment_method: {
      card: { token: tokenId },
      billing_details: {
        name,
        email: getValue(user, "email") || "",
        phone: getValue(user, "mobile") || "",
        address: { country: getValue(user, "country") },
      },
    },
  };
};

export { getConfirmCardSetupData };
