import { Box, InputBase, makeStyles, Typography } from "@material-ui/core";
import React from "react";

import Ratings from "../components/Ratings";
import { BOOKING_STATUS, JobStatus } from "../constants/booking";
import { Colors } from "../constants/colors";
import { REVIEW_NOTES_LIMIT_FOR_THERAPIST } from "../constants/common";
import { flaggedWordsForProfile } from "../constants/flaggedWords";
import { REVIEW_CLIENT_MESSAGE } from "../constants/message";
import { BOOKING_TIP_TYPE } from "../constants/tip";
import {
  covidStatusForBookingDetail,
  therapistProfilePictureUrlForBookingDetail,
} from "../helpers/booking";
import { parseApiError } from "../helpers/error";
import { useAccessToken } from "../hooks/common";
import { useMobile } from "../hooks/mobile";
import { useTherapistReview, useUpdateTherapistReview } from "../hooks/review/review.hooks";
import { useUpdateTipMutation } from "../hooks/tip.hook";
import { Booking, BookingDetails } from "../models";
import { checkFlaggedWord } from "../services/utility/flaggedWord.service";
import { useAlertStore } from "../stores/alert";
import { getValue } from "../utils/object";
import AvatarImageWithCovidStatus, { ImageSize } from "./AvatarImageWithCovidStatus";
import Button, { ButtonType } from "./Button";
import Dialog from "./Dialog";
import Divider from "./Divider";
import { ImagePicker } from "./Image/ImagePicker";
import { Image, RemoveableImage } from "./Image/RemovableImage";
import { TippingAmounts } from "./Modals/TippingModal/TippingAmounts";
import { RateTherapistSuccessModal } from "./RateTherapistSuccessModal";
import { isTipReducedOnCompletedBooking } from "../helpers/tip";

interface Props {
  open: boolean;
  detail: BookingDetails | null;
  onClose: () => unknown;
  onCompleted: () => unknown;
  showFlaggedModal: (arg: boolean) => unknown;
  currencySymbol: string;
  baseRate: number;
  isTippingAllowed: boolean;
  bookingTip: Booking["bookingTips"][0] | null;
  countryCode: string;
}

export default function RateTherapistModal({
  open,
  detail,
  onClose,
  onCompleted,
  showFlaggedModal,
  currencySymbol,
  baseRate,
  isTippingAllowed,
  bookingTip,
  countryCode,
}: Props): JSX.Element {
  const accessToken = useAccessToken();
  const isMobile = useMobile();

  const [rating, setRating] = React.useState(0);
  const [feedback, setFeedback] = React.useState("");

  const [selectedImagesFiles, setSelectedImagesFiles] = React.useState<Image[]>([]);
  const [removedImages, setRemovedImages] = React.useState<number[]>([]);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [tipAmount, setTipAmount] = React.useState(0);
  const [tipId, setTipId] = React.useState(BOOKING_TIP_TYPE.NOT_NOW);
  const [tipValueChanged, setTipValueChanged] = React.useState(false);
  const [textFieldError, setTextFieldError] = React.useState<string | null>(null);

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { mutateAsync: updateTip } = useUpdateTipMutation();

  const reviewLeft = detail?.job?.status === JobStatus.reviewLeft;

  const useStyles = makeStyles((theme) => ({
    input: {
      "&::placeholder": {
        color: Colors.LightBlueGrey,
        opacity: 1.0,
      },
    },
  }));

  const inputClasses = useStyles();
  const { isLoading: submitting, mutate: reviewTherapist } = useTherapistReview({
    onSuccess: () => {
      setSuccessMessage(REVIEW_CLIENT_MESSAGE);
      onCompleted();
      setShowSuccessModal(true);
    },
    onError: (error: any) => {
      setErrorMessage(parseApiError(error));
    },
  });

  const { isLoading: isUpdating, mutate: updateReview } = useUpdateTherapistReview({
    onSuccess: () => {
      setSuccessMessage(REVIEW_CLIENT_MESSAGE);
      onCompleted();
      setShowSuccessModal(true);
    },
    onError: (error: any) => {
      setErrorMessage(parseApiError(error));
    },
  });

  React.useEffect(() => {
    if (detail) {
      setRating(detail.job?.therapistreview?.rating);
      setFeedback((detail.job?.therapistreview?.review || "").trim());
      setSelectedImagesFiles((detail.job?.therapistreview?.reviewFiles || []) as Image[]);
    }
  }, [detail]);

  React.useEffect(() => {
    setTipValueChanged(true);
  }, [tipAmount]);

  const onImagePicked = (pickedImages: Array<any>) => {
    const images = pickedImages.map((image, index) => ({
      id: `uploaded-image-${index}-${Date.now()}`,
      isLocallyUploaded: true,
      filePath: URL.createObjectURL(image),
      imageFileToUpload: image,
    }));

    setSelectedImagesFiles((preSelectedImages) => [...preSelectedImages, ...images]);
  };

  const onImageRemoved = (removedImage: Image) => {
    const upatedImagesAfterRemoved = selectedImagesFiles.filter(({ id }) => removedImage.id !== id);
    setSelectedImagesFiles(upatedImagesAfterRemoved);
    const isLocallyUploaded = getValue(removedImage, "isLocallyUploaded", false);
    if (!isLocallyUploaded) {
      setRemovedImages((prevImagesToRemove) => [...prevImagesToRemove, removedImage.id as number]);
    }
  };

  const onReviewTherapist = (value: string) => {
    if (value.length > REVIEW_NOTES_LIMIT_FOR_THERAPIST) return;
    setFeedback(value);
  };

  if (!detail) {
    return <Box />;
  }
  const { job } = detail;
  const { id: jobId } = job;
  const therapistName = detail.job?.user?.firstName || "your therapist";

  const url = therapistProfilePictureUrlForBookingDetail(detail);
  const isCovidVaccinated = covidStatusForBookingDetail(detail);

  const isReviewOnly = !isTippingAllowed || tipAmount === 0 || !tipValueChanged;

  const handleSubmitFeedback = () => {
    if (!rating) {
      return setErrorMessage(`Please provide your rating for ${therapistName}`);
    }

    if (feedback && checkFlaggedWord(flaggedWordsForProfile, feedback)) {
      showFlaggedModal(true);
      return;
    }

    if (isTippingAllowed && tipValueChanged && tipAmount && tipId) {
      // check if tip is reduced
      if (
        isTipReducedOnCompletedBooking(
          [job],
          [{ jobId, tipAmount, tipId }],
          bookingTip ? [bookingTip] : []
        )
      ) {
        setErrorMessage("Tip cannot be reduced once the therapist has completed the booking");
        return;
      }

      updateTip({
        bookingId: detail.bookingId.toString(),
        isEvenlySplit: false,
        tipPerJobs: [
          {
            jobId: detail.job.id,
            tipAmount,
            tipId,
          },
        ],
      }).catch(() => {});
    }

    const images = selectedImagesFiles
      .filter(
        ({ isLocallyUploaded, imageFileToUpload }) => isLocallyUploaded && !!imageFileToUpload
      )
      .map(({ imageFileToUpload }) => imageFileToUpload);
    if (reviewLeft) {
      updateReview({
        jobId,
        rating,
        review: feedback,
        accessToken,
        images,
        removedImages,
      });
    } else {
      reviewTherapist({
        jobId,
        rating,
        review: feedback,
        images,
        accessToken,
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          width={isMobile ? "100%" : "544px"}
          bgcolor="#ffffff"
          borderRadius="11px"
          display="flex"
          flexDirection="column"
          paddingY={"24px"}
        >
          <Box display="flex" flex={1} alignItems="center" justifyContent="center">
            <Box
              width="85%"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <AvatarImageWithCovidStatus
                size={ImageSize.medium}
                isCovidVaccinated={isCovidVaccinated}
                imageSrc={url}
              />
              <Box
                fontFamily="Museo"
                fontWeight={700}
                fontSize="21px"
                letterSpacing="-0.28px"
                color={Colors.NightBlue}
                mt="12px"
              >
                How was {therapistName}?
              </Box>
              <Box
                fontFamily="Open Sans"
                fontSize="16px"
                textAlign="center"
                color={Colors.BlueyGrey}
                mt="12px"
                mb="16px"
              >
                Your honest feedback helps to ensure that the quality and standard of providers
                remain high.
              </Box>
              <Box mt={"16px"}>
                <Ratings
                  width="31.5px"
                  height="30px"
                  rating={rating}
                  onClickRating={(rating) => {
                    setRating(rating);
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box width="100%" mt={3}>
            <Box
              minHeight="70px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              paddingX={"48px"}
            >
              <Box mt={2} mb={3} width="100%">
                <InputBase
                  placeholder="Leave a review (optional)"
                  onChange={(event) => {
                    onReviewTherapist(event.target.value);
                  }}
                  fullWidth
                  value={feedback}
                  multiline
                  style={{
                    color: Colors.TurquoiseBlue,
                    paddingLeft: "8px",
                  }}
                  classes={{
                    input: inputClasses.input,
                  }}
                />
                <Box
                  bgcolor={Colors.LightPeriwinkle}
                  height="1px"
                  width="100%"
                  style={{ marginTop: "10px" }}
                />

                <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
                  <Box fontFamily="Open Sans" fontSize="14px" color={Colors.BlueyGrey} mt={1}>{`${
                    feedback?.length || 0
                  } / ${REVIEW_NOTES_LIMIT_FOR_THERAPIST}`}</Box>
                </Box>
              </Box>
              {selectedImagesFiles.length > 0 ? (
                <Box mt={2} mb={3} width={"100%"}>
                  <Box style={imageStyles.container} flexWrap={"wrap"}>
                    {selectedImagesFiles.map((image) => (
                      <RemoveableImage image={image} onRemove={onImageRemoved} />
                    ))}
                  </Box>
                </Box>
              ) : (
                <></>
              )}

              <Box display={"flex"} width={"100%"}>
                <ImagePicker
                  buttonStyle={{
                    padding: "8px 16px",
                  }}
                  onImagePicked={onImagePicked}
                  multipleImage
                />
              </Box>
            </Box>
          </Box>

          <Divider marginY={"24px"} />

          {isTippingAllowed && (
            <>
              <Box width={"100%"}>
                <Box display={"flex"} flexDirection={"column"} gridGap={"16px"} paddingX={"48px"}>
                  <Typography>
                    <Box
                      sx={{
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#41506F",
                        textAlign: "left",
                      }}
                    >
                      Add a tip (optional)
                    </Box>
                  </Typography>
                  <TippingAmounts
                    baseRate={baseRate}
                    bookingType={BOOKING_STATUS.COMPLETED}
                    currency={currencySymbol}
                    onTipUpdate={(tipAmount, cardId) => {
                      if (cardId === BOOKING_TIP_TYPE.CUSTOM && tipAmount === 0) {
                        setTextFieldError("Please enter a valid tip amount");
                        return;
                      }

                      setTextFieldError(null);
                      setTipAmount(tipAmount);
                      setTipId(cardId);
                    }}
                    therapistCount={1}
                    cardSize="md"
                    cardSpacing="normal"
                    customTipAmount={
                      bookingTip?.type === BOOKING_TIP_TYPE.CUSTOM ? (bookingTip?.amount ?? 0) : 0
                    }
                    selectedCardId={bookingTip?.type ?? undefined}
                    countryCode={countryCode}
                    textFieldError={textFieldError}
                  />
                </Box>
              </Box>

              <Divider marginY={"24px"} />
            </>
          )}

          <Box width={"100%"} paddingX={"48px"} boxSizing={"border-box"}>
            <Box display={"flex"} gridGap={"16px"} width={"100%"}>
              <Button
                type={ButtonType.outlined}
                onClick={() => {
                  onClose();
                }}
                title="Cancel"
              />
              <Button
                type={ButtonType.secondary}
                onClick={handleSubmitFeedback}
                title="Add"
                loading={reviewLeft ? isUpdating : submitting}
                disabled={textFieldError !== null}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
      {showSuccessModal && (
        <RateTherapistSuccessModal
          currency={currencySymbol}
          onClose={() => {
            setShowSuccessModal(false);
            setTipValueChanged(false);
          }}
          proName={therapistName}
          tipAmount={tipAmount}
          visible={showSuccessModal}
          onlyReview={isReviewOnly}
        />
      )}
    </>
  );
}
const imageStyles = {
  container: {
    display: "flex",
    width: "100%",
    gap: "12px",
  },
};
