import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@material-ui/core";

import { Recipient } from "../../../../models";
import Dialog from "../../../../components/Dialog";
import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
import Dropdown from "../../../../components/Dropdown";
import TextField from "../../../../components/TextField";
import { COUNTRY } from "../../../../constants/countries";
import CloseIcon from "../../../../images/modal-close.svg";
import { capitalizeFirstLetter } from "../../../../helpers/string";
import Contraindications from "../../../../components/Contraindications";
import { isMassageType, useBookingStore } from "../../../../stores/booking";
import Button, { ButtonSize, ButtonType } from "../../../../components/Button";
import { useUserServices } from "../../../../hooks/services/userServices.hooks";
import { NOTE_FOR_THERAPIST_PLACHOLDER } from "../../../../constants/placeholder";
import { SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST } from "../../../../constants/common";
import {
  AllUserPreferences,
  labelForUserPreference,
  optionsForUserPreference,
} from "../../../../constants/preferences";
import { checkIfEmpty } from "../../../../utils/object";
import { useAlertStore } from "../../../../stores/alert";

interface Props {
  recipient: Recipient;
  userCountry?: string;
  open: boolean;
  onClose: () => unknown;
  onSave: (data: any) => unknown;
  immediateUpdate?: boolean;
  isLoading?: boolean;
  selectedInstruction?: any;
  setSelectedInstruction: (value: boolean) => unknown;
  serviceSelectionDisabled?: boolean;
  selectedServiceId?: number | null;
  resetOnClose?: boolean;
}

const RecipientPreferenceModals = ({
  recipient,
  userCountry,
  open,
  onClose,
  onSave,
  isLoading = false,
  selectedInstruction = {},
  setSelectedInstruction,
  serviceSelectionDisabled = false,
  selectedServiceId,
  resetOnClose = true,
}: Props) => {
  const isMobile = useMobile();
  const [instructions, setInstructions] = useState("");
  const [contraindications, setContraindications] = useState<string | null>(null);
  const [hasContraindications, setHasContraindications] = useState<boolean | null>(null);
  const [selectedServiceType, setSelectedServiceType] = useState<number | null>(
    selectedServiceId || null
  );
  const [isMassage, setIsMassage] = useState<boolean>(false);

  const { setErrorMessage } = useAlertStore();

  const [preferences, setPreferences] = useState<{
    [preference: string]: string;
  }>({});

  const { services: rates } = useUserServices(userCountry || COUNTRY.AU);

  const _serviceTypeOptions = () => {
    const services = rates
      .filter(({ country }) => country === (userCountry || COUNTRY.AU))
      .map((rate) => {
        return {
          value: rate.id,
          title: capitalizeFirstLetter(rate.alias || rate.name),
          profession: rate.profession || "therapist",
          isMassage: rate.isMassage,
        };
      });
    return [...services];
  };

  const serviceTypeOptions = _serviceTypeOptions();

  useEffect(() => {
    if (!checkIfEmpty(selectedInstruction)) {
      const parsedPreference = JSON.parse(selectedInstruction.preference);
      setHasContraindications(parsedPreference.hasContraindications);
      setContraindications(parsedPreference.contraindications);
      setPreferences(parsedPreference);
      setInstructions(parsedPreference.specialInstructions);

      if (selectedInstruction.serviceId) {
        setSelectedServiceType(selectedInstruction.serviceId);
      }
    }
  }, [selectedInstruction]);

  useEffect(() => {
    const service = rates
      .filter(({ country }) => country === (userCountry || COUNTRY.AU))
      .find((rate) => rate.id === selectedServiceType);

    setIsMassage(service?.isMassage || false);
  }, [selectedServiceType, rates]);

  const resetPreferences = () => {
    setSelectedServiceType(null);
    setHasContraindications(null);
    setContraindications(null);
    setPreferences({});
    setInstructions("");
    setSelectedInstruction(false);
  };

  const formatData = () => {
    const preference = {
      ...preferences,
      specialInstructions: instructions,
      hasContraindications,
      contraindications,
    };

    const data = { preference, recipientId: recipient.id, serviceId: selectedServiceType };

    if (!selectedServiceType) {
      setErrorMessage("Select service to add preference");
      handleCloseModal();
      return;
    }

    onSave(data);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    resetOnClose && resetPreferences();
    onClose();
  };

  const getTherapistProfession = () => {
    const selectedService = serviceTypeOptions.find(
      (option) => option.value === selectedServiceType
    );
    return selectedService?.profession || "therapist";
  };

  return (
    <Dialog open={open} onClose={handleCloseModal} maxWidth={false} fullScreen={isMobile}>
      <Box
        width={isMobile ? "100%" : "600px"}
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          position="relative"
          height="52px"
        >
          <IconButton
            onClick={handleCloseModal}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <img src={CloseIcon} />
          </IconButton>
        </Box>

        <Box ml="48px" mr="48px" mb="32px">
          <Box
            fontFamily="Museo"
            style={{
              fontWeight: 600,
              fontSize: "30px",
              lineHeight: "33px",
              color: Colors.NightBlue,
            }}
          >
            {`Add ${recipient.firstName}'s preferences`}
          </Box>

          <Dropdown
            title="Service type"
            isDisabled={serviceSelectionDisabled}
            options={serviceTypeOptions}
            selectedOption={serviceTypeOptions.find(
              (option) => option.value === selectedServiceType
            )}
            onSelectedOption={(option) => {
              setSelectedServiceType(option.value);
            }}
          />

          {isMassage &&
            AllUserPreferences.map((userPreference, index) => {
              const options = optionsForUserPreference(userPreference).map((option) => {
                return {
                  value: option,
                  title: option,
                };
              });

              return (
                <Dropdown
                  key={`recipient-preference-dropdown-${index}`}
                  title={labelForUserPreference(userPreference)}
                  options={options}
                  onSelectedOption={(option) => {
                    const updatedPreferences = { ...preferences };
                    updatedPreferences[userPreference] = option.value;
                    setPreferences(updatedPreferences);
                  }}
                  selectedOption={options.find(
                    (option) => option.value === preferences[userPreference]
                  )}
                />
              );
            })}
          {selectedServiceType && (
            <>
              <Contraindications
                contraindications={contraindications}
                hasContraindications={hasContraindications}
                setContraindications={setContraindications}
                setHasContraindications={setHasContraindications}
                gender={recipient.gender}
              />

              <TextField
                title={`Notes for ${getTherapistProfession()}`}
                placeholder={NOTE_FOR_THERAPIST_PLACHOLDER}
                value={instructions}
                onChange={(text) => setInstructions(text)}
                multiline
                maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
                style={{ paddingBottom: "0px" }}
              />
            </>
          )}
        </Box>

        <Box height="1px" width="100%" bgcolor={Colors.LightPeriwinkle} />

        <Box display="flex" flexDirection="row" p="24px">
          <Button
            loading={isLoading}
            title={checkIfEmpty(selectedInstruction) ? "Add" : "Save and apply"}
            type={ButtonType.primary}
            size={ButtonSize.large}
            onClick={formatData}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default RecipientPreferenceModals;
