import React from "react";
import { Box, IconButton } from "@material-ui/core";

import { Text } from "./v2/Styled";
import * as Styled from "./v2/Styled/enum";
import CloseIcon from "../images/cross.svg";
import { Colors } from "../constants/colors";

interface Props {
  title: string;
  subtitle?: string;
  onClose: () => unknown;
}

export default function ModalHeader({ title, subtitle, onClose }: Props): JSX.Element {
  return (
    <Box
      paddingTop={Styled.Spacing.S14}
      paddingLeft={Styled.Spacing.S10}
      paddingRight={Styled.Spacing.S10}
      position={Styled.Position.Relative}
    >
      <Box position={Styled.Position.Absolute} top={Styled.Spacing.S2} right={Styled.Spacing.S2}>
        <IconButton onClick={onClose}>
          <img src={CloseIcon} alt="closeIcon" />
        </IconButton>
      </Box>
      <Box
        gridGap={Styled.Spacing.S4}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
      >
        <Box>
          <Text
            color={Colors.NightBlue}
            size={Styled.FontSize.F30}
            font={Styled.FontFamily.Museo}
            weight={Styled.FontWeight.Bold}
            lineHeight={Styled.LineHeight.L33}
          >
            {title}
          </Text>
        </Box>
        {subtitle && (
          <Box>
            <Text
              color={Colors.Dusk}
              size={Styled.FontSize.F20}
              font={Styled.FontFamily.Museo}
              weight={Styled.FontWeight.Medium}
              lineHeight={Styled.LineHeight.L30}
            >
              {subtitle}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}
