import { Box } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Colors } from "../../constants/colors";
import { ABANDON_REASON, abandonReasonOptions } from "../../constants/prodashboard";
import { useMobile } from "../../hooks/mobile";
import { ConfirmAbandonJobParameters, TherapistBooking } from "../../models";
import { isBookingAbandonAllowed } from "../../pages/ProDashboard/AbandonBookingLogic";
import { getJobAbandonFee } from "../../services/proDashboard/jobAbandon.service";
import Button, { ButtonSize, ButtonType } from "../Button";
import Dialog from "../Dialog";
import Dropdown, { DropdownOption } from "../Dropdown";
import TextField from "../TextField";
import { LoadingSpinner } from "../loadingSpinner";

interface Props {
  open: boolean;
  onClose: (e: MouseEvent) => unknown;
  onConfirm: ({ id, fee, reason }: ConfirmAbandonJobParameters) => unknown;
  abandoning: boolean;
  jobId: number | undefined;
  arrivalTime: string | undefined;
  selectedJob: TherapistBooking | undefined;
}

const abandonReasonOption = abandonReasonOptions as DropdownOption[];

export default function AbandonBookingModal({
  open,
  onClose,
  onConfirm,
  abandoning,
  arrivalTime,
  jobId,
  selectedJob,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const [abandonReason, setAbandonReason] = useState(ABANDON_REASON.CHANGED_MY_MIND);
  const [otherReason, setOtherReason] = useState("");

  // fetching abandon fee for current job id
  const { data: jobAbandonFee } = useQuery(
    ["job-abandon-fee", jobId, abandonReason],
    async () => await getJobAbandonFee(jobId, abandonReason)
  );

  const onAbandonReasonChange = async (reason: string) => {
    setAbandonReason(reason);
  };

  const title = isBookingAbandonAllowed(selectedJob, abandonReason)?.title
    ? isBookingAbandonAllowed(selectedJob, abandonReason)?.title
    : jobAbandonFee?.title;

  const body = isBookingAbandonAllowed(selectedJob, abandonReason)?.body
    ? isBookingAbandonAllowed(selectedJob, abandonReason)?.body
    : jobAbandonFee?.description;

  const isAllowAbandon = isBookingAbandonAllowed(selectedJob, abandonReason)?.allowAbandon;

  return (
    <Dialog
      open={open}
      onClose={(e: MouseEvent) => {
        onClose(e);
        setAbandonReason(ABANDON_REASON.CHANGED_MY_MIND);
      }}
      fullWidth={isMobile}
      PaperProps={{ style: { overflowY: "visible" } }}
    >
      <Box padding={"48px"} bgcolor="#ffffff" borderRadius="8px">
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="start"
        >
          {title && (
            <Box
              fontFamily="Museo"
              fontWeight={600}
              fontSize="24px"
              lineHeight="26.5px"
              color={Colors.Indigo}
            >
              {title}
            </Box>
          )}

          {body && (
            <Box
              fontFamily="Museo"
              fontStyle="normal"
              fontSize="16px"
              lineHeight="24px"
              color={Colors.Dusk}
              mt={title ? "16px" : "5px"}
              fontWeight={500}
              mb="30px"
            >
              <Box>{body}</Box>
            </Box>
          )}
          <Dropdown
            title="Reason for abandoning"
            options={abandonReasonOption}
            selectedOption={abandonReasonOption.find((option) => option.value === abandonReason)}
            onSelectedOption={(option) => onAbandonReasonChange(option.value)}
          />
          {ABANDON_REASON.OTHER === abandonReason && (
            <TextField
              title="Add your reason here"
              placeholder="(required)"
              value={otherReason}
              onChange={(text) => setOtherReason(text)}
              maxLength={400}
            />
          )}
          <Box fontSize="12px" fontFamily="Open Sans" color={Colors.BlueyGrey} fontWeight={600}>
            This information will be shared with the client.
          </Box>
          {abandoning ? (
            <Box mt={4} width={"100%"}>
              <LoadingSpinner paddingTop="0rem" />
            </Box>
          ) : (
            <Box style={styles.actionWrapper} flexDirection="row">
              <Button
                height={48}
                type={ButtonType.outlined}
                size={ButtonSize.small}
                title="Back"
                onClick={(e) => {
                  onClose(e);
                  setAbandonReason(ABANDON_REASON.CHANGED_MY_MIND);
                }}
                style={{ marginRight: "12px" }}
              />
              <Button
                height={48}
                type={isAllowAbandon ? ButtonType.secondary : ButtonType.lightSteel}
                size={ButtonSize.small}
                title="Submit"
                onClick={() =>
                  onConfirm &&
                  onConfirm({
                    id: jobId,
                    fee: jobAbandonFee?.fee,
                    reason: abandonReason === ABANDON_REASON.OTHER ? otherReason : abandonReason,
                  })
                }
                disabled={!isAllowAbandon}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "30px",
  },
};
