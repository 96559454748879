import { Box, styled } from "@material-ui/core";
import React, { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styles } from "./styles";
import { RootState } from "../../../stores/V2";
import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { TermsDocumentType } from "./TermsAndPolicies";
import Dropdown, { DropdownOption } from "../../Dropdown";
import InputModal from "../../Modals/InputModal/InputModal";
import Button, { ButtonSize, ButtonType } from "../../Button";
import { actions } from "../../../stores/V2/documents/addDocuments";
import {
  useDocumentTypeOptions,
  usePendingAgreements,
  useRemoveAgreement,
} from "../../../hooks/userTerms.hook";
import {
  AGE_CARE_TERMS_DOCUMENTS_TYPE,
  REGEX,
  USER_UPLOADED_CONTENT_TYPE,
} from "../../../data/enums";
import TermsAndPoliciesUploadedFiles from "./TermsPoliciesUploadFiles";
import { parseApiError } from "../../../helpers/error";
import { useAlertStore } from "../../../stores/alert";
import TextField from "../../TextField";
import { allowUploadDocumentExtension } from "../../../helpers/services";
const BoxColumn = styled(Box)({
  flexDirection: "column",
});
type Props = {
  hideTitle?: boolean;
};
const AddDocument = ({ hideTitle = false }: Props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const state = useSelector((state: RootState) => state.documents);

  const [showOtherField, setShowOtherField] = useState(false);

  const { isLoading: isDocumentOptionsLoading, data: documentOptions } = useDocumentTypeOptions();

  const { data: pendingAgreements } = usePendingAgreements({
    proId: state.therapistId as number,
    enabled: hideTitle && !!state.therapistId,
  });

  const onRemoveError = (error: any) => {
    const errorMessage = parseApiError(error) || "Unable to remove document. Please try again.";
    setErrorMessage(errorMessage);
  };

  const onDocumentRemoved = (_: any) => {
    setSuccessMessage("Your terms and policies have been removed.");
  };

  const { isLoading: isRemoving, mutate: removeAgreementDocument } = useRemoveAgreement({
    therapistId: state.therapistId as number,
    onError: onRemoveError,
    onSuccess: onDocumentRemoved,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUserInput = (value: string) => {
    dispatch(actions.setUserInput(value));
    dispatch(actions.setShowInputModal(false));
  };

  const validateUserInput = (input: string) => {
    return REGEX.URL.test(input);
  };

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setContentType(USER_UPLOADED_CONTENT_TYPE.FILE));
    const file = getValue(e, "target.files[0]");
    dispatch(actions.setUserInput(file));
  }, []);

  const handleSelectedOption = (option: TermsDocumentType) => {
    const value = getValue(option, "value");
    if (value === AGE_CARE_TERMS_DOCUMENTS_TYPE.OTHER_AGE_CARE_REQIREMENT) {
      setShowOtherField(true);
    } else {
      setShowOtherField(false);
      dispatch(actions.setOtherDocumentName(null));
    }
    dispatch(actions.setSelectedTermPolicies(option.value));
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAddLink = () => {
    dispatch(actions.setShowInputModal(true));
    dispatch(actions.setContentType(USER_UPLOADED_CONTENT_TYPE.LINK));
  };

  const getUserInputForDisplay = (input: typeof state.userInput) => {
    if (typeof input === "object") return input.name;
    if (typeof input === "string") return input;
    return "";
  };

  const resetInput = () => {
    dispatch(actions.setUserInput(undefined));
    // This will also clear the currently selected file in the file input field if there is one
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  if (isDocumentOptionsLoading || !documentOptions) return <></>;

  const transformedPendingAgreements = (pendingAgreements || []).map((doc: any) => {
    const { documents } = doc;
    const [document] = documents || [];

    const { link, contentType, originalLink, documentTypeId, name } = document;
    return {
      ...doc,
      filePath: link,
      type: documentTypeId,
      name,
      contentType,
      originalLink,
    };
  });

  const handleRemoveDocs = (documentId: number) => {
    if (!isRemoving) {
      removeAgreementDocument(documentId);
    }
  };

  const titleStyle = { ...styles.subTitle, ...styles.dusk };

  return (
    <>
      {!hideTitle ? (
        <Box>
          <Box style={titleStyle} marginBottom={"24px"}>
            Add document
          </Box>
        </Box>
      ) : (
        <Box>
          <Box style={styles.title} marginBottom={"24px"}>
            Your documents
          </Box>
          <Box style={{ ...styles.description, ...styles.dusk }}>
            PDF, JPG and PNG files accepted.
          </Box>
          <Box marginTop={"24px"}>
            {transformedPendingAgreements.length > 0 ? (
              <TermsAndPoliciesUploadedFiles
                uploadedFilesData={transformedPendingAgreements}
                docsType={documentOptions}
                removeAlreadyUploadedDocs={handleRemoveDocs}
                styles={{ marginTop: "16px" }}
              />
            ) : (
              <Box style={{ ...styles.description, ...styles.grey }}>No documents uploaded</Box>
            )}
          </Box>
          <Box
            style={{ ...styles.subTitle, ...styles.dusk }}
            marginBottom={"24px"}
            marginTop={"40px"}
          >
            Add document
          </Box>
        </Box>
      )}
      <Box>
        <Dropdown
          title="Document type"
          options={documentOptions}
          onSelectedOption={(option) => handleSelectedOption(option)}
          selectedOption={(documentOptions as DropdownOption[]).find(
            ({ value }: TermsDocumentType) => value === state.selectedTermPolicies
          )}
        />
      </Box>
      {showOtherField ? (
        <>
          <TextField
            title="Other document type name"
            onChange={(text) => {
              dispatch(actions.setOtherDocumentName(text));
            }}
            value={state.otherDocumentName}
            placeholder="Enter document type name"
          />
          <></>
        </>
      ) : (
        <></>
      )}
      {!!state.userInput ? (
        <Box maxWidth={isMobile ? "100%" : "400px"} style={styles.selectedFileWrapper}>
          <Box style={{ ...styles.description, ...styles.dusk, ...styles.flex }} marginTop={"24px"}>
            {getUserInputForDisplay(state.userInput)}
          </Box>
          <Button
            title="Remove"
            colorTitle={Colors.Danger}
            type={ButtonType.background}
            width="auto"
            onClick={() => resetInput()}
            size={ButtonSize.small}
            style={{ height: "20px", marginTop: "24px" }}
          />
        </Box>
      ) : (
        <React.Fragment />
      )}
      {state.selectedTermPolicies ? (
        <Box style={styles.block} mt={"32px"}>
          <Box style={styles.displayFlex}>
            <Button
              width={"100px"}
              title="Select file"
              size={ButtonSize.small}
              type={ButtonType.outlined}
              onClick={handleFileSelect}
            />
            <input
              type="file"
              accept={allowUploadDocumentExtension()}
              ref={fileInputRef}
              style={{ visibility: "hidden", width: 0 }}
              onChange={handleFileChange}
            />
          </Box>
          <Button
            width={"100px"}
            title="Add link"
            size={ButtonSize.small}
            type={ButtonType.outlined}
            onClick={handleAddLink}
          />
        </Box>
      ) : (
        <React.Fragment />
      )}
      <BoxColumn>
        <InputModal
          open={state.showInputModal}
          onClose={() => dispatch(actions.setShowInputModal(false))}
          title={"Add web url"}
          validateInput={validateUserInput}
          validationErrorMessage={"Please enter a valid URL"}
          onConfirm={handleUserInput}
        />
      </BoxColumn>
    </>
  );
};
export default AddDocument;
