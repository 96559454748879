import moment from "moment";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { Box, ButtonBase } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DayPicker, { NavbarElementProps } from "react-day-picker";

import "react-day-picker/lib/style.css";
import "../../styles/DayPicker.css";

import { useAlertStore } from "../../stores/alert";
import { getTimeRanges, useServicesStore } from "../../stores/booking";

import Button, { ButtonType } from "../../components/Button";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import { Colors } from "../../constants/colors";
import { getTimeOptions, minutesToTime } from "../../helpers/time";
import { useMobile } from "../../hooks/mobile";
import CalendarNext from "../../images/calendar-next.png";
import CalendarPrevious from "../../images/calendar-previous.png";
import { trackEvent } from "../../services/segment/track.service";
import ReviewWrapper from "./Wrapper/ReviewWrapper";

import { RootState } from "../../stores/V2";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import { getBookingPrice } from "../../stores/V2/booking/booking.transformer";
import {
  getCurrentTimeStamp,
  getCurrentTimeInMinutes,
  convertDayMomentToDate,
  resetTimeFromDate,
  isDateObjectToday,
  convertDatetoTimezoneMoment,
} from "../../utils/date.util";
import { STEP_PROGRESS } from "../../constants/booking";
import { checkIfEmpty, getValue } from "../../utils/object";
import { getNearestNextTimeOption } from "../../services/bookings/prefillDateTime.service";
import Divider from "../../components/Divider";
import { getDateTimeLabelWithMinutes } from "../../services/bookings/bookingTime.service";
import { DEFAULT_TIMEZONE } from "../../constants/time";

export function DayPickerNavbar({
  month,
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
}: NavbarElementProps) {
  const buttonStyle = {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
  };

  return (
    <div className={className}>
      <Box display="flex" justifyContent="space-between" pl="22px" pr="22px">
        <Box fontFamily="Museo" fontSize="20px" fontWeight={700} color={Colors.Dusk}>
          {moment(month).format("MMMM YYYY")}
        </Box>

        <Box display="flex" flexDirection="row">
          <ButtonBase style={buttonStyle} onClick={() => onPreviousClick()}>
            <img src={CalendarPrevious} alt="Back Button" />
          </ButtonBase>
          <ButtonBase style={buttonStyle} onClick={() => onNextClick()}>
            <img src={CalendarNext} alt="Next Button" />
          </ButtonBase>
        </Box>
      </Box>
    </div>
  );
}

interface Params {
  id?: string;
}

export default function ReviewDateAndTime(): JSX.Element {
  const { id } = useParams<Params>();

  const history = useHistory();
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const { timeRange } = useServicesStore();
  const { setErrorMessage } = useAlertStore();
  const { booking: state } = useSelector((state: RootState) => state);

  const addressTimezone = getValue(state, "address.timezone") || state.timezone;

  const [selectedDay, setSelectedDay] = useState<Date>();
  const [arrivalTimeInMinutes, setArrivalTime] = useState<number>();
  const [timeRangeFilterFromInMinutes, setTimeRangeFilterFromInMinutes] = useState<number>();

  const timeOptions = getTimeOptions(timeRange) as DropdownOption[];

  useEffect(() => {
    trackEvent("Date Time Viewed", {
      step: 4,
      version: 2,
    });
  }, []);

  useEffect(() => {
    updatePrice();
  }, [state.timeOfArrival]);

  useEffect(() => {
    initialize();
  }, [state.id]);

  const updatePrice = async () => {
    const price = await getBookingPrice(state);
    if (price) {
      dispatch(bookingActions.updateBookingPrice({ price }));
    } else {
      setErrorMessage("Unable to load booking price. Please Try again.");
    }
  };

  const initialize = () => {
    if (!timeRange || timeRange.length <= 0) getTimeRanges();

    const { selectedDateMoment, timeOfArrival, timezone: bookingTimezone } = state;
    if (timeOfArrival || selectedDateMoment) {
      const selectedBookingDay = convertDayMomentToDate(timeOfArrival || selectedDateMoment);
      setSelectedDay(selectedBookingDay);
    }

    const timezone = addressTimezone || bookingTimezone;

    const arrivalTimeInMinutes = moment.duration(timeOfArrival.format("HH:mm")).asMinutes();

    setArrivalTime(arrivalTimeInMinutes);

    const currentTimeStamp = getCurrentTimeStamp(timezone);
    const currentTimeStampInMinutes = currentTimeStamp.hours() * 60 + currentTimeStamp.minutes();

    setTimeRangeFilterFromInMinutes(currentTimeStampInMinutes);
  };

  const verifyFields = () => {
    if (isNil(selectedDay)) {
      setErrorMessage("Please select a date");
      return false;
    }
    const selectedTimeOfArrival = convertTimeInMinutesToProperTime(
      selectedDay,
      arrivalTimeInMinutes
    );
    const { selectedDateMoment, timezone } = state;
    const currentTime = getCurrentTimeStamp(timezone);

    if (
      !selectedTimeOfArrival.isSame(moment(selectedDateMoment), "minutes") &&
      selectedTimeOfArrival.isBefore(currentTime)
    ) {
      setErrorMessage("Earliest time is too early");
      return false;
    }

    return true;
  };

  const handleBack = () => history.replace(`/my-bookings/review/${id}`, { initialize: false });

  const handleContinue = () => {
    const isValid = verifyFields();
    if (!isValid) handleBack();

    handleFieldChange(selectedDay, arrivalTimeInMinutes);
    history.replace(`/my-bookings/review/${id}`, { initialize: false });
  };

  const convertTimeInMinutesToProperTime = (day: any, time: any) => {
    const { timezone } = state;
    const arrivalTime = minutesToTime(time || 0);

    const timeOfArrival = convertDatetoTimezoneMoment(day, timezone)
      .set("hour", arrivalTime.hour)
      .set("minute", arrivalTime.mins)
      .set("second", 0)
      .set("millisecond", 0);
    return timeOfArrival;
  };

  const handleFieldChange = (day: any, time: any) => {
    let timeOfArrival = convertTimeInMinutesToProperTime(day, time);
    const { timeOfArrival: bookingArrivalTime } = state;
    if (timeOfArrival.format() !== bookingArrivalTime.format()) {
      dispatch(bookingActions.updateBookings({ timeOfArrival }));
    }
  };

  const handleDayChange = (selectedDay: Date) => {
    const day = resetTimeFromDate(selectedDay);
    setSelectedDay(day);

    let arrivalTime = arrivalTimeInMinutes;
    const currentTimeInMins = getCurrentTimeInMinutes(addressTimezone);

    // update start time if required
    const isToday = isDateObjectToday(day, addressTimezone);
    if (isToday && arrivalTime && arrivalTime < currentTimeInMins) {
      arrivalTime = getNearestNextTimeOption({ timeOptions, timeInMinutes: currentTimeInMins });
      setArrivalTime(arrivalTime);
    }
    handleFieldChange(day, arrivalTime);
  };

  const handleTimeChange = (selectedTime: any) => {
    setArrivalTime(selectedTime);
    handleFieldChange(selectedDay, selectedTime);
  };

  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const filteredTimeRange = (selectedDay: any) => {
    if (!selectedDay) return [];

    const isToday = isDateObjectToday(selectedDay, addressTimezone);
    if (!isToday) return timeOptions;

    const minFeasibleEarliest = getCurrentTimeInMinutes(addressTimezone);
    return timeOptions.filter(({ value }) => value >= minFeasibleEarliest);
  };

  const getDateTimeLabel = () => {
    if (checkIfEmpty(selectedDay) && !arrivalTimeInMinutes) {
      return "Select any date and time";
    }

    if (!selectedDay) return "Select any date and time";

    const preferredLabel = getDateTimeLabelWithMinutes({
      fromDate: selectedDay,
      toDate: selectedDay,
      earliestMinutes: arrivalTimeInMinutes,
      latestMinutes: arrivalTimeInMinutes,
      timezone: addressTimezone || DEFAULT_TIMEZONE,
    });
    return preferredLabel.datetime;
  };

  const modifiers = { from: selectedDay, to: selectedDay };

  return (
    <ReviewWrapper
      buttons={[
        <Button title={"Back"} onClick={handleBack} type={ButtonType.outlined} />,
        <Button title={"Continue"} type={ButtonType.secondary} onClick={handleContinue} />,
      ]}
      progress={STEP_PROGRESS[4]}
      title="Date & time"
      label="Step 4 of 7"
      onClose={handleBack}
    >
      <Box
        mb={"16px"}
        mt={"16px"}
        fontFamily={"Museo"}
        fontSize={"16px"}
        fontWeight={"500"}
        color={Colors.Dusk}
        letterSpacing={"0.5px"}
        lineHeight={"24px"}
      >
        Select a specific date & time to update the booking. The appointment date & start time taken
        into consider and responded accordingly by your provider and you'll receive a notification.
      </Box>
      <Box style={{ marginTop: "16px", marginBottom: "16px" }}>
        <Box
          style={{ fontFamily: "Museo", fontWeight: "600", fontSize: "20px", color: Colors.Dusk }}
        >
          {getDateTimeLabel()}
        </Box>
      </Box>
      <Divider mt={"16px"} mb={"16px"} />
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} marginTop="30px">
        <Box
          mt="16px"
          width="100%"
          display={isMobile ? "flex" : undefined}
          alignItems={isMobile ? "center" : undefined}
          flexDirection={isMobile ? "column" : undefined}
        >
          <DayPicker
            month={selectedDay}
            firstDayOfWeek={1}
            onDayClick={(day, modifiers) => {
              if (modifiers.disabled) return;
              handleDayChange(day);
            }}
            selectedDays={[modifiers.from, { from: modifiers.from, to: modifiers.to }]}
            disabledDays={{
              before: new Date(),
              after: oneYearFromNow,
            }}
            captionElement={() => {
              return <Box />;
            }}
            navbarElement={(props) => {
              return <DayPickerNavbar {...props} />;
            }}
            modifiers={modifiers}
          />
        </Box>

        <Box width="100%">
          <Dropdown
            title="Booking start time"
            options={filteredTimeRange(selectedDay)}
            onSelectedOption={(option) => {
              handleTimeChange(option.value);
            }}
            checkDirection
            selectedOption={timeOptions.find((option) => option.value === arrivalTimeInMinutes)}
          />
        </Box>
      </Box>
    </ReviewWrapper>
  );
}
