import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Each from "../../../components/Each";
import Plus from "../../../images/plus-white.svg";
import { Colors } from "../../../constants/colors";
import { useMobile } from "../../../hooks/mobile";
import { checkIfEmpty } from "../../../utils/object";
import { Booking, Recipient } from "../../../models";
import { Box, Text } from "../../../components/v2/Styled";
import BookingItem from "../../../components/Bookings/BookingItem";
import { LoadingSpinner } from "../../../components/loadingSpinner";
import PaginationControl from "../../../components/PaginationControl";
import { usePastBookings } from "../../../hooks/booking/booking.hooks";
import { FilterButton } from "../../../components/FilterButton/FilterButton";
import UseBookingQueryFilter from "../../../hooks/booking/bookingFilter.hook";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { NoFilteredBookings } from "../../../components/Bookings/NoFilteredBookings";
import {
  BOOKING_STATUS,
  MassageFor,
  RECIPIENT_PAST_BOOKINGS_PER_PAGE,
} from "../../../constants/booking";
import { BookingFilterModal } from "../../../components/Modals/FilterModal/BookingFilterModal";
import { useBookingFilterCount, useCachedBookingSort } from "../../../hooks/utility/filter.hooks";
import NoBookingsPlaceholder, {
  NoBookingsPlaceholderType,
} from "../../../components/Bookings/NoBookingsPlaceholder";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { useDispatch } from "react-redux";
import { actions as bookingActions } from "../../../stores/V2/booking/booking";
import { Paths } from "../../../constants/paths";
import { useBookingStore, useRecipientDetailsStore } from "../../../stores/booking";

interface Props {
  recipient: Recipient;
}

const RecipientPastBookings = ({ recipient }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showFilterAndSortModal, setShowFilterAndSortModal] = React.useState(false);
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();
  const { cachedSortBy } = useCachedBookingSort();
  const { getFilterCount } = useBookingFilterCount();

  const bookingSortBy = cachedSortBy || "desc";
  const [numOfFilter, setNumOfFilter] = useState(bookingSortBy !== "desc" ? 1 : 0);
  const [searchQuery, setSearchQuery] = useState({ sortBy: bookingSortBy });
  const { safeBookingQueries } = UseBookingQueryFilter({});
  const dispatch = useDispatch();
  const {
    isLoading,
    invalidatePastBookings,
    bookings: pastBookings,
    pageCount,
  } = usePastBookings({
    currentPage,
    perPage: RECIPIENT_PAST_BOOKINGS_PER_PAGE,
    search: { ...searchQuery, ...safeBookingQueries, recipientId: recipient.id },
  });

  const {
    lastBooking,
    setExitBookingFlowPath,
    recipient: recipientInfo,
    setRecipient,
  } = useBookingStore();

  const rep = useRecipientDetailsStore();

  useEffect(() => {
    setNumOfFilter(getFilterCount(safeBookingQueries, "desc"));
  }, [safeBookingQueries, getFilterCount]);

  const handleBookingClicked = (booking: Booking, status: string) => {
    const { id: bookingId } = booking;

    const url =
      status === BOOKING_STATUS.ARRANGED || status === BOOKING_STATUS.DECLINED
        ? `/my-bookings/review/${bookingId}`
        : `/my-bookings/${bookingId}`;
    history.push(url, {
      back: `${location.pathname}${location.search}`,
    });
  };

  const handleNewBookingClicked = () => {
    dispatch(bookingActions.updateBookingRecipient({ recipient }));
    setRecipient(recipient);
    useRecipientDetailsStore.setState({
      massageFor: MassageFor.someoneElse,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
    });
    setExitBookingFlowPath(location.pathname);
    history.push(Paths.ReviewAndBook);
  };

  const buildRow = (booking: Booking) => {
    return (
      <BookingItem
        booking={booking}
        fullwidth
        showDivider={false}
        onClick={() => handleBookingClicked(booking, booking.status)}
        bookingMutate={invalidatePastBookings}
      />
    );
  };

  return (
    <Box gap={Spacing.S6} marginY={Spacing.S11} direction={FlexDirection.Column}>
      {/* Label */}
      <Box flex={1} alignItems={"center"} justifyContent={isMobile ? "flex-end" : "space-between"}>
        {isMobile ? (
          <></>
        ) : (
          <Text
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
            size={FontSize.F24}
            color={Colors.NightBlue}
          >
            {recipient.firstName}'s past bookings
          </Text>
        )}
        <div
          style={{
            display: "flex",
            gap: isMobile ? "8px" : "16px",
            alignItems: "center",
            width: isMobile ? "100%" : "",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={() => {
              handleNewBookingClicked();
            }}
            style={{
              borderRadius: "100px",
              maxWidth: "200px",
              paddingRight: "16px",
              paddingLeft: "5px",
            }}
            icon={Plus}
            title="Add new booking"
          ></Button>
          <div style={{ gap: 0, width: isMobile ? "100px" : "230px" }}>
            <FilterButton
              title={isMobile ? "" : "Filter and sort"}
              onClick={() => setShowFilterAndSortModal(true)}
              filterCount={numOfFilter}
            />
          </div>
        </div>
      </Box>

      {isLoading ? <LoadingSpinner /> : <></>}

      {checkIfEmpty(pastBookings) && !isLoading ? (
        numOfFilter ? (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <NoFilteredBookings sortOrEditFilter={setShowFilterAndSortModal} />
          </Box>
        ) : (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <div
              style={{
                marginTop: isMobile ? 0 : "8vh",
              }}
            >
              <NoBookingsPlaceholder type={NoBookingsPlaceholderType.past} />
            </div>
          </Box>
        )
      ) : (
        <Box direction={FlexDirection.Column}>
          <Each of={pastBookings} render={buildRow} />
        </Box>
      )}

      {pageCount > 0 ? (
        <Box justifyContent={"flex-end"}>
          <PaginationControl
            currentPage={currentPage}
            pages={pageCount}
            onNext={() => setCurrentPage(currentPage + 1)}
            onPrevious={() => setCurrentPage(currentPage - 1)}
            startFromOne
          />
        </Box>
      ) : (
        <></>
      )}

      <BookingFilterModal
        open={showFilterAndSortModal}
        onClose={() => setShowFilterAndSortModal(false)}
        forUpcoming={false}
        showRecipientField={false}
      />
    </Box>
  );
};

export default RecipientPastBookings;
