import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";
import { Job } from "../../models";

const useJobDetail = ({ jobId }: { jobId: number | string }) => {
  const { isLoading, data, refetch, error } = useQuery({
    queryKey: [`job-${jobId}`, jobId],
    queryFn: ({ signal }) => {
      return get(`api/v2/therapists/jobs/${jobId}`, {}, true, { signal });
    },
    refetchOnWindowFocus: "always",
  });

  return { job: data?.job as Job, isLoading, refetch, error };
};

const useJobDetailPublic = ({
  jobId,
  accessToken,
}: {
  jobId: number | string;
  accessToken: any;
}) => {
  const { isLoading, data, refetch, error, isRefetching } = useQuery({
    queryKey: [`PUBLIC-job-${jobId}`, jobId],
    queryFn: ({ signal }) => {
      console.log(signal);
      if (accessToken) {
        return get(`api/v2/therapists/jobs/${jobId}`, {}, true, { signal });
      }
      return get(`api/v2/therapists/jobs/public/${jobId}`, {}, true, { signal });
    },
  });

  return { job: data?.job as Job, isLoading, refetch, error, isRefetching };
};

export { useJobDetail, useJobDetailPublic };
