import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Each from "../../../../../components/Each";
import { getValue } from "../../../../../utils/object";
import { Colors } from "../../../../../constants/colors";
import TextField from "../../../../../components/TextField";
import DateField from "../../../../../components/DateField";
import { SSNInputField } from "../../../../../components/SSNField";
import * as Styled from "../../../../../components/v2/Styled/enum";
import Dropdown, { DropdownOption } from "../../../../../components/Dropdown";
import { FormFieldType, StripeOnboardingFormFiled, TFormData, TPersonalDetails } from "./model";

interface Props {
  value?: string | DropdownOption | Date | boolean;
  field: StripeOnboardingFormFiled;
  formData: TFormData;
  onChange: (val: any, overrideName?: keyof TPersonalDetails) => unknown;
  options?: DropdownOption[];
}

const Field = ({ field, value, formData, onChange, options }: Props): JSX.Element => {
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const label = getValue(field, "label");
  const name = getValue(field, "name") as keyof TFormData;
  const type = getValue(field, "type");
  const placeholder = getValue(field, "placeholder");
  const description = getValue(field, "description");
  const isDisabled = getValue(field, "disabled");

  const defaultValue = getValue(field, "defaultValue");

  switch (type) {
    case FormFieldType.Text:
    case FormFieldType.Email:
      return (
        <TextField
          title={label}
          onChange={onChange}
          readOnly={isDisabled}
          placeholder={placeholder}
          value={fieldValue as string}
        />
      );
    case FormFieldType.Select:
      return (
        <Dropdown
          title={label}
          placeholder="Select"
          isDropdownDirectionUp
          isDisabled={isDisabled}
          options={options || []}
          onSelectedOption={onChange}
          selectedOption={(fieldValue || defaultValue) as DropdownOption}
        />
      );
    case FormFieldType.Date:
      // @ts-ignore
      const dateValue = fieldValue instanceof Date ? fieldValue : new Date(fieldValue);
      const disableFuture = ["dob"].includes(name);
      return (
        <DateField
          title={label}
          selectedDate={dateValue}
          onSelectedDate={onChange}
          color={Colors.TurquoiseBlue}
          disableFuture={disableFuture}
        />
      );
    case FormFieldType.TextSubSection:
      const subFields = getValue(field, "fields", []);

      return (
        <Box display={Styled.Display.Flex} gridGap={Styled.Spacing.S4}>
          <Each
            of={subFields}
            render={(field) => {
              const label = getValue(field, "label");
              const name = getValue(field, "name") as keyof TFormData;
              const placeholder = getValue(field, "placeholder");
              const isDisabledField = getValue(field, "disabled", false);

              return (
                <TextField
                  title={label}
                  value={formData[name]}
                  placeholder={placeholder}
                  readOnly={isDisabledField}
                  onChange={(val) => onChange(val, name)}
                />
              );
            }}
          />
        </Box>
      );
    case FormFieldType.Sensitive:
      const idNumberProvided = getValue(formData, "idNumberProvided", false);
      let overrideValue = "";

      if (name === "idNumber" && idNumberProvided) {
        overrideValue = "000000000";
      }

      return (
        <SSNInputField
          title={label}
          onChange={onChange}
          subtitle={description}
          readonly={idNumberProvided}
          value={(overrideValue || fieldValue) as string}
        />
      );
  }
  return <></>;
};

export default Field;
