import { CompleteAfterPayButton } from "@get-blys/ui.complete-after-pay-button";
import { Box, Link } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";
import { parseApiError } from "../../../../helpers/error";
import PriceBreakdown from "./PriceBreakdown";

import ConfirmFutureBookingCancellation from "../../../../components/Bookings/Action/ConfirmFutureBookingCancellation";
import CancelBooking from "../../../../components/CancelBooking";
import Divider from "../../../../components/Divider";
import BookingPaymentMethod from "../../../../components/Payment/BookingPaymentMethod";
import PaymentModal from "../../../../components/PaymentFrame/PaymentModal";
import {
  UPDATE_RECURRING_MODAL_SKIP_KEY,
  bookingFrequencyType,
} from "../../../../constants/booking";
import { useUpcomingBookings } from "../../../../hooks/booking/booking.hooks";
import {
  isAfterPayUnpaidBooking,
  sendUpdateRequest,
} from "../../../../services/bookings/bookings.service";
import { actions as bookingActions } from "../../../../stores/V2/booking/booking";
import {
  hasBookingAddressChanged,
  hasBookingRecipientChanged,
  hasBookingRecurringChanged,
  hasServiceChanged,
  hasTimeOfArrivalChanged,
  hasTreatmentDetailsChangedForBooking,
  transformUpdatedData,
} from "../../../../stores/V2/booking/booking.transformer";
import { useAlertStore } from "../../../../stores/alert";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import ConfirmBookingUpdateModal from "../ConfirmBookingUpdateModal";
import queryClient from "../../../../config/queryClient";
import { QUERY_KEYS } from "../../../../constants/queryKey";
import { requestRecurringDataUpdate } from "../../../../services/bookings/recurring/recurringBooking.service";
import ConfirmRecurringCancellationModal from "../ConfirmRecurringCancellationModal";

interface Props {
  id?: string;
  booking: any;
}

export default function PriceSummary({ booking, id }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { updated, existingBooking, selectedService, recurring } = booking;

  const { invalidateUpcomingBookings } = useUpcomingBookings({});

  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [updateBookingModal, setUpdateBookingModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isCancellingFutureBookings, setIsCancellingFutureBookings] = useState(false);
  const [isUpdatingBookingFrequency, setIsUpdatingBookingFrequency] = useState(false);
  const [updateFrequencyWarning, setUpdateFrequencyWarning] = useState("");

  const [updateFutureRecurring, setUpdateFutureRecurring] = useState(false);

  const updatedKeys = Object.keys(updated);

  const hasBookingDetailsChanged = (updated: any, existingBooking: any, updatedKeys: any) => {
    return (
      hasServiceChanged(updated, existingBooking) ||
      hasTreatmentDetailsChangedForBooking(updated, existingBooking) ||
      hasBookingAddressChanged(updated, existingBooking) ||
      hasBookingRecipientChanged(updated, existingBooking, updatedKeys) ||
      hasBookingRecurringChanged(updated, existingBooking) ||
      hasTimeOfArrivalChanged(updated, existingBooking)
    );
  };

  const isUpdated = hasBookingDetailsChanged(updated, existingBooking, updatedKeys);

  const handleBookingCancel = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setShowCancelBooking(true);
  };

  const updateExistingBookingFrequency = (frequency: number) => {
    dispatch(bookingActions.updateExistingBookingFrequency({ frequency }));
  };

  const onUpdateClick = async () => {
    const recurringFields = [
      UPDATE_RECURRING_MODAL_SKIP_KEY.recurring,
      UPDATE_RECURRING_MODAL_SKIP_KEY.recuringEndOn,
    ];

    const isSkipModal = updatedKeys.every((updateField) => recurringFields.includes(updateField));

    if (
      existingBooking?.recurring?.shouldCreateFutureBookings === true &&
      updated?.recurring?.shouldCreateFutureBookings === false
    ) {
      setIsCancellingFutureBookings(true);
      return;
    }

    const skipRecurringModal =
      isSkipModal ||
      bookingFrequencyType.oneOff === getValue(existingBooking, "recurring.frequency");
    if (skipRecurringModal) {
      if (bookingFrequencyType.oneOff !== getValue(existingBooking, "recurring.frequency")) {
        const confirmRequired = await checkRecurringConfirmationRequired();
        if (confirmRequired) return;
      }
      handleUpdate();
    } else {
      setUpdateBookingModal(true);
    }
  };

  const checkRecurringConfirmationRequired = async () => {
    if (!id) return false;

    const updatedData = transformUpdatedData(updated, existingBooking);
    if (isEmpty(updatedData)) return false;

    const isRequired = await showFrequencyConfirmationIfRequired({ updatedData, bookingId: id });
    return isRequired;
  };

  const showFrequencyConfirmationIfRequired = async ({ updatedData = {}, bookingId }: any) => {
    const updatedFrequency = getValue(updatedData, "frequency");

    // if frequency or other recurring attrib updated, check for confirmation required
    const hasFrequencyChanged =
      updatedFrequency !== null ||
      updatedFrequency !== undefined ||
      !checkIfEmpty(updatedData.recurring);

    // check if requires client confirmation
    if (hasFrequencyChanged) {
      const recurringData: any = {};
      const updatedRecurring = getValue(updated, "recurring") || {};
      const existingRecurring = getValue(existingBooking, "recurring") || {};

      recurringData.recurring = isEmpty(updatedRecurring) ? existingRecurring : updatedRecurring;
      const requestResponse = await requestRecurringDataUpdate({ bookingId, data: recurringData });
      const { cancellationFee, warningMessage } = requestResponse;
      // show confirmation modal for recurring update warning
      if (cancellationFee > 0) {
        setUpdateFrequencyWarning(warningMessage);
        setIsUpdatingBookingFrequency(true);
        return true;
      }
    }

    return false;
  };

  const handleUpdate = async (futureRecurring?: boolean) => {
    try {
      if (!id) return;

      const updatedData = transformUpdatedData(updated, existingBooking);

      if (isEmpty(updatedData)) {
        dispatch(bookingActions.clearUpdates({}));
        return;
      }

      if (futureRecurring) {
        updatedData.updateFutureRecurring = futureRecurring;
      }

      const { status } = await sendUpdateRequest(id || "", updatedData);
      if (status !== "OK") throw Error("Unable to send booking request.");

      invalidateUpcomingBookings();

      const profession = getValue(selectedService, "profession", "therapist");

      // update the booking frequency for existing booking;
      updateExistingBookingFrequency(recurring?.frequency);

      const recurringFields = [
        UPDATE_RECURRING_MODAL_SKIP_KEY.recurring,
        UPDATE_RECURRING_MODAL_SKIP_KEY.recuringEndOn,
        UPDATE_RECURRING_MODAL_SKIP_KEY.preferredTherapists,
      ];

      const isOnlyRecurringAttribute = updatedKeys.every((updateField) =>
        recurringFields.includes(updateField)
      );

      if (isOnlyRecurringAttribute) {
        setSuccessMessage("Your booking has been updated successfully.");
      } else {
        setSuccessMessage(`Your update request has been sent to the ${profession} for approval`);
      }
      setUpdateFutureRecurring(false);
      dispatch(bookingActions.clearUpdates({}));
    } catch (err: any) {
      setErrorMessage(parseApiError(err));
    }
  };

  const completeAfterPay = (bookingId: number) => {
    history.push(`/afterpay-process-order/${bookingId}`, {
      autoPay: true,
    });
  };

  const confirmFutureBookingCancellation = () => {
    handleUpdate();
    setIsCancellingFutureBookings(false);
  };

  const updateAfterRecurringCheck = async (futureRecurring?: boolean) => {
    setUpdateFutureRecurring(futureRecurring || false);
    const isConfirmRequired = await checkRecurringConfirmationRequired();
    if (isConfirmRequired) return;

    handleUpdate(futureRecurring);
  };

  const handleConfirmFrequencyUpdate = async () => {
    setIsUpdatingBookingFrequency(false);
    handleUpdate(updateFutureRecurring);
  };

  return (
    <>
      <Box>
        <PriceBreakdown booking={booking} />

        <Box padding="0px 32px">
          <Divider />
          <BookingPaymentMethod
            handleAddCard={() => {
              setShowPaymentModal(true);
            }}
            showPaymentMethodChangeBtn={!booking.isActuallyPaid}
          />
          <Divider />
        </Box>

        {isAfterPayUnpaidBooking(booking) && (
          <Box padding={"24px 16px 8px 12px"}>
            <CompleteAfterPayButton isMobile={false} onPress={() => completeAfterPay(booking.id)} />
          </Box>
        )}
        <Box style={{ padding: "0px 32px 24px 32px" }}>
          {updatedKeys.length > 0 && isUpdated && (
            <Box marginY="24px">
              <Button
                title="Update booking"
                onClick={onUpdateClick}
                type={ButtonType.secondary}
                style={{ padding: "12px 24px", borderRadius: "4px" }}
              />
            </Box>
          )}

          <Link style={{ cursor: "pointer" }} underline="none" onClick={handleBookingCancel}>
            <Box
              fontFamily="Museo"
              fontWeight={700}
              fontSize="14px"
              color={Colors.Tomato}
              mt="16px"
            >
              Cancel booking
            </Box>
          </Link>
        </Box>

        <CancelBooking
          booking={booking}
          open={showCancelBooking}
          onConfirm={() => {
            queryClient.invalidateQueries([QUERY_KEYS.BOOKING_DETAIL]);
            history.push(`/my-bookings/${booking.id}`);
          }}
          onClose={() => setShowCancelBooking(false)}
        />
      </Box>

      <ConfirmBookingUpdateModal
        open={updateBookingModal}
        onClose={() => setUpdateBookingModal(false)}
        handleUpdate={updateAfterRecurringCheck}
      />

      {/* warning for cancellation charges on future booking cancelled */}
      <ConfirmRecurringCancellationModal
        open={isUpdatingBookingFrequency}
        description={updateFrequencyWarning}
        onClose={() => setIsUpdatingBookingFrequency(false)}
        handleUpdate={handleConfirmFrequencyUpdate}
      />

      <ConfirmFutureBookingCancellation
        open={isCancellingFutureBookings}
        onClose={() => setIsCancellingFutureBookings(false)}
        onConfirm={confirmFutureBookingCancellation}
        bookingId={booking.id}
      />

      <PaymentModal
        booking={booking}
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        hidePayWithInvoice={false}
        hidePayWithNDIS={false}
        showInvoiceLink={false}
      />
    </>
  );
}
