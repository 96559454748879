import queryClient from "../../config/queryClient";
import { MEMBERSHIP_KEYS } from "../../hooks/industrymembership.hooks";

export const validateIndustryMembership = (data: any) => {
  if (!data.selectedIndustry) {
    return "Please select industry";
  }
  if (!data.selectedMember) {
    return "Please select membership type";
  }
  if (!data.files) {
    return "Please upload a file";
  }
  if (!data.membershipNumber) {
    return "Please enter membership number";
  }
  if (!data.expiryDate) {
    return "Please enter expiry date";
  }
  return null;
};

export const invalidateMembership = () =>
  queryClient.invalidateQueries({ queryKey: [MEMBERSHIP_KEYS.memberships] });
