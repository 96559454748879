const PRODUCTION_ENV = "production";
const STAGING_ENV = "staging";
const DEV_ENV = "dev";
const LOCAL_ENV = "local";

export const environment = {
  name: process.env.REACT_APP_ENV,
  isDevelopment: process.env.REACT_APP_ENV === DEV_ENV || process.env.REACT_APP_ENV === LOCAL_ENV,
  isStaging: process.env.REACT_APP_ENV === STAGING_ENV,
  isProduction: process.env.REACT_APP_ENV === PRODUCTION_ENV,
};

const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = ((env) => {
  switch (env) {
    case PRODUCTION_ENV:
      return API_URL || "https://api.getblys.com.au";
    case STAGING_ENV:
      return API_URL || "https://api-staging.getblys.com.au";
    case DEV_ENV:
      return API_URL || "https://api-dev.getblys.com.au";
    default:
      // return API_URL;
      return API_URL || "http://localhost:3000";
  }
})(process.env.REACT_APP_ENV);

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;
export const BASE_UPLOADS_URL = ((env) => {
  switch (env) {
    case PRODUCTION_ENV:
      return STORAGE_URL || "https://storage.getblys.com.au";
    case STAGING_ENV:
      return STORAGE_URL || "https://storage-staging.getblys.com.au";
    case DEV_ENV:
      return STORAGE_URL || "https://blys-dev.s3.ap-southeast-2.amazonaws.com";
    default:
      return STORAGE_URL || "https://blys-dev.s3.ap-southeast-2.amazonaws.com";
  }
})(process.env.REACT_APP_ENV);

export const BOOKING_PRICE_MASTER_TOKEN =
  "c3FIOG9vSGV4VHo4QzAyg5T1JvNnJoZ3ExaVNyQWw6WjRsanRKZG5lQk9qUE1BVQ";

export const SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST = 2000;
export const REVIEW_NOTES_LIMIT_FOR_THERAPIST = 2000;
export const LOCATION_NOTES_LIMIT = 2000;

export const USE_APP_LINKING = process.env.hasOwnProperty("REACT_APP_USE_APP_LINKING")
  ? process.env.REACT_APP_USE_APP_LINKING
  : true;

export const RECAPTCHA_SITE_KEY = ((env) => {
  switch (env) {
    case PRODUCTION_ENV:
      return "6LcCIbcqAAAAAIjMpMs3Ac1BdIS_vYbV4bpkJw48";
    case STAGING_ENV:
      // return "6LdfZzIcAAAAANlP1X2MYZ_dEqHJ5bmbypBxGuge";
      return "6Lc4JLcqAAAAAGYF3e5lbzj-LStYbSknYia1J7jl";
    default:
      return "6LdfZzIcAAAAANlP1X2MYZ_dEqHJ5bmbypBxGuge";
  }
})(process.env.REACT_APP_ENV);

export const STRIPE_PUBLISHABLE_KEY = ((env) => (country: string) => {
  switch (env) {
    case PRODUCTION_ENV:
      switch (country) {
        case "US":
          return (
            process.env.US_STRIPE_KEY ||
            "pk_live_51Mggp9EAbN3cEewZOzYqtWsCpMi7KOQa4X2077eDmQQB5vPISXSRKAZtmfaXKDS4rccM04wIkLS5bc0N3TdJvCC900psG2zHgS"
          );
        // return process.env.US_STRIPE_KEY || "pk_live_X4jzeHfA6FdtFAlYmFHSEfFf";
        case "GB":
          return (
            process.env.UK_STRIPE_KEY ||
            // "pk_live_51Mggp9EAbN3cEewZOzYqtWsCpMi7KOQa4X2077eDmQQB5vPISXSRKAZtmfaXKDS4rccM04wIkLS5bc0N3TdJvCC900psG2zHgS"
            "pk_live_51Mggp9EAbN3cEewZOzYqtWsCpMi7KOQa4X2077eDmQQB5vPISXSRKAZtmfaXKDS4rccM04wIkLS5bc0N3TdJvCC900psG2zHgS"
          );
        // return process.env.UK_STRIPE_KEY || "pk_live_X4jzeHfA6FdtFAlYmFHSEfFf";
        case "AU":
          return process.env.AU_STRIPE_KEY || "pk_live_X4jzeHfA6FdtFAlYmFHSEfFf";
        default:
          return process.env.AU_STRIPE_KEY || "pk_live_X4jzeHfA6FdtFAlYmFHSEfFf";
      }
    case STAGING_ENV:
      switch (country) {
        case "US":
        case "GB":
          return "pk_test_51Mggp9EAbN3cEewZdb9FN0CDmIwUDlRDZTVP3Aospfz8WYkklwXutd1CIiJmKxicumVeJPwovDipERjzUaXjOskr005f1Q7bmB";
        case "AU":
          return "pk_test_LGjtGg0Iivp0lwHDOm74wNpm";
        default:
          return "pk_test_LGjtGg0Iivp0lwHDOm74wNpm";
      }
    default:
      return "pk_test_LGjtGg0Iivp0lwHDOm74wNpm";
  }
})(process.env.REACT_APP_ENV);

export const STRIPE_PUBLISHABLE_KEY_FOR_CONNECT = ((env) => (country: string) => {
  // The key may be different for Stripe Connected Account (mainly used for Providers)
  switch (env) {
    case PRODUCTION_ENV:
      switch (country) {
        case "US":
          return (
            process.env.US_STRIPE_KEY_FOR_CONNECT ||
            "pk_live_51Mggp9EAbN3cEewZOzYqtWsCpMi7KOQa4X2077eDmQQB5vPISXSRKAZtmfaXKDS4rccM04wIkLS5bc0N3TdJvCC900psG2zHgS"
          );
        case "GB":
          return process.env.UK_STRIPE_KEY_FOR_CONNECT || "pk_live_X4jzeHfA6FdtFAlYmFHSEfFf";
        case "AU":
          return process.env.AU_STRIPE_KEY_FOR_CONNECT || "pk_live_X4jzeHfA6FdtFAlYmFHSEfFf";
        default:
          return process.env.AU_STRIPE_KEY_FOR_CONNECT || "pk_live_X4jzeHfA6FdtFAlYmFHSEfFf";
      }
    case STAGING_ENV:
      switch (country) {
        case "US":
        case "GB":
          return "pk_test_51Mggp9EAbN3cEewZdb9FN0CDmIwUDlRDZTVP3Aospfz8WYkklwXutd1CIiJmKxicumVeJPwovDipERjzUaXjOskr005f1Q7bmB";
        case "AU":
          return "pk_test_LGjtGg0Iivp0lwHDOm74wNpm";
        default:
          return "pk_test_LGjtGg0Iivp0lwHDOm74wNpm";
      }
    default:
      return "pk_test_LGjtGg0Iivp0lwHDOm74wNpm";
  }
})(process.env.REACT_APP_ENV);

export const AFTERPAY_SCRIPT_SRC = ((env) => {
  return env === PRODUCTION_ENV
    ? "https://portal.afterpay.com/afterpay.js"
    : "https://portal.sandbox.afterpay.com/afterpay.js";
})(process.env.REACT_APP_ENV);

export const AFTERPAY_SUCCESS_MOBILE_APP_REDIRECT_URL = ((env) => {
  switch (env) {
    case PRODUCTION_ENV:
      return "blys://afterpay-success";
    case STAGING_ENV:
      return "blys-staging://afterpay-success";
    default:
      return "exp://192.168.1.69:19000/--/afterpay-success";
  }
})(process.env.REACT_APP_ENV);

export const SEGMENT_KEY = ((env) => {
  switch (env) {
    case PRODUCTION_ENV:
      const hostname = window.location.hostname;
      if (hostname == "app.getblys.com.au") {
        return "zewCIUzAxGYrHzGiouhNjNcFJgzKYSkD";
      }

      return "nBMauHTxz4lbGcnoHOZxGmfepe6UtyH9";
    case STAGING_ENV:
      return "nBMauHTxz4lbGcnoHOZxGmfepe6UtyH9";
    default:
      return "nBMauHTxz4lbGcnoHOZxGmfepe6UtyH9";
  }
})(process.env.REACT_APP_ENV);

export const MARKETING_SITE_URL = "https://getblys.com.au";
export const LEARN_MORE_ABOUT_OFFICE_MASSAGE_URL =
  "https://getblys.com.au/our-massages/corporate-massage/";

export const APPLE_PAY_SUCCESS_APP_REDIRECT_URL = ((env) => {
  switch (env) {
    case PRODUCTION_ENV:
      return "blys://apple-pay-success";
    case STAGING_ENV:
      return "blys-staging://apple-pay-success";
    default:
      return "exp://10.1.1.5:19000/--/apple-pay-success";
  }
})(process.env.REACT_APP_ENV);

const PROVIDERS_DOMAIN = process.env.REACT_APP_PROVIDERS_DOMAIN;
export const getProvidersDomain = () => {
  if (PROVIDERS_DOMAIN) {
    return PROVIDERS_DOMAIN;
  }

  if (environment.isProduction) {
    return "https://providers.getblys.com.au";
  } else if (environment.isStaging) {
    return "https://providers-staging.getblys.com.au";
  } else if (environment.isDevelopment) {
    return "https://providers-dev.getblys.com.au";
  } else {
    return "http://localhost:5000";
  }
};

export const getDefaultSiteURL = () => {
  if (environment.isProduction) {
    return "https://app.getblys.com";
  } else if (environment.isStaging) {
    return "https://app-staging.getblys.com";
  }
  return "http://localhost:3001";
};

export const USE_FIREBASE_APP_CHECK = process.env.hasOwnProperty("REACT_APP_USE_FIREBASE_APP_CHECK")
  ? process.env.REACT_APP_USE_FIREBASE_APP_CHECK
  : true;
