import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Moment } from "moment";

import AddressModal from "../../components/Addresses/AddressModal";
import CardWrapper from "../../components/BookingDetails/BookingDetailCard";
import InfoModal from "../../components/Modals/InfoModal/InfoModal";
import JobHeaderSubtitle from "../../components/ProDashboard/Job/JobHeaderSubtitle";
import SectionPageHeader from "../../components/SectionPageHeader";
import CustomTimePicker from "../../components/TimePicker/TimePicker";
import { Spacing } from "../../components/v2/Styled/enum";
import { BOOKING_STATUS, BOOKING_TIME, JobStatus } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { BOOKINGS_SECTION_VALUES } from "../../constants/prodashboard";
import { BOOKING_SESSION_TYPE, parseServiceDescriptionForJob } from "../../helpers/booking";
import { parseApiV2Error } from "../../helpers/error";
import { checkIfBackToBackUpdateRequired } from "../../helpers/job";
import { useJobDetailPublic } from "../../hooks/job/job.hooks";
import { useMobile, useTab } from "../../hooks/mobile";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import TherapistIcon from "../../images/pro-icon.png";
import {
  acceptJob,
  getFormattedJob,
  validateTimeOfArrival,
} from "../../services/proDashboard/job.service";
import { invalidateQueries } from "../../services/query";
import { RootState } from "../../stores/V2";
import { bookingInitializerPublic } from "../../stores/V2/booking/booking.initializers";
import { useAlertStore } from "../../stores/alert";
import { isCoupleMassageType } from "../../stores/booking";
import {
  addMinutes,
  convertToMoment,
  isFutureMoment,
  isSelectedDateBeforeEndDate,
} from "../../utils/date.util";
import { checkIfEmpty, getValue } from "../../utils/object";
import BookingDetails from "../Job/BookingDetails/BookingDetails";
import ClientDetails from "../Job/ClientDetails/ClientDetails";
import ClientPaymentDetails from "../Job/ClientPaymentDetails";
import JobActions from "../Job/JobActions/JobActions";
import DateAndTimeModal from "../Job/Modals/DateAndTimeModal/DateAndTimeModal";
import PayoutInfoModal from "../Job/Modals/PayoutInfoModal/PayoutInfoModal";
import PayoutSummary from "../Job/PayoutSummary/PayoutSummary";
import RecurringInfo from "../Job/RecurringInfo";
import TreatmentDetailSection from "../Job/TreatmentDetails/TreatmentDetailsSection";
import BackToBackUpdate from "../Job/UpdateRequest/BackToBackUpdate";
import ChatButton from "../../components/Chat/ChatButton";
import { KEYS } from "../../hooks/userTerms.hook";
import PastBookingWarningModal from "../Job/Modals/PastBookingWarningModal/pastBookingWarningModal";
import { calculateMinMaxMinutes, isOldBooking as isPastBooking } from "../Job/utils/utils";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { useAccessToken } from "../../hooks/common";
import { Helmet } from "react-helmet-async";
import ErrorMessageView from "../../components/Error/ErrorMessageView";
import Button from "../../components/Button";

const JobDetailsPublic = () => {
  const params: any = useParams();
  const history = useHistory();
  const { id: jobId } = params;
  const dispatch = useDispatch();
  const location = useLocation();

  const bookingData = useSelector(({ booking }: RootState) => booking);

  const isMobile = useMobile();
  const isTab = useTab();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { job, isLoading, refetch: refreshJobDetails } = useJobDetailPublic({ jobId });
  const { services } = useServiceRates();

  const [showTimePicker, setShowTimePicker] = useState(false);
  const [selectedArrivalType, setArrivalType] = useState(BOOKING_TIME.PREFERRED);
  const [data, setData] = useState<any>(null);

  const [showInfo, setShowInfo] = useState(false);
  const [infoDetails, setInfoModalDetails] = useState({ title: "", description: "" });

  const [disableActions, setDisableActions] = useState(false);

  const [showAcceptRangeModal, setShowAcceptRangeModal] = useState(false);

  const [showOldBookingWarning, setShowOldBookingWarning] = useState(false);

  const [modelInfo, setModelInfo] = useState({
    visible: false,
    title: "",
    description: "",
  });

  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [canChat, setCanChat] = useState(false);

  useEffect(() => {
    // Find the existing robots meta tag or create a new one
    let metaTag = document.querySelector('meta[name="robots"]');
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "robots");
      document.head.appendChild(metaTag);
    }

    // Set the content to index, follow
    metaTag.setAttribute("content", "index, follow");

    // Clean up if necessary (optional)
    return () => {
      if (metaTag) {
        metaTag.setAttribute("content", "noindex, nofollow");
      }
    };
  }, []);

  const accessToken = useAccessToken();

  useEffect(() => {
    if (accessToken) {
      history.push(`/pro/bookings/${jobId}`);
    }
  }, [accessToken]);

  const currency = getValue(
    job,
    "bookingdetail.booking.service.selectedCountry.currencySymbol",
    ""
  );

  const setDefaultArrivalTime = ({ arrivalType }: any) => {
    const booking = getValue(job, "bookingdetail.booking");
    if (!booking) return;

    let { earliestTime } = booking;
    if (arrivalType === BOOKING_TIME.BACKUP && booking?.backup) {
      const backup = booking.backup;
      earliestTime = backup?.earliestTime;
    }
  };

  useEffect(() => {
    initLoadBooking();
  }, [isLoading, job]);

  const initLoadBooking = () => {
    const loadBooking = getValue(location, "state.initialize", true);
    if (loadBooking || !bookingData.id) {
      initialize();
    }
  };

  const initialize = async () => {
    try {
      if (!jobId) return;
      // dispatch(bookingActions.toggleFetching({ isFetching: true }));
      bookingInitializerPublic(jobId, dispatch, (message) => {
        // setErrorMessage(message);
        // history.push(Paths.ProPublicBookings, { jobId });
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const formattedData = getFormattedJob(job);
    setDefaultArrivalTime({ arrivalType: selectedArrivalType });
    setData(formattedData);
  }, [job]);

  if (isLoading) {
    return (
      <Box>
        <LoadingSpinner></LoadingSpinner>
      </Box>
    );
  }
  if (checkIfEmpty(job)) {
    return (
      <Box width="100%" height="70vh" display={"flex"} alignItems="center" justifyContent="center">
        <Box>
          <ErrorMessageView message="No booking found."></ErrorMessageView>
          <Button type={2} size={1} title={"Go back"} onClick={() => history.goBack()} />
        </Box>
      </Box>
    );
  }

  const getServiceDescription = () => {
    if (!job || checkIfEmpty(job)) return "";
    const label = parseServiceDescriptionForJob({
      job,
      services,
      includeDuration: false,
      includeSessionType: false,
    });

    return label;
  };

  const handleAccept = (date: any, timezone: string) => {
    try {
      setShowTimePicker(false);

      acceptBooking({ timeOfArrival: date });
    } catch (err: any) {
      parseApiV2Error(err);
    }
  };

  const onInfoClicked = (details: any) => {
    if (checkIfEmpty(details)) return;

    const { title, description } = details;
    setInfoModalDetails({ title, description });
    setShowInfo(true);
  };

  const onAcceptClicked = () => {
    history.push(Paths.Login, { jobId: job.id });
  };

  const dismissTimePicker = () => {
    setShowTimePicker(false);
    setDefaultArrivalTime({ arrivalType: selectedArrivalType });
  };

  const toggleDisableActions = (value: boolean) => {
    setDisableActions(value);
  };

  const acceptBooking = async ({ timeOfArrival }: any) => {
    try {
      toggleDisableActions(true);

      // validate timeOfArrival and arrival type

      const booking = getValue(job, "bookingdetail.booking");
      if (!booking) return;

      const { timezone } = booking;
      const { isValid, arrivalType } = validateTimeOfArrival(
        timeOfArrival,
        timezone,
        booking,
        setErrorMessage
      );
      if (!isValid) {
        toggleDisableActions(false);
        return;
      }

      await acceptJob({
        jobId: job.id,
        status: arrivalType,
        timeOfArrival,
      });
      invalidateQueries([`job-${job.id}`]);
      invalidateQueries([KEYS.JOB_AGREEMENTS]);
      history.push(Paths.ProDashboardBookings, { section: BOOKINGS_SECTION_VALUES.UPCOMING });
      setSuccessMessage("The job has been accepted");
    } catch (err: any) {
      setErrorMessage(err.message || "Sorry something went wrong. Please try again.");
    }
    toggleDisableActions(false);
  };

  const hasAlternativeOffer = ({ booking }: any) => !checkIfEmpty(booking?.alternativeDate);

  const allowAlternateOffer = ({ booking }: any) => {
    return (
      !hasAlternativeOffer({ booking }) &&
      [BOOKING_SESSION_TYPE.SINGLES, BOOKING_SESSION_TYPE.BACK_TO_BACK].includes(
        booking.sessionType
      )
    );
  };

  const booking = getValue(job, "bookingdetail.booking");
  const therapistCount = getValue(booking, "bookingdetails", []).length || 1;

  const onAcceptRangedBooking = ({
    jobId,
    selectedDateTime,
  }: {
    jobId: string;
    selectedDateTime: Moment;
  }) => {
    acceptBooking({ timeOfArrival: selectedDateTime });
  };

  const getTimeLimits = ({ booking }: any) => {
    let { earliestTime, latestTime, backup: backupDate } = booking;
    if (!isFutureMoment(addMinutes(earliestTime, 5).toISOString(), booking.timezone)) {
      // add now +5 minutes to earliest time
      earliestTime = addMinutes(new Date().toUTCString(), 5);
    }
    if (!backupDate) return { min: earliestTime, max: latestTime };

    let { earliestTime: backupEarliest, latestTime: backupLatest } = backupDate;
    if (!isFutureMoment(addMinutes(backupEarliest, 5).toISOString(), booking.timezone)) {
      // add now +5 minutes to earliest time
      backupEarliest = addMinutes(new Date().toUTCString(), 5);
    }

    const min = isSelectedDateBeforeEndDate(earliestTime, backupEarliest)
      ? earliestTime
      : backupEarliest;
    const max = isSelectedDateBeforeEndDate(latestTime, backupLatest) ? backupLatest : latestTime;
    return { min, max };
  };

  const { min: minTime, max: maxTime } = getTimeLimits({ booking });
  const { max: maxMinutes, min: minMinutes } = calculateMinMaxMinutes(booking);

  const sessionType = getValue(booking, "sessionType");
  const bookingDetailId = getValue(job, "bookingdetail.id");
  const isCoupleBooking = isCoupleMassageType(sessionType);

  const getTreatmentDetails = () => {
    const bookingdetails =
      getValue(bookingData, "bookingDetails") || getValue(bookingData, "bookingdetails");

    let bookingDetails = bookingdetails;

    if (isCoupleBooking) {
      bookingDetails = bookingdetails.filter(
        (data: any) => data.bookingDetailId === bookingDetailId
      );
    }

    let treatments = bookingDetails.map((details: any) => {
      let { treatmentDetails } = details;
      if (!checkIfEmpty(treatmentDetails)) {
        const updatedDetails = treatmentDetails.map((detail: any) => ({
          ...detail,
          treatmentId: detail.id || detail.treatmentId,
        }));
        treatmentDetails = updatedDetails;
      }
      return treatmentDetails;
    });
    return treatments.flat();
  };

  const treatmentDetails = getTreatmentDetails();

  const jobStatus = getValue(job, "status");

  const bookingStatus = getValue(booking, "status");

  const numberOfTherapist = getValue(booking, "bookingdetails", []);

  const { address: bookingAddress, userId } = bookingData;

  const isPaidBooking = !(bookingStatus === BOOKING_STATUS.DECLINED);

  const backToBackUpdateRequired = checkIfBackToBackUpdateRequired(booking, job);

  const CanChatButton = (
    <>
      {canChat && (
        <ChatButton
          bookingId={jobId}
          conversationId={booking.conversationId}
          text="Message client"
          forClient={false}
        />
      )}
    </>
  );

  return (
    <Box>
      <Helmet>
        <title>{"New Bookings | Blys"}</title>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta
          name="description"
          content="Easily view and manage your new bookings on Blys. Stay updated on upcoming appointments and streamline your schedule, all in one place."
        ></meta>
      </Helmet>
      <SectionPageHeader
        title={getServiceDescription()}
        onBack={() => history.goBack()}
        subtitleElement={
          <JobHeaderSubtitle jobId={job.id} jobStatus={jobStatus} bookingStatus={bookingStatus} />
        }
      />
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        ml={isMobile ? "24px" : "80px"}
        mr={isMobile ? "24px" : undefined}
        mt={isMobile ? "40px" : "42px"}
        mb={"16px"}
        alignItems={isMobile ? "center" : undefined}
      >
        <Box display="flex" flexDirection="column" width={isMobile ? "100%" : "468px"}>
          <Box>
            <BookingDetails
              job={job}
              address={data?.address}
              hideSensativeData={true}
              booking={getValue(job, "bookingdetail.booking")}
              openMapModal={() => {}}
              isPaidBooking={isPaidBooking}
            />
            <ClientDetails
              job={job}
              hideSensativeData={true}
              isAdditionalDocument={true}
              refreshJobDetails={refreshJobDetails}
              showAction={false}
            />
          </Box>
        </Box>

        <Box ml={isMobile ? 0 : "29px"} mb={"60px"} maxWidth={isMobile ? "100%" : "468px"}>
          {/* <Box id="mobile" mb={isMobile ? "22px" : "60px"} /> */}

          <CardWrapper
            style={{
              rowGap: Spacing.S4,
            }}
          >
            <TreatmentDetailSection
              job={job}
              booking={booking}
              sessionType={data?.sessionType}
              corporateInfo={getValue(booking, "corporateInfo")}
              treatmentDetails={treatmentDetails}
              show
            />
            <RecurringInfo booking={booking} />
            <Box display="flex" alignItems="center" gridGap={16}>
              <img src={TherapistIcon} alt="therapist" style={{ width: 24, height: 24 }} />
              <Box style={{ ...styles.detailText }}>
                {therapistCount} {therapistCount > 1 ? "Therapists" : "Therapist"}
              </Box>
            </Box>
          </CardWrapper>

          <ClientPaymentDetails job={job} style={{ marginTop: "16px" }} />

          <Box mt={"16px"}>
            <PayoutSummary
              job={job}
              selectedTime={selectedArrivalType}
              onInfoClicked={onInfoClicked}
              proPayouts={getValue(location, "state.proPayouts", null)}
            />

            {backToBackUpdateRequired && <BackToBackUpdate />}
          </Box>

          {(jobStatus === JobStatus.pending || jobStatus === JobStatus.rebooking) && (
            <JobActions
              onAccept={onAcceptClicked}
              onDecline={onAcceptClicked}
              onAlternateOffer={onAcceptClicked}
              allowAlternateOffer={allowAlternateOffer({ booking })}
              disabled={disableActions}
              CanChatBtn={CanChatButton}
            />
          )}
        </Box>
      </Box>

      <PayoutInfoModal
        renderHTML
        info={infoDetails}
        visible={showInfo}
        onClose={() => setShowInfo(false)}
      />

      {/* accept range booking */}
      <DateAndTimeModal
        visible={showAcceptRangeModal}
        isMobile={isMobile}
        isTab={isTab}
        onClose={() => setShowAcceptRangeModal(false)}
        jobId={job.id?.toString()}
        offeredTime={booking?.earliestTime}
        timezone={getValue(booking, "timezone")}
        label="Time of arrival"
        actionName="Accept"
        onConfirm={onAcceptRangedBooking}
        maxMinutes={maxMinutes}
        minMinutes={minMinutes}
        restrictTimeOptions={true}
      />

      <CustomTimePicker
        onOpen={() => {}}
        onChange={() => {}}
        open={showTimePicker}
        onClose={dismissTimePicker}
        maxTime={maxTime}
        minTime={minTime}
        timezone={getValue(booking, "timezone")}
        onAccept={(date) => handleAccept(date, booking.timezone)}
      />

      {/* Address modal */}
      <AddressModal
        open={addressModalOpen}
        onClose={() => setAddressModalOpen(false)}
        onSaved={(address) => {}}
        userId={userId}
        addedByPro={true}
      />

      <PastBookingWarningModal
        visible={showOldBookingWarning}
        onClose={() => setShowOldBookingWarning(false)}
      />

      <InfoModal
        title={modelInfo.title}
        visible={modelInfo.visible}
        description={modelInfo.description}
        handleClose={() => setModelInfo({ ...modelInfo, visible: false })}
        divider={false}
      />
    </Box>
  );
};

const styles = {
  header: {
    fontWeight: 600,
    color: Colors.Dusk,
    marginBottom: "16px",
  },
  wrapper: {
    display: "flex",
  },
  payoutHeader: {
    fontSize: "16px",
    fontWeight: 600,
    color: Colors.Dusk,
  },
  detailText: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
};

export default JobDetailsPublic;
