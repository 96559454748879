import qs from "query-string";
import toLower from "lodash/toLower";

export function firstLetterCapitalized(string: string) {
  const str = toLowerCase(string);
  return str.length > 0 ? str.charAt(0).toUpperCase() : "";
}

export function removeSpaces(string: string) {
  return string.replace(/\s/g, "");
}

export const toLowerCase = (value: string) => {
  return toLower(value);
};

export const getQueryString = (object: any) => new URLSearchParams(object).toString();

export const parseQueryString = (queryString: string) => qs.parse(queryString);

export const stringifyParams = (object: any, options?: any) => qs.stringify(object, options);

export function capitalizeFirstLetter(string: string = "") {
  const str = toLowerCase(string);
  return str.charAt(0).toUpperCase() + str.slice(1);
}
