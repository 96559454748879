import { Box, Grid, Typography } from "@material-ui/core";
import deepEqual from "deep-equal";
import { isEmpty, isNil, toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useHistory } from "react-router-dom";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import LogoLink from "../../components/LogoLink";
import TextField from "../../components/TextField";
import MetaTags from "../../components/Voucher/MetaTags";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { useMobile, useTab } from "../../hooks/mobile";
import * as segmentTrackService from "../../services/segment/track.service";
import { useAlertStore } from "../../stores/alert";
import { fetchVoucherOptions, useVoucherStore, Voucher, VoucherPrice } from "../../stores/voucher";
import "../../styles/DayPicker.css";
import { DayPickerNavbar } from "../NewBooking/DateAndTime";
import InfoIcon from "../../images/info-error.svg";
import GiftVoucherPreview from "./GiftVoucherPreview";
import PurchaseVoucherLandingWrapper from "./PurchaseVoucherLandingWrapper";
import { useUserStore } from "../../stores/user";
import { getDateObject } from "../../utils/date.util";
import { COUNTRIES_CURRENCY } from "../../constants/countries";
import { Spacing } from "../../components/v2/Styled/enum";
import { getValue } from "../../utils/object";
import { setCountryCode } from "../../utils/country";
import pixelsService from "../../services/pixels/pixels.service";
import GiftVoucherFooter from "../../components/GiftVoucher/GiftVoucherFooter";
import { UseQueryResult } from "@tanstack/react-query";
import { useVoucherPrice } from "../../hooks/voucher/voucherPrice.hooks";
import { MAX_VOUCHER_PURCHASE, VOUCHER_PURCHASE_STEP_PROGRESS } from "../../constants/voucher";

const emailVoucherOptions = [
  { value: "recipient", title: "Yes Please" },
  { value: "client", title: "No - email it to me please" },
];

const voucherCountOptions = Array.from({ length: MAX_VOUCHER_PURCHASE }, (_, i) => ({
  value: i + 1,
  title: (i + 1).toString(),
}));

const whenToSendOptions = [
  { value: "asap", title: "Now" },
  { value: "specificDate", title: "On a specific date" },
];

export default function PurchaseVoucher() {
  const isMobile = useMobile();
  const isTab = useTab();
  const history = useHistory();
  const [voucherOptions, setVoucherOptions] = useState<DropdownOption[]>([]);
  const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([]);
  const {
    voucher,
    setVoucher,
    voucherCount,
    setVoucherCount,
    voucherPrice,
    resetVoucherPrice,
    recipientName,
    setRecipientName,
    recipientEmail,
    setRecipientEmail,
    yourName,
    setYourName,
    notes,
    setNotes,
    sendTo,
    setSendTo,
    sendASAP,
    setSendASAP,
    sendOn,
    setSendOn,
    voucherOptions: options,
    countryOptions: countries,
    selectedCountry,
    setSelectedCountry,
    setSelectedVoucherPrice,
  } = useVoucherStore();

  useEffect(() => {
    segmentTrackService.trackNewVoucherStepViewed();
    fetchVoucherOptions();
  }, []);

  const { user } = useUserStore();

  const [error, setError] = useState("");
  const { data } = useVoucherPrice({
    voucherCount: voucherCount || 0,
    voucher: (voucher || {}) as Voucher,
    selectedCountry,
  }) as UseQueryResult<VoucherPrice, unknown>;

  useEffect(() => {
    if (!isNil(data?.price)) {
      useVoucherStore.setState({
        voucherPrice: data,
      });
    }
  }, [data]);

  useEffect(() => {
    const [voucherFromSelectedCountry] = [...options].filter(
      (voucher) => Object.keys(voucher)[0] === selectedCountry
    );
    if (voucherFromSelectedCountry) {
      const voucherOptions = (voucherFromSelectedCountry as any)[selectedCountry]?.map(
        (voucher: any) => {
          return {
            title: voucher.text,
            value: voucher,
          } as DropdownOption;
        }
      );
      setVoucherOptions(voucherOptions);
    }
  }, [options, selectedCountry]);

  useEffect(() => {
    setCountryOptions(
      [...countries].map((country) => {
        return {
          title: COUNTRIES_CURRENCY[country],
          value: country,
        } as DropdownOption;
      })
    );
  }, [countries]);

  // useEffect(() => {
  //   if (voucher?.type === "custom" && !checkIfEmpty(voucherOptions)) {
  //     console.log(voucherOptions, "voucherOptions")
  //     const customVoucher = voucherOptions.find((option) => option.value.type === "custom");
  //     console.log({ customVoucher });
  //     setVoucher(customVoucher?.value || voucher?.value);
  //   }
  // }, [voucher, voucherOptions, setVoucher]);

  // useEffect(() => {
  //   if (voucher?.type !== "custom") {
  //     console.log("contiton",voucher);
  //     setVoucher(null);
  //   }
  // }, [selectedCountry]);

  //for text in the custom value
  const { setErrorMessage } = useAlertStore();
  const verifyFields = () => {
    if (isNil(voucher)) {
      setErrorMessage("Please select a voucher");
      return false;
    }

    if (isEmpty(recipientName)) {
      setErrorMessage("Please enter your recipient name");
      return false;
    }

    if (!isEmpty(error)) {
      setErrorMessage(error);
      return false;
    }

    if (isEmpty(yourName)) {
      setErrorMessage("Please enter your name");
      return false;
    }

    if (sendTo === "recipient" && isEmpty(recipientEmail)) {
      setErrorMessage("Please provide your recipient's email");
      return false;
    }

    if (sendASAP === "specificDate" && isNil(sendOn)) {
      setErrorMessage("Please let us know when to send this gift voucher");
      return false;
    }

    if (voucher.type === "custom") {
      if (voucher.value && parseInt(voucher.value) >= 50) {
        return true;
      }
      setErrorMessage(`Minimum voucher value is ${voucher.currency}50`);
      return false;
    }

    return true;
  };

  const handleContinue = () => {
    if (!verifyFields()) {
      return;
    }

    const accessToken = localStorage.getItem("token");

    const voucherSchedule = sendASAP === "asap" ? sendASAP : sendOn;
    const voucherRecipient = recipientName ? "yes" : "no";
    segmentTrackService.trackNewVoucherStepCompleted(
      voucherSchedule,
      voucherRecipient,
      voucher?.text
    );

    const selectedCurrency = countryOptions.find((option) => option.value === selectedCountry);

    const cartData = {
      currency: selectedCurrency?.title || "AUD",
      value: voucher?.value,
    };

    pixelsService.trackAddToCart(cartData);

    if (isEmpty(accessToken)) {
      history.push(Paths.PurchaseVoucherSignUp);
    } else {
      history.push(Paths.PurchaseVoucherPayment);
    }
  };

  const handleCustomValueChange = (value: string | null) => {
    let currentVoucher = voucher;
    if (currentVoucher) {
      currentVoucher.voucherValue = value;
      currentVoucher.value = value;
      currentVoucher.giftText = value;
    }
    if (isNaN(value as any)) {
      setError("Please enter numbers only");
    } else {
      setError("");
      setVoucher(currentVoucher);
    }
  };

  const getPreferredModifier = () => {
    let fromDate = sendOn;

    const selected = {
      from: getDateObject(fromDate),
    };
    return selected;
  };
  const preferredModifiers = getPreferredModifier();

  return (
    <PurchaseVoucherLandingWrapper>
      <MetaTags />
      <Box>
        <LogoLink />
        <Typography
          variant="h1"
          style={{
            color: Colors.NightBlue,
            marginTop: isMobile ? "40px" : "32px",
            marginBottom: isMobile ? "32px" : "85px",
            fontSize: "36px",
          }}
        >
          Blys voucher
        </Typography>
        <Grid
          container
          justifyContent="space-between"
          style={{ flexWrap: "wrap-reverse", gap: "20px" }}
        >
          <Grid item>
            {isMobile && (
              <Box marginBottom={Spacing.S8}>
                <GiftVoucherPreview />
              </Box>
            )}

            <Box width={isMobile ? undefined : "400px"}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box
                  fontFamily="Museo"
                  color={Colors.Dusk}
                  fontWeight={700}
                  flex={1}
                  fontSize={"14px"}
                  style={{ textAlign: "left", marginBottom: "8px" }}
                >
                  Choose your voucher
                </Box>
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      color: Colors.Dusk,
                      fontSize: "14px",
                    }}
                  >
                    Purchase prices indicate base rates for treatments. Final booking price may vary
                    depending on treatment type, service location and time.
                  </Typography>
                </Box>
                <Box display="flex" flexDirection={"row"}>
                  <Dropdown
                    paddingTop={1}
                    options={countryOptions}
                    onSelectedOption={(option) => {
                      // localStorage.setItem("countryCode", option.value);
                      // setSelectedCountry(option.value);
                      setSelectedCountry(option.value);
                      setCountryCode(option.value);
                      setVoucher(null);
                      resetVoucherPrice();
                    }}
                    selectedOption={countryOptions.find(
                      (option) => option.value === selectedCountry
                    )}
                    styles={{
                      width: "20%",
                      optionMarginRight: "15px",
                      optionMarginLeft: "15px",
                    }}
                  />
                  <Box style={{ width: "5%" }} display={"block"} />
                  <Dropdown
                    paddingTop={1}
                    options={voucherOptions}
                    selectedOption={voucherOptions.find((option) =>
                      voucher?.type
                        ? option.value.type === "custom"
                        : deepEqual(option.value, voucher)
                    )}
                    onSelectedOption={(option) => {
                      setVoucher(option.value);
                      setSelectedVoucherPrice(getValue(option, "value.value"));
                    }}
                    styles={{ width: "75%" }}
                  />
                </Box>
              </Box>

              {voucher?.type === "custom" && (
                <Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <TextField
                      title="Enter custom gift value"
                      placeholder={`Custom value (${voucher?.currency})`}
                      value={voucher.voucherValue}
                      onChange={(value) => handleCustomValueChange(value)}
                      inputTextColor={error ? "#DC3545" : Colors.TurquoiseBlue}
                      subtitle={`Minimum value is ${selectedCountry}50`}
                      subtitleColor={Colors.NightBlue}
                    />
                    {error && (
                      <img
                        src={InfoIcon}
                        width="16px"
                        height="16px"
                        style={{
                          objectFit: "cover",
                          marginTop: "44px",
                          color: "#DC3545",
                          transform: "rotate(-180deg)",
                        }}
                      />
                    )}
                  </Box>
                  {error && (
                    <Box
                      fontSize={14}
                      fontFamily="Museo"
                      marginTop="-15px"
                      paddingBottom="21px"
                      color="#DC3545"
                    >
                      {error}
                    </Box>
                  )}
                </Box>
              )}
              <Dropdown
                title="How many vouchers would you like to buy?"
                options={voucherCountOptions}
                selectedOption={voucherCountOptions.find((option) => option.value === voucherCount)}
                onSelectedOption={(option) => setVoucherCount(toNumber(option.value))}
              />
              <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
                <TextField
                  title="To"
                  placeholder="Recipient name"
                  value={recipientName}
                  onChange={(text) => setRecipientName(text)}
                />
                <Box width="20px" />
                <TextField
                  title="From"
                  placeholder="Your name"
                  value={yourName}
                  onChange={(text) => setYourName(text)}
                />
              </Box>
              <TextField
                title="Note"
                placeholder="Leave your personal message"
                value={notes}
                onChange={(text) => setNotes(text)}
                multiline
                maxLength={250}
              />

              <Dropdown
                title="Would you like us to email the voucher to recipient?"
                options={emailVoucherOptions}
                selectedOption={emailVoucherOptions.find((option) => option.value === sendTo)}
                onSelectedOption={(option) => setSendTo(option.value)}
              />

              {sendTo === "recipient" && (
                <>
                  <TextField
                    title="To"
                    placeholder="Recipient's email"
                    value={recipientEmail}
                    onChange={(text) => setRecipientEmail(text)}
                    type="email"
                  />
                  <Box
                    fontSize={14}
                    fontFamily="Museo"
                    marginTop="-15px"
                    paddingBottom="21px"
                    color={Colors.Dusk}
                    mt={"0px"}
                  >
                    A copy of the voucher will be emailed to you too.
                  </Box>
                </>
              )}

              <Dropdown
                title="When would you like us to send this gift voucher?"
                options={whenToSendOptions}
                selectedOption={whenToSendOptions.find((option) => option.value === sendASAP)}
                onSelectedOption={(option) => setSendASAP(option.value)}
              />

              {sendASAP === "specificDate" && (
                <Box
                  width="100%"
                  mt="48px"
                  mb="48px"
                  justifyContent="center"
                  display="flex"
                  flexDirection="row"
                >
                  <DayPicker
                    firstDayOfWeek={1}
                    onDayClick={(day, modifiers) => {
                      if (!modifiers.disabled) {
                        setSendOn(day);
                      }
                    }}
                    selectedDays={sendOn ? [sendOn] : []}
                    disabledDays={{
                      before: new Date(),
                    }}
                    captionElement={() => {
                      return <Box />;
                    }}
                    navbarElement={(props) => {
                      return <DayPickerNavbar {...props} />;
                    }}
                    modifiers={preferredModifiers}
                  />
                </Box>
              )}
            </Box>
            <Box height={isMobile ? "0px" : "50px"}></Box>
          </Grid>
          <Grid item>{!isMobile && <GiftVoucherPreview country={user?.country} />}</Grid>
        </Grid>
      </Box>
      <GiftVoucherFooter
        noOfVoucher={voucherCount}
        totalPrice={voucherPrice?.price || 0}
        currency={voucher?.currency || ""}
        voucherPurchaseProgress={VOUCHER_PURCHASE_STEP_PROGRESS[1]}
      >
        <Button
          title="Continue"
          type={ButtonType.secondary}
          width={isMobile || isTab ? "150px" : "173px"}
          size={isMobile || isTab ? ButtonSize.small : ButtonSize.large}
          onClick={handleContinue}
        />
      </GiftVoucherFooter>
    </PurchaseVoucherLandingWrapper>
  );
}
