import { Box, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { isEmpty } from "lodash";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LandingWrapper from "../../components/LandingWrapper";
import LogoLink from "../../components/LogoLink";
import { AFTERPAY_SUCCESS_MOBILE_APP_REDIRECT_URL } from "../../constants/common";
import { Paths } from "../../constants/paths";
import { parseApiError } from "../../helpers/error";
import { useMobile } from "../../hooks/mobile";
import { useAlertStore } from "../../stores/alert";

interface Params {
  id: string;
}

export default function () {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams<Params>();

  const query = new URLSearchParams(location.search);

  const orderToken = query.get("orderToken");
  const accessToken = query.get("accessToken");
  const status = query.get("status");
  const isMobileOrder = query.get("isMobileOrder");

  console.debug("id: ", id);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const hasRunEffect = React.useRef(false);

  React.useEffect(() => {
    if (hasRunEffect.current) {
      return;
    }
    // This is avoide the useEffect to render multuple time in initial render.
    hasRunEffect.current = true;

    axios
      .post(`/afterpay/success/${id}?accessToken=${accessToken}`, {
        afterpayOrderToken: orderToken,
      })
      .then((response) => {
        console.debug("response: ", response);

        if (response.status === 450) {
          // therapist notified
          setErrorMessage(
            "Error: Afterpay has been cancelled, you have not been charged. Please try again or use another method of payment."
          );
          return;
        }

        if (response.status === 451) {
          // therapist NOT notified
          setErrorMessage(
            "Error: Afterpay has been cancelled, you have not been charged. Please try again or use another method of payment."
          );

          return;
        }
        if (isMobileOrder === "true") return;

        if (status == "SUCCESS") {
          history.push(`/my-bookings/${id}?accessToken=${accessToken}`);
        } else {
          history.push(`/my-bookings/${id}`);
        }

        // if (isMobileOrder === "1") {
        //   window.location.replace(AFTERPAY_SUCCESS_MOBILE_APP_REDIRECT_URL);
        // } else {
        //   //history.push(Paths.Bookings);

        //   history.push(`/bookings/${id}`);
        // }
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  }, []);

  return (
    <>
      <LandingWrapper>
        <Box width={isMobile ? undefined : "400px"}>
          <LogoLink />
        </Box>

        {!isEmpty(orderToken) && !isEmpty(accessToken) ? (
          <CircularProgress />
        ) : (
          <Box>Error: Invalid link</Box>
        )}
      </LandingWrapper>
    </>
  );
}
