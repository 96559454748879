import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import { useUserStore } from "../../../stores/user";
import MapActions from "../../../components/Map/MapActions";
import MapApiService from "../../../services/map/MapApiService";
import config from "../../../config/proDashboard/dashboardConfig.json";
import { BOOKINGS_SECTION_VALUES } from "../../../constants/prodashboard";
import {
  MAX_ALLOWED_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  MIN_INITIAL_ZOOM_LEVEL,
  drawArea,
  drawMarker,
  getMapConfig,
} from "../../../services/map/map.service";

import CIRCLE_MARKER from "../../../images/booking-ellipse.svg";
import EXACT_MARKER from "../../../images/exact-booking-marker.svg";
import TherapistAreaMarker from "../../../images/center-marker.svg";
import ServiceAreaMarker from "../../../images/serviceAreaMarker2.svg";
import CIRCLE_MARKER_SELECTED from "../../../images/booking-ellipse-selected.svg";

interface Props {
  id: string;
  tab: string;
  markerRef: any;
  bookings: any[];
  serviceAreaInfo: any;
  showServiceAreas?: boolean;
  closeBookingModal: Function;
  onBookingSelect: (booking: any) => void;
}

const BookingsMapArea = ({
  id,
  tab,
  bookings,
  markerRef,
  onBookingSelect,
  serviceAreaInfo,
  showServiceAreas = false,
  closeBookingModal = () => {},
}: Props): JSX.Element => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [navLocation, setNavLocation] = useState<{
    lat: number;
    lng: number;
  }>();
  const isMobile = useMobile();
  const { user } = useUserStore();

  const { fixed, realTime } = serviceAreaInfo;
  const { mapConfig } = getMapConfig(config, user?.country);

  const initMap = async () => {
    const serviceAreaCoords = fixed
      ? {
          lat: fixed.latitude,
          lng: fixed.longitude,
        }
      : null;

    const therapistAreaCoords =
      realTime && realTime.enabled
        ? {
            lat: realTime.latitude,
            lng: realTime.longitude,
          }
        : null;

    const loadedMap = await MapApiService.getMap(
      document.getElementById(id) as HTMLElement,
      serviceAreaCoords || therapistAreaCoords
    );

    loadedMap.setOptions({
      disableDefaultUI: true,
      draggable: true,
      zoomControl: false,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      streetViewControl: false,
      maxZoom: tab === BOOKINGS_SECTION_VALUES.UPCOMING ? MAX_ALLOWED_ZOOM_LEVEL : MAX_ZOOM_LEVEL,
    });
    setMap(loadedMap);
  };

  const mapServiceAreas = ({ fixed, realTime }: any) => {
    if (!showServiceAreas) return;

    const areaOptions = {
      strokeColor: "#5197FF",
      strokeOpacity: 0.6,
      strokeWeight: 1,
      fillColor: "#5197FF",
      fillOpacity: 0.1,
    };

    if (fixed) {
      const areaInfo = {
        coords: {
          lat: fixed.latitude,
          lng: fixed.longitude,
        },
        radius: fixed.radius,
      };

      drawMarker({
        map,
        google,
        coords: areaInfo.coords,
        icon: {
          url: ServiceAreaMarker,
          anchor: new google.maps.Point(8, 8),
        },
      });
      drawArea({ areaInfo, map, google, options: areaOptions });
    }
    if (realTime && realTime.enabled) {
      const areaInfo = {
        coords: {
          lat: realTime.latitude,
          lng: realTime.longitude,
        },
        radius: realTime.radius,
      };

      drawMarker({
        map,
        google,
        coords: areaInfo.coords,
        icon: {
          url: TherapistAreaMarker,
          anchor: new google.maps.Point(8, 8),
        },
      });
      drawArea({ areaInfo, map, google, options: areaOptions });
    }
  };

  //Bookings markers
  const addBookingMarkers = () => {
    const BOOKING_MARKER = {
      [BOOKINGS_SECTION_VALUES.NEW]: {
        MARK: {
          url: CIRCLE_MARKER,
          scaledSize: new google.maps.Size(40, 40),
          anchor: new google.maps.Point(40 / 2, 40 / 2),
        },
        SELECTED_MARK: {
          url: CIRCLE_MARKER_SELECTED,
          scaledSize: new google.maps.Size(60, 60),
          anchor: new google.maps.Point(60 / 2, 60 / 2),
        },
      },
      [BOOKINGS_SECTION_VALUES.UPCOMING]: {
        MARK: {
          url: EXACT_MARKER,
          scaledSize: new google.maps.Size(28, 28),
        },
        SELECTED_MARK: {
          url: EXACT_MARKER,
          scaledSize: new google.maps.Size(40, 40),
        },
      },
    };

    bookings.forEach((booking) => {
      const bookingAreaCenter = {
        lat: getValue(booking, "bookingdetail.booking.address.latitude"),
        lng: getValue(booking, "bookingdetail.booking.address.longitude"),
      };

      const marker = new google.maps.Marker({
        map,
        position: bookingAreaCenter,
        icon: BOOKING_MARKER[tab].MARK,
      });

      marker.addListener("click", () => {
        closeBookingModal("", true); // cleanup: delect previous marker
        markerRef.current = { marker, icon: BOOKING_MARKER[tab].MARK };
        marker.setIcon(BOOKING_MARKER[tab].SELECTED_MARK);
        map?.panTo(bookingAreaCenter);
        onBookingMarkerClicked(booking);
      });
    });
  };

  const setBounds = () => {
    if (map) {
      const coords = bookings.map((booking) => {
        const address = getValue(booking, "bookingdetail.booking.address");
        return {
          lat: address.latitude,
          lng: address.longitude,
        };
      });

      const bounds = new google.maps.LatLngBounds();
      coords.forEach(({ lat, lng }) => {
        bounds.extend({ lat, lng });
      });
      map.fitBounds(bounds);
      if ((map.getZoom() || 0) > MIN_INITIAL_ZOOM_LEVEL) {
        map.setZoom(MIN_INITIAL_ZOOM_LEVEL);
      }
    }
  };

  const onBookingMarkerClicked = (booking: any) => {
    onBookingSelect(booking);
  };

  const initializeNavLocation = () => {
    let center = mapConfig.center;
    if (realTime && realTime.enabled) {
      center = { latitude: realTime?.latitude, longitude: realTime?.longitude };
    } else if (fixed) {
      center = {
        latitude: fixed?.latitude,
        longitude: fixed?.longitude,
      };
    }
    setNavLocation({
      lat: center.latitude,
      lng: center.longitude,
    });
  };

  useEffect(() => {
    if (map) {
      addBookingMarkers();
      setBounds();
      mapServiceAreas({
        fixed,
        realTime,
      });
    }
  }, [map]);

  useEffect(() => {
    if (!map) initMap();
    initializeNavLocation();
  }, []);

  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
        height: isMobile ? "90vh" : "729px",
        overflow: "hidden",
      }}
    >
      <div id={id} style={{ width: "100%", height: "100%" }}></div>

      <MapActions map={map} panToLatLng={navLocation} />
    </Box>
  );
};

export default BookingsMapArea;
