import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BookMassageButton, {
  BookMassageButtonSource,
  BookMassageButtonType,
} from "../components/BookMassageButton";
import PastBookings from "./Bookings/Past";
import UpcommingBookings from "./Bookings/Upcoming";
import SectionPageHeader from "../components/SectionPageHeader";
import WelcomeModal from "../components/WelcomeModal";
import Wrapper from "../components/Wrapper";
import { BOOKING_STATUS } from "../constants/booking";
import { Paths } from "../constants/paths";
import { useMobile } from "../hooks/mobile";
import { Booking } from "../models";
import { useUserStore } from "../stores/user";
import { fetchAddresses } from "../stores/address";
import UseBookingQueryFilter from "../hooks/booking/bookingFilter.hook";
import pixelsService from "../services/pixels/pixels.service";

const sections = ["Upcoming", "Past bookings"];

export default function Bookings(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const section = query.get("section");
  const page = query.get("page");

  const showPastBookings = section === "past";
  const { allBookingQueries, parseQueryString } = UseBookingQueryFilter({
    forUpcoming: !showPastBookings,
  });
  let [linkWithFilters, setLinkWithFilters] = useState<string>(
    showPastBookings ? Paths.Bookings : Paths.PastBookings
  );
  const { user, setUserWelcomedAt } = useUserStore();

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState<number>(
    showPastBookings ? 1 : 0
  );

  const [currentPastPage, setCurrentPastPage] = React.useState(1);

  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);

  useEffect(() => {
    fetchAddresses();
    pixelsService.trackPageView();
  }, []);

  useEffect(() => {
    setSelectedSectionIndex(showPastBookings ? 1 : 0);
  }, [section]);

  React.useEffect(() => {
    if (user) {
      if (!user.welcomedAt && !showWelcomeModal) {
        setShowWelcomeModal(true);
        setUserWelcomedAt();
      }
      return;
    }
  }, [user]);

  React.useEffect(() => {
    if (showPastBookings) {
      setSelectedSectionIndex(1);
    } else {
      setSelectedSectionIndex(0);
    }
  }, [location]);

  React.useEffect(() => {
    if (showPastBookings && page) {
      const pageNumber = parseInt(page);

      if (!isNaN(pageNumber)) {
        setCurrentPastPage(pageNumber);
      }
    }
  }, [page]);

  const pushToBookingDetails = (booking: Booking, status: string) => {
    const { id: bookingId } = booking;

    const url =
      status === BOOKING_STATUS.ARRANGED || status === BOOKING_STATUS.DECLINED
        ? `bookings/review/${bookingId}`
        : `bookings/${bookingId}`;
    history.push(url, {
      back: `${location.pathname}${location.search}`,
    });
  };

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeader
            title="Bookings"
            rightButton={
              isMobile ? (
                <BookMassageButton
                  type={BookMassageButtonType.iconButtonTitled}
                  source={BookMassageButtonSource.Navigation}
                />
              ) : undefined
            }
            sections={sections}
            selectedSectionIndex={selectedSectionIndex}
            onSelectedSectionIndex={(index) => {
              if (index === 0) {
                setLinkWithFilters(`${Paths.Bookings}?${parseQueryString(allBookingQueries)}`);
              } else if (index === 1) {
                setLinkWithFilters(`${Paths.Bookings}?${parseQueryString(allBookingQueries)}`);
              }
              history.push(linkWithFilters);
            }}
          />
        }
      >
        {selectedSectionIndex === 0 ? (
          <UpcommingBookings
            onPastBookingClicked={() =>
              setLinkWithFilters(`${Paths.Bookings}?${parseQueryString(allBookingQueries)}`)
            }
            isMobile={isMobile}
            onBookingClick={pushToBookingDetails}
          />
        ) : (
          <PastBookings isMobile={isMobile} onBookingClick={pushToBookingDetails} />
        )}
      </Wrapper>
      <WelcomeModal open={showWelcomeModal} onClose={() => setShowWelcomeModal(false)} />
    </>
  );
}
