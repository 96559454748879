import { Box, ButtonBase } from "@material-ui/core";
import { isNil } from "lodash";
import React from "react";
import { Colors } from "../constants/colors";
import { useUserStore } from "../stores/user";
import "../styles/scrollbar-hidden.css";
import CircleExclamation from "./Icons/CircleExclamation";
import { useMobile } from "../hooks/mobile";

interface Props {
  scrollPositionKey?: string;
  sections: Array<string>;
  selectedIndex: number;
  onSelectedIndex: (index: number) => unknown;
  disabledSections?: string[];
}

const titleStyle = (selected: boolean, disabled?: boolean) => {
  return {
    fontFamily: "Museo",
    fontWeight: selected ? 700 : 500,
    fontSize: "16px",
    color: disabled ? Colors.LightBlueGrey : selected ? Colors.NightBlue : Colors.SlateGrey,
  };
};

export default function HeaderSectionControl({
  scrollPositionKey,
  sections,
  selectedIndex,
  onSelectedIndex,
  disabledSections,
}: Props): JSX.Element {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const { user } = useUserStore();
  const isMobile = useMobile();

  // Restore scroll position if available
  // NOTE: This is to maintain scroll position when user navigates between different sections i.e. within Pro Dashboard
  React.useEffect(() => {
    if (scrollPositionKey) {
      const savedScrollLeft = localStorage.getItem(scrollPositionKey);

      if (!isNil(savedScrollLeft) && containerRef.current) {
        containerRef.current.scrollLeft = parseInt(savedScrollLeft);
      }
    }
  }, []);

  return (
    <div
      className="scrollbar-hidden"
      style={{
        overflowX: "scroll",
        display: "flex",
        flexDirection: "row",
        maxWidth: "90vw",
        whiteSpace: "nowrap",
        marginTop: isMobile ? "24px" : "0px",
      }}
      ref={containerRef}
    >
      {sections.map((section, index) => (
        <ButtonBase
          disabled={disabledSections?.includes(section)}
          onClick={() => {
            if (scrollPositionKey && !isNil(containerRef.current?.scrollLeft)) {
              localStorage.setItem(scrollPositionKey, String(containerRef.current?.scrollLeft));
            }

            onSelectedIndex(index);
          }}
        >
          <Box marginLeft={index > 0 ? "25px" : undefined}>
            <Box
              style={titleStyle(selectedIndex === index, disabledSections?.includes(section))}
              height="26px"
              marginBottom="4px"
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              gridGap={8}
            >
              {(section === "Personal" || section === "Profile") &&
                (!user?.emailVerifiedAt || !user?.mobileVerifiedAt) && <CircleExclamation />}
              {section}
            </Box>
            {selectedIndex === index && (
              <Box width="100%" height="3px" bgcolor={Colors.TurquoiseBlue} />
            )}
          </Box>
        </ButtonBase>
      ))}
    </div>
  );
}
