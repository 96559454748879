import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";

const VOUCHER_KEY = "VOUCHER-PRICE";

interface Voucher{
    massageDuration: number;
    sessionType: string;
    value: string | null;
}

interface VoucherPriceParams {
    voucherCount: number;
    voucher: Voucher | null;
    selectedCountry: string;
  }

export const useVoucherPrice = ({voucherCount, voucher, selectedCountry}: VoucherPriceParams) =>{
  return useQuery([VOUCHER_KEY, { voucherCount, voucher, selectedCountry }], () =>{ 
    if (!voucher || voucherCount === 0) {
      return {};
    }
      
    const { massageDuration, sessionType, value } = voucher;
    return get("giftVouchers/price", { 
      massageDuration, 
      sessionType, 
      couponPrice: value, 
      country: selectedCountry, 
      numberOfVouchers: voucherCount 
    });
  }
  );
};
