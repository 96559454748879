import { useMutation } from "@tanstack/react-query";
import { post } from "../../api/client";

export const useBlockTherapist = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => unknown;
  onError?: () => unknown;
}) =>
  useMutation(
    (data: { therapistId: number | string; blockReason: string }) =>
      post(`blockTherapist/${data.therapistId}`, { blockReason: data.blockReason }),
    {
      onSuccess: () => onSuccess(),
      onError: () => onError(),
    }
  );
