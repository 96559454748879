import { Box } from "@material-ui/core";
import React, { useRef } from "react";
import Button, { ButtonType } from "../../../../../components/Button";
import { allowUploadImageExtension } from "../../../../../helpers/services";

interface UploadImagePanelProps {
  file: File | undefined;
  setFile: (file: File) => unknown;
}

const UploadImagePanel: React.FC<UploadImagePanelProps> = ({ file, setFile }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  return (
    <Box
      style={{
        height: "265px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        border: "1px solid #EFEFEF",
        flexDirection: "column",
      }}
    >
      {file && (
        <img
          style={{ height: "100%", maxHeight: "200px", width: "100%", paddingBottom: "10px" }}
          alt="signature"
          src={URL.createObjectURL(file)}
        />
      )}
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept={allowUploadImageExtension()}
        onChange={handleChange}
      />

      <Button
        onClick={() => inputRef.current?.click()}
        type={ButtonType.secondary}
        title="Select File"
      />
    </Box>
  );
};

export default UploadImagePanel;
