import { Button, Divider } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import { dropdownStylesCombined } from "../../Dropdown";
import { Box } from "@material-ui/core";
import { FilterList } from "@mui/icons-material";
import { publicBookingSortOptions } from "../../../constants/sortOptions";
import RadioButton from "../../RadioButton";
import { useMobile } from "../../../hooks/mobile";

interface BookingSortOption {
  id: number;
  title: string;
  value: string;
  field: string;
  selected?: boolean;
}

export const SortComponentModal = ({
  filter,
  handleFilterApplied,
  setShowSortModal,
}: {
  filter: any;
  handleFilterApplied: any;
  setShowSortModal: any;
}) => {
  if (filter && filter.order) {
    if (typeof filter.order === "string") {
      filter.order = JSON.parse(filter.order);
    }
    setSelected(filter.order?.id);
  } else {
    setSelected(0);
  }
  const isMobile = useMobile();
  const handleClear = useCallback(() => {
    filter.order = null;
    setSelected(null);
    handleFilterApplied();
    setShowSortModal(false);
  }, [handleFilterApplied, setShowSortModal]);

  const applyFilters = useCallback(() => {
    handleFilterApplied();
    setShowSortModal(false);
  }, [handleFilterApplied, setShowSortModal]);

  function setSelected(id: number | null) {
    publicBookingSortOptions.map((option: BookingSortOption) => {
      option.selected = option.id === id;
    });
  }

  const handleSelect = (sortOpt: BookingSortOption) => {
    setSelected(sortOpt.id);
    handleFilterApplied("order", sortOpt);
  };

  return (
    <>
      {isMobile ? (
        <>
          <Box flexDirection={FlexDirection.Column} gridGap={Spacing.S3} marginY={Spacing.S4}>
            <Box
              fontWeight={FontWeight.Bold}
              fontSize={FontSize.F20}
              color={Colors.NightBlue}
              fontFamily={FontFamily.Museo}
              marginBottom={Spacing.S4}
            >
              Sort by
            </Box>
            <Box>
              {publicBookingSortOptions.map((sortOpt: BookingSortOption, index) => (
                <Box
                  key={sortOpt.title + index}
                  alignItems="center"
                  gridGap={Spacing.S4}
                  display={Display.Flex}
                  marginY={Spacing.S2}
                >
                  <RadioButton
                    style={{ height: 16, width: 16 }}
                    selected={sortOpt.selected}
                    onSelected={() => {
                      handleSelect(sortOpt);
                    }}
                  />
                  <Box
                    color={Colors.Dusk}
                    fontSize="16px"
                    fontFamily="Museo"
                    className="cursor-pointer"
                    onClick={() => {
                      handleSelect(sortOpt);
                    }}
                  >
                    {sortOpt.title}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          position="absolute"
          style={{ ...dropdownStylesCombined, marginTop: Spacing.S4, right: 0 }}
          padding={Spacing.S6}
          minWidth={400}
        >
          <Box
            sx={{
              flexDirection: FlexDirection.Column,
              gridGap: Spacing.S4,
              marginBottom: Spacing.S4,
            }}
          >
            <Box
              fontWeight={FontWeight.Bold}
              fontSize={FontSize.F16}
              color={Colors.NightBlue}
              fontFamily="Museo"
              marginBottom={Spacing.S3}
            >
              Sort by
            </Box>
            <Box>
              {publicBookingSortOptions.map((sortOpt: BookingSortOption, index) => (
                <Box
                  display={"flex"}
                  alignItems="center"
                  key={sortOpt.title + index}
                  sx={{ gridGap: Spacing.S1, marginY: Spacing.S3 }}
                >
                  <RadioButton
                    style={{ height: 16, width: 16 }}
                    selected={sortOpt.selected}
                    onSelected={() => {
                      handleSelect(sortOpt);
                    }}
                  />
                  <Box
                    color={Colors.Dusk}
                    fontSize="16px"
                    fontFamily="Museo"
                    className="cursor-pointer"
                    onClick={() => {
                      handleSelect(sortOpt);
                    }}
                  >
                    {sortOpt.title}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <Box display={"flex"} justifyContent="space-between">
            <Button
              variant="text"
              sx={{
                fontFamily: FontFamily.Museo,
                color: filter.order ? Colors.TextDanger : Colors.Grey,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
              }}
              onClick={handleClear}
            >
              Clear all
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: Colors.TurquoiseBlue,
                fontFamily: FontFamily.Museo,
                color: Colors.White,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
                "&:hover": {
                  backgroundColor: Colors.TurquoiseBlue,
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
              onClick={applyFilters}
            >
              Apply
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default function SortComponent({
  filter,
  handleFilterApplied,
  setCloseOthers,
  closeOthers,
}: {
  filter: any;
  handleFilterApplied: any;
  setCloseOthers?: any;
  closeOthers?: boolean | string;
}) {
  const [showSortModal, setShowSortModal] = useState(false);
  useEffect(() => {
    if (closeOthers !== "SortComponent") {
      setShowSortModal(false);
    }
  }, [closeOthers]);
  return (
    <Box position="relative">
      <Button
        variant="outlined"
        startIcon={<FilterList />}
        style={{
          width: "200px",
          height: Spacing.S11,
          fontFamily: FontFamily.Museo,
          fontSize: FontSize.F16,
          fontWeight: FontWeight.Medium,
          color: Colors.Grey,
          borderColor: Colors.LightBlueGrey,
          padding: "0px 24px",
          borderRadius: "100px",
          textTransform: "none",
          cursor: "pointer",
          textAlign: "left",
          justifyContent: "left",
        }}
        onClick={() => {
          setShowSortModal(!showSortModal);
          setCloseOthers("SortComponent");
        }}
      >
        Filter and sort
      </Button>
      {showSortModal && (
        <SortComponentModal
          filter={filter}
          handleFilterApplied={handleFilterApplied}
          setShowSortModal={setShowSortModal}
        />
      )}
    </Box>
  );
}
