import { Box, ButtonBase, Popover } from "@material-ui/core";
import axios from "axios";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import useSWR from "swr";
import BookingStatusPill from "../../components/Bookings/BookingStatusPill";
import Map from "../../components/Map";
import PopoverItem, { PopoverItemType } from "../../components/PopoverItem";
import PopoverItemSeparator from "../../components/PopoverItemSeparator";
import Ratings from "../../components/Ratings";
import { BOOKING_STATUS, BookingUpdateStatus, JobStatus } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { BASE_UPLOADS_URL } from "../../constants/common";
import {
  getManualBookingStatus,
  getMassageDescriptionForBookingDetail,
  getTherapistFirstNameWithBookingDetail,
  therapistProfilePictureUrlForBookingDetail,
} from "../../helpers/booking";
import { parseApiError } from "../../helpers/error";
import { checkIfFallbackUpdateRequestHasProviderPendingStatus } from "../../helpers/job";
import {
  isChatEnableForBooking,
  isChatEnableForCoupleBookingByDetail,
} from "../../hooks/chat/chat.hooks";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import useShareSheet from "../../hooks/shareSheet";
import FindingTherapistIcon from "../../images/finding-therapist.svg";
import MoreIcon from "../../images/therapists-more.svg";
import {
  Booking,
  BookingDetails,
  TherapistPublicProfile,
  TherapistPublicProfileData,
  User,
} from "../../models";
import { getRangedBookingFormattedDateTime } from "../../services/bookings/bookingTime.service";
import { getProfileLink } from "../../services/therapist/therapist.service";
import { useAlertStore } from "../../stores/alert";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty, getValue } from "../../utils/object";
import AvatarImageWithCovidStatus, { ImageSize } from "../AvatarImageWithCovidStatus";
import BookMassageButton, { BookMassageButtonSource } from "../BookMassageButton";
import BookingNotConfirmedActions from "../Bookings/BookingNotConfirmedActions";
import RebookingAction from "../Bookings/RebookActions";
import UpdateRequestActions from "../Bookings/UpdateRequestActions";
import { ButtonSize } from "../Button";
import ChatButton from "../Chat/ChatButton";
import ComplaintModal from "../Complaint/ComplaintModal";
import TermsAndPoliciesDocuments from "../TermsAndPoliciesDocuments/TermsAndPoliciesDocuments";
import TextLink from "../TextLink";
import { Spacing } from "../v2/Styled/enum";
import BookingDetailsProgressBar from "./BookingDetailsProgressBar";
import { isCoupleMassageType } from "../../stores/booking";
import useAuth from "../../hooks/auth.hooks";

interface Props {
  detail: BookingDetails;
  booking: Booking;
  onClickRating: () => unknown;
  onClickTherapist: (pro: User | TherapistPublicProfileData) => unknown;
  bookingMutate: () => unknown;
}

export default function BookingDetailsItem({
  detail,
  booking,
  onClickRating,
  onClickTherapist,
  bookingMutate,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const accessToken = useAccessToken();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { user } = useUserStore();

  const [showComplaintModal, setShowComplaintModal] = useState(false);
  const isJobPending =
    detail.job.status === JobStatus.pending || detail.job.status === JobStatus.rebooking;
  const profession = getValue(booking, "service.profession", "therapist");
  const isJobCancelled = detail.job.status === JobStatus.cancelled;
  const isJobRebooking = detail.job.status === JobStatus.rebooking;
  const { isAdminLoggedInAsClient } = useAuth();

  const showPreferredTherapists =
    isJobPending && !isEmpty(detail.booking.preferredTherapists) && isJobRebooking;

  const addressTimezone = getValue(booking, "address.timezone") || booking.timezone;

  const _therapistId = () => {
    if (showPreferredTherapists) {
      return detail.job.therapistsToRebook;
    }

    return detail.job?.therapistId;
  };

  const therapistId = _therapistId();

  const { data, mutate: therapistPublicProfileMutate } = useSWR(
    therapistId ? `/therapists/${therapistId}/publicProfile?accessToken=${accessToken}` : null
  );

  const therapistPublicProfile = data as TherapistPublicProfile;
  const rating = therapistPublicProfile?.therapistData?.therapistprofile?.rating || 0;
  const isCovidVaccinated = therapistPublicProfile?.therapistData?.isCovidVaccinated || false;

  const numberOfReviews = therapistPublicProfile?.totalReviews || 0;

  const _url = () => {
    const profileImage = getValue(
      therapistPublicProfile,
      "therapistData.therapistprofile.profileImage"
    );

    if (showPreferredTherapists && profileImage) {
      return `${BASE_UPLOADS_URL}/${profileImage}`;
    }

    return therapistProfilePictureUrlForBookingDetail(detail);
  };

  const url = _url();

  const profilePicture = () => {
    if (isJobPending && !showPreferredTherapists) {
      return <img src={FindingTherapistIcon} />;
    }

    return url ? (
      <Box width="30px" height="30px">
        <AvatarImageWithCovidStatus
          size={ImageSize.small}
          isCovidVaccinated={isCovidVaccinated}
          imageSrc={url}
        />
      </Box>
    ) : (
      <Box width="30px" height="30px">
        <AvatarImageWithCovidStatus
          size={ImageSize.small}
          isCovidVaccinated={false}
          imageSrc={null}
        />
      </Box>
    );
  };

  const name = () => {
    if (showPreferredTherapists) {
      return "Confirming your booking";
    }

    if (isJobPending) {
      return `Finding your ${profession}`;
    }
    const massageLabel = getValue(
      detail,
      "treatmentDetails[0].treatment.label",
      detail.massageType
    );

    return `${massageLabel} with ${getTherapistFirstNameWithBookingDetail(detail)}`;
  };

  const rebookingHelperText = () => {
    if (booking.rebookingStatus) {
      return `${therapistPublicProfile?.therapistData?.firstName} is unavailable`;
    }

    return `Checking ${therapistPublicProfile?.therapistData?.firstName}'s availability...`;
  };

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | null>(null);

  const shareSheet = useShareSheet();

  const handleShowMoreOptions = (event: any) => {
    event.stopPropagation();
    setPopoverAnchorEl(event.currentTarget as HTMLElement);
  };

  const reportTherapist = () => {
    setShowComplaintModal(true);
  };

  const getDisplayDateTime = ({ booking }: { booking: Booking }) => {
    const { earliestTime, latestTime, timeOfArrival } = booking;

    const earliest = timeOfArrival || earliestTime;
    const latest = timeOfArrival || latestTime;
    const { datetime } = getRangedBookingFormattedDateTime({
      earliestTime: earliest,
      latestTime: latest,
      timezone: addressTimezone,
    });
    return datetime;
  };

  const shouldAllowTerms = () => {
    const allowedJobStatus = [JobStatus.accepted];
    return allowedJobStatus.includes(detail.job.status);
  };

  const shouldButtonBeDisabled = () => {
    if (isJobPending) {
      return true;
    } else {
      return false;
    }
  };

  const allowNewTerms = shouldAllowTerms();

  const agreements = getValue(detail, "job.agreement.documents", []);
  const proDocuments = getValue(detail, "job.providerDocument", []);
  const showAgreements = !isJobPending && agreements && agreements.length > 0;
  const hasProsDocuments = !isJobPending && !checkIfEmpty(proDocuments);

  const showDocumentsList = showAgreements || hasProsDocuments;

  let bookingStatus: JobStatus | BookingUpdateStatus = detail.job.status;
  const hasFallbackPendingRequest = checkIfFallbackUpdateRequestHasProviderPendingStatus(booking);
  if (hasFallbackPendingRequest) {
    bookingStatus = BookingUpdateStatus.requestByClient;
  }

  const { canClientChat } = isChatEnableForBooking(booking);
  const canChatInCouplesBooking =
    isCoupleMassageType(booking.sessionType) && isChatEnableForCoupleBookingByDetail(detail);

  return (
    <>
      <Box
        bgcolor="#ffffff"
        borderRadius="6px"
        border={`solid 1px ${Colors.PaleLilac}`}
        mb="16px"
        width={isMobile ? "90vw" : "400px"}
      >
        <Box mt="20px" ml="24px" mr="24px">
          {!isJobCancelled && (
            <ButtonBase
              disabled={shouldButtonBeDisabled()}
              onClick={() => {
                if (detail.job?.user) {
                  onClickTherapist(detail.job?.user);
                } else if (therapistPublicProfile) {
                  onClickTherapist(therapistPublicProfile.therapistData);
                }
              }}
              style={{ width: "100%" }}
            >
              <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                <Box display="flex" flexDirection="row" alignItems="center" mr="12px">
                  {profilePicture()}
                  <Box ml="12px">
                    <Box
                      fontFamily="Museo"
                      fontWeight={700}
                      color={Colors.NightBlue}
                      fontSize="16px"
                      textAlign="left"
                    >
                      {name()}
                    </Box>
                    {showPreferredTherapists ? (
                      <Box
                        fontFamily="Open Sans"
                        fontSize="14px"
                        color={Colors.Dusk}
                        textAlign="left"
                      >
                        {rebookingHelperText()}
                      </Box>
                    ) : isJobPending ? (
                      <Box
                        fontFamily="Open Sans"
                        fontSize="14px"
                        color={Colors.Dusk}
                        textAlign="left"
                      >
                        Checking availability...
                      </Box>
                    ) : (
                      <>
                        <Box display="flex" flexDirection="row" alignItems="center" mt="1px">
                          {<Ratings rating={rating} />}

                          <Box fontFamily="Open Sans" fontSize="12px" color={Colors.Dusk} ml="6px">
                            {`${numberOfReviews} Reviews`}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>

                {therapistPublicProfile && (
                  <ButtonBase onClick={handleShowMoreOptions} disableTouchRipple={true}>
                    <Box
                      minHeight="55px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img src={MoreIcon} />
                    </Box>
                  </ButtonBase>
                )}
              </Box>
            </ButtonBase>
          )}

          {booking.address?.latitude && booking.address?.longitude && (
            <Box height="100px" mt="22px">
              <Map
                id={`map-bookingdetail-${detail.id}`}
                latitude={booking.address.latitude}
                longitude={booking.address.longitude}
              />
            </Box>
          )}

          <Box display="flex" flexDirection="column">
            <Box mt="22px" display="flex" flexDirection="row">
              <Box flex={1}>
                <BookingStatusPill
                  status={getManualBookingStatus(booking) || bookingStatus}
                  rebookingStatus={booking.rebookingStatus}
                  bookingStatus={booking.status === BOOKING_STATUS.DECLINED ? booking.status : ""}
                />
                <Box fontFamily="Open Sans" fontSize="14px" color={Colors.Dusk} mt="7px">
                  {getDisplayDateTime({ booking })}
                </Box>
              </Box>
              {booking.status === BOOKING_STATUS.COMPLETED && (
                <>
                  {/* change rating */}
                  {detail.job?.status === JobStatus.reviewLeft && (
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                      <Box mb="12px">
                        <Ratings
                          rating={detail.job?.therapistreview?.rating || 0}
                          width="19.8px"
                          height="18.9px"
                        />
                      </Box>
                      <TextLink title="Update review" fontSize="14px" onClick={onClickRating} />
                    </Box>
                  )}
                  {/* new rating */}
                  {detail.job?.status === JobStatus.finished && (
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                      <Box mb="12px">
                        <Ratings rating={0} width="19.8px" height="18.9px" />
                      </Box>
                      <TextLink title="Review therapist" fontSize="14px" onClick={onClickRating} />
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* Tipping here */}
            {booking.status === BOOKING_STATUS.COMPLETED && <Box>Tip Jhon</Box>}

            <Box mt="18px">
              <BookingDetailsProgressBar bookingDetail={detail} />
            </Box>
            <TermsAndPoliciesDocuments
              showAddAdditionalDocuments={!isJobPending}
              showAgreements={showAgreements}
              hasProsDocuments={hasProsDocuments}
              agreements={agreements}
              proDocuments={proDocuments}
              bookingId={booking.id}
              therapistId={therapistId}
              allowUpload={allowNewTerms}
              alignItems="start"
            />

            <RebookingAction booking={booking} bookingMutate={bookingMutate} showHeader={true} />
            <BookingNotConfirmedActions booking={booking} bookingMutate={bookingMutate} />
          </Box>

          {(canClientChat || canChatInCouplesBooking) && booking.conversationId && (
            <Box mt="22px">
              <ChatButton
                text={`Message ${therapistPublicProfile?.therapistData.firstName}`}
                bookingId={booking.id}
                conversationId={booking.conversationId}
              />
            </Box>
          )}

          <UpdateRequestActions
            showTitle={false}
            booking={booking}
            bookingMutate={bookingMutate}
            jobId={detail.job.id}
          />
          {booking.status === BOOKING_STATUS.COMPLETED ? (
            <>
              <Box width="100%" height="1px" bgcolor={Colors.PaleLilac} mt="22px" />
              <Box
                width="100%"
                height="100px"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Box
                    fontFamily="Museo"
                    fontWeight={700}
                    fontSize="16px"
                    color={Colors.Dusk}
                    letterSpacing="0.2px"
                  >
                    Service provided
                  </Box>
                  <Box fontFamily="Open Sans" fontSize="14px" color={Colors.BlueyGrey}>
                    {getMassageDescriptionForBookingDetail(detail)}
                  </Box>
                </Box>
                {therapistPublicProfile && (
                  <BookMassageButton
                    booking={booking}
                    size={ButtonSize.small}
                    width="150px"
                    preferredTherapist={therapistPublicProfile}
                    preferredTherapistId={therapistId}
                    source={BookMassageButtonSource.PastBookings}
                  />
                )}
              </Box>
            </>
          ) : (
            <Box height="24px" />
          )}
        </Box>
      </Box>
      <Popover
        id={"booking-details-therapist-popover"}
        open={popoverAnchorEl !== null}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" width="280px">
          {therapistPublicProfile?.isFavorite === false && (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title="Add to favourites"
                onClick={() => {
                  setPopoverAnchorEl(null);

                  axios
                    .post(`/favorite/${therapistId}`, {
                      accessToken,
                    })
                    .then((response) => {
                      therapistPublicProfileMutate();

                      setSuccessMessage("Added this therapist to favourites");
                    })
                    .catch((error) => {
                      setErrorMessage(parseApiError(error));
                    });
                }}
              />
              <PopoverItemSeparator />
            </>
          )}

          {therapistPublicProfile?.isFavorite === true && (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title="Remove from favourites"
                onClick={() => {
                  setPopoverAnchorEl(null);

                  axios
                    .delete(`/favorite/${therapistId}`, {
                      data: {
                        accessToken,
                      },
                    })
                    .then((response) => {
                      therapistPublicProfileMutate();

                      setSuccessMessage("Removed this therapist from favourites");
                    })
                    .catch((error) => {
                      setErrorMessage(parseApiError(error));
                    });
                }}
              />
              <PopoverItemSeparator />
            </>
          )}

          {shareSheet.isSupported && (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title="Recommend to a friend"
                onClick={() => {
                  setPopoverAnchorEl(null);

                  shareSheet.share({
                    title: "Here's $20 off your first Blys Massage!",
                    text: `My fav Blys therapist! Book with my code ${user?.referralCode} for $20 off`,
                    url: getProfileLink(therapistPublicProfile?.therapistData),
                  });
                }}
              />
              <PopoverItemSeparator />
            </>
          )}

          {therapistPublicProfile?.isBlocked === false && (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title={`Block ${therapistPublicProfile?.therapistData?.firstName || "therapist"}`}
                onClick={() => {
                  setPopoverAnchorEl(null);

                  axios
                    .post(`/blockTherapist/${therapistId}`, {
                      accessToken,
                      blockReason: "Blocked",
                    })
                    .then((response) => {
                      therapistPublicProfileMutate();
                      setSuccessMessage("Therapist blocked");
                    })
                    .catch((error) => {
                      console.debug("error: ", error);

                      alert(error);
                    });
                }}
              />
            </>
          )}

          {therapistPublicProfile?.isBlocked === true && (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title={`Unblock ${therapistPublicProfile?.therapistData?.firstName || "therapist"}`}
                onClick={() => {
                  setPopoverAnchorEl(null);

                  axios
                    .delete(`/blockTherapist/${therapistId}`, {
                      data: {
                        accessToken,
                      },
                    })
                    .then((response) => {
                      therapistPublicProfileMutate();
                      setSuccessMessage("Therapist unblocked");
                    })
                    .catch((error) => {
                      alert(error);
                    });
                }}
              />
            </>
          )}

          <PopoverItemSeparator />
          <PopoverItem
            type={PopoverItemType.default}
            title={`Report ${getValue(
              therapistPublicProfile,
              "therapistData.firstName",
              "therapist"
            )}`}
            onClick={reportTherapist}
          />
          <ComplaintModal
            complaintFor={parseInt(getValue(therapistPublicProfile, "therapistData.id"))}
            open={showComplaintModal}
            onClose={() => {
              setShowComplaintModal(false);
              setPopoverAnchorEl(null);
            }}
          />
        </Box>
      </Popover>
    </>
  );
}
