import { Box, ButtonBase } from "@material-ui/core";
import { isNil } from "lodash";
import React from "react";
import { getValue } from "../utils/object";
import { Colors } from "../constants/colors";
import { environment } from "../constants/common";
import { stringForAddress } from "../helpers/address";
import { extractLocationData, getSearchResults, GooglePlaceSearchResult } from "../helpers/google";
import { useAccessToken } from "../hooks/common";
import { useMobile } from "../hooks/mobile";
import AddressLocationPin from "../images/address-location-pin.svg";
import SelectLocationPin from "../images/map-pin.png";
import SavedAddressLocationPin from "../images/saved-address-location-pin.svg";
import { Address } from "../models";
import { fetchAddresses, useAddressStore } from "../stores/address";
import { fetchLocationTypes, labelForLocationType } from "../stores/types";
import AddressModal from "./Addresses/AddressModal";
import { dropdownStylesCombined, StyledButtonBase } from "./Dropdown";
import { getCountryRestrictions } from "../services/countries/countries.services";
import { MIN_LENGTH_TO_AUTO_SEARCH } from "../constants/address";
import { Search } from "@mui/icons-material";
import { FontFamily, FontSize, FontWeight, Spacing } from "./v2/Styled/enum";
import { TextField, Typography } from "@mui/material";

export interface SelectedAddress {
  address: string;
  postcode: string;
  suburb: string;
  state?: string;
  country: string;
  type?: string;
  lat?: number;
  long?: number;
  fullAddress?: string;
  countryCode?: string;
}

interface Props {
  value: string | null;
  onChange: (value: string) => unknown;
  onSelectedAddress: (address: SelectedAddress) => unknown;
  showSavedAddresses?: boolean;
  locationType?: string | null; // To facilitate placeholder message
  title?: string;
  placeholder?: string;
  onMapSelected?: () => unknown;
  textFieldStyles?: React.CSSProperties;
}

export default function AddressAutocomplete({
  value,
  onChange,
  onSelectedAddress,
  showSavedAddresses = false,
  locationType,
  title = "Address",
  placeholder: placeholderOverride,
  onMapSelected,
  textFieldStyles,
}: Props): JSX.Element {
  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const [hasAddressSelected, setHasAddressSelected] = React.useState(false);
  const [hasFullAddressChanged, setHasFullAddressChanged] = React.useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = React.useState("");
  const [countryRestrictions, setCountryRestrictions] = React.useState([]);

  const [showDropdown, setShowDropdown] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<Array<GooglePlaceSearchResult>>([]);

  const { addresses } = useAddressStore();

  React.useEffect(() => {
    fetchAddresses();
  }, []);

  const [addressModalOpen, setAddressModalOpen] = React.useState(false);
  const [editingAddress, setEditingAddress] = React.useState<Address | null>(null);

  const _filteredSavedAddresses = () => {
    if (value && value.length >= MIN_LENGTH_TO_AUTO_SEARCH && addresses) {
      return addresses.filter((address) => {
        return (
          address.address.toLowerCase().includes(value.toLowerCase()) ||
          address.suburb.toLowerCase().includes(value.toLowerCase()) ||
          address.state?.toLowerCase().includes(value.toLowerCase()) ||
          address.postcode.toLowerCase().includes(value.toLowerCase()) ||
          address.country?.toLowerCase().includes(value.toLowerCase())
        );
      });
    }

    return [];
  };

  const filteredSavedAddresses = _filteredSavedAddresses();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchTimeoutRef = React.useRef<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
      if (hasFullAddressChanged && !hasAddressSelected) {
        setAddressErrorMessage("Please select an address from the dropdown");
      }
    }
  };

  const fetchCountryRestrictions = async () => {
    const data = await getCountryRestrictions();
    if (data) {
      const countryList = getValue(data, "countries", []);
      const restrictedCountries = environment.isProduction
        ? countryList
        : countryList.concat(["np"]);
      setCountryRestrictions(restrictedCountries);
    }
  };

  React.useEffect(() => {
    // Fetch location types
    fetchLocationTypes();
    fetchCountryRestrictions();
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hasFullAddressChanged, hasAddressSelected]);

  React.useEffect(() => {
    if (!isNil(value) && value.length >= MIN_LENGTH_TO_AUTO_SEARCH) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        getSearchResults(value, countryRestrictions)
          .then((results) => {
            setSearchResults(results);
          })
          .catch((error) => {
            console.debug("fetch place search error: ", error);
          });
      }, 1000);
    }
  }, [value]);

  const _placeholder = () => {
    if (placeholderOverride) {
      return placeholderOverride;
    }

    if (locationType === "hotel") {
      return "Hotel name or Street address";
    }

    if (locationType === "home") {
      return "House/unit number & street";
    }

    return "Street address";
  };

  const placeholder = _placeholder();

  const getFormattedFullAddress = (
    fullAddress: string,
    suggestedFullAddress: string,
    isStreetNumberAvailable: boolean
  ) => {
    if (isStreetNumberAvailable) {
      return fullAddress;
    }

    const extractedStreetNumber = suggestedFullAddress.match(/^[0-9]+/) ?? [];

    return extractedStreetNumber.length > 0
      ? `${extractedStreetNumber[0]} ${fullAddress}`
      : fullAddress;
  };

  const onSelectedSearchResult = async (result: GooglePlaceSearchResult) => {
    setShowDropdown(false);

    const locationData = await extractLocationData(result);
    if (locationData && locationData?.fullAddress) {
      const {
        fullAddress,
        country,
        countryCode,
        state,
        suburb,
        latitude,
        longitude,
        postcode,
        address,
        streetNumber,
      } = locationData;
      setHasAddressSelected(true);
      setAddressErrorMessage("");
      setHasFullAddressChanged(false);
      onSelectedAddress({
        address: address.trim(),
        postcode,
        suburb,
        state,
        country,
        countryCode,
        lat: latitude,
        long: longitude,
        fullAddress: getFormattedFullAddress(
          fullAddress,
          result.formatted_address,
          streetNumber !== ""
        ),
      });
    }
  };

  return (
    <>
      <div ref={containerRef}>
        <Box position="relative">
          <Typography
            style={{
              fontFamily: FontFamily.Museo,
              fontWeight: FontWeight.Bold,
              fontSize: FontSize.F16,
              lineHeight: Spacing.S6,
              color: Colors.DarkSteelGrey,
              marginBottom: Spacing.S4,
            }}
          >
            {title}
          </Typography>
          <TextField
            title={title}
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                fontFamily: FontFamily.Museo,
                fontSize: FontSize.F16,
                color: !!addressErrorMessage ? Colors.Danger : Colors.TurquoiseBlue,
                borderRadius: "100px",
                height: isMobile ? "26px" : "56px",
                paddingLeft: isMobile ? "" : Spacing.S5,
                paddingRight: isMobile ? Spacing.S3 : Spacing.S5,
                marginBottom: Spacing.S4,
                "& fieldset": isMobile
                  ? { border: "none" }
                  : {
                      border: `1px solid ${Colors.LightPeriwinkle}`,
                    },
                "&:hover fieldset": {
                  borderColor: Colors.TurquoiseBlue,
                },
                "&.Mui-focused fieldset": {
                  borderColor: Colors.TurquoiseBlue,
                },
              },
              "& .MuiOutlinedInput-input": {
                "&::placeholder": {
                  color: Colors.LightPeriwinkle, // Custom placeholder color
                  opacity: 1, // Ensures the placeholder is fully visible
                },
              },
              ...textFieldStyles,
            }}
            InputProps={{
              startAdornment: isMobile ? undefined : (
                <Search height={16} width={16} style={{ marginRight: 8, color: Colors.Grey }} />
              ),
            }}
            size="small"
            titleFontSize="16px"
            onChange={(event) => {
              onChange(event.target.value);
              setHasFullAddressChanged(true);
              setHasAddressSelected(false);
              setAddressErrorMessage("");
            }}
            value={value}
            onFocus={() => {
              setShowDropdown(true);
            }}
            multiline={true}
            onBlur={() => {}}
          />
          {!!addressErrorMessage && (
            <Box
              marginTop="-15px"
              marginBottom={Spacing.S4}
              textAlign={"center"}
              color={Colors.Danger}
              fontFamily="Open Sans"
              fontSize="14px"
            >
              {addressErrorMessage}
            </Box>
          )}

          {showDropdown && (
            <Box position="absolute" style={dropdownStylesCombined}>
              {showSavedAddresses &&
                filteredSavedAddresses.map((address) => (
                  <>
                    <Box display="flex" flexDirection="row">
                      <StyledButtonBase
                        onClick={() => {
                          setShowDropdown(false);

                          onSelectedAddress(address);
                        }}
                      >
                        <img
                          src={SavedAddressLocationPin}
                          style={{ marginLeft: "21px", marginRight: "21px" }}
                          alt="location pin"
                        />
                        <Box display="flex" flexDirection={isMobile ? "column" : "row"} flex={1}>
                          <Box
                            fontFamily="Open Sans"
                            fontSize="16px"
                            fontWeight={700}
                            color={Colors.NightBlue}
                            textAlign="left"
                          >
                            {labelForLocationType(address.type) || address.type}
                          </Box>
                          <Box
                            fontFamily="Open Sans"
                            fontSize="16px"
                            color={Colors.Dusk}
                            textAlign="left"
                            fontWeight={600}
                          >
                            &nbsp;- {stringForAddress(address)}
                          </Box>
                        </Box>
                        <ButtonBase
                          onClick={(event) => {
                            event.stopPropagation();

                            setShowDropdown(false);

                            setEditingAddress(address);
                            setAddressModalOpen(true);
                          }}
                        >
                          <Box
                            fontFamily="Museo"
                            fontWeight={500}
                            fontSize="16px"
                            textAlign="right"
                            color={Colors.BlueyGrey}
                            mr="20px"
                            width="60px"
                          >
                            Edit
                          </Box>
                        </ButtonBase>
                      </StyledButtonBase>
                    </Box>

                    <Box width="100%" height="1px" bgcolor={Colors.LightPeriwinkle} />
                  </>
                ))}

              {searchResults.map((result, index) => (
                <>
                  <Box display="flex" flexDirection="row">
                    <StyledButtonBase onClick={() => onSelectedSearchResult(result)}>
                      <img
                        src={AddressLocationPin}
                        alt="Location pin"
                        style={{ marginLeft: "21px", marginRight: "21px" }}
                      />
                      <Box
                        fontFamily="Open Sans"
                        fontSize="16px"
                        color={Colors.Dusk}
                        textAlign="left"
                        flex={1}
                        fontWeight={600}
                        mr="16px"
                      >
                        {result.formatted_address}
                      </Box>
                      {showSavedAddresses && (
                        <ButtonBase
                          onClick={(event) => {
                            event.stopPropagation();
                            onSelectedSearchResult(result);
                          }}
                        >
                          <Box
                            fontFamily="Museo"
                            fontWeight={500}
                            fontSize="16px"
                            textAlign="right"
                            color={Colors.TurquoiseBlue}
                            mr="20px"
                            width="60px"
                          >
                            Save
                          </Box>
                        </ButtonBase>
                      )}
                    </StyledButtonBase>
                  </Box>

                  {index !== searchResults.length && (
                    <Box width="100%" height="1px" bgcolor={Colors.LightPeriwinkle} />
                  )}
                </>
              ))}

              <Box display="flex" flexDirection="row">
                <StyledButtonBase onClick={onMapSelected && onMapSelected}>
                  <img
                    src={SelectLocationPin}
                    style={{
                      marginLeft: "21px",
                      marginRight: "21px",
                      width: "24px",
                      height: "24px",
                    }}
                    alt="location-pin"
                  />
                  <Box
                    fontFamily="Open Sans"
                    fontSize="16px"
                    color={Colors.Dusk}
                    textAlign="left"
                    flex={1}
                    fontWeight={600}
                    mr="16px"
                  >
                    Set location on map
                  </Box>
                </StyledButtonBase>
              </Box>
            </Box>
          )}
        </Box>
      </div>
      <AddressModal
        open={addressModalOpen}
        existingAddress={editingAddress}
        onClose={() => setAddressModalOpen(false)}
        onSaved={(address) => {
          setAddressModalOpen(false);
          onSelectedAddress(address);
          fetchAddresses();
        }}
      />
    </>
  );
}
