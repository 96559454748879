/* eslint-disable react-hooks/rules-of-hooks */
import { Box, ButtonBase, CircularProgress, Drawer, Popover } from "@material-ui/core";
import axios from "axios";
import { ceil, isEmpty, isNil } from "lodash";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { deleteApi, post } from "../api/client";
import BookMassageButton, { BookMassageButtonSource } from "../components/BookMassageButton";
import Button, { ButtonSize, ButtonType } from "../components/Button";
import LoginModal from "../components/LoginModal";
import PopoverItem, { PopoverItemType } from "../components/PopoverItem";
import FavouriteButton from "../components/TherapistProfile/FavouriteButton";
import RecentReviewItem from "../components/TherapistProfile/RecentReviewItem";
import { Colors } from "../constants/colors";
import { BASE_UPLOADS_URL } from "../constants/common";
import { Paths } from "../constants/paths";
import { ProFileOwnership } from "../constants/prodashboard";
import { TrackingEvents } from "../constants/tracking";
import { parseApiError } from "../helpers/error";
import { genericBioForTherapistPublicProfile, getTherapistSkills } from "../helpers/therapist";
import { withVersion } from "../helpers/tracking";
import { useAccessToken } from "../hooks/common";
import { useMobile } from "../hooks/mobile";
import useShareSheet from "../hooks/shareSheet";
import CloseIcon from "../images/cross.svg";
import Background from "../images/pro-profile-banner.svg";
import MoreIcon from "../images/therapists-more.svg";
import VaccinatedIcon from "../images/vaccinated_logo.png";
import CheckedIcon from "../images/checked-icon.svg";
import { Booking, PaginatedResponse, TherapistPublicProfile, UserReview } from "../models";
import { trackEvent } from "../services/segment/track.service";
import { getProfileLink } from "../services/therapist/therapist.service";
import { useAlertStore } from "../stores/alert";
import { useBookingStore } from "../stores/booking";
import { useUserStore } from "../stores/user";
import AvatarImageWithCovidStatus, { ImageSize } from "./AvatarImageWithCovidStatus";
import ComplaintModal from "./Complaint/ComplaintModal";
import Dialog from "./Dialog";
import Ratings from "./Ratings";
import AvatarName from "./TherapistProfile/AvatarName";
import SkillPill from "./TherapistProfile/SkillPill";
import TherapistPortfolio from "./TherapistProfile/TherapistPortfolio";
import "./TherapistProfileDrawer.css";
import TitleMetaChange from "./TitleMetaChange";
import PopoverItemSeparator from "./PopoverItemSeparator";
import LicenseBadge from "./Badges/LicenseBadge";
import { getValue } from "../utils/object";
import { ProfileBadge } from "./ProfileBadge";

interface Props {
  open: boolean;
  therapistId: number | null;
  onClose?: () => unknown;
  mutateKey?: string;
  type?: string;
  showLicenseNumber?: boolean;
}

// const pageSize = 2;

// const paginate = (array: Array<any>, pageNumber: number) => {
//   return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
// };

export enum TherapistProfileType {
  dialog,
  drawer,
  publicLink,
}

const perPage = 20;

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
  open,
  therapistId,
  onClose,
  mutateKey,
  type = "drawer",
  showLicenseNumber = false,
}: Props) {
  const isMobile = useMobile();

  useEffect(() => {
    if (open && !isNil(therapistId)) {
      trackEvent(
        TrackingEvents.TherapistProfileViewed,
        withVersion({
          therapistId,
        })
      );
    }
  }, [open, therapistId]);

  if (type === "drawer") {
    return (
      <Drawer
        open={open}
        anchor="left"
        onClose={onClose}
        PaperProps={{ style: { maxWidth: isMobile ? "100%" : "1006px" } }}
      >
        <TherapistProfile
          type={TherapistProfileType.drawer}
          therapistId={therapistId}
          onClose={onClose}
          mutateKey={mutateKey}
          showLicenseNumber={showLicenseNumber}
        />
      </Drawer>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile} maxWidth="lg">
      <Box
        maxWidth={isMobile ? "100%" : "1006px"}
        height={isMobile ? "100%" : "1092px"}
        position="relative"
      >
        <TherapistProfile
          type={TherapistProfileType.dialog}
          therapistId={therapistId}
          onClose={onClose}
          showLicenseNumber={showLicenseNumber}
          mutateKey={mutateKey}
        />
      </Box>
    </Dialog>
  );
}

interface TherapistProfileProps {
  type: TherapistProfileType;
  therapistId: number | null;
  mutateKey?: string;
  onClose?: () => unknown;
  closable?: boolean;
  showLicenseNumber?: boolean;
}

export function TherapistProfile({
  type,
  therapistId,
  mutateKey,
  onClose,
  closable = true,
  showLicenseNumber = false,
}: TherapistProfileProps): JSX.Element {
  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const history = useHistory();

  const { user, fetchMe } = useUserStore();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { setPreferredTherapists, setLastBooking } = useBookingStore();
  const [showComplaintModal, setShowComplaintModal] = useState(false);

  const { data, mutate: publicProfileMutate } = useSWR(
    accessToken
      ? `/therapists/${therapistId}/publicProfile?accessToken=${accessToken}&type=${type}`
      : `/therapists/${therapistId}/guestPublicProfile`
  );

  const therapistPublicProfile = data as TherapistPublicProfile;

  const rating = therapistPublicProfile?.therapistData?.therapistprofile?.rating || 0;

  const numberOfReviews = therapistPublicProfile?.totalReviews || 0;

  const therapistProfilePictureUrl = therapistPublicProfile?.therapistData?.therapistprofile
    ?.profileImage
    ? `${BASE_UPLOADS_URL}/${therapistPublicProfile.therapistData.therapistprofile.profileImage}`
    : null;

  const name = therapistPublicProfile
    ? `${therapistPublicProfile.therapistData?.firstName}`
    : "Therapist";

  const _bio = () => {
    if (!therapistPublicProfile || therapistPublicProfile.isSuspended) {
      return null;
    }

    return genericBioForTherapistPublicProfile(therapistPublicProfile);
  };

  const bio = !isEmpty(therapistPublicProfile?.therapistData?.therapistprofile?.bio)
    ? therapistPublicProfile.therapistData.therapistprofile.bio
    : _bio();

  const kit = !isEmpty(therapistPublicProfile?.therapistData?.therapistprofile?.kit)
    ? therapistPublicProfile.therapistData.therapistprofile.kit
    : "";

  const firstName = therapistPublicProfile?.therapistData?.firstName || "Therapist";

  const portfolioFiles = !isEmpty(therapistPublicProfile?.therapistData?.useruploadedfiles)
    ? therapistPublicProfile.therapistData.useruploadedfiles.filter(
        (file) => file.type === ProFileOwnership.portfolio
      )
    : [];

  // const [city, setCity] = React.useState("");
  // const [currentReviewsPage, setCurrentReviewsPage] = React.useState(0);

  // const recentReviews = therapistPublicProfile?.recentReviews || [];

  // const reviewsPages = Math.ceil(recentReviews.length / pageSize);

  // const reviewsForCurrentPage = paginate(recentReviews, currentReviewsPage);

  React.useEffect(() => {
    if (mutateKey) {
      publicProfileMutate();
    }
  }, [mutateKey]);

  React.useEffect(() => {
    fetchMe();
  }, []);

  const shareSheet = useShareSheet();

  const [recentReviews, setRecentReviews] = React.useState<UserReview[]>([]);
  const [currentReviewsPage, setCurrentReviewsPage] = React.useState<number>(0);
  const [reviewsPageCount, setReviewsPageCount] = React.useState<number>(1);
  const [fetchingReviews, setFetchingReviews] = React.useState(false);

  React.useEffect(() => {
    setCurrentReviewsPage(0);
    setReviewsPageCount(1);
    setRecentReviews([]);
  }, [therapistId]);

  React.useEffect(() => {
    if (currentReviewsPage === 0) {
      therapistId && fetchMoreReviews();
    }
  }, [therapistId, currentReviewsPage]);

  const fetchMoreReviews = () => {
    if (currentReviewsPage < reviewsPageCount) {
      setFetchingReviews(true);

      console.debug("currentReviewsPage: ", currentReviewsPage);

      const nextPage: number = currentReviewsPage + 1;

      console.debug("nextPage: ", nextPage);

      const data = {
        admin: true,
        currentPage: nextPage,
        perPage,
        search: {
          therapistId,
        },
      };

      console.debug("data: ", data);

      axios
        .get(`/reviews/${therapistId}`, {
          params: data,
        })
        .then((response) => {
          setFetchingReviews(false);

          const moreReviews = response.data.reviewsList as UserReview[];
          const paginatedResponse = response.data as PaginatedResponse;

          setRecentReviews(recentReviews.concat(moreReviews));
          setCurrentReviewsPage(+paginatedResponse.currentPage);
          setReviewsPageCount(ceil(+paginatedResponse.objectCount / perPage));
        })
        .catch((error) => {
          setFetchingReviews(false);

          setErrorMessage(parseApiError(error));
        });
    }
  };

  const getTherapistAddress = (addresses: any[]) => {
    if (!addresses || !addresses.length)
      return {
        suburb: "",
        state: "",
      };

    return addresses[addresses.length - 1];
  };

  const specialty = getTherapistSkills(therapistPublicProfile?.therapistData?.userservices);

  const { suburb: therapistSuburb, state: therapistState } = getTherapistAddress(
    therapistPublicProfile?.therapistData?.addresses
  );
  const totalCompletedJobs =
    therapistPublicProfile?.therapistData?.therapistprofile?.totalCompletedJobs;
  const imgURL = therapistPublicProfile?.therapistData?.therapistprofile?.profileImage;
  const licenseNumber = getValue(
    therapistPublicProfile,
    "therapistData.therapistprofile.abn",
    null
  );
  const usProvider = getValue(therapistPublicProfile, "therapistData.country", "") === "US";
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [checkoutOnLoginSuccess, setCheckoutOnLoginSuccess] = React.useState(false);
  const [checkingOut, setCheckingOut] = React.useState(false);

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleBlockTherapist = () => {
    setPopoverAnchorEl(null);
    post(`blockTherapist/${therapistId}`, { blockReason: "Blocked" })
      .then((response) => {
        publicProfileMutate();
        setSuccessMessage("Therapist blocked");
      })
      .catch((error) => {
        console.debug("error: ", error);
        alert(error);
      });
  };

  const handleUnblockTherapist = () => {
    setPopoverAnchorEl(null);
    deleteApi(`blockTherapist/${therapistId}`)
      .then((response) => {
        publicProfileMutate();
        setSuccessMessage("Therapist unblocked");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const reportTherapist = () => {
    setShowComplaintModal(true);
  };

  const showBadge = () => {
    return (
      therapistPublicProfile?.therapistData.isCovidVaccinated ||
      Object.keys(therapistPublicProfile?.industryVerifications ?? {}).some(
        (key) => therapistPublicProfile?.industryVerifications[key]
      )
    );
  };

  useEffect(() => {
    return () => {
      document.title = 'Book Wellness At Home | Blys';
    };
  }, []);

  if (checkingOut) {
    return <CircularProgress style={{ color: Colors.TurquoiseBlue }} />;
  }

  return (
    <>
      <TitleMetaChange
        titleText={`${therapistPublicProfile?.therapistData?.firstName} - Mobile Massage and Beauty Therapist in ${therapistSuburb}`}
        imgUrl={`${BASE_UPLOADS_URL}/${imgURL}`}
        descText={`Experienced at-home massage and beauty therapist in ${therapistSuburb},  ${therapistState} with ${totalCompletedJobs} bookings. ${therapistPublicProfile?.therapistData?.firstName} specialises in ${specialty}.`}
      />
      <Box
        maxWidth={isMobile ? "100%" : "1006px"}
        display="flex"
        flexDirection="column"
        alignItems={isMobile ? undefined : "center"}
        paddingTop="80px"
        paddingBottom="130px"
        position="relative"
      >
        <Box
          position="absolute"
          top="0px"
          height="145px"
          width="100%"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        />

        {closable && (
          <Box position="absolute" p="12px" m="16px" top="0px" left={0}>
            <Box
              width="24px"
              height="24px"
              onClick={onClose}
              style={{ cursor: "pointer" }}
              zIndex={999}
            >
              <img src={CloseIcon} alt="close" />
            </Box>
          </Box>
        )}

        <Box>
          {therapistPublicProfile?.isSuspended && (
            <h4 style={{ marginTop: "150px" }}>
              This provider is not available on the Blys platform. Please book another provider for
              your appointment.
            </h4>
          )}
          {!therapistPublicProfile?.isSuspended && (
            <Box px={"48px"} maxWidth={isMobile ? "100%" : "910px"}>
              <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                {therapistProfilePictureUrl ? (
                  <AvatarImageWithCovidStatus
                    size={ImageSize.large}
                    imageSrc={therapistProfilePictureUrl as any}
                    isCovidVaccinated={therapistPublicProfile?.therapistData?.isCovidVaccinated}
                    whiteBorderImage={true}
                  />
                ) : (
                  <AvatarName
                    width={isMobile ? "96px" : "125px"}
                    height={isMobile ? "96px" : "125px"}
                    firstName={therapistPublicProfile?.therapistData?.firstName}
                    lastName={therapistPublicProfile?.therapistData?.lastName}
                    fontSize="36px"
                    style={{ border: "3px solid white" }}
                  />
                )}
                <ButtonBase
                  onClick={(event) => {
                    setPopoverAnchorEl(event.currentTarget as HTMLElement);
                  }}
                  disableTouchRipple={true}
                >
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <img src={MoreIcon} alt={"more icon"} />
                  </Box>
                </ButtonBase>
              </Box>
              <Box display="flex" flexDirection="row" mt={2} mb={4}>
                <Box display="flex" flexDirection="column" flex={1} style={{ gap: "6px" }}>
                  <Box fontFamily="Museo" fontWeight={700} color={Colors.NightBlue} fontSize="30px">
                    {name}
                  </Box>
                  {licenseNumber && showLicenseNumber && usProvider && (
                    <LicenseBadge licenseNumber={licenseNumber} />
                  )}
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  {!!rating && (
                    <>
                      <Box display="flex" flexDirection="row">
                        <Box
                          fontFamily="Open Sans"
                          fontWeight={600}
                          fontSize="16px"
                          color={Colors.Dusk}
                          mr="8px"
                        >
                          {rating.toFixed(1)}
                        </Box>
                        <Ratings rating={rating} width="19.8px" height="18.9px" />
                      </Box>
                      <Box fontFamily="Open Sans" fontSize="12px" color={Colors.Dusk} mt="4px">
                        ({numberOfReviews} Reviews)
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
                {(type === TherapistProfileType.drawer || type === TherapistProfileType.dialog) && (
                  <BookMassageButton
                    size={ButtonSize.large}
                    preferredTherapist={therapistPublicProfile}
                    preferredTherapistId={therapistId}
                    source={BookMassageButtonSource.TherapistProfileSlideIn}
                    onBookPress={type === TherapistProfileType.dialog ? onClose : undefined}
                  />
                )}

                {type === TherapistProfileType.publicLink && isNil(accessToken) && (
                  <Button
                    width="150px"
                    type={ButtonType.secondary}
                    size={ButtonSize.large}
                    title={`Book ${
                      therapistPublicProfile?.therapistData?.firstName || "therapist"
                    }`}
                    onClick={() => {
                      // Show login modal
                      // setLoginModalOpen(true);
                      setCheckoutOnLoginSuccess(true);
                      if (therapistPublicProfile) {
                        setPreferredTherapists([therapistPublicProfile]);
                      }
                      return history.push(Paths.NewBooking);
                    }}
                  />
                )}

                {type === TherapistProfileType.publicLink && !isNil(accessToken) && (
                  <BookMassageButton
                    size={ButtonSize.large}
                    preferredTherapist={therapistPublicProfile}
                    preferredTherapistId={therapistId}
                    rebookText="Book"
                  />
                )}

                <Box width="16px" height="16px" />
                <Box display="flex" flexDirection="row">
                  {shareSheet.isSupported && user?.referralCode && (
                    <Box mr={1.5}>
                      <Button
                        type={ButtonType.outlined}
                        size={ButtonSize.large}
                        style={{ padding: "12px 24px" }}
                        title="Recommend to a friend"
                        onClick={() => {
                          if (isNil(accessToken)) {
                            setLoginModalOpen(true);
                            return;
                          }

                          shareSheet.share({
                            title: "Here's $20 off your first Blys Massage!",
                            text: `My fav Blys therapist! Book with my code ${user?.referralCode} for $20 off`,
                            url: getProfileLink(therapistPublicProfile.therapistData),
                          });
                        }}
                      />
                    </Box>
                  )}

                  <FavouriteButton
                    favourited={therapistPublicProfile?.isFavorite === true}
                    buttonStyle={{ height: "48px", padding: "12px 24px", width: "auto" }}
                    onClick={() => {
                      if (isNil(accessToken)) {
                        setLoginModalOpen(true);
                        return;
                      }

                      if (therapistPublicProfile?.isFavorite === true) {
                        axios
                          .delete(`/favorite/${therapistId}`, {
                            data: {
                              accessToken,
                            },
                          })
                          .then((response) => {
                            publicProfileMutate();
                          })
                          .catch((error) => {
                            setErrorMessage(parseApiError(error));
                          });
                      } else {
                        axios
                          .post(`/favorite/${therapistId}`, {
                            accessToken,
                          })
                          .then((response) => {
                            publicProfileMutate();
                          })
                          .catch((error) => {
                            setErrorMessage(parseApiError(error));
                          });
                      }
                    }}
                  />
                </Box>
              </Box>

              <Box
                fontFamily="Museo"
                fontWeight={500}
                color={Colors.Dusk}
                lineHeight="26px"
                fontSize="18px"
                mt="35px"
                mb="35px"
              >
                {bio}
              </Box>
              <Box height="2px" width="100%" bgcolor={Colors.VeryLightBlue} />

              {kit ? (
                <>
                  <Box
                    fontFamily="Museo"
                    fontWeight={700}
                    fontSize="24px"
                    color={Colors.NightBlue}
                    mt={5}
                    mb={4}
                  >
                    {firstName}'s kit
                  </Box>

                  <Box
                    fontFamily="Museo"
                    fontWeight={500}
                    color={Colors.Dusk}
                    lineHeight="26px"
                    fontSize="18px"
                    mt={4}
                    mb={6}
                  >
                    {kit}
                  </Box>
                </>
              ) : (
                <></>
              )}
              {portfolioFiles.length > 0 ? (
                <TherapistPortfolio firstName={firstName} files={portfolioFiles} />
              ) : (
                <></>
              )}

              {!!kit || portfolioFiles.length ? (
                <Box height="2px" width="100%" bgcolor={Colors.VeryLightBlue} mt={5} />
              ) : (
                <></>
              )}

              <Box
                fontFamily="Museo"
                fontWeight={700}
                fontSize="24px"
                color={Colors.NightBlue}
                mt={4}
                mb={4}
              >
                {therapistPublicProfile?.therapistData.firstName || "Therapist"}
                's skills
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                // justifyContent="space-between"
              >
                {therapistPublicProfile?.userServices.map((service) => (
                  <SkillPill service={service} />
                ))}
              </Box>

              <Box height="2px" width="100%" bgcolor={Colors.VeryLightBlue} mt={5} />

              {showBadge() && (
                <>
                  <Box
                    fontFamily="Museo"
                    fontWeight={700}
                    fontSize="24px"
                    color={Colors.NightBlue}
                    mt={4}
                    mb={4}
                  >
                    {therapistPublicProfile?.therapistData.firstName || "Therapist"}
                    's badges
                  </Box>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" gridGap={"15px"}>
                    {therapistPublicProfile?.therapistData.isCovidVaccinated && (
                      <ProfileBadge text="Covid-19 Vaccinated" imageSrc={VaccinatedIcon} />
                    )}

                    {Object.keys(therapistPublicProfile?.industryVerifications ?? {})
                      .filter((key) => therapistPublicProfile?.industryVerifications[key])
                      .map((key) => (
                        <ProfileBadge
                          key={key}
                          text={`${key.toUpperCase()} Registered`}
                          imageSrc={CheckedIcon}
                        />
                      ))}
                  </Box>
                  <Box height="2px" width="100%" bgcolor={Colors.VeryLightBlue} mt={5} />
                </>
              )}

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mt={4}
                mb={1}
              >
                <Box
                  fontFamily="Museo"
                  fontWeight={700}
                  fontSize="24px"
                  color={Colors.NightBlue}
                  marginBottom="35.5px"
                >
                  {recentReviews.length === 0 ? "No reviews yet" : "Recent reviews"}
                </Box>
                {/* <PaginationControl
                pages={reviewsPages}
                currentPage={currentReviewsPage}
                onPrevious={() => {
                  if (currentReviewsPage > 0) {
                    setCurrentReviewsPage(currentReviewsPage - 1);
                  }
                }}
                onNext={() => {
                  if (currentReviewsPage < reviewsPages - 1) {
                    setCurrentReviewsPage(currentReviewsPage + 1);
                  }
                }}
                textHidden
              /> */}
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {recentReviews.map((review) => (
                  <RecentReviewItem review={review} isMobile={isMobile} />
                ))}
              </Box>

              {currentReviewsPage < reviewsPageCount && !fetchingReviews && (
                <Box mt={3}>
                  <Button
                    title="Load more"
                    type={ButtonType.secondary}
                    onClick={() => {
                      fetchMoreReviews();
                    }}
                  />
                </Box>
              )}
              {fetchingReviews && <CircularProgress style={{ color: Colors.TurquoiseBlue }} />}
            </Box>
          )}
        </Box>

        <LoginModal
          open={loginModalOpen}
          onClose={() => setLoginModalOpen(false)}
          onSuccess={(accessToken) => {
            localStorage.setItem("token", accessToken);

            setLoginModalOpen(false);

            publicProfileMutate();

            if (checkoutOnLoginSuccess) {
              setCheckingOut(true);

              axios
                .get("/bookings/last?channel=web", {
                  params: {
                    accessToken: localStorage.getItem("token"),
                  },
                })
                .then((response) => {
                  setCheckingOut(false);

                  const lastBooking = response.data as Booking;

                  if (lastBooking) {
                    setLastBooking(lastBooking);
                    setPreferredTherapists([therapistPublicProfile]);

                    history.push(Paths.ReviewAndBook);
                  } else {
                    setPreferredTherapists([therapistPublicProfile]);
                    history.push(Paths.NewBooking);
                  }
                })
                .catch((error) => {
                  setCheckingOut(false);

                  // Cannot fetch last booking, just go to new booking flow
                  setPreferredTherapists([therapistPublicProfile]);
                  history.push(Paths.NewBooking);
                });
            }
          }}
          subtitle="Please login to proceed"
        />

        <Popover
          id={"fav-therapist-popover"}
          open={popoverAnchorEl !== null}
          anchorEl={popoverAnchorEl}
          onClose={() => setPopoverAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box display="flex" flexDirection="column" width="200px">
            {therapistPublicProfile?.isBlocked === false && (
              <PopoverItem
                type={PopoverItemType.default}
                title={`Block ${therapistPublicProfile?.therapistData?.firstName || "therapist"}`}
                onClick={handleBlockTherapist}
              />
            )}

            {therapistPublicProfile?.isBlocked === true && (
              <PopoverItem
                type={PopoverItemType.default}
                title={`Unblock ${therapistPublicProfile?.therapistData?.firstName || "therapist"}`}
                onClick={handleUnblockTherapist}
              />
            )}
            <PopoverItemSeparator />

            <PopoverItem
              type={PopoverItemType.default}
              title={`Report ${therapistPublicProfile?.therapistData?.firstName || "therapist"}`}
              onClick={reportTherapist}
            />
          </Box>
        </Popover>
        <ComplaintModal
          complaintFor={parseInt(therapistPublicProfile?.therapistData?.id)}
          open={showComplaintModal}
          onClose={() => {
            setShowComplaintModal(false);
            setPopoverAnchorEl(null);
          }}
        />
      </Box>
    </>
  );
}
