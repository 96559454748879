import { Box } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import React, { useState, useEffect } from "react";
import useSWR from "swr";
import AddNewCardModal from "../components/Payment/AddNewCardModal";
import AddPaymentMethod from "../components/Payment/AddPaymentMethod";
import PaymentItem from "../components/Payment/PaymentItem";
import SectionPageHeader from "../components/SectionPageHeader";
// import TextLink from "../components/TextLink";
import AdditionalBillingIdentifier from "../components/AdditionalBillingIdentifiers";
import BillingDetails from "../components/BillingDetails/billingDetails";
import AddNewCardModelStripe from "../components/Payment/AddNewCardModelStripe";
import Wrapper from "../components/Wrapper";
import { Colors } from "../constants/colors";
import { TrackingEvents } from "../constants/tracking";
import { BRAINTREE_COUNTRIES } from "../data/enums";
import { parseApiV2Error } from "../helpers/error";
import { withVersion } from "../helpers/tracking";
import { useMobile } from "../hooks/mobile";
import { useAllowedPayments } from "../hooks/payment.hooks";
import BraintreeSecuredLockIcon from "../images/braintree-secured-lock.svg";
import { PaymentMethod } from "../models";
import { trackEvent } from "../services/segment/track.service";
import { useAlertStore } from "../stores/alert";
import { useUserStore } from "../stores/user";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../constants/paths";
import Billing from "./Payment/Billing";
import { useUserRole } from "../hooks/user.hooks";
import pixelsService from "../services/pixels/pixels.service";

const sectionsItems = {
  PaymentMethod: "Payment methods",
  Billings: "Billing",
};

const getQueryParams = (search: string) => {
  return new URLSearchParams(search);
};

export default function Payment(): JSX.Element {
  const isMobile = useMobile();

  const { user } = useUserStore();
  const history = useHistory();
  const location = useLocation();

  const { isB2BUser } = useUserRole();

  const queryParams = getQueryParams(location.search);
  const activeTab = queryParams.get("activeTab");

  const { isPaypalAllowed, isStripeAllowed } = useAllowedPayments(user);

  const [accessToken, setAccessToken] = useState(localStorage.getItem("token"));

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const [showAddNewCardModal, setShowAddNewCardModal] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(
    activeTab ? parseInt(activeTab, 10) : 0
  );

  const { data: paymentMethodsData, mutate: paymentMethodsMutate } = useSWR(
    `/paymentmethods?accessToken=${accessToken}`
  );
  const paymentMethods = paymentMethodsData ? (paymentMethodsData as [PaymentMethod]) : [];

  const paymentProvider = "Stripe";

  // React.useEffect(() => {
  //   if (!isNil(paymentMethodsData) && isEmpty(paymentMethods)) {
  //     setShowAddNewCardModal(true);
  //   }
  // }, [paymentMethodsData]);

  const noMethods = !isNil(paymentMethodsData) && isEmpty(paymentMethods);

  const sections = [sectionsItems.PaymentMethod];
  if (isB2BUser) {
    sections.push(sectionsItems.Billings);
  }

  useEffect(() => {
    pixelsService.trackPageView();
  }, []);

  return (
    <Wrapper
      header={
        <SectionPageHeader
          title="Payment"
          sections={sections}
          selectedSectionIndex={selectedSectionIndex}
          // subtitle="Manage your payment methods"
          onSelectedSectionIndex={(index) => {
            history.push(`${Paths.Payment}/?activeTab=${index}`);
            setSelectedSectionIndex(index);
          }}
        />
      }
      onLoggedIn={() => {
        setAccessToken(localStorage.getItem("token"));
      }}
    >
      <Box
        m={isMobile ? "16px" : undefined}
        mt={!isMobile ? "40px" : "40px"}
        ml={!isMobile ? "80px" : undefined}
        mr={!isMobile ? "80px" : undefined}
        mb="55px"
      >
        {selectedSectionIndex === 0 && (
          <>
            <Box maxWidth={isMobile ? undefined : "468px"}>
              {!noMethods && (
                <Box mb="48px">
                  <Box
                    fontFamily="Museo"
                    fontSize="16px"
                    lineHeight="24px"
                    fontWeight={600}
                    color={Colors.Dusk}
                  >
                    Saved payment methods
                  </Box>

                  <Box display="flex" flexDirection="row">
                    <img src={BraintreeSecuredLockIcon} />
                    <Box
                      fontFamily="Open Sans"
                      fontSize="14px"
                      color={Colors.BlueyGrey}
                      ml="4px"
                      mt="16px"
                      mb="16px"
                    >
                      Payments securely provided by {paymentProvider}
                    </Box>
                  </Box>
                  {paymentMethods.map((method: PaymentMethod) => (
                    <PaymentItem
                      paymentMethod={method}
                      onDeleteClicked={() => {
                        axios
                          .delete(`api/v2/payment-methods/${method.id}?accessToken=${accessToken}`)
                          .then((response) => {
                            paymentMethodsMutate();

                            setSuccessMessage("Payment method deleted");
                          })
                          .catch((error: any) => {
                            setErrorMessage(parseApiV2Error(error));
                          });
                      }}
                    />
                  ))}
                </Box>
              )}

              <AddPaymentMethod
                canAddPaypal={isPaypalAllowed}
                onPaypalAdded={() => {
                  paymentMethodsMutate();
                }}
                onAddCard={() => {
                  setShowAddNewCardModal(true);

                  trackEvent(TrackingEvents.AddCardClicked, withVersion());
                }}
              />
              <BillingDetails />
              <AdditionalBillingIdentifier />
            </Box>

            {user && isStripeAllowed ? (
              <>
                <AddNewCardModelStripe
                  open={showAddNewCardModal}
                  onClose={() => setShowAddNewCardModal(false)}
                  onAddedNewCard={() => {
                    paymentMethodsMutate();
                    setShowAddNewCardModal(false);
                  }}
                />
              </>
            ) : (
              <>
                <AddNewCardModal
                  open={showAddNewCardModal}
                  onClose={() => setShowAddNewCardModal(false)}
                  onAddedNewCard={() => {
                    paymentMethodsMutate();
                    setShowAddNewCardModal(false);
                  }}
                />
              </>
            )}
          </>
        )}

        {selectedSectionIndex === 1 && <Billing />}
      </Box>
    </Wrapper>
  );
}
