import React, { ReactNode } from "react";
import NewBookingFooter from "../NewBookingFooter";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import NewBookingProgressBar from "../NewBookingProgressBar";
import { useMobile, useTab } from "../../hooks/mobile";
import VoucherPriceBreakdownModal from "../Modals/VoucherPriceBreakdownModal/VoucherPriceBreakdownModal";

interface GiftVoucherFooterProps {
  children: ReactNode;
  noOfVoucher: number;
  totalPrice: number;
  currency: string;
  voucherPurchaseProgress: number;
}

const GiftVoucherFooter = ({
  children,
  noOfVoucher,
  totalPrice,
  currency,
  voucherPurchaseProgress,
}: GiftVoucherFooterProps) => {
  const isMobile = useMobile();
  const isTab = useTab();
  const showMobileAndTabFooter = isMobile || isTab;

  const footerHeight = showMobileAndTabFooter ? 85.0 : 81.0;
  const [showPriceBreakdownModal, setShowPriceBreakdownModal] = React.useState(false);

  const onClickTotalVoucherPrice = () => {
    if (totalPrice > 0) {
      setShowPriceBreakdownModal(true);
    }
  };

  return (
    <NewBookingFooter height={footerHeight}>
      <Box
        height={showMobileAndTabFooter ? "37px" : "100%"}
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        width="100%"
        style={{ borderTop: `1px solid ${Colors.LightPeriwinkle}` }}
        justifyContent={showMobileAndTabFooter ? "space-between" : "center"}
        paddingTop={showMobileAndTabFooter ? "24px" : "0px"}
        paddingBottom={showMobileAndTabFooter ? "24px" : "0px"}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          marginRight={showMobileAndTabFooter ? "0px" : "7px"}
          lineHeight={showMobileAndTabFooter ? "24px" : "27px"}
          marginLeft={showMobileAndTabFooter ? "24px" : "0px"}
        >
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize={showMobileAndTabFooter ? "16px" : "18px"}
            lineHeight={showMobileAndTabFooter ? "24px" : "27px"}
            color={Colors.BlueyGrey}
          >
            Qty:{noOfVoucher}
          </Box>
          <Box
            style={{
              width: showMobileAndTabFooter ? "4px" : "6px",
              height: showMobileAndTabFooter ? "4px" : "6px",
              backgroundColor: Colors.Dusk,
              borderRadius: "50%",
              marginLeft: showMobileAndTabFooter ? "4px" : "7px",
              marginRight: showMobileAndTabFooter ? "4px" : "7px",
            }}
          />
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize={showMobileAndTabFooter ? "16px" : "18px"}
            color={Colors.Dusk}
            lineHeight={showMobileAndTabFooter ? "24px" : "27px"}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={onClickTotalVoucherPrice}
          >
            {currency}
            {totalPrice.toFixed(2)}
          </Box>
        </Box>
        <Box
          width="650px"
          marginLeft="24px"
          marginRight="24px"
          visibility={showMobileAndTabFooter ? "hidden" : "visible"}
        >
          <NewBookingProgressBar progress={voucherPurchaseProgress}></NewBookingProgressBar>
        </Box>
        <Box marginRight={showMobileAndTabFooter ? "24px" : "0px"}>{children}</Box>
      </Box>
      <VoucherPriceBreakdownModal
        open={showPriceBreakdownModal}
        onToggle={setShowPriceBreakdownModal}
      />
    </NewBookingFooter>
  );
};

export default GiftVoucherFooter;
