import React, { useImperativeHandle, useRef, useState } from "react";
import { Box } from "@mui/material";
import { StripeElementChangeEvent, StripeError, Token } from "@stripe/stripe-js";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "../../../fonts/Museo.css";
import * as Styled from "../../v2/Styled/enum";
import { getValue } from "../../../utils/object";
import { useUserStore } from "../../../stores/user";
import { useAlertStore } from "../../../stores/alert";
import { useStripeStore } from "../../../stores/stripe";
import { DEFAULT_COUNTRY } from "../../../utils/country";
import { StripeClientErrorType } from "../../../constants/stripe";
import { COUNTRIES_CURRENCY } from "../../../constants/countries";
import { stripeTransformerService } from "../../../services/stripe/customer";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import StripeCardField, { CARD_DETAILS_FIELD, StripeCardFieldType } from "./StripeCardField";

interface Props {
  onTokenCreated: (
    e: MouseEvent,
    token: Token | undefined,
    cb: (e: MouseEvent, d: any) => unknown
  ) => void;
  forPayment?: boolean;
  FooterComponent?: React.ReactNode;
}

export interface StripeCardFormHandle {
  onSubmit: (e: MouseEvent, cb: (e: MouseEvent, d: any) => unknown) => Promise<void>;
}

const StripeCardElement = React.forwardRef(
  (
    { onTokenCreated, forPayment = false, FooterComponent }: Props,
    ref: React.Ref<StripeCardFormHandle>
  ) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useUserStore();
    const { setErrorMessage } = useAlertStore();
    const { clientSecret, createSetupIntent } = useStripeStore();

    const cardNumberRef = useRef<any>(null);
    const cardExpiryRef = useRef<any>(null);
    const cardCvcRef = useRef<any>(null);

    const [cardError, setCardError] = useState({
      number: "",
      expiry: "",
      cvc: "",
      country: "",
    });

    const handleError = (error: StripeError) => {
      sendErrorLog({
        domain: "STRIPE_CARD_ELEMENT",
        log: {
          title: "Error creating stripe card token",
          message: getValue(error, "message"),
          data: error,
        },
        channel: LOG_CHANNEL.SLACK,
      });

      const errorMessage =
        error.type === StripeClientErrorType.StripeCardError
          ? error.message
          : "Please check your card details.";
      setErrorMessage(errorMessage);
    };

    const handleSubmit = async (e: MouseEvent, cb = (e: MouseEvent, d: any): any => {}) => {
      e.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        return;
      }

      const userCountry = getValue(user, "country");
      const data = forPayment
        ? { address_country: userCountry }
        : {
            currency: COUNTRIES_CURRENCY[userCountry] || COUNTRIES_CURRENCY[DEFAULT_COUNTRY],
          };

      const { token, error: tokenError } = await stripe.createToken(cardElement, data);

      if (tokenError) {
        handleError(tokenError);
        return;
      }

      if (forPayment) {
        let stripeClientSecret = clientSecret as string;
        if (!stripeClientSecret) {
          await createSetupIntent();
        }

        const cardData = stripeTransformerService.getConfirmCardSetupData({
          user,
          token,
        });
        const { setupIntent, error: confirmError } = await stripe.confirmCardSetup(
          stripeClientSecret,
          cardData
        );

        if (confirmError) {
          handleError(confirmError);
          return;
        }

        const paymentMethodId = getValue(setupIntent, "payment_method");
        onTokenCreated(e, paymentMethodId, cb);
      } else {
        onTokenCreated(e, token, cb);
      }
    };

    // Customizes the ref object that is exposed to the parent component
    useImperativeHandle(ref, () => ({
      onSubmit: handleSubmit,
    }));

    const handleCardInfoChange = (field: StripeCardFieldType, event: StripeElementChangeEvent) => {
      const errorMessage = getValue(event, "error.message");

      const nextFieldRef = {
        [CARD_DETAILS_FIELD.NUMBER]: cardExpiryRef,
        [CARD_DETAILS_FIELD.EXPIRY]: cardCvcRef,
        [CARD_DETAILS_FIELD.CVC]: null,
      }[field];

      if (event.complete && nextFieldRef?.current) {
        nextFieldRef.current.focus();
      }

      setCardError({
        ...cardError,
        [field]: errorMessage ? errorMessage : "",
      });
    };

    return (
      <Box
        display={Styled.Display.Flex}
        gap={Styled.Spacing.S6}
        flexDirection={Styled.FlexDirection.Column}
      >
        <StripeCardField
          ref={cardNumberRef}
          title={"Card number"}
          errorMessage={cardError.number}
          onChange={handleCardInfoChange}
          field={CARD_DETAILS_FIELD.NUMBER}
        />

        <Box display={Styled.Display.Flex} gap={Styled.Spacing.S4}>
          <StripeCardField
            title={"Expiry"}
            ref={cardExpiryRef}
            errorMessage={cardError.expiry}
            onChange={handleCardInfoChange}
            field={CARD_DETAILS_FIELD.EXPIRY}
          />

          <StripeCardField
            ref={cardCvcRef}
            title={"Security code"}
            errorMessage={cardError.cvc}
            field={CARD_DETAILS_FIELD.CVC}
            onChange={handleCardInfoChange}
          />
        </Box>

        {FooterComponent}
      </Box>
    );
  }
);

export default StripeCardElement;
