import { ButtonBase } from "@mui/material";
import { Box, DialogActions, DialogContent, styled, Checkbox } from "@material-ui/core";
import React, { useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Dialog from "../../Dialog";
import { RootState } from "../../../stores/V2";
import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { TermsDocumentType } from "../../Users/TermsAndPolicies/TermsAndPolicies";
import Dropdown, { DropdownOption } from "../../Dropdown";
import InputModal from "../../Modals/InputModal/InputModal";
import Button, { ButtonSize, ButtonType } from "../../Button";
import { actions } from "../../../stores/V2/documents/addDocuments";
import { useAlertStore } from "../../../stores/alert";
import {
  useDocumentTypeOptions,
} from "../../../hooks/userTerms.hook";
import { REGEX, USER_UPLOADED_CONTENT_TYPE, AGE_CARE_TERMS_DOCUMENTS_TYPE } from "../../../data/enums";
import CrossIcon from "../../../images/cross.svg";
import TextField from "../../TextField";
import { styles } from "./styles";
import { allowUploadDocumentExtension, filterImageAndApplicationExtension } from "../../../helpers/services";

const BoxColumn = styled(Box)({
  flexDirection: "column",
});

type Props = {
  hideTitle?: boolean;
  open: boolean;
  title: string;
  onClose: () => unknown;
  onConfirm: () => unknown;
  isSaving: boolean;
  therapistId: number | null;
};

const AddDocumentModal = ({open, title, onClose, onConfirm, isSaving, therapistId }: Props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { setErrorMessage } = useAlertStore();

  const state = useSelector((state: RootState) => state.documents);


  const { isLoading: isDocumentOptionsLoading, data: documentOptions } = useDocumentTypeOptions();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUserInput = (value: string) => {
    dispatch(actions.setUserInput(value));
    dispatch(actions.setShowInputModal(false));
  };

  const validateUserInput = (input: string) => {
    return REGEX.URL.test(input);
  };

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setContentType(USER_UPLOADED_CONTENT_TYPE.FILE));
    const file = getValue(e, "target.files[0]");
    const testResult = filterImageAndApplicationExtension(file);
    if(!testResult){
      setErrorMessage("Unsupported file type. Please upload a PNG, JPG, JPEG or PDF file.");
      return;
    }
    dispatch(actions.setUserInput(file));
  }, []);

  const handleSelectedOption = (option: TermsDocumentType) => {
    dispatch(actions.setSelectedTermPolicies(option.value));
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAddLink = () => {
    dispatch(actions.setShowInputModal(true));
    dispatch(actions.setContentType(USER_UPLOADED_CONTENT_TYPE.LINK));
  };

  const getUserInputForDisplay = (input: typeof state.userInput) => {
    if (typeof input === "object") return input.name;
    if (typeof input === "string") return input;
    return "";
  };

  const resetInput = () => {
    dispatch(actions.setUserInput(undefined));
    // This will also clear the currently selected file in the file input field if there is one
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSendForSignature = (checked: boolean) => {
    dispatch(actions.setSendForSignature(checked));
  };

  const handleSubmit = () => {
    if (!onConfirm) return;
    if (!state.selectedTermPolicies) {
      setErrorMessage("Please select the document type.");
      return;
    }

    onConfirm();
  };

  if (therapistId !== state.therapistId) return <></>;
  if (isDocumentOptionsLoading || !documentOptions) return <></>;

  const isOtherDocumentType =
    state.selectedTermPolicies === AGE_CARE_TERMS_DOCUMENTS_TYPE.OTHER_AGE_CARE_REQIREMENT;

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        maxWidth={"sm"}
        style={{ overflowY: "visible" }}
      >
        <Box style={{ ...styles.wrapper, display: state.showInputModal ? "none" : "block" }}>
          <Box style={styles.titleWrapper}>
            <ButtonBase style={styles.closeWrapper} onClick={onClose}>
              <img src={CrossIcon} alt={"Close"} style={styles.closeBtn} />
            </ButtonBase>
            <Box>
              <Box style={styles.title}>{title}</Box>
            </Box>
          </Box>
          <DialogContent style={{ ...styles.contentWrapper, ...{ overflow: "visible" } }}>
            <Box>
              <Dropdown
                paddingTop="0px"
                paddingBottom="0px"
                title="Document type"
                options={documentOptions}
                onSelectedOption={(option) => handleSelectedOption(option)}
                selectedOption={(documentOptions as DropdownOption[]).find(
                  ({ value }: TermsDocumentType) => value === state.selectedTermPolicies,
                )}
              />
              {isOtherDocumentType && (
                <Box mt={"16px"}>
                  <TextField
                    title="Other document type name"
                    onChange={(text) => {
                      dispatch(actions.setOtherDocumentName(text));
                    }}
                    value={state.otherDocumentName}
                    placeholder="Enter document type name"
                  />
                </Box>
              )}
            </Box>
            {!!state.userInput ? (
              <Box maxWidth={isMobile ? "100%" : "400px"} style={styles.selectedFileWrapper}>
                <Box style={{ ...styles.description, ...styles.dusk, ...styles.flex }} marginTop={"24px"}>
                  {getUserInputForDisplay(state.userInput)}
                </Box>
                <Button
                  title="Remove"
                  colorTitle={Colors.Danger}
                  type={ButtonType.background}
                  width="auto"
                  onClick={() => resetInput()}
                  size={ButtonSize.small}
                  style={{ height: "20px", marginTop: "24px" }}
                />
              </Box>
            ) : (
              <React.Fragment />
            )}
            {(
              <Box display={"flex"} flexDirection={"column"} gridGap={"32px"}>
                <Box style={styles.block} mt={"32px"}>
                  <Box style={styles.displayFlex}>
                    <Button
                      width={"100px"}
                      title="Select file"
                      size={ButtonSize.small}
                      type={ButtonType.outlined}
                      onClick={handleFileSelect}
                    />
                    <input
                      type="file"
                      accept={allowUploadDocumentExtension()}
                      ref={fileInputRef}
                      style={{ visibility: "hidden", width: 0 }}
                      onChange={handleFileChange}
                    />
                  </Box>
                  <Button
                    width={"100px"}
                    title="Add link"
                    size={ButtonSize.small}
                    type={ButtonType.outlined}
                    onClick={handleAddLink}
                  />
                </Box>
                {!!state.userInput && <Box display={"flex"} flexDirection={"row"} gridGap={"8px"}>
                  <Checkbox
                    style={{
                      color: state.sendForSignature ? Colors.TurquoiseBlue : Colors.Grey,
                      margin: 0,
                      padding: 0,
                      borderRadius: 30,
                    }}
                    className="booking-frequency-checkbox"
                    checked={state.sendForSignature}
                    onChange={(_, checked) => handleSendForSignature(checked)}
                  />
                  <Box style={styles.infoText}>
                    Send this {state.contentType === USER_UPLOADED_CONTENT_TYPE.LINK ? "link" : "document"} to the provider for their signature
                  </Box>
                </Box>}
              </Box>
            )}
            <BoxColumn>
              <InputModal
                open={state.showInputModal}
                onClose={() => dispatch(actions.setShowInputModal(false))}
                title={"Add web url"}
                validateInput={validateUserInput}
                validationErrorMessage={"Please enter a valid URL"}
                onConfirm={handleUserInput}
              />
            </BoxColumn>
          </DialogContent>
          <DialogActions style={styles.footerWrapper}>
            <Button
              title={"Cancel"}
              width={"150px"}
              type={ButtonType.outlined}
              onClick={onClose}
            />
            <Button
              title={`Add ${state.contentType === USER_UPLOADED_CONTENT_TYPE.LINK ? "link" : "document"}`}
              width={"150px"}
              type={ButtonType.indigo}
              onClick={handleSubmit}
              loading={isSaving}
              disabled={!(state.selectedTermPolicies && state.userInput)}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
export default AddDocumentModal;
