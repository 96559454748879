import { Box } from "@material-ui/core";

import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { useUserStore } from "../../stores/user";
import { BOOKING_STATUS } from "../../constants/booking";
import Button, { ButtonSize, ButtonType } from "../Button";
import { getBookingUpdateDetails } from "../../services/bookings/bookings.service";
import InfoText from "../Text/InfoText/infoText";
import InfoModal from "../Modals/InfoModal/InfoModal";
import { useState } from "react";
import Divider from "../Divider";
import OpenBookingConfirmationModal from "./OpenBookingConfirmationModal";

interface IUpdateOrCancel {
  handleRequestCancel: () => void;
  handleUpdateRequest: () => void;
  handleAlternativeTimes?: () => void;
  handlePublishBooking?: () => void;
  bookingStatus: string;
  bookingUpdates: any;
  alternativeOffers: number;
  sessionType: string;
  preferredTherapists: string;
  isB2B?: boolean;
}

const UpdateOrCancel = ({
  handleRequestCancel,
  handleUpdateRequest,
  handleAlternativeTimes,
  handlePublishBooking,
  bookingStatus,
  bookingUpdates,
  alternativeOffers,
  sessionType,
  preferredTherapists,
  isB2B = false,
}: IUpdateOrCancel) => {
  const isMobile = useMobile();
  const { user } = useUserStore();
  const [showOpenBookingInfo, setShowOpenBookingInfo] = useState(false);
  const [showOpenBookingConfirmationModal, setShowOpenBookingConfirmationModal] = useState(false);

  const styles = {
    badge: {
      zIndex: 2,
      top: "-10px",
      right: "-5px",
      color: "#FFF",
      width: "24px",
      height: "24px",
      display: "flex",
      fontSize: "16px",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#DC3545",
    },
  };

  const boxWidth = isMobile ? 0 : 8;
  const boxHeight = isMobile ? 8 : 0;
  const buttonWidth = isMobile ? "100%" : "160px";
  const flexDirection = isMobile ? "column-reverse" : "row";
  styles.badge.backgroundColor = alternativeOffers === 0 ? Colors.LightPeriwinkle : "#DC3545";

  const checkIsUpdateDeclined = () => {
    let requestDeclined = false;

    if (bookingUpdates && bookingUpdates.length > 0 && user) {
      const { id } = user;
      const requestDetails = getBookingUpdateDetails(id, bookingUpdates);
      if (requestDetails && requestDetails.isDeclined && requestDetails.isRequestByClient) {
        requestDeclined = true;
      }
    }
    return requestDeclined;
  };

  const showOpenToAllPros = bookingStatus === BOOKING_STATUS.NEW && preferredTherapists;

  const showAlternativeOffer = () => {
    // don't show in following cases
    // for groupbooking, corporate, direct booking or booking status is not NEW
    return (
      !isB2B &&
      bookingStatus === BOOKING_STATUS.NEW &&
      !["corporate", "groupbooking"].includes(sessionType) &&
      !preferredTherapists
    );
  };

  const requestDeclined = checkIsUpdateDeclined();

  const checkIfRequestedByPro = () => {
    let requestByPro = false;
    let requestDetails: any;

    if (bookingUpdates && bookingUpdates.length > 0 && user) {
      const { id } = user;
      requestDetails = getBookingUpdateDetails(id, bookingUpdates);
      if (requestDetails?.isRequestedByPro && requestDetails?.isPending) {
        requestByPro = true;
      }
    }
    return { requestByPro };
  };

  const { requestByPro } = checkIfRequestedByPro();

  if (requestDeclined) return <></>;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection={flexDirection}
        justifyContent="center"
        marginTop={"20px"}
        alignItems="flex-start"
      >
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.small}
          width={buttonWidth}
          height={48}
          colorTitle={Colors.Dusk}
          title="Cancel booking"
          fontStyle={{
            fontFamily: "Museo",
            fontWeight: 600,
            fontSize: 16,
          }}
          style={{ borderRadius: 4, flexGrow: 1 }}
          onClick={handleRequestCancel}
        />
        <Box width={boxWidth} height={boxHeight} />
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.small}
          width={buttonWidth}
          height={48}
          colorTitle={Colors.Dusk}
          title="Update booking"
          fontStyle={{
            fontFamily: "Museo",
            fontWeight: 600,
            fontSize: 16,
          }}
          style={{ borderRadius: 4, flexGrow: 1 }}
          onClick={handleUpdateRequest}
        />
        {showAlternativeOffer() ? (
          <>
            <Box width={boxWidth} height={boxHeight} />
            <Box position={"relative"} flexGrow={1} width={buttonWidth}>
              <Box position={"absolute"} style={styles.badge}>
                {alternativeOffers}
              </Box>
              <Button
                type={ButtonType.outlined}
                size={ButtonSize.small}
                width={"100%"}
                height={48}
                colorTitle={Colors.Dusk}
                title="Offers from providers"
                fontStyle={{
                  fontFamily: "Museo",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#41506F",
                }}
                style={{ borderRadius: 4, flexGrow: 1 }}
                onClick={handleAlternativeTimes}
              />
            </Box>
          </>
        ) : (
          <Box />
        )}
        {showOpenToAllPros && (
          <>
            <Box width={boxWidth} height={boxHeight} />
            <Box flexGrow={1} width={buttonWidth}>
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Button
                  title="Open booking to all providers"
                  type={ButtonType.outlined}
                  size={ButtonSize.large}
                  onClick={() => setShowOpenBookingConfirmationModal(true)}
                />
                <Box>
                  <InfoText
                    text="How this works"
                    showInfoButton={true}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 6,
                      marginBottom: 5,
                    }}
                    textStyle={{
                      fontSize: 14,
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      color: Colors.Grey,
                    }}
                    onInfoClicked={() => setShowOpenBookingInfo(true)}
                  />
                  <InfoModal
                    visible={showOpenBookingInfo}
                    title="Open booking to all pros"
                    description="Your booking may be confirmed sooner if you open it to all available providers in your area."
                    handleClose={() => setShowOpenBookingInfo(false)}
                    divider={false}
                    titleStyle={{ color: Colors.Indigo, lineHeight: "110%" }}
                    descriptionStyle={{ fontSize: "16px", fontWeight: "400", lineHeight: "150%" }}
                  />
                  <OpenBookingConfirmationModal
                    open={showOpenBookingConfirmationModal}
                    onClose={() => setShowOpenBookingConfirmationModal(false)}
                    onConfirm={handlePublishBooking}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default UpdateOrCancel;
