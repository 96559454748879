/* eslint-disable indent */
import { Box, Link } from "@material-ui/core";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import BookingDetailsOrderItem, {
  BookingDetailsOrderItemType,
} from "../../components/BookingDetails/BookingDetailsOrderItem";
import BookingDetailsSummaryItem, {
  BookingDetailsSummaryItemType,
} from "../../components/BookingDetails/BookingDetailsSummaryItem";

import {
  BOOKING_SESSION_TYPE,
  bookingIsCancelled,
  bookingIsComplete,
  getMassageDescriptionForBooking,
} from "../../helpers/booking";

import BookMassageButton, { BookMassageButtonSource } from "../../components/BookMassageButton";
import BookingDetailsItem from "../../components/BookingDetails/BookingDetailsItem";
import AfterPayPaymentPending from "../../components/Bookings/AfterpayPaymentPending";
import PaymentDeclined from "../../components/Bookings/PaymentDeclined";
import PaymentPending from "../../components/Bookings/PaymentPending";
import { CorporateLink } from "../../components/Bookings/V2/Corporate";
import TotalDueAccordion from "../../components/Bookings/V2/TotalDueAccordion/TotalDueAccordion";
import { ButtonSize } from "../../components/Button";
import CancelBooking from "../../components/CancelBooking";
import FlaggedWordDisclaimerModal from "../../components/DisclaimerModal/flaggedWordDisclaimerModal";
import Divider from "../../components/Divider";
import InfoModal from "../../components/Modals/InfoModal/InfoModal";
import BookingPaymentMethod from "../../components/Payment/BookingPaymentMethod";
import PaymentModal from "../../components/PaymentFrame/PaymentModal";
import RateTherapistModal from "../../components/RateTherapistModal";
import BookingNoteForTherapistItem from "../../components/ReviewAndBook/BookingNoteForTherapistItem";
import { ReviewAndBookPrefTherapist } from "../../components/ReviewAndBook/PreferredTherapistView";
import SectionPageHeader from "../../components/SectionPageHeader";
import TherapistProfileDrawer from "../../components/TherapistProfileDrawer";
import TreatmentDescription from "../../components/Treatment/TreatmentDescription/TreatmentDescriptionV2";
import CancellationPolicyText from "../../components/Util/CancellationPolicyText";
import Wrapper from "../../components/Wrapper";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { BOOKING_DETAIL_FIELDS, BOOKING_STATUS, TOTAL_DUE_INFO } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { PaymentType } from "../../constants/payment";
import { checkIfJobAccepted } from "../../helpers/booking";
import { isCorporateSession } from "../../helpers/massage";
import { useGetBookingDetail } from "../../hooks/booking/booking.hooks";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import { Booking, BookingDetails, ServiceRatePrice } from "../../models";
import { isAfterPayUnpaidBooking } from "../../services/bookings/bookings.service";
import { getPublicProfile } from "../../services/therapist/therapist.service";
import { useAlertStore } from "../../stores/alert";
import {
  getAddOnDetailById,
  getTreatmentDetailByTreatmentId,
  getTreatmentDetailsIcon,
  isCoupleMassageType,
  isMassageType,
  loadTreatmentDetails,
  useBookingStore,
  useDateTimeStore,
  useLocationDetailsStore,
  usePaymentStore,
} from "../../stores/booking";
import { DEFAULT_COUNTRY } from "../../utils/country";
import { getDayInTimezone } from "../../utils/date.util";
import { checkIfEmpty, convertParamsToObject, getValue } from "../../utils/object";
import { copyStringToClipboard } from "../../utils/string";
import BookingTimeDetails from "./Components/BookingDetails/BookingTimeDetails";
import BookingTreatmentDetail from "./Components/BookingDetails/BookingTreatmentDetail";
import InvoiceLinks from "./Components/InvoiceLinks/InvoiceLinks";
import TreatmentDetailsBlock from "./Components/OrderSummary/TreatmentDetailsBlock";
import { useFlaggedWordContentForProfile } from "../../hooks/flaggedWordModalContent/flaggedWordContentForProfile.hooks";
import { DEFAULT_TIMEZONE } from "../../constants/time";
import { AlignItems, Display, FlexDirection, FontFamily, FontSize, FontWeight, JustifyContent, Spacing, TextAlign } from "../../components/v2/Styled/enum";

interface Params {
  id?: string;
}

interface CancellationPolicyProps {
  bookingCancelled: boolean;
}

function CancellationPolicy({ bookingCancelled }: CancellationPolicyProps): JSX.Element {
  return <CancellationPolicyText bookingCancelled={bookingCancelled} />;
}

export default function BookingDetail(): JSX.Element {
  const history = useHistory();
  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const location = useLocation();
  const { setCountryCode } = useLocationDetailsStore();
  const { state } = location;

  const redirectedAfterBookingPlaced = get(state, "redirectedAfterBookingPlaced", false);

  const { setSuccessMessage } = useAlertStore();

  const { id } = useParams<Params>();

  const { data: bookingData, refetch: bookingMutate } = useGetBookingDetail(id!);
  const booking = bookingData as Booking;

  const sessionType = getValue(booking, "sessionType") || BOOKING_SESSION_TYPE.SINGLES;

  const bookingComplete = booking && bookingIsComplete(booking);
  const bookingCancelled = booking && bookingIsCancelled(booking);

  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [jobTherapistsId, setJobTherapistsId] = useState<any>([]);
  const [jobTherapists, setJobTherapists] = useState<any>([]);
  const [showTotalDueUpToModal, setShowTotalDueUpToModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const onSuccess = () => {
    bookingMutate();
    setShowPaymentModal(false);
    setSuccessMessage("Booking payment method updated successfully.");
  };

  const {
    setTreatmentDetails,
    setUpdatingForBooking,
    setExitBookingFlowPath,
    setNumOfRecipients,
    preferredTherapists,
    setPreferredTherapists,
    serviceId,
    setAddress,
  } = useBookingStore();
  const { setPaymentType, setPaymentMethod, bookingPrice } = usePaymentStore();

  const { setSelectedDayRange, setBackupSelectedDayRange } = useDateTimeStore();
  const [treatmentForInfo, setTreatmentForInfo] = useState<ServiceRatePrice>();

  const [showTreatmentInfoModal, setShowTreatmentInfoModal] = useState(false);

  const [showRateTherapist, setShowRateTherapist] = useState(false);
  const [rateTherapistBookingDetail, setRateTherapistBookingDetail] =
    useState<BookingDetails | null>(null);

  const [therapistProfileOpen, setTherapistProfileOpen] = useState(false);
  const [therapistProfileId, setTherapistProfileId] = useState<number | null>(null);

  const bookingCountryCode = getValue(booking, "address.countryCode") || DEFAULT_COUNTRY;
  const { services } = useServiceRates(bookingCountryCode);

  const isMassage = isMassageType(serviceId);
  const isCouplesMassage = isCoupleMassageType(sessionType);

  const [flaggedWordDisclaimerOpen, setFlaggedWordDisclaimerOpen] = useState<boolean>(false);
  const flaggedWordProfileContent = useFlaggedWordContentForProfile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCountryCode(bookingCountryCode);
    setAddress(booking?.address);
  }, [services, bookingCountryCode]);

  useEffect(() => {
    if (booking) {
      const bookingTimezone = getValue(booking, "timezone", DEFAULT_TIMEZONE);

      setNumOfRecipients(booking?.numberOfClients || 1);
      setOrResetPreferredTherapist(booking);
      setSelectedDayRange(
        getDayInTimezone(booking?.earliestTime, bookingTimezone),
        getDayInTimezone(booking?.latestTime, bookingTimezone)
      );
      setBackupSelectedDayRange(
        getDayInTimezone(booking?.backup?.earliestTime, bookingTimezone),
        getDayInTimezone(booking?.backup?.latestTime, bookingTimezone)
      );
      setPaymentType(booking.paymentType || PaymentType.card);
      setPaymentMethod(booking.paymentmethod);
    }
  }, [booking]);

  const queryParams = convertParamsToObject(new URLSearchParams(location?.search || ""));

  // to open rating modal
  // note: open only for confirmed bookings
  useEffect(() => {
    if (!showRateTherapist && bookingComplete && queryParams?.openRatingModal) {
      if (booking.bookingdetails) setRateTherapistBookingDetail(booking.bookingdetails[0]);
      setShowRateTherapist(true);
    }
  }, [booking, showRateTherapist, bookingComplete, queryParams?.openRatingModal]);

  useEffect(() => {
    if (
      booking &&
      (booking.status === BOOKING_STATUS.ARRANGED || booking.status === BOOKING_STATUS.DECLINED)
    ) {
      history.replace(`/bookings/review/${booking.id}`);
      return;
    }
  }, [booking]);

  useEffect(() => {
    bookingMutate();
  }, [location, bookingMutate]);

  const getBaseRateWithSurcharges = () => {
    return booking.priceWithoutDiscount - booking.clientPlatformFee - (booking.tipAmount || 0);
  };

  const handleBookingCancel = () => {
    setShowCancelBooking(true);
  };

  const handleShowTotalDueUpToModalClose = () => {
    setShowTotalDueUpToModal(false);
  };

  const setOrResetPreferredTherapist = async (booking: Booking) => {
    // const therapistId = getPreferredTherapistId(booking); //returns first therapist id if 2 therapists
    // if (!therapistId) {
    //   setPreferredTherapists([]);
    //   return;
    // }
    // getPublicProfile(therapistId, accessToken).then(({ data }) => setPreferredTherapists([data]));

    if (booking && booking.status === BOOKING_STATUS.COMPLETED) {
      const previousJobTherapists = booking.bookingdetails.map((detail) =>
        getValue(detail, "job.therapistId")
      );
      setJobTherapistsId(previousJobTherapists);

      const publicProfile: any = [];

      for (let i = 0; i < previousJobTherapists.length; i++) {
        const profile = await getPublicProfile(previousJobTherapists[i], accessToken);
        publicProfile[i] = profile.data;
      }
      setJobTherapists(publicProfile);
      setPreferredTherapists(publicProfile);
    }
  };

  const handleTreatmentChange = () => {
    if (booking.isHairAndMakeup) {
      const treatmentDetails = getValue(booking, "bookingdetails[0].treatmentDetails");
      setTreatmentDetails(treatmentDetails);
    }
    setUpdatingForBooking(booking);

    setExitBookingFlowPath(location.pathname);

    history.replace(Paths.ServiceDetails);
  };



  const handleTreatmentClick = (td: any) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    const treatment = getValue(td, "treatment") || getTreatmentDetailByTreatmentId(treatmentId);
    setTreatmentForInfo(treatment);
    setShowTreatmentInfoModal(true);
  };

  const handleAddOnClick = ({ td, addOnId }: { td: any; addOnId: any }) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    const addOn = getAddOnDetailById({ treatmentId, id: addOnId });
    setTreatmentForInfo(addOn);
    setShowTreatmentInfoModal(true);
  };

  const handlePrefProOnChange = () => {
    setUpdatingForBooking(booking);
    history.push(Paths.PreferredProviders);
  };

  const getTreatmentRowIcon = () => {
    let therapistCount = 1;
    if (isCorporateType) {
      therapistCount = getValue(booking, "corporateInfo.numberOfPros") || 1;
    } else if (isMassage) {
      therapistCount = isCouplesMassage ? 2 : 1;
    }
    return getTreatmentDetailsIcon(therapistCount);
  };

  const hasBackupData = !!booking?.backup;

  const isCorporateType = isCorporateSession(getValue(booking, "sessionType", ""));
  const corporateHasPaidBreak = getValue(booking, "corporateInfo.hasPaidBreak", false);

  const corporateLink = isCorporateType ? getValue(booking, "bookingLink", null) : null;
  const isAnyJobAccepted = checkIfJobAccepted(booking);

  const bookingCurrency = getValue(booking, "service.selectedCountry.currencySymbol", "A$");

  const preferredTherapist = !!booking
    ? getValue(booking, "preferredTherapistProfile")
    : preferredTherapists;

  const corporateInfo = getValue(booking, "corporateInfo") || null;
  // filter corporate bookings from NAP
  const hasCorporateSchedule = isCorporateType && !checkIfEmpty(corporateInfo);

  const handleBack = () => {
    // Note :- redirectedAfterBookingPlaced flag has been added so that when clicking back from the booking detail page
    // after placing a booking it gets redirected to the upcommings page instead of goBack() which was used to preserve
    // the page after going back from booking detail page if it was redirected from the upcomming booking.

    /*    if (redirectedAfterBookingPlaced) {
      history.push(Paths.Bookings);
    } else if (isRedirected) {
      history.goBack();
    } else if (back) {
      history.push(back);
    } else {
      history.back(Paths.Bookings);
    } */

    if (location.key) {
      if (redirectedAfterBookingPlaced) {
        history.push(Paths.Bookings);
        return;
      }
      history.goBack();
    } else {
      history.push(Paths.Bookings);
    }
  };

  const handlePendingChange = (field: string) => {
    setUpdatingForBooking(booking);

    let redirectPath = Paths.SavedLocations;
    switch (field) {
      case BOOKING_DETAIL_FIELDS.ADDRESS:
        redirectPath = Paths.SavedLocations;
        break;
      case BOOKING_DETAIL_FIELDS.DATETIME:
        redirectPath = Paths.DateAndTime;
        break;
      case BOOKING_DETAIL_FIELDS.RECIPIENT:
        redirectPath = Paths.SavedRecipients;
        break;
      case BOOKING_DETAIL_FIELDS.BOOKING_FREQUENCY:
      default:
        redirectPath = Paths.BookingFrequency;
        break;
    }
    history.push(redirectPath);
  };

  const isPartiallyConfirmedCouples = () => {
    if (sessionType !== BOOKING_SESSION_TYPE.COUPLES) return false;
    const therapists = (booking.bookingdetails || [{}]).map(
      ({ job }) => getValue(job, "therapistId") || null
    );

    const isPending = therapists.every((id) => id === null);
    if (isPending) return false;

    return therapists.some((id) => id === null);
  };

  const handlePendingUpdate = (field: string) => {
    const partiallyConfirmedCouples = isPartiallyConfirmedCouples();
    const allowPendingUpdate = !partiallyConfirmedCouples;

    if (!allowPendingUpdate) return undefined;
    return () => handlePendingChange(field);
  };

  const isBookingCancelled = getValue(booking, "status", "") === BOOKING_STATUS.CANCELLED;
  const path = corporateLink ? `${corporateLink}/schedules` : null;

  const handleViewSchedule = () => {
    const schedulePath = `${Paths.CorporateSlots}/${booking.uuid}/schedules`;
    history.push(schedulePath);
  };

  const handleCopyToClipboard = (path: string | null) => {
    path && copyStringToClipboard(path);
    setSuccessMessage("Booking link copied to clipboard");
  };

  return booking ? (
    <>
      <Wrapper>
        <SectionPageHeader
          title={getMassageDescriptionForBooking(booking)}
          titleStyle={
            isMobile
              ? {
                fontFamily: FontFamily.Museo,
                fontWeight: FontWeight.Bold,
                fontSize: FontSize.F20,
                color: Colors.NightBlue,
                textAlign: TextAlign.left,
              }
              : undefined
          }
          subtitleElement={
            <Box mt={1} fontSize={16} fontFamily={"Museo"} color={Colors.Dusk}>
              Booking #{id}
            </Box>
          }
          onBack={handleBack}
        />
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          ml={isMobile ? "24px" : "80px"}
          mr={isMobile ? "24px" : undefined}
          mt={isMobile ? "40px" : "42px"}
          mb={isMobile ? "1px" : "40px"}
          alignItems={isMobile ? "center" : undefined}
        >
          <Box display="flex" flexDirection="column">
            {isMobile && <Box height="16px" />}

            {booking.bookingdetails.map((detail) => (
              <BookingDetailsItem
                key={`booking-detail-${detail.id}`}
                detail={detail}
                booking={booking}
                onClickRating={() => {
                  setRateTherapistBookingDetail(detail);
                  setShowRateTherapist(true);
                }}
                onClickTherapist={(pro) => {
                  if (pro) {
                    const id: any = pro.id;
                    setTherapistProfileId(id);
                    setTherapistProfileOpen(true);
                  }
                }}
                bookingMutate={bookingMutate}
              />
            ))}
            {booking.status === BOOKING_STATUS.COMPLETED && booking.bookingdetails.length > 1 && (
              <BookMassageButton
                booking={booking}
                size={ButtonSize.small}
                width="100%"
                title="Rebook all pros"
                preferredTherapistId={jobTherapistsId}
                preferredTherapist={jobTherapists}
                source={BookMassageButtonSource.PastBookings}
              />
            )}
          </Box>

          <Box ml={isMobile ? undefined : Spacing.S7} maxWidth={isMobile ? "100%" : "500px"}>
            {!bookingComplete && !bookingCancelled && (
              <Box ml={isMobile ? undefined : Spacing.S7} maxWidth={isMobile ? "100%" : "500px"}>
                <Box
                  bgcolor={Colors.White}
                  borderRadius="6px"
                  padding="32px 0px 24px"
                  border={`solid 1px ${Colors.PaleLilac}`}
                  style={!isMobile ? { overflow: "auto" } : {}}
                >
                  <Box
                    padding="0px 32px"
                    display={Display.Flex}
                    flexDirection={FlexDirection.Column}
                    gridRowGap={Spacing.S4}
                    marginBottom={Spacing.S4}
                  >
                    <Box
                      display={Display.Flex}
                      flexDirection={FlexDirection.Column}
                      gridRowGap={Spacing.S4}
                    >
                      <BookingDetailsOrderItem
                        booking={booking}
                        type={BookingDetailsOrderItemType.location}
                        onChangeClicked={handlePendingUpdate(BOOKING_DETAIL_FIELDS.ADDRESS)}
                      />

                      <BookingTreatmentDetail
                        icon={getTreatmentRowIcon()}
                        booking={booking}
                        onChangeClicked={handleTreatmentChange}
                        onTreatmentClicked={handleTreatmentClick}
                        onAddOnClicked={handleAddOnClick}
                      />

                      <BookingDetailsOrderItem
                        booking={booking}
                        type={BookingDetailsOrderItemType.recipient}
                        onChangeClicked={handlePendingUpdate(BOOKING_DETAIL_FIELDS.RECIPIENT)}
                      />

                      <BookingTimeDetails
                        booking={booking}
                        onChange={handlePendingUpdate(BOOKING_DETAIL_FIELDS.DATETIME)}
                      />

                      <ReviewAndBookPrefTherapist
                        profile={preferredTherapist}
                        onChange={handlePrefProOnChange}
                        serviceId={serviceId}
                      />

                      <BookingDetailsOrderItem
                        booking={booking}
                        type={BookingDetailsOrderItemType.frequency}
                        onChangeClicked={handlePendingUpdate(BOOKING_DETAIL_FIELDS.BOOKING_FREQUENCY)}
                      />

                      <BookingNoteForTherapistItem
                        booking={booking}
                        refreshBooking={() => bookingMutate()}
                        onRecipientUpdated={() => bookingMutate()}
                        onChangeClicked={() => {
                          // loads booking treatment details
                          booking.serviceId && loadTreatmentDetails(booking.serviceId);
                          setUpdatingForBooking(booking);
                        }}
                      />
                    </Box>

                    <Box
                      paddingBottom={Spacing.S2}
                      display={Display.Flex}
                      flexDirection={FlexDirection.Column}
                      gridRowGap={Spacing.S3}
                    >
                      <Box
                        fontFamily={FontFamily.Museo}
                        fontSize={FontSize.F20}
                        fontWeight={FontWeight.Bold}
                        color={Colors.NightBlue}
                        letterSpacing="0.25px"
                      >
                        Summary
                      </Box>

                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.massage}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.parkingAndTravel}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.peakHourSurcharge}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.publicHolidaySurcharge}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.tipping}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.discount}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.coupon}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.credit}
                        booking={booking}
                      />
                      <BookingDetailsSummaryItem
                        type={BookingDetailsSummaryItemType.clientPlatformFee}
                        booking={booking}
                      />
                    </Box>
                  </Box>
                  <Box padding="0px 16px">
                    <TotalDueAccordion
                      currency={bookingCurrency}
                      totalPrice={booking?.price}
                      bookingStatus={booking?.status}
                      hasBackup={hasBackupData}
                      bookingPrice={bookingPrice}
                      setShowTotalDueUpToModal={setShowTotalDueUpToModal}
                    />
                  </Box>

                  <Box padding="0px 32px">
                    <Divider />
                    <BookingPaymentMethod
                      handleAddCard={() => setShowPaymentModal(true)}
                      showPaymentMethodChangeBtn={!booking.isActuallyPaid}
                    />
                  </Box>

                  <Box padding="0px 32px">
                    <Divider />
                    <Link
                      style={{ cursor: "pointer" }}
                      underline="none"
                      onClick={handleBookingCancel}
                    >
                      <Box
                        fontFamily="Museo"
                        fontWeight={700}
                        fontSize="14px"
                        color={Colors.Tomato}
                        mt="16px"
                      >
                        Cancel booking
                      </Box>
                    </Link>
                  </Box>
                </Box>

                {booking.status === BOOKING_STATUS.DECLINED && (
                  <Box
                    margin={"16px 0px"}
                    bgcolor="white"
                    borderRadius="6px"
                    padding="24px"
                    border={`solid 1px ${Colors.PaleLilac}`}
                    style={!isMobile ? { overflow: "auto" } : {}}
                  >
                    <PaymentDeclined isMobile={true} booking={booking} />
                  </Box>
                )}

                {booking.hasInvoices && (
                  <Box
                    style={{
                      borderRadius: "6px",
                      border: `solid 1px ${Colors.PaleLilac}`,
                      backgroundColor: Colors.White,
                    }}
                    padding="24px"
                    mt="16px"
                    fontFamily="Museo"
                  >
                    {!booking.isActuallyPaid &&
                      !booking.statusFlag?.isPending &&
                      !isAfterPayUnpaidBooking(booking) && (
                        <>
                          <PaymentPending
                            divider={false}
                            isMobile={isMobile}
                            booking={booking}
                            bookingMutate={bookingMutate}
                          />
                          <Divider marginTop={"24px"} marginBottom={"16px"} />
                        </>
                      )}

                    {!isAfterPayUnpaidBooking(booking) && (
                      <Box style={{ display: "flex", justifyContent: "center" }}>
                        <InvoiceLinks booking={booking} />
                      </Box>
                    )}
                  </Box>
                )}

                {hasCorporateSchedule && (
                  <CorporateLink
                    title="Booking link"
                    description="Your unique schedule link for your team will be here when your booking is confirmed."
                    onCopyLink={() => { }}
                    onViewSchedule={() => { }}
                    isDisabled={true} // disabled for pending booking, this page is not used for confirmed
                  />
                )}
              </Box>
            )}

            {(bookingComplete || bookingCancelled) && (
              <Box ml={isMobile ? undefined : "30px"} maxWidth={isMobile ? "100%" : "468px"}>
                <Box
                  borderRadius="6px"
                  border={`solid 1px ${Colors.PaleLilac}`}
                  bgcolor="white"
                  padding="32px 32px 24px"
                >
                  {isAfterPayUnpaidBooking(booking) && (
                    <Box paddingBottom={"16px"} textAlign={"center"}>
                      <Box
                        fontFamily={"Museo"}
                        fontWeight={600}
                        color="#DC3545"
                        fontSize={isMobile ? "16px" : "20px"}
                      >
                        Complete booking payment
                      </Box>
                    </Box>
                  )}

                  <TreatmentDetailsBlock
                    id={booking.id.toString()}
                    booking={booking}
                    allowChange={false}
                    addPadding={false}
                    allowedActionFields={[BOOKING_DETAIL_FIELDS.BOOKING_FREQUENCY]}
                  />

                  <Box
                    marginBottom={Spacing.S4}
                    marginTop={Spacing.S4}
                    display={Display.Flex}
                    flexDirection={FlexDirection.Column}
                    gridRowGap={Spacing.S3}
                  >
                    <Box
                      fontFamily="Museo"
                      fontSize="20px"
                      fontWeight={700}
                      color={Colors.NightBlue}
                      letterSpacing="0.25px"
                    >
                      Receipt
                    </Box>
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.massage}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.parkingAndTravel}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.peakHourSurcharge}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.publicHolidaySurcharge}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.tipping}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.coupon}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.credit}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.discount}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.clientPlatformFee}
                      booking={booking}
                    />
                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.cancellationFee}
                      booking={booking}
                    />

                    <BookingDetailsSummaryItem
                      type={BookingDetailsSummaryItemType.totalPaid}
                      booking={booking}
                    />
                  </Box>

                  <Box
                    fontFamily={FontFamily.Museo}
                    fontSize={FontSize.F16} 
                    fontWeight={FontWeight.Bold}
                    color={Colors.Dusk}
                    display={Display.Flex}
                    flexDirection={FlexDirection.Row}
                    bgcolor={Colors.PaleGrey}
                    height={Spacing.S13}
                    alignItems={AlignItems.center}
                    justifyContent={JustifyContent.spaceBetween}
                    borderRadius={Spacing.S2}
                    mb="25px"
                  >
                    <Box flex={1} ml="19px">
                      {(booking?.totalAmountDue || 0) > 0 ? "Total due" : "Total paid"}
                    </Box>
                    <Box mr="19px">
                      {(booking?.totalAmountDue || booking?.totalPaid || 0)?.toPriceString(
                        bookingCurrency
                      )}
                    </Box>
                  </Box>

                  <CancellationPolicy bookingCancelled={bookingCancelled} />
                  <Box>
                    <Box mt={Spacing.S3}>
                      <BookingPaymentMethod
                        handleAddCard={() => setShowPaymentModal(true)}
                        showPaymentMethodChangeBtn={!booking.isActuallyPaid}
                        height={"auto"}
                      />
                    </Box>
                  </Box>
                </Box>

                {booking.hasInvoices && (
                  <Box
                    style={{
                      borderRadius: "6px",
                      border: `solid 1px ${Colors.PaleLilac}`,
                      backgroundColor: Colors.White,
                    }}
                    padding="24px"
                    mt="16px"
                    fontFamily="Museo"
                  >
                    {isAfterPayUnpaidBooking(booking) ? (
                      <>
                        <AfterPayPaymentPending
                          divider={false}
                          isMobile={isMobile}
                          booking={booking}
                          bookingMutate={bookingMutate}
                        />
                      </>
                    ) : (
                      <>
                        {!booking.isActuallyPaid && (
                          <>
                            <PaymentPending
                              divider={false}
                              isMobile={isMobile}
                              booking={booking}
                              bookingMutate={bookingMutate}
                            />
                            <Divider marginTop={"24px"} marginBottom={"16px"} />
                          </>
                        )}
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                          <InvoiceLinks booking={booking} />
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            )}

            {isBookingCancelled && hasCorporateSchedule && (
              <Box ml={isMobile ? undefined : Spacing.S7} maxWidth={isMobile ? "100%" : "500px"}>
                <CorporateLink
                  title="Booking link"
                  description="Share or view the last updated time slots."
                  onCopyLink={() => handleCopyToClipboard(path)}
                  onViewSchedule={handleViewSchedule}
                  isDisabled={false} // disabled for pending booking, this page is not used for confirmed
                />
              </Box>
            )}

            {isMobile && <Box height="55px" />}
          </Box>
        </Box>
      </Wrapper>
      <CancelBooking
        booking={booking}
        open={showCancelBooking}
        onConfirm={() => {
          history.push("/bookings");
        }}
        onClose={() => setShowCancelBooking(false)}
      />

      <TreatmentDescription
        isOpen={showTreatmentInfoModal}
        onClose={() => {
          setShowTreatmentInfoModal(false);
        }}
        title={treatmentForInfo?.label || ""}
        description={treatmentForInfo?.fullDescription || ""}
      />

      <RateTherapistModal
        open={showRateTherapist}
        detail={rateTherapistBookingDetail}
        onClose={() => setShowRateTherapist(false)}
        onCompleted={() => {
          setShowRateTherapist(false);

          bookingMutate();
        }}
        showFlaggedModal={(isFlagged) => {
          setFlaggedWordDisclaimerOpen(isFlagged);
        }}
        currencySymbol={(booking as any)?.service?.selectedCountry?.currencySymbol ?? "A$"}
        baseRate={getBaseRateWithSurcharges()}
        isTippingAllowed={
          booking.isTippingAllowed &&
          booking.paymentType !== PaymentType.payWithInvoice &&
          booking.paymentType !== PaymentType.payWithNDISFunding
        }
        bookingTip={
          booking.bookingTips.find((tip) => tip.jobId === rateTherapistBookingDetail?.job.id) ||
          null
        }
        countryCode={booking.service.selectedCountry.code ?? booking.service.country}
      />

      <FlaggedWordDisclaimerModal
        bodyContent={flaggedWordProfileContent.content}
        open={flaggedWordDisclaimerOpen}
        onClose={() => setFlaggedWordDisclaimerOpen(false)}
        titleMessage={flaggedWordProfileContent.title}
        closeBtnTitle="Edit Review"
      />

      <InfoModal
        visible={showTotalDueUpToModal}
        title={TOTAL_DUE_INFO.title}
        description={TOTAL_DUE_INFO.description}
        handleClose={handleShowTotalDueUpToModalClose}
        divider={false}
      />

      <TherapistProfileDrawer
        open={therapistProfileOpen}
        therapistId={therapistProfileId}
        onClose={() => setTherapistProfileOpen(false)}
      />

      <PaymentModal
        booking={booking}
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        hidePayWithInvoice={false}
        hidePayWithNDIS={false}
        showInvoiceLink={false}
        changePaymentMethod={true}
        onSuccess={onSuccess}
      />
    </>
  ) : (
    <Wrapper>
      <Box height={"100dvh"}>
        <LoadingSpinner />
      </Box>
    </Wrapper>
  );
}
