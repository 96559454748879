import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";

import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import TextField from "../TextField";
import Button, { ButtonType, ButtonSize } from "../Button";
import { Recipient } from "../../models";
import { useMobile } from "../../hooks/mobile";
import Dialog from "../Dialog";
import Dropdown from "../Dropdown";
import { useAccessToken } from "../../hooks/common";
import { useGetAllowDirectProChangeStatus } from "../../hooks/settings/bookings.hooks";
import { SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST } from "../../constants/common";
import MobileTextField from "../MobileTextField";
import { AllRelationships, MassageFor, Relationship } from "../../constants/booking";
import { getCountryCallingCode, getCountryCode } from "../../helpers/mobile";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import CovidVaccinationInfo, { MessageType } from "../CovidVaccinationInfo";
import { removeSpaces } from "../../helpers/string";
import { useBookingStore } from "../../stores/booking";
import Gender from "../Gender";
import isValidEmail from "../../helpers/validation";
import { Colors } from "../../constants/colors";
import { useUserStore } from "../../stores/user";
import GuardianForm from "./Guardian";
import { getValue, checkIfEmpty } from "../../utils/object";
import { NOTE_FOR_THERAPIST_PLACHOLDER } from "../../constants/placeholder";
import BillingIdentiferItem from "../AdditionalBillingIdentifiers/BillingIdentifierItem";
import { getBillingIdentifierConfig } from "../../services/additionalBillingIdentifier/additionalBillingIdentifier.service";
import Contraindications from "../Contraindications";
import { getCountryCode as getCountryCodeFromIpAddress } from "../../utils/country";
import { PointOfContactInfo } from "../PointOfContactInfo/PointOfContactInfo";
import { isArray } from "../../utils/array";
import { useUserRole } from "../../hooks/user.hooks";
import AutoUpdateSetting from "../BookingSettings/AutoUpdateSetting";
import { FontSize } from "../v2/Styled/enum";

interface Props {
  existingRecipient?: Recipient | null;
  open: boolean;
  onClose: () => unknown;
  onSaved: (recipient: Recipient) => unknown;
  immediateUpdate?: boolean;
}

const relationshipOptions = AllRelationships.map((relationship) => {
  return {
    value: relationship,
    title: Relationship.toString(relationship),
  };
});

const RecipientModal = ({
  existingRecipient,
  open,
  onClose,
  onSaved,
  immediateUpdate = true,
}: Props) => {
  const isMobile = useMobile();
  const accessToken = useAccessToken();
  const { isHomeCareClient } = useUserRole();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [relationship, setRelationship] = useState<Relationship | null>(null);
  const [gender, setGender] = useState("");
  const [notes, setNotes] = useState("");
  const [hasContraindications, setHasContraindications] = useState<null | boolean>(null);
  const [contraindications, setContraindications] = useState<null | string>(null);
  const [isCovidVaccinated, setIsCovidVaccinated] = useState(false);
  const [customerId, setCustomerId] = useState<number | string>("");
  const [orderNumber, setOrderNumber] = useState("");
  const [lineItemCode, setLineItemCode] = useState("");
  const [saving, setSaving] = useState(false);

  // guradian related data
  const [guardianFirstName, setGuardianFirstName] = useState("");
  const [guardianLastName, setGuardianLastName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [guardianMobile, setGuardianMobile] = useState("");
  const [guardianCountryCode, setGuardianCountryCode] = useState("");
  const [isRecipientPOC, setIsRecipientPOC] = useState(false);
  const [isGuardianPOC, setIsGuardianPOC] = useState(false);
  const [autoUpdateEnabled, setAutoUpdate] = useState(false);

  const { user } = useUserStore();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { setRecipient } = useBookingStore();
  const { data: userBookingSettings } = useGetAllowDirectProChangeStatus();

  const canAddRecipientsContactPerson = getValue(user, "permission.canAddRecipientsContactPerson");

  const { recipientDetailConfig } = getBillingIdentifierConfig(user);

  const setCountryDialingCode = async () => {
    const countryCode = await getCountryCodeFromIpAddress();
    const dailingCode = getCountryCallingCode(countryCode);
    setCountryCode(`+${dailingCode}`);
    setGuardianCountryCode(`+${dailingCode}`);
  };
  const resetRecipientState = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setRelationship(null);
    setGender("");
    setNotes("");
    setIsCovidVaccinated(false);
    setCustomerId("");
    setOrderNumber("");
    setLineItemCode("");
    setGuardianFirstName("");
    setGuardianEmail("");
    setGuardianLastName("");
    setGuardianMobile("");
    setCountryDialingCode();
    setHasContraindications(null);
    setContraindications("");
  };

  useEffect(() => {
    const userUpdateSetting = getValue(userBookingSettings, "allowDirectProChange");
    const defaultAutoUpdateSetting = isNil(userUpdateSetting) ? false : userUpdateSetting;

    if (!isEmpty(existingRecipient) && !!existingRecipient) {
      setFirstName(existingRecipient.firstName);
      setLastName(existingRecipient.lastName);
      setEmail(existingRecipient.email);

      const autoUpdate = isNil(existingRecipient.autoUpdateBooking)
        ? defaultAutoUpdateSetting
        : existingRecipient.autoUpdateBooking;
      setAutoUpdate(autoUpdate);

      const mobileCountryCodeData = getCountryCode(existingRecipient.mobile);

      setCountryCode(mobileCountryCodeData?.countryCode || "");
      setMobile(mobileCountryCodeData?.withoutCountryCode || "");
      setRelationship(existingRecipient.relationship);
      setGender(existingRecipient.gender);
      setNotes(existingRecipient.specialInstructions);
      setIsCovidVaccinated(existingRecipient.isCovidVaccinated || false);
      setCustomerId(existingRecipient?.customerId || "");
      setOrderNumber(existingRecipient?.orderNumber || "");
      setLineItemCode(existingRecipient?.lineItemCode || "");
      setContraindications(existingRecipient?.contraindications || null);
      setHasContraindications(existingRecipient?.hasContraindications);
      setIsGuardianPOC(existingRecipient?.primaryContactUserId !== existingRecipient?.aliasUserId);

      if (existingRecipient.aliasUserId) {
        setIsRecipientPOC(
          existingRecipient?.aliasUserId === existingRecipient?.primaryContactUserId
        );
      }

      const aliasUserId = getValue(existingRecipient, "aliasUserId", null);
      const primaryContactUserId = getValue(existingRecipient, "primaryContactUserId", null);
      const isRecipientPOC =
        (aliasUserId && primaryContactUserId && aliasUserId === primaryContactUserId) || false;
      setIsRecipientPOC(isRecipientPOC);
      if (existingRecipient?.contactPerson) {
        let contactPersonList = getValue(existingRecipient, "contactPerson", []);
        if (contactPersonList) {
          contactPersonList = isArray(contactPersonList) ? contactPersonList : [contactPersonList];
        }

        const guardianOrNextOfKin = contactPersonList.find(
          (contact: any) => !!getValue(contact, "firstName", "") && !getValue(contact, "invitedBy")
        );

        const { firstName, lastName, email, countryCode, isPrimaryPointOfContact } =
          guardianOrNextOfKin || {};

        let { mobile: guardianMobile } = guardianOrNextOfKin || {};

        if (countryCode && guardianMobile) {
          const formattedGuardianMobile = getCountryCode(`${countryCode}${guardianMobile}`);
          if (formattedGuardianMobile)
            guardianMobile = formattedGuardianMobile.withoutCountryCode;
        }

        setGuardianFirstName(firstName);
        setGuardianLastName(lastName);
        setGuardianEmail(email);
        setGuardianCountryCode(countryCode);
        setGuardianMobile(guardianMobile);
        setIsGuardianPOC(isPrimaryPointOfContact || false);
      } else {
        setGuardianFirstName("");
        setGuardianEmail("");
        setGuardianLastName("");
        setGuardianCountryCode("");
        setGuardianMobile("");
        setIsGuardianPOC(false);
      }
    } else {
      resetRecipientState();

      setAutoUpdate(defaultAutoUpdateSetting);
    }
  }, [existingRecipient]);

  useEffect(() => {
    const isUpdating = !checkIfEmpty(existingRecipient);
    if (isUpdating) return;

    const userUpdateSetting = getValue(userBookingSettings, "allowDirectProChange");
    const defaultAutoUpdateSetting = isNil(userUpdateSetting) ? false : userUpdateSetting;

    setAutoUpdate(defaultAutoUpdateSetting);
  }, [open, existingRecipient]);

  const verifyFields = () => {
    if (isEmpty(firstName)) {
      setErrorMessage("Please enter their first name");
      return false;
    }

    if (isEmpty(lastName)) {
      setErrorMessage("Please enter their last name");
      return false;
    }

    if ((isHomeCareClient && isRecipientPOC) && isEmpty(email)) {
      setErrorMessage("Please enter their email address");
      return false;
    }

    const emailAndPhoneNumberMissing = isEmpty(email) && isEmpty(mobile);

    if(emailAndPhoneNumberMissing) {
      setErrorMessage("Please enter their email address or phone number");
      return false;
    }

    /** if(!isHomeCareClient && emailAndPhoneNumberMissing) {
      setErrorMessage("Please enter their email address or phone number");
      return false;
    }*/

    if (email && !isValidEmail(email)) {
      setErrorMessage("Please enter valid email");
      return false;
    }

    if (isEmpty(countryCode)) {
      setErrorMessage("Please enter their country code");
      return false;
    }

    /** if (isEmpty(mobile) && (isHomeCareClient && isRecipientPOC)) {
      setErrorMessage("Please enter their mobile");
      return false;
    }*/

    if (isNil(relationship)) {
      setErrorMessage("Please specify your relationship with them");
      return false;
    }

    if (isEmpty(gender)) {
      setErrorMessage("Please specify their gender");
      return false;
    }

    if (isGuardianPOC) {
      if (!guardianFirstName) {
        setErrorMessage("Please enter the first name of their guardian.");
        return false;
      }

      if (!guardianLastName) {
        setErrorMessage("Please enter the last name of their guardian.");
        return false;
      }

      if (!guardianEmail) {
        setErrorMessage("Please enter the email of their guardian.");
        return false;
      }

      if (guardianEmail && !isValidEmail(guardianEmail)) {
        setErrorMessage("Please enter the valid email of their guardian.");
        return false;
      }

      /** if (!guardianMobile) {
        setErrorMessage("Please enter the mobile of their guardian.");
        return false;
      }*/

    }

    return true;
  };

  const resetRecipientData = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setRelationship(null);
    setGender("");
    setNotes("");
    setIsCovidVaccinated(false);
    setCustomerId("");
    setOrderNumber("");
    setLineItemCode("");
    setGuardianFirstName("");
    setGuardianEmail("");
    setGuardianLastName("");
    setGuardianMobile("");
    setCountryDialingCode();
  };

  const handleUpdateRecipient = (data: any) => {
    if (!existingRecipient) return;

    const path = `/users/me/recipients/${existingRecipient.id}`;
    axios
      .put(path, data)
      .then((response) => {
        setSaving(false);
        let toastMessage = "Recipient updated.";
        const updatedRecipient = getValue(response, "data");
        const updatedPrimaryContactUserId = getValue(updatedRecipient, "primaryContactUserId");
        const previousPrimaryContactUserId = getValue(existingRecipient, "primaryContactUserId");

        if (
          updatedPrimaryContactUserId &&
          updatedPrimaryContactUserId !== previousPrimaryContactUserId
        ) {
          let firstName = updatedRecipient.firstName;
          const isGuardianPOC = getValue(
            updatedRecipient,
            "contactPerson.isPrimaryPointOfContact",
            ""
          );
          if (isGuardianPOC) {
            firstName = getValue(updatedRecipient, "contactPerson.firstName", "");
          }
          toastMessage = `${toastMessage} We notified ${firstName} that they’re the primary contact.`;
        }
        setSuccessMessage(toastMessage);
        setRecipient(response.data);
        onSaved(response.data);
      })
      .catch((error) => {
        setSaving(false);
        setErrorMessage(parseApiError(error));
      });
  };

  const createNewRecipient = (data: any) => {
    const path = "/users/me/recipients";
    axios
      .post(path, data)
      .then((response) => {
        setSaving(false);
        const hasPrimaryContact = getValue(response, "data.primaryContactUserId");
        const recipient = getValue(response, "data");
        let firstName = getValue(recipient, "firstName", "");
        const isGuardianPOC = getValue(recipient, "contactPerson.isPrimaryPointOfContact");
        let toastMessage = "Added new recipient.";
        if (isGuardianPOC) {
          firstName = getValue(recipient, "contactPerson.firstName");
        }
        if (hasPrimaryContact) {
          toastMessage = `${toastMessage} We notified ${firstName} that they’re the primary contact.`;
        }
        setSuccessMessage(toastMessage);
        onSaved(response.data);
        resetRecipientData();
      })
      .catch((error) => {
        setSaving(false);

        setErrorMessage(parseApiError(error));
      });
  };

  const handleSave = () => {
    if (!verifyFields()) return;

    const contactNumber = mobile ? removeSpaces(`${countryCode}${mobile}`) : null;

    const data: any = {
      accessToken,
      firstName,
      lastName,
      mobile: contactNumber,
      email,
      gender,
      relationship,
      specialInstructions: notes,
      isCovidVaccinated,
      contraindications,
      hasContraindications: hasContraindications || false,
      isPrimaryPointOfContact: isRecipientPOC,
      allowAutoUpdate: autoUpdateEnabled,
    };

    if (user?.role) {
      data.orderNumber = orderNumber || null;
      data.customerId = customerId || null;
      data.lineItemCode = lineItemCode || null;
    }

    const guardianInfo = {
      firstName: guardianFirstName || null,
      lastName: guardianLastName || null,
      email: guardianEmail || null,
      countryCode: guardianCountryCode || null,
      mobile: guardianMobile || null,
      relation: "Guardian / Carer / Next of kin",
      isPrimaryPointOfContact: isGuardianPOC,
    };

    data.contactPerson = guardianInfo;

    setSaving(true);
    if (existingRecipient) {
      if (immediateUpdate) {
        handleUpdateRecipient(data);
      } else {
        onSaved({ id: existingRecipient.id, ...data } as any);
      }
    } else {
      createNewRecipient(data);
    }
    setHasContraindications(null);
    setContraindications(null);
  };

  const onAutoUpdateChange = (checked: boolean) => {
    setAutoUpdate(checked);
  };

  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
        <ModalHeader
          title={existingRecipient ? "Edit recipient" : "Add new recipient"}
          onClose={onClose}
        />
        <Box paddingLeft="40px" paddingRight="40px" paddingBottom="30px">
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <TextField
              title="First name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
            />
            <Box width="60px" />
            <TextField title="Last name" value={lastName} onChange={(text) => setLastName(text)} />
          </Box>
          <TextField
            title="Email"
            placeholder="your@email.com"
            value={email}
            onChange={(text) => setEmail(text)}
            type="email"
          />
          <MobileTextField
            countryCode={countryCode}
            mobile={mobile}
            mobileFieldTitle="Mobile"
            onCountryCodeChange={(code) => setCountryCode(code)}
            onMobileChange={(text) => setMobile(text)}
          />
          <PointOfContactInfo
            isPointOfContact={isRecipientPOC}
            onCheckBoxChange={(isChecked) => {
              setIsRecipientPOC(isChecked);
              if (isGuardianPOC) {
                setIsGuardianPOC(false);
              }
            }}
            firstName={firstName}
          />
          {/* Allow provider auto update */}
          <AutoUpdateSetting
            checked={autoUpdateEnabled}
            onChange={(checked) => onAutoUpdateChange(checked)}
            text="Allow providers to change this recipient's booking details without confirmation"
            textColor={Colors.Dusk}
            textSize={FontSize.F14}
          />
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Dropdown
              title="Relationship"
              options={relationshipOptions}
              selectedOption={relationshipOptions.find((option) => option.value === relationship)}
              onSelectedOption={(option) => setRelationship(option.value)}
            />
          </Box>

          <Box>
            <Gender onChange={(text: any) => setGender(text)} value={gender} />
          </Box>
          <Contraindications
            hasContraindications={hasContraindications}
            setHasContraindications={setHasContraindications}
            contraindications={contraindications}
            setContraindications={setContraindications}
            gender={gender}
          />
          <TextField
            title="Notes for therapist"
            subtitle="Your note is visible to providers."
            value={notes}
            onChange={(text) => {
              setNotes(text);
            }}
            multiline
            maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
            placeholder={NOTE_FOR_THERAPIST_PLACHOLDER}
          />

          <CovidVaccinationInfo
            massageFor={MassageFor.someoneElse}
            isCovidVaccinated={isCovidVaccinated}
            type={MessageType.client}
            onCheckBoxChange={(status) => setIsCovidVaccinated(status)}
          />

          {canAddRecipientsContactPerson && (
            <GuardianForm
              guardianCountryCode={guardianCountryCode}
              setGuardianCountryCode={setGuardianCountryCode}
              guardianFirstName={guardianFirstName}
              setGuardianFirstName={setGuardianFirstName}
              isGuardianPOC={isGuardianPOC}
              setIsGuardianPOC={setIsGuardianPOC}
              setIsRecipientPOC={setIsRecipientPOC}
              guardianLastName={guardianLastName}
              setGuardianLastName={setGuardianLastName}
              guardianEmail={guardianEmail}
              setGuardianEmail={setGuardianEmail}
              guardianMobile={guardianMobile}
              setGuardianMobile={setGuardianMobile}
              isMobile={isMobile}
            />
          )}
          {user?.role ? (
            <>
              <Box
                fontFamily="Museo"
                fontSize="24px"
                color={Colors.Dusk}
                fontWeight={600}
                lineHeight="26px"
                mt="30px"
                mb="25px"
              >
                Additional billing identifiers
              </Box>
              <BillingIdentiferItem
                customerId={String(customerId)}
                orderNumber={orderNumber}
                lineItemCode={lineItemCode}
                setCustomerId={setCustomerId}
                setOrderNo={setOrderNumber}
                setItemCode={setLineItemCode}
                additionalBillConfig={recipientDetailConfig()}
              />
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      <ModalFooter>
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.small}
          title="Cancel"
          width="130px"
          onClick={() => {
            onClose();
          }}
        />
        <Box width="14.5px" />
        <Button
          size={ButtonSize.small}
          title="Save"
          width="130px"
          loading={saving}
          onClick={() => handleSave()}
        />
      </ModalFooter>
    </Dialog>
  );
};

export default RecipientModal;
