import { useQuery } from "@tanstack/react-query";
import countryCodes from "../data/countryCodes.json";
import { DEFAULT_COUNTRY, getCountryCode } from "../utils/country";
import { DropdownOption } from "../components/Dropdown";

export const useCountryCode = () => {
  const { data } = useQuery(["countryCode"], async () => await getCountryCode());

  return data || DEFAULT_COUNTRY;
};

export const useCountryOptions = (): DropdownOption[] =>
  countryCodes.map(({ name, code }) => ({ title: name, value: code }));
