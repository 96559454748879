const DECLINED_REASONS_VALUE = {
  TIME: "time",
  TOO_FAR: "tooFar",
  TOO_LATE: "tooLate",
  HEALTH_FUND: "healthFund",
  ANOTHER_THERAPIST: "anotherTherapist",
  DONT_OFFER_SERVICE: "dontOfferService",
  LOW_PAYOUT: "lowPayout",
  OTHER: "other",
};

const POSSIBLE_REASONS_TO_DECLINE = [
  { label: "Not available at that time", value: DECLINED_REASONS_VALUE.TIME },
  { label: "Too far for me", value: DECLINED_REASONS_VALUE.TOO_FAR },
  { label: "Too late for me", value: DECLINED_REASONS_VALUE.TOO_LATE },
  { label: "I don’t have that health fund", value: DECLINED_REASONS_VALUE.HEALTH_FUND },
  { label: "Requesting another therapist", value: DECLINED_REASONS_VALUE.ANOTHER_THERAPIST },
  { label: "I don’t offer this service", value: DECLINED_REASONS_VALUE.DONT_OFFER_SERVICE },
  { label: "Payout/ price too low", value: DECLINED_REASONS_VALUE.LOW_PAYOUT },
  { label: "Other", value: DECLINED_REASONS_VALUE.OTHER },
];

// need to move these to backend
const ALTERNATIVE_OFFER_MESSAGE =
  "Your offer has been sent to the client. If the client accepts your offer, the booking will be confirmed and added to your Upcoming bookings.";
const ALTERNATIVE_OFFER_UPDATE_MESSAGE =
  "Your alternative date & time has been updated. If the client accepts your offer, the booking will be confirmed and added to your Upcoming bookings.";

export {
  POSSIBLE_REASONS_TO_DECLINE,
  ALTERNATIVE_OFFER_MESSAGE,
  ALTERNATIVE_OFFER_UPDATE_MESSAGE,
  DECLINED_REASONS_VALUE,
};
