import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import queryClient from "../../config/queryClient";

interface Save2FAArgs {
  appName: string;
  userId: string;
  type: string;
  secret?: string;
  uri?: string;
  qr?: string;
  mfaCode?: string;
}

interface MfaLoginArgs {
  code: string;
  email: string;
  serviceId?: string;
}

const KEYS = {
  MFA: "mfa",
};

export const useGenerateMfa = () =>
  useMutation((data: { appName: string; email: string }) =>
    httpClient.post("api/v2/users/mfa/new", data)
  );

export const useEnableMfa = () =>
  useMutation((data: Save2FAArgs) => httpClient.post("api/v2/users/mfa-enable", data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.MFA] });
    },
  });

export const useRemoveMfa = () =>
  useMutation(
    ({ userId, code }: { userId: string; code: string }) =>
      httpClient.post("api/v2/users/mfa-remove", { userId, code }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [KEYS.MFA] });
      },
    }
  );

export const useGetUserMfa = ({ userId }: { userId: string }) =>
  useQuery([KEYS.MFA, userId], () =>
    httpClient.get(`api/v2/users/mfa/${userId}`).then((res) => res.data)
  );

export const useMfaLogin = () =>
  useMutation((data: MfaLoginArgs) => httpClient.post("api/v2/users/mfa-login", data));

export const useSendTwoFAVerificationCode = () =>
  useMutation(({ mobileNumber, fbToken }: { mobileNumber: string; fbToken: string | null }) =>
    httpClient.post("api/v2/users/mnvcrrh", { mobileNumber }, false, {
      "X-SESSION": fbToken,
    })
  );
