import React, { useEffect } from "react";
import { Paths } from "../../constants/paths";
import Wrapper from "../../components/Wrapper";
import { useMobile } from "../../hooks/mobile";
import { useUserStore } from "../../stores/user";
import { RecipientWithSummary } from "../../models";
import { Relationship } from "../../constants/booking";
import RecipientReviews from "./sections/RecipientReviewSection";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SectionPageHeaderV2 from "../../components/SectionPageHeaderV2";
import RecipientDetailsSection from "./sections/RecipientDetailsSection";
import RecipientAddressSection from "./sections/RecipientAddressSection";
import RecipientBillingSection from "./sections/RecipientBillingSection";
import RecipientBookingsSection from "./sections/RecipientBookingsSection";
import RecipientProvidersSection from "./sections/RecipientProvidersSection";
import RecipientDocumentsSection from "./sections/RecipientDocumentsSection";
import { useGetSingleRecipient } from "../../hooks/recipient/recipient.hooks";
import RecipientTreatmentNoteSection from "./sections/RecipientTreatmentNotesSection";
import { RecipientSectionType as Section, RecipientPageSections as Sections } from "./pageSections";

const getActiveComponent = (slug: string, recipient: RecipientWithSummary) => {
  switch (slug) {
    case "details":
      return <RecipientDetailsSection recipient={recipient} />;
    case "bookings":
      return <RecipientBookingsSection recipient={recipient} />;
    case "reviews":
      return <RecipientReviews recipient={recipient} />;
    case "invoices":
      return <RecipientBillingSection recipient={recipient} />;
    case "addresses":
      return <RecipientAddressSection recipient={recipient} />;
    case "providers":
      return <RecipientProvidersSection recipient={recipient} />;
    case "documents":
      return <RecipientDocumentsSection recipient={recipient} />;
    case "treatment-notes":
      return <RecipientTreatmentNoteSection recipient={recipient} />;
    default:
      return <RecipientDetailsSection recipient={recipient} />;
  }
};

const getSelectedSectionIndex = (arr: Section[], activeSection: Section) => {
  let selectedIndex = arr.findIndex((sec) => sec.slug === activeSection.slug);

  if (selectedIndex < 0) {
    selectedIndex = 0;
  }

  return selectedIndex;
};

const getActiveSection = (slug: string = "") => {
  let section = Sections.find((s) => s.slug === slug);
  return section ? section : Sections[0];
};

const RecipientsPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();
  const user = useUserStore();
  const isHomeCareUser = user.user?.isHomeCareProvider;
  const { id, section: activeSectionSlug } = useParams<{ id?: string; section?: string }>();

  const recipientId = parseInt(id || "");
  const { data: recipient, isLoading } = useGetSingleRecipient({ id: recipientId });
  const validSections = Sections.filter((section) => (isHomeCareUser ? true : !section.homecare));
  const activeSection = getActiveSection(activeSectionSlug);

  const initialIndex = getSelectedSectionIndex(validSections, activeSection);
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState<number>(initialIndex);
  useEffect(() => {
    setSelectedSectionIndex(getSelectedSectionIndex(validSections, activeSection));
  }, [location.pathname, activeSection, validSections]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!recipient) {
    return null;
  }

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeaderV2
            title={`${recipient.firstName} ${recipient.lastName}`}
            subtitle={Relationship.toString(recipient.relationship)}
            disabledSections={Sections.filter((sec) => sec.disabled).map((sec) => sec.title)}
            sections={[...validSections.map((section) => section.title)]}
            onBack={isMobile ? undefined : () => history.push(Paths.Recipients)}
            selectedSectionIndex={selectedSectionIndex}
            onSelectedSectionIndex={(index) => {
              let activeSection = validSections[index];
              if (!activeSection) {
                activeSection = validSections[0];
              }
              history.push(`/recipients/${id}/${activeSection.slug}`);
            }}
          />
        }
      >
        {getActiveComponent(activeSection.slug, recipient)}
      </Wrapper>
    </>
  );
};

export default RecipientsPage;
