import { useCountDown } from "@get-blys/hooks.use-count-down";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../constants/colors";
import { retryPayment } from "../../services/bookings/bookings.service";
import { useAlertStore } from "../../stores/alert";
import Button, { ButtonSize, ButtonType } from "../Button";
import PaymentModal from "../PaymentFrame/PaymentModal";
import { ERROR_ORDER, parseApiErrorFromResponse } from "../../helpers/error";

export default function PaymentDeclined({
  booking,
  isMobile = false,
}: {
  booking: any;
  isMobile?: boolean;
}) {
  const buttonWidth = isMobile ? "48%" : "148px";
  const [enablePaymentModal, setEnablePaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { setErrorMessage } = useAlertStore();
  const history = useHistory();

  const countDown = useCountDown({
    createdAt: booking.bookingPayment ? booking.bookingPayment.declinedPaymentExpiryTime : null,
    timezone: booking.timezone,
  });

  const getPaymentFailedMessage = (bookingLogs: any = []) => {
    const log = bookingLogs.find((bookingLog: any) =>
      bookingLog.text.startsWith("Payment declined")
    );
    return log?.text ?? "";
  };

  const handleRetry = async () => {
    try {
      // check if payment method is selected
      if (!booking?.paymentmethod?.id) {
        return setErrorMessage("Please select your payment method!");
      }

      setPaymentLoading(true);

      // make api call
      await retryPayment(booking.id);

      // reload page
      history.go(0);
    } catch (error) {
      setErrorMessage(
        parseApiErrorFromResponse({
          error,
          order: [ERROR_ORDER.GENERIC, ERROR_ORDER.DETAIL, ERROR_ORDER.RESPONSE],
        })
      );
    } finally {
      setPaymentLoading(false);
    }
  };

  const paymentFailedMessage = getPaymentFailedMessage(booking.bookinglogs);

  const bookingDeclinedMessage = "Please try again or the booking will cancel";
  const updatedRequestDeclinedMessage =
    "Please try again or the booking will revert to its original state";
  const message = booking.isUpdateRequestPaymentDeclined
    ? updatedRequestDeclinedMessage
    : bookingDeclinedMessage;

  return (
    <>
      <Box
        display="flex"
        justifyContent={"space-between"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box>
          <Box
            marginBottom={"8px"}
            fontFamily="Museo"
            fontWeight={700}
            fontSize="18"
            fontSize="18px"
            color={Colors.Dusk}
          >
            Payment declined
          </Box>
          <Box
            fontSize="14"
            color={Colors.Dusk}
            fontFamily="Open Sans"
            style={{
              fontSize: "14px",
            }}
          >
            {paymentFailedMessage}{" "}
            {countDown ? (
              <span>
                {message} in: <span style={{ color: Colors.Danger }}>{countDown}</span>{" "}
              </span>
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box
          style={{
            marginTop: "16px",
            marginLeft: isMobile ? "0px" : "16px",
            display: isMobile ? "flex" : "block",
            justifyContent: "space-between",
          }}
        >
          <Button
            type={ButtonType.outlined}
            size={ButtonSize.small}
            height={48}
            width={buttonWidth}
            colorTitle={Colors.Dusk}
            title="Manage payment"
            fontStyle={{
              fontFamily: "Museo",
              fontWeight: 600,
              fontSize: 14,
            }}
            style={{ borderRadius: 4, marginBottom: "8px" }}
            onClick={() => setEnablePaymentModal(true)}
          />
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            height={48}
            width={buttonWidth}
            colorTitle={Colors.White}
            title="Retry now"
            loading={paymentLoading}
            fontStyle={{
              fontFamily: "Museo",
              fontWeight: 600,
              fontSize: 14,
            }}
            style={{ borderRadius: 4 }}
            onClick={() => handleRetry()}
          />
        </Box>
      </Box>
      <PaymentModal
        booking={booking}
        open={enablePaymentModal}
        onClose={() => setEnablePaymentModal(false)}
      />
    </>
  );
}
