import { isEmpty, isNil } from "lodash";
import moment from "moment";
import {
  BOOKING_STATUS,
  BookingUpdateStatus,
  DEFAULT_PROFESSION,
  JobStatus,
  MASSAGE_TYPES,
  MassageType,
  RecurringType,
  formatDisplayNewService,
  weekDayPositions,
} from "../constants/booking";
import { BASE_UPLOADS_URL } from "../constants/common";
import { DEFAULT_BOOKING_DATE_FORMAT } from "../constants/date";
import { Booking, BookingDetails, ServiceRate, ServiceRatePrice } from "../models";
import { getRangedBookingFormattedDateTime } from "../services/bookings/bookingTime.service";
import {
  getBookingUpdateDetails,
  isAfterPayUnpaidBooking,
  isManualBookingUnpaid,
} from "../services/bookings/bookings.service";
import {
  MonthData,
  Recurring,
  getMassageDurationFromBooking,
  isBackToBackMassageType,
  isCoupleMassageType,
  massageTreatmentDetails,
} from "../stores/booking";
import {
  formatDateWithTimezone,
  getDayNameFromIndex,
  getNextBookingDateForRecurrence,
  getTimeOfArrivalWithGraceTime,
  isSameDay,
} from "../utils/date.util";
import { checkIfEmpty, getValue } from "../utils/object";
import { checkIfFallbackUpdateRequestHasProviderPendingStatus } from "./job";
import { capitalizeFirstLetter } from "./string";

export const timeRangeForBooking = (booking: Booking) => {
  const { earliestTime, latestTime, timeOfArrival, sessionType } = booking;
  const earliestTimeMoment = moment(earliestTime);

  const latestTimeMoment = moment(latestTime);

  const earliestHourMinute = earliestTimeMoment.tz(booking.timezone).format("h:mma");
  const latestHourMinute = latestTimeMoment.tz(booking.timezone).format("h:mma");

  if (timeOfArrival) {
    const timeOfArrivalMoment = moment(timeOfArrival);
    const timeOfArrivalHourMinute = timeOfArrivalMoment.tz(booking.timezone).format("h:mma");

    return `starting at ${timeOfArrivalHourMinute}`;
  } else {
    if (earliestTimeMoment.isSame(latestTimeMoment) || isCoupleMassageType(sessionType)) {
      return `starting at ${earliestHourMinute}`;
    } else {
      return `starting anytime between ${earliestHourMinute} - ${latestHourMinute}`;
    }
  }
};

export const BOOKING_SESSION_TYPE = {
  SINGLES: "singles",
  COUPLES: "couples",
  BACK_TO_BACK: "backtoback",
  CORPORATE: "corporate",
  GROUP_BOOKING: "groupbooking",
};

const getShortSessionDescriptionForBooking = (booking: Booking) => {
  const { sessionType } = booking;

  switch (sessionType) {
    case "singles":
      return "Single";
    case "couples":
    case "backtoback":
      return "Couples";
    case "corporate":
      return "Corporate";
    case "groupbooking":
      return "Group Booking";

    case "initialConsultation(online)":
      return "Initial Consultation(Online)";
    case "followUpSession(online)":
      return "Follow-up Session(Online)";
    case "begginerYoga(online)":
      return "Beginner Yoga (Online)";
    case "wakeUpYoga(online)":
      return "Wake Up Yoga(Online)";
    case "comeDownYoga(online)":
      return "Come Down Yoga(Online)";
    case "powerHouseYoga(online)":
      return "Power House Yoga(Online)";
    case "pilates(30)min(online)":
      return "30min Session(Online)";
    case "pilates(60)min(online)":
      return "60min Session(Online)";
    case "personalTrainer(30)min(online)":
      return "30min Session(Online)";
    case "personalTrainer(60)min(online)":
      return "60min Session(Online)";
    case "psychology(30)min(online)":
      return "30min Session(Online)";
    case "psychology(60)min(online)":
      return "60min Session(Online)";
    default:
      return null;
  }
};

const getServiceTypeDescription = (massageType: string) => {
  switch (massageType) {
    case "initialConsultation(online)":
    case "followUpSession(online)":
      return "Physiotherapy";
    case "begginerYoga(online)":
    case "wakeUpYoga(online)":
    case "comeDownYoga(online)":
    case "powerHouseYoga(online)":
      return "Yoga";
    case "pilates(30)min(online)":
    case "pilates(60)min(online)":
      return "Pilates";
    case "personalTrainer(30)min(online)":
    case "personalTrainer(60)min(online)":
      return "Personal Trainer";
    case "psychology(30)min(online)":
    case "psychology(60)min(online)":
      return "Psychology";

    default:
      return "";
  }
};

export const durationAndTimeRangeForBookingDetails = (detail: BookingDetails) => {
  return durationAndTimeRangeForBooking(detail.booking, detail);
};

export const durationAndTimeRangeForBooking = (
  booking: Booking,
  forBookingDetails?: BookingDetails
) => {
  const { earliestTime, latestTime, timeOfArrival, sessionType, corporateInfo } = booking;
  const isCoupleMassage = isCoupleMassageType(sessionType);
  const allMassageTypes = Object.keys(MASSAGE_TYPES);

  const bookingDetails = forBookingDetails || booking.bookingdetails[0] || {};
  const { job, massageType } = bookingDetails;

  let earliestTimeMoment = moment(earliestTime);

  let latestTimeMoment = moment(latestTime);

  let earliestHourMinute = earliestTimeMoment.tz(booking.timezone).format("h:mma");
  let latestHourMinute = latestTimeMoment.tz(booking.timezone).format("h:mma");

  const [length1, length2] = getMassageDurationFromBooking(sessionType, booking.bookingdetails);

  let duration = length1 || booking.massageDuration || 0;

  if (corporateInfo && !checkIfEmpty(corporateInfo)) {
    duration = corporateInfo.totalEventDuration;
  }

  let durationText = length2 ? `${length1}min + ${length2}min` : `${duration}min`;

  let session = getShortSessionDescriptionForBooking(booking);

  if (job?.finishedAt) {
    // Past booking
    if (booking?.serviceType == "Massage") {
      return `${durationText} ${session} massage - `;
    }
    return "";
  } else {
    // Upcoming

    if (timeOfArrival) {
      let timeOfArrivalMoment = moment(timeOfArrival);
      let timeOfArrivalHourMinute = timeOfArrivalMoment.tz(booking.timezone).format("h:mma");

      if (allMassageTypes.includes(massageType)) {
        return `${durationText} ${session} massage at ${timeOfArrivalHourMinute}`;
      } else {
        return `${getServiceTypeDescription(massageType)} at ${timeOfArrivalHourMinute}`;
      }
    } else {
      if (allMassageTypes.includes(massageType)) {
        if (earliestTimeMoment.isSame(latestTimeMoment) || isCoupleMassage) {
          return `${durationText} ${session} massage at ${earliestHourMinute}`;
        } else {
          return `${durationText} ${session} massage between ${earliestHourMinute} - ${latestHourMinute}`;
        }
      } else {
        const serviceTypeDescription = getServiceTypeDescription(massageType);

        if (earliestTimeMoment.isSame(latestTimeMoment) || isCoupleMassage) {
          return serviceTypeDescription
            ? `${serviceTypeDescription} at ${earliestHourMinute}`
            : `At ${earliestHourMinute}`;
        } else {
          return serviceTypeDescription
            ? `${serviceTypeDescription} between ${earliestHourMinute} - ${latestHourMinute}`
            : `Between ${earliestHourMinute} - ${latestHourMinute}`;
        }
      }
    }
  }
};

export const timeDifferenceForBooking = (booking: Booking) => {
  const finishedAt = booking.bookingdetails[0]?.job?.finishedAt;

  if (finishedAt) {
    return moment(finishedAt).fromNow();
  } else {
    return moment(booking.timeOfArrival || booking.earliestTime).fromNow();
  }
};

export function therapistProfilePictureUrlForBookingDetail(bookingDetail: BookingDetails) {
  let path = bookingDetail.job?.user?.therapistprofile?.profileImage;

  if (path) {
    return `${BASE_UPLOADS_URL}/${path}`;
  } else {
    return null;
  }
}

export function covidStatusForBookingDetail(bookingDetail: BookingDetails) {
  let covidStatus = bookingDetail.job?.user?.isCovidVaccinated;

  return covidStatus || false;
}

export function getMassageDescriptionForBooking(booking: Booking) {
  let massageDescriptions: Array<string> = [];

  let { bookingdetails, title } = booking;
  if (title) return title;

  const treatmentDetails = bookingdetails[0]?.treatmentDetails;
  if (treatmentDetails && treatmentDetails.length > 1) {
    if (!isBackToBackMassageType(booking.sessionType)) {
      return booking.serviceType;
    }

    // to show description for b2b massage type
    const massageTypes = new Set();
    treatmentDetails.forEach((detail) => {
      massageTypes.add(MassageType.toString(detail.massageType));
    });
    return Array.from(massageTypes).join(", ");
  }

  if (bookingdetails && bookingdetails.length) {
    bookingdetails.forEach((detail) => {
      // massageTypes.push(detail.massageType);
      massageDescriptions.push(MassageType.toString(detail.massageType));
    });

    return massageDescriptions.join(", ");
  }

  // defaults to service type if booking details wans't created
  return booking.serviceType;
}

export function getMassageDescriptionForBookingDetail(bookingDetail: BookingDetails) {
  let { massageType }: any = bookingDetail;

  if (massageType) {
    switch (massageType) {
      case "swedish":
        return "Swedish";

      case "deeptissue":
        return "Deep Tissue";

      case "sports":
        return "Sports";

      case "pregnancy":
        return "Pregnancy";

      case "corporatechair":
        return "Chair";

      case "corporatetable":
        return "Table";

      case "corporateroving":
        return "Roving";

      case "chair":
        return "Chair";
      case "table":
        return "Table";
      case "roving":
        return "Roving";

      default:
        return formatDisplayNewService(massageType);
    }
  } else {
    return "";
  }
}

export function getManualBookingStatus(booking: Booking) {
  if (booking.statusFlag?.isCancelled || booking.statusFlag?.isPending) {
    return null;
  } else if (isManualBookingUnpaid(booking)) {
    return JobStatus.pendingPayment;
  }
  return null;
}

export function getAfterPayBookingStatus(booking: Booking) {
  if (booking.statusFlag?.isCancelled || booking.statusFlag?.isPending) {
    return null;
  } else if (isAfterPayUnpaidBooking(booking) && booking.status !== BOOKING_STATUS.COMPLETED) {
    return JobStatus.pendingPayment;
  }

  return null;
}

export function getBookingStatusForBooking(booking: Booking) {
  const { bookingdetails, userId, bookingUpdates } = booking;
  const hasPendingRequest = checkIfFallbackUpdateRequestHasProviderPendingStatus(booking);

  const afterPayBookingStatus = getAfterPayBookingStatus(booking);
  if (afterPayBookingStatus) return afterPayBookingStatus;

  if (bookingdetails && bookingdetails.length > 0) {
    let statuses: Array<string> = [];

    bookingdetails.forEach((detail: any) => {
      let status = detail.job?.status || detail.status;

      const updateDetails = getBookingUpdateDetails(userId, bookingUpdates);
      if (updateDetails) {
        const { jobStatus } = updateDetails;
        if (jobStatus) status = jobStatus;
      }

      if (status) {
        statuses.push(status);
      }
    });

    if (hasPendingRequest) {
      statuses.push(BookingUpdateStatus.requestByClient);
    }

    if (statuses.length === 0) {
      return JobStatus.pending;
    }

    if (statuses.includes(JobStatus.pending)) {
      return JobStatus.pending;
    } else if (statuses.includes(BookingUpdateStatus.requestByClient)) {
      return BookingUpdateStatus.requestByClient;
    } else if (statuses.includes(JobStatus.rebooking)) {
      return JobStatus.rebooking;
    } else if (statuses.includes(JobStatus.accepted)) {
      return JobStatus.accepted;
    } else if (statuses.includes(JobStatus.onway)) {
      return JobStatus.onway;
    } else if (statuses.includes(JobStatus.finished)) {
      return JobStatus.finished;
    } else if (statuses.includes(JobStatus.cancelled)) {
      return JobStatus.cancelled;
    } else if (statuses.includes(JobStatus.reviewLeft)) {
      return JobStatus.reviewLeft;
    }

    return JobStatus.pending;
  } else {
    return JobStatus.pending; // Pending if no details yet
  }
}

export function bookingIsComplete(booking: Booking) {
  return (
    getBookingStatusForBooking(booking) === JobStatus.finished ||
    getBookingStatusForBooking(booking) === JobStatus.reviewLeft
  );
}

export function bookingIsCancelled(booking: Booking) {
  return getBookingStatusForBooking(booking) === JobStatus.cancelled;
}

export function bookingIsConfirmed(booking: Booking) {
  return getBookingStatusForBooking(booking) === JobStatus.accepted;
}

export function getDateForBooking(booking: Booking) {
  let { earliestTime } = booking;
  let earliestTimeMoment = moment(earliestTime);

  return earliestTimeMoment.tz(booking.timezone).format("ddd, D MMM");
}

// same as getTimeRangeForBooking but does not take the entire booking Object
export function getBookingTimeRange(
  earliestTime: string,
  latestTime: string,
  sessionType: string,
  timeOfArrival: string,
  timezone = "Australia/Sydney"
) {
  let earliestTimeMoment = moment(earliestTime);

  let latestTimeMoment = moment(latestTime);

  let earliestHourMinute = earliestTimeMoment.tz(timezone).format("h:mma");
  let latestHourMinute = latestTimeMoment.tz(timezone).format("h:mma");

  if (timeOfArrival) {
    let timeOfArrivalMoment = moment(timeOfArrival);
    let timeOfArrivalHourMinute = timeOfArrivalMoment.tz(timezone).format("h:mma");

    return `starting at ${timeOfArrivalHourMinute}`;
  } else {
    if (earliestTimeMoment.isSame(latestTimeMoment) || isCoupleMassageType(sessionType)) {
      return `starting at ${earliestHourMinute}`;
    } else {
      return `starting anytime between ${earliestHourMinute} - ${latestHourMinute}`;
    }
  }
}

export function getTimeRangeForBooking(booking: Booking) {
  let { earliestTime, latestTime, timeOfArrival, sessionType } = booking;

  let earliestTimeMoment = moment(earliestTime);

  let latestTimeMoment = moment(latestTime);

  let earliestHourMinute = earliestTimeMoment.tz(booking.timezone).format("h:mma");
  let latestHourMinute = latestTimeMoment.tz(booking.timezone).format("h:mma");

  if (timeOfArrival) {
    let timeOfArrivalMoment = moment(timeOfArrival);
    let timeOfArrivalHourMinute = timeOfArrivalMoment.tz(booking.timezone).format("h:mma");

    return `starting at ${timeOfArrivalHourMinute}`;
  } else {
    if (earliestTimeMoment.isSame(latestTimeMoment) || isCoupleMassageType(sessionType)) {
      return `starting at ${earliestHourMinute}`;
    } else {
      return `starting anytime between ${earliestHourMinute} - ${latestHourMinute}`;
    }
  }
}

export function getTherapistFirstNameWithBookingDetail(detail: BookingDetails) {
  return detail?.job?.user?.firstName || "";
}

export function getPreferredTherapistId(booking: Booking) {
  if (!booking.preferredTherapists) {
    return null;
  }

  let therapistId = booking.preferredTherapists;
  const preferredTherapists = (booking.preferredTherapists || "").split(",");
  if (typeof preferredTherapists === "object" && preferredTherapists) {
    therapistId = preferredTherapists[0];
  }

  return parseInt(therapistId, 10);
}

export const checkIfJobAccepted = (booking: Booking | undefined, allJobs = false) => {
  if (!booking) return;
  const { bookingdetails } = booking;
  return allJobs
    ? bookingdetails.every(({ job }) => job.status === JobStatus.accepted)
    : bookingdetails.some(({ job }) => job.status === JobStatus.accepted);
};

export const bookingFrequencyCaption = ({
  bookingDate,
  frequency,
  timezone = "Australia/Sydney",
  monthData,
  type = RecurringType.WEEK,
}: {
  bookingDate: Date | string | null | undefined;
  frequency: number | null | undefined;
  timezone?: string;
  monthData: MonthData | null;
  shouldCreateFutureBookings?: boolean;
  type?: RecurringType | null;
}) => {
  if (!frequency) {
    return;
  }

  // if (shouldCreateFutureBookings) {
  //   return "Your future bookings will be placed along with this booking";
  // }

  const nextDate = getNextBookingDateForRecurrence({
    date: bookingDate,
    monthData,
    frequency,
    type,
  });

  const formattedDate =
    !isEmpty(nextDate) && nextDate
      ? nextDate.tz(timezone || "Australia/Sydney").format(DEFAULT_BOOKING_DATE_FORMAT)
      : nextDate;
  return `Your next booking will be on ${formattedDate}.`;
};

export const frequencyTitleForBooking = ({
  frequency,
  recurring,
}: {
  frequency: number | null;
  recurring: Recurring;
}) => {
  if (isNil(frequency)) {
    return "Select Booking Frequency";
  }
  if (frequency === 0) {
    return "One off";
  }

  const monthText = frequency && frequency > 1 ? "months" : "month";
  const weekText = frequency && frequency > 1 ? "weeks" : "week";
  const dayText = frequency && frequency > 1 ? "days" : "day";
  const frequencyText = frequency && frequency > 1 ? `${frequency} ` : "";

  if (recurring?.type === RecurringType.MONTH) {
    const dayOfMonth = getValue(recurring, "monthly.dayOfMonth", null);
    const weekOfMonth = getValue(recurring, "monthly.weekOfMonth", null);

    if (dayOfMonth) {
      return `Repeats every ${frequencyText}${monthText} on day ${dayOfMonth}`;
    } else if (weekOfMonth) {
      const dayOfWeek = getDayNameFromIndex(recurring.monthly?.dayOfWeek || 1);
      return `Repeats every ${frequencyText}${monthText} on ${
        weekDayPositions[weekOfMonth - 1] || weekDayPositions[weekDayPositions.length]
      } ${dayOfWeek}`;
    }
  }
  return `Repeats every ${frequencyText} ${
    recurring?.type === RecurringType.DAY ? dayText : weekText
  }`;
};

export const getJobSessionTypeText = (sessionType: string) => {
  switch (sessionType) {
    case BOOKING_SESSION_TYPE.SINGLES:
      return "Single";

    case BOOKING_SESSION_TYPE.COUPLES:
      return "Couple";

    case BOOKING_SESSION_TYPE.BACK_TO_BACK:
      return "Couple (1 therapist, back-to-back)";

    case BOOKING_SESSION_TYPE.CORPORATE:
      return "Corporate";

    case BOOKING_SESSION_TYPE.GROUP_BOOKING:
      return "Group booking";

    default:
      return "None";
  }
};

export const getTotalJobDuration = ({
  massageDuration,
  sessionType,
  serviceDuration,
}: {
  massageDuration: number;
  sessionType: string;
  serviceDuration: number;
}) => {
  let totalDuration = massageDuration || 0;
  // eslint-disable-next-line max-len
  if (
    massageDuration > 0 &&
    sessionType === BOOKING_SESSION_TYPE.BACK_TO_BACK &&
    !serviceDuration
  ) {
    totalDuration = massageDuration * 2;
  } else if (serviceDuration && serviceDuration > 0) {
    totalDuration = serviceDuration;
  } else {
    totalDuration = massageDuration || 0;
  }

  const totalDurationInHours = totalDuration % 60 === 0 ? totalDuration / 60 : totalDuration / 60;
  return `${parseFloat(totalDurationInHours.toFixed(2))}h`;
};

const getFormattedJobTime = ({ earliestTime, latestTime, timeOfArrival, timezone }: any) => {
  const tz = timezone || null;
  if (timeOfArrival) {
    return formatDateWithTimezone(timeOfArrival, tz, "hh:mma");
  } else if (moment(earliestTime).isSame(latestTime)) {
    return formatDateWithTimezone(earliestTime, tz, "hh:mma");
  }
  let date = formatDateWithTimezone(earliestTime, tz, "hh:mma");
  if (latestTime) {
    date += ` - ${formatDateWithTimezone(latestTime, tz, "hh:mma")}`;
  }
  return date;
};

export const getFormattedJobDateTime = ({
  earliestTime,
  latestTime,
  timeOfArrival,
  timezone,
  showTextWithRange = false,
}: any) => {
  const selectedEarliestTime = timeOfArrival || earliestTime;

  const isRanged = !isSameDay(earliestTime, latestTime, timezone);
  if (isRanged) {
    const { date: formattedDate, time: formattedTime } = getRangedBookingFormattedDateTime({
      earliestTime,
      latestTime,
      timezone,
      prefix: false,
    });

    if (showTextWithRange) {
      return `${formattedDate}, starting anytime between ${formattedTime}`;
    }

    return `${formattedDate}, ${formattedTime}`;
  }

  const month = formatDateWithTimezone(selectedEarliestTime, timezone, DEFAULT_BOOKING_DATE_FORMAT);
  const time = getFormattedJobTime({ earliestTime, latestTime, timeOfArrival, timezone });

  if (showTextWithRange) {
    return `${month}, starting anytime between ${time}`;
  }

  return `${month} ${time}`;
};
export const parseServiceDescriptionForJob = ({
  job,
  services,
  includeDuration = true,
  includeSessionType = true,
}: any) => {
  const isMassage = getValue(job, "bookingdetail.booking.isMassage");
  const { massageDuration, sessionType } = getValue(job, "bookingdetail.booking");

  const massageType = getValue(job, "bookingdetail.massageType") || "";
  const jobDuration = getTotalJobDuration({
    massageDuration,
    sessionType,
    serviceDuration: getValue(job, "bookingdetail.serviceDuration"),
  });

  const booking = getValue(job, "bookingdetail.booking") || {};
  const { sessionType: bookingSession } = booking;

  const isBackToBack = bookingSession === BOOKING_SESSION_TYPE.BACK_TO_BACK;

  const selectedService = getValue(booking, "service");
  const bookingServices = getValue(booking, "bookingservices", []);

  let serviceLabel = `${selectedService?.alias} `;

  if (!isBackToBack) {
    serviceLabel += "| ";
    if (isMassage) {
      const treatementLabel = getValue(job, "bookingdetail.treatmentDetails[0].treatment.label");
      const prices = services.find(({ id }: any) => id === selectedService.id)?.prices || [];
      if (prices && prices.length > 0) {
        const treatmentId = getValue(job, "bookingdetail.treatmentDetails[0].treatmentTypeId");

        serviceLabel +=
          prices.find(({ id }: any) => id === treatmentId)?.label || treatementLabel || massageType;
      } else {
        serviceLabel += treatementLabel || massageType;
      }
    } else {
      if (!bookingServices || bookingServices.length <= 0) {
        const labels = massageType.split(" | ");
        labels[0] = getValue(selectedService, "alias", massageType); // replace service type with service alias
        serviceLabel += labels.join(" | ");
      } else {
        serviceLabel += bookingServices[0].treatment;
      }
    }
  }

  let formattedLabel = `${serviceLabel}`;
  if (includeDuration) {
    formattedLabel = `${jobDuration} - ` + formattedLabel;
  }
  if (isMassage && includeSessionType) {
    formattedLabel += ` - ${getJobSessionTypeText(sessionType)}`;
  }
  return formattedLabel;
};

export const getTooSoonToCancelMessage = (timeOfArrival: string) => {
  return {
    title: "Too soon to cancel",
    body: `Providers are given a 20min grace period for no-shows.\nPlease wait until ${getTimeOfArrivalWithGraceTime(
      timeOfArrival
    ).format("h:mma")} for your provider to show up.`,
  };
};

export const getSelectedAddOnName = ({ addOn }: { addOn: ServiceRatePrice[] | undefined }) => {
  const addOnName =
    addOn?.map((data: ServiceRatePrice) => data.label || data.name).join(" | ") ?? "None";

  return checkIfEmpty(addOnName) ? "None" : addOnName;
};

export const formatMassageTreatmentDetailsData = (
  massageTreatmentDetails: massageTreatmentDetails[]
) => {
  return massageTreatmentDetails.map((data: massageTreatmentDetails) => {
    const details = { treatmentTypeId: data.treatmentTypeId, duration: data.duration, addons: [] };
    const addOn = data.addons?.map((data: any) => ({
      id: data.treatmentAddons?.id || data?.id,
      custom: false,
    })) as any;
    details.addons = addOn;
    return details;
  });
};

export const formatTreatmentDetailsData = (treatmentDetails: any[]) => {
  return treatmentDetails.map((data: any) => {
    const details = { treatmentTypeId: data.treatmentId, addons: [] };
    const addOn = data.addons?.map((data: any) => ({
      id: data.treatmentAddons?.id || data?.id,
      custom: false,
    })) as any;
    details.addons = addOn;
    return details;
  });
};

export const formatTreatmentAddOnData = (addOn: ServiceRatePrice[] = []) => {
  return addOn.map((data: any) => ({
    id: data?.treatmentAddons?.id || data?.id,
    custom: data?.custom || false,
  }));
};

export const getTreatmentAddOnForConfirmedBooking = ({
  selectedTreatmentId,
  treatmentsList,
  proAddOns = [],
  selectedCountry,
}: {
  selectedTreatmentId: number;
  treatmentsList: any;
  proAddOns?: any[];
  selectedCountry: { currency: string; currencySymbol: string } | undefined;
}) => {
  const addOnList =
    treatmentsList
      .filter((data: any) => data.id === selectedTreatmentId)
      .flatMap((data: any) => data?.addons) || [];

  const systemAddOns = tranformTreatmentAddOn({
    addons: addOnList,
    custom: false,
    selectedCountry,
  });
  const proCreatedAddOns = tranformTreatmentAddOn({
    addons: proAddOns,
    custom: true,
    selectedCountry,
  });

  return [...systemAddOns, ...proCreatedAddOns];
};

export const getSelectedAddOnForConfirmedBooking = ({
  treatmentDetails,
  index,
}: {
  treatmentDetails: any;
  index: number;
}) => {
  return treatmentDetails[index]?.addons || [];
};

export const isMultipleAddOnAllowdForConfirmedBooking = ({
  selectedTreatmentId,
  treatmentsList,
}: {
  selectedTreatmentId: number;
  treatmentsList: any;
}) => {
  const treatment = treatmentsList.find((data: any) => data.id === selectedTreatmentId);
  if (!treatment) return false;
  return treatment?.isMultipleAddonsAllowed || false;
};

export const tranformTreatmentAddOn = ({
  addons,
  custom = false,
  selectedCountry,
}: {
  addons: any[];
  custom: boolean;
  selectedCountry: { currency: string; currencySymbol: string } | undefined;
}) => {
  return addons.map((data) => ({ ...data, custom, selectedCountry }));
};

export const getServiceLabel = (service?: ServiceRate, isCouples = false) => {
  if (!service) {
    return "Select service";
  }

  const serviceName = service.alias;
  let therapistCount = 1;

  if (service.isMassage && isCouples) {
    therapistCount = 2;
  }

  const profession = capitalizeFirstLetter(getValue(service, "profession") || DEFAULT_PROFESSION);
  let suffix = `${profession}${therapistCount > 1 ? "s" : ""}`;
  return `${serviceName} | ${therapistCount} ${suffix}`;
};
