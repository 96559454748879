import useSWR from "swr";
import React from "react";
import { Box } from "@material-ui/core";
import { UploadedFile } from "../../models";
import { Colors } from "../../constants/colors";
import { useUserStore } from "../../stores/user";
import { useAccessToken } from "../../hooks/common";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import { DEFAULT_COUNTRY } from "../../utils/country";
import Button, { ButtonType } from "../../components/Button";
import { ProFileOwnership } from "../../constants/prodashboard";
import UploadedFileRow from "../../components/ProDashboard/UploadedFileRow";
import * as therapistService from "../../services/therapist/therapist.service";
import {allowUploadImageExtension, filterImageAndApplicationExtension } from "../../helpers/services";
import ActionModal from "../../components/ActionModal";

const Portfolio = () => {
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const portfolioUploadRef = React.useRef<HTMLInputElement>(null);

  const accessToken = useAccessToken();
  const { user } = useUserStore();
  const [removeConfirmVisible, setRemoveConfirmVisible] = React.useState(false);
  const [fileBeingHandled, setFileBeingHandled] = React.useState<UploadedFile | null>(null);
  const [removeLoading, setRemoveLoading] = React.useState(false);

  const beautyProfession = therapistService.getBeautyProfessionFromCountry(
    user?.country || DEFAULT_COUNTRY
  );

  const { data: uploadedFilesData, mutate: uploadedFilesMutate } = useSWR(
    `/therapists/me/files?accessToken=${accessToken}`
  );

  const uploadedFiles = uploadedFilesData
    ? (uploadedFilesData as UploadedFile[]).filter(
        (file) => file.type === ProFileOwnership.portfolio
      )
    : [];

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event?.target?.files && event.target.files[0];
    if(!filterImageAndApplicationExtension(file)){
      setErrorMessage("Unsupported file type. Please upload a PNG, JPG, or JPEG file.");
      return;
    }
    if (file) {
      const form = new FormData();
      form.append("image", file);

      if (user) {
        therapistService
          .uploadFile(user.id, accessToken, form)
          .then(() => {
            uploadedFilesMutate();
            setSuccessMessage("This file has been uploaded");
          })
          .catch((error) => {
            setErrorMessage(parseApiError(error));
          });
      }
    }
 
  };

  const onFileRemove = (file: any) => {
    if (user) {
      setRemoveLoading(true);
      therapistService
        .removeFile(file.id, user.id, accessToken)
        .then((response) => {
          uploadedFilesMutate();
          setSuccessMessage("This file has been deleted");
        })
        .catch((error) => {
          setErrorMessage(parseApiError(error));
        })
        .finally(() => {
          setRemoveLoading(false);
          setRemoveConfirmVisible(false);
        });
    } else {
      setErrorMessage("Something went wrong. Please try again.");
      setRemoveConfirmVisible(false);
    }
  };

  const disableFileAddition = uploadedFiles.length > 19;

  return (
    <Box padding={"21px 0"}>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="14px"
        lineHeight="21px"
        color={Colors.Dusk}
      >
        My Portfolio
      </Box>
      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        fontWeight={400}
        lineHeight="21px"
        color={Colors.Dusk}
        mt={1}
        mb={2}
      >
        {`If you’re a ${beautyProfession}, hair and makeup artist or hairdresser upload images of your work to showcase your talents.`}
      </Box>
      {uploadedFiles.map((uploadedFile: any) => (
        <UploadedFileRow
          file={uploadedFile}
          onRemove={() => {
            setRemoveConfirmVisible(true);
            setFileBeingHandled(uploadedFile);
          }}
        />
      ))}
      <Box mt="37px">
        <Button
          title="Upload Image"
          type={ButtonType.outlined}
          width="132px"
          disabled={disableFileAddition}
          onClick={() => {
            if (portfolioUploadRef.current) {
              portfolioUploadRef.current.click();
            }
          }}
        />
      </Box>
      <input
        type="file"
        id="portfolio-upload-file"
        ref={portfolioUploadRef}
        style={{ display: "none" }}
        onChange={(event) => {
          onFileInputChange(event);
        }}
        accept={allowUploadImageExtension()}
      />
      <ActionModal
        open={removeConfirmVisible}
        title={"Remove file"}
        description={
          "Please confirm if you want to remove this file. This action can’t be undone. If you want to upload it again, you’ll need to reselect the file."
        }
        onCancel={() => {
          setRemoveConfirmVisible(false);
        }}
        onConfirm={() => {
          onFileRemove(fileBeingHandled as UploadedFile);
        }}
        confirmText="Confirm"
        cancelText="Back"
        isLoading={removeLoading}
      />
    </Box>
  );
};

export default Portfolio;
