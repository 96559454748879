import { isArray } from "../../../utils/array";
import { formatDate } from "../../../utils/date.util";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { DATE_ISO_8601_FORMAT } from "../../../constants/date";
import { useNameLockedForUser } from "../../../hooks/userProfileLock.hooks";
import { PayoutOnboardingSteps } from "../../../pages/ProDashboard/Business/Payouts/SetupPayoutTimeline";
import {
  IFormData,
  FormStepIndex,
  FormStepsName,
  AdditionalInformation,
} from "../../../pages/ProDashboard/Business/Payouts/Forms/model";

const transformStripePayoutDetailsResponse = (stripeAccount: any) => {
  return {
    [FormStepsName.PersonalDetails]: {
      firstName: getValue(stripeAccount, "firstName") || "",
      lastName: getValue(stripeAccount, "lastName") || "",
      email: getValue(stripeAccount, "email") || "",
      mobile: getValue(stripeAccount, "phone") || "",
      dob: getValue(stripeAccount, "dob") || "",
      idNumberProvided: getValue(stripeAccount, "idNumberProvided") || "",
      idNumber: getValue(stripeAccount, "idNumber") || "",
    },
    [FormStepsName.Address]: {
      city: getValue(stripeAccount, "address.city", ""),
      line1: getValue(stripeAccount, "address.line1", ""),
      line2: getValue(stripeAccount, "address.line2", ""),
      state: getValue(stripeAccount, "address.state", ""),
      country: getValue(stripeAccount, "address.country", ""),
      postalCode: getValue(stripeAccount, "address.postal_code", ""),
    },
    [FormStepsName.IdentityVerification]: {
      documentId: getValue(stripeAccount, "identityDocument.document.id"),
      documentNumber: getValue(stripeAccount, "identityDocument.document.documentNumber"),
      documentType: getValue(stripeAccount, "identityDocument.document.documentType"),
      expiryDate: getValue(stripeAccount, "identityDocument.document.expiresAt"),
      documentPath: getValue(stripeAccount, "identityDocument.document.documentPath"),
      documentProvided: getValue(stripeAccount, "identityDocument.status", "") === "verified",
    },
    [FormStepsName.AccountForPayout]: {
      type: getValue(stripeAccount, "externalAccount.type"),
      token: getValue(stripeAccount, "externalAccount.id"),
      accountNumber: getValue(stripeAccount, "externalAccount.accountNumber", null),
      routingNumber: getValue(stripeAccount, "externalAccount.routingNumber", null),
      details: {
        last4: getValue(stripeAccount, "externalAccount.last4"),
        expiryMonth: getValue(stripeAccount, "externalAccount.expiryMonth"),
        expiryYear: getValue(stripeAccount, "externalAccount.expiryYear"),
        type: getValue(stripeAccount, "externalAccount.type"),
        cardType: getValue(stripeAccount, "externalAccount.brand"),
        name: getValue(stripeAccount, "externalAccount.bankName"),
      } as Record<string, any>,
    },
  } as IFormData;
};

const getAllOnboardingFormSteps = (options: []): Record<string, Array<any>> => {
  let data = {
    allSteps: [],
    steps: [],
  };
  if (checkIfEmpty(options)) return data;

  const allSteps = [
    ...(options || []),
    {
      step: (options || []).length + 1,
      name: FormStepsName.ReviewAndConfirm,
      title: "Confirm",
      label: "Review & confirm",
      description:
        "Please take a moment to carefully review your information before submitting it.",
    },
  ];

  const steps = allSteps
    .map(
      (item: any) =>
        ({
          step: getValue(item, "step", ""),
          name: getValue(item, "name", ""),
          title: getValue(item, "title", ""),
          label: getValue(item, "label", ""),
        }) as PayoutOnboardingSteps
    )
    .sort((a: PayoutOnboardingSteps, b: PayoutOnboardingSteps) => a.step - b.step);

  return { allSteps, steps };
};

const getAdditionalDetails = ({
  data,
  options = {},
}: {
  data: IFormData;
  options: Record<string, any>;
}) => {
  const additionalDetails = {
    [FormStepsName.PersonalDetails]: {},
    [FormStepsName.Address]: {},
    [FormStepsName.IdentityVerification]: {},
    [FormStepsName.AccountForPayout]: {},
  };

  if (options.isNameUnLocked) {
    additionalDetails[FormStepsName.PersonalDetails] = {
      firstName: data[FormStepsName.PersonalDetails].firstName || "",
      lastName: data[FormStepsName.PersonalDetails].lastName || "",
    };
  }

  return additionalDetails;
};

const getPayloadForAccountUpdate = (
  data: IFormData,
  step?: number,
  options: Record<string, any> = {}
) => {
  const dateOfBirth = data[FormStepsName.PersonalDetails].dob || "";

  const additionalDetails = getAdditionalDetails({ data, options });

  const payloadData = {
    [FormStepsName.PersonalDetails]: {
      ...additionalDetails[FormStepsName.PersonalDetails],
      dob: formatDate({ date: dateOfBirth, format: DATE_ISO_8601_FORMAT }) || "",
      idNumber: data[FormStepsName.PersonalDetails].idNumber || "",
    },
    [FormStepsName.Address]: {
      line1: data[FormStepsName.Address].line1 || "",
      line2: data[FormStepsName.Address].line2 || null,
      city: data[FormStepsName.Address].city || "",
      state: data[FormStepsName.Address].state || "",
      postalCode: data[FormStepsName.Address].postalCode || "",
      country: data[FormStepsName.Address].country || "",
    },
    [FormStepsName.IdentityVerification]: {
      documentId: data[FormStepsName.IdentityVerification].documentId || null,
    },
    [FormStepsName.AccountForPayout]: {
      type: data[FormStepsName.AccountForPayout].type || "",
      accountNumber: data[FormStepsName.AccountForPayout].accountNumber || "",
      routingNumber: data[FormStepsName.AccountForPayout].routingNumber || "",
      token: data[FormStepsName.AccountForPayout].token || "",
    },
  };

  return { data: payloadData, step: step || Object.keys(data).length };
};

const transformRequirementsToFormName = (requirements: []): FormStepsName[] => {
  if (!isArray(requirements)) return [];

  return (requirements || [])
    .map((req) => {
      const name = getValue(req, "name") || "";
      switch (name) {
        case AdditionalInformation.PersonalDetails:
          return FormStepsName.PersonalDetails;
        case AdditionalInformation.AddressVerification:
          return FormStepsName.Address;
        case AdditionalInformation.ExternalAccount:
          return FormStepsName.AccountForPayout;
        case AdditionalInformation.IdentityVerification:
          return FormStepsName.IdentityVerification;
        default:
          return;
      }
    })
    .filter(Boolean) as FormStepsName[];
};

const transformStepIndexToStepName = (index: number): FormStepsName =>
  Object.keys(FormStepIndex).find(
    (key) => FormStepIndex[key as FormStepsName] === index
  ) as FormStepsName;

export {
  getAllOnboardingFormSteps,
  getPayloadForAccountUpdate,
  transformStepIndexToStepName,
  transformRequirementsToFormName,
  transformStripePayoutDetailsResponse,
};
