import { useMutation, useQuery } from "@tanstack/react-query";
import { get, deleteApi, post } from "../api/client";

export const MEMBERSHIP_KEYS = {
  memberships: "memberships",
  qualificationOptions: "industryQualificationOptions",
};

export const useGetMemberships = () => {
  return useQuery([MEMBERSHIP_KEYS.memberships], () => get("api/v2/industry/memberships"));
};

export const useGetMembershipOptions = () => {
  return useQuery([MEMBERSHIP_KEYS.qualificationOptions], () =>
    get("api/v2/industry/qualifications")
  );
};

export const useDeleteMembership = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const { isLoading, mutateAsync } = useMutation(
    async (id: number) => {
      return deleteApi(`api/v2/industry/membership/${id}`);
    },
    { onSuccess, onError }
  );

  return { isLoading, deleteMembership: mutateAsync };
};

export const useAddMembership = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const { isLoading, mutateAsync } = useMutation(
    async (data: any) => {
      return post("api/v2/industry/membership", data);
    },
    {
      onError,
      onSuccess,
    }
  );
  return { isLoading, addMembership: mutateAsync };
};
