import { Box, ButtonBase, Popover } from "@material-ui/core";
import { useState } from "react";

import { Colors } from "../../../constants/colors";
import { checkIfEmpty, getValue } from "../../../utils/object";
import CovidStatus from "../CovidStatus/CovidStatus";
import { BOOKING_SESSION_TYPE } from "../../../helpers/booking";
import { JobStatus, MassageFor } from "../../../constants/booking";
import SpecialInstructionSection from "./SpecialInstructions/SpecialInstructions";
import SectionHeader from "../../../components/Headers/SectionHeader/SectionHeader";
import { useAlertStore } from "../../../stores/alert";
import { cardWrapperStyles } from "../../../components/BookingDetails/BookingDetailCard";
import PopoverItemSeparator from "../../../components/PopoverItemSeparator";
import PopoverItem, { PopoverItemType } from "../../../components/PopoverItem";
import ComplaintModal from "../../../components/Complaint/ComplaintModal";
import BlockClientModal from "../../../components/Client/BlockClientModal";
import MoreIcon from "../../../images/therapists-more.svg";
import { useUnblockClient } from "../../../hooks/client/blockClient.hooks";
import JobDocuments from "../documents/documents";
import {
  Display,
  FlexDirection,
  JustifyContent,
  Spacing,
} from "../../../components/v2/Styled/enum";
import Divider from "../../../components/Divider";
import TreatmentNotes from "./TreatmentNotes/TreatmentNotes";
import ClientRating from "./ClientRating/ClientRating";

interface Props {
  job: any;
  isAdditionalDocument: any;
  hideSensativeData: boolean;
  refreshJobDetails?: any;
  showAction?: boolean;
}

const ClientDetails = ({
  job,
  hideSensativeData,
  isAdditionalDocument,
  refreshJobDetails,
  showAction = true,
}: Props) => {
  const [showComplaintModal, setShowComplaintModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { mutate: unblockClient, isLoading } = useUnblockClient();

  const getClientName = ({ booking }: any) => {
    if (!booking || checkIfEmpty(booking)) return "";

    const client = booking?.recipient || booking?.user;

    if (!client || checkIfEmpty(client)) return "";
    return `${client.firstName} ${client.lastName.charAt(0)}.`;
  };

  const getClientGender = ({ booking }: any) => {
    if (!booking || checkIfEmpty(booking)) return "";

    let client = booking?.user;
    if (booking.recipient && booking.massageFor === MassageFor.someoneElse) {
      client = booking.recipient;
    }

    if (!client || checkIfEmpty(client)) return "";

    const gender = client?.gender;
    switch (gender) {
      case "male":
        return "I am male";

      case "female":
        return "I am female";

      case "non binary / non confirming":
        return "I am non-binary / non confirming";

      case "prefer not to respond":
        return "";

      default:
        return "";
    }
  };

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);

  const handleShowMoreOptions = (event: any) => {
    event.stopPropagation();
    setPopoverAnchorEl(event.currentTarget as HTMLElement);
  };

  const reportTherapist = () => {
    setShowComplaintModal(true);
  };

  const isRegularClient = ({ booking }: any) => {
    if (!booking) return false;
    return !booking.recipient && booking.user.totalMassagesBooked > 1;
  };

  const getClientReview = (job: any) => {
    return getValue(job, "userreview") || null;
  };

  const shouldShowRating = () => {
    const { status: jobStatus } = job;
    return [JobStatus.finished, JobStatus.reviewLeft].includes(jobStatus);
  };

  const shouldShowTreatmentNotes = () => {
    const { status: jobStatus } = job;
    return ![JobStatus.pending, JobStatus.rebooking].includes(jobStatus);
  };

  const isUserBlocked = !!getValue(job, "userBlock");
  const booking = getValue(job, "bookingdetail.booking");

  const showClientGender =
    (booking?.sessionType !== BOOKING_SESSION_TYPE.CORPORATE &&
      getClientGender({ booking }) &&
      !hideSensativeData) ||
    true;

  const clientReview = getClientReview(job);
  const shouldShowRatings = shouldShowRating();
  const shouldShowNotes = shouldShowTreatmentNotes();

  return (
    <Box
      style={{
        ...cardWrapperStyles.card,
        display: Display.Flex,
        padding: Spacing.S6,
        flexDirection: FlexDirection.Column,
      }}
    >
      <Box
        style={{
          ...styles.wrapper,
          display: Display.Flex,
          flexDirection: FlexDirection.Column,
          gap: Spacing.S6,
        }}
      >
        {/* About section */}
        <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
          <Box
            style={{
              display: "flex",
              flexDirection: FlexDirection.Row,
              justifyContent: JustifyContent.spaceBetween,
              marginBottom: "10px",
            }}
          >
            <SectionHeader title={`About ${getClientName({ booking })}`} />
            {showAction && (
              <ButtonBase onClick={handleShowMoreOptions} disableTouchRipple={true}>
                <img src={MoreIcon} alt="more" />
              </ButtonBase>
            )}
          </Box>

          <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S1}>
            {isRegularClient({ booking }) ? (
              <Box style={{ ...styles.detail }}>Regular Client</Box>
            ) : (
              <></>
            )}
            {showClientGender ? (
              <Box style={{ ...styles.detail }}>{getClientGender({ booking })}</Box>
            ) : (
              <></>
            )}
            <CovidStatus booking={booking} style={styles.detail} />
          </Box>
        </Box>
        {shouldShowNotes || (!hideSensativeData && <Divider />)}

        {/* Special instruction */}
        {!hideSensativeData ? <SpecialInstructionSection booking={booking} /> : <></>}
        {!hideSensativeData && <Divider />}

        {/* Documents */}
        <JobDocuments job={job} />

        {/* Client rating */}
        {shouldShowRatings && (
          <>
            <ClientRating
              jobId={job.id}
              review={clientReview}
              timezone={booking.timezone}
              refreshJob={refreshJobDetails}
            />
            <Divider />
          </>
        )}

        {/* Treatment notes */}
        {shouldShowNotes && (
          <TreatmentNotes job={job} booking={booking} refreshJob={refreshJobDetails} />
        )}
      </Box>

      <Popover
        id={"booking-details-therapist-popover"}
        open={popoverAnchorEl !== null}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" width="200px">
          {isUserBlocked ? (
            <PopoverItem
              type={PopoverItemType.default}
              title={"Unblock client"}
              onClick={() => {
                unblockClient(
                  { jobId: job.id },
                  {
                    onSuccess: () => {
                      setSuccessMessage("Client unblocked.");
                      refreshJobDetails();
                    },
                    onError: () => {
                      setErrorMessage("Something went wrong! Please try again later.");
                    },
                  }
                );
                setPopoverAnchorEl(null);
              }}
            />
          ) : (
            <PopoverItem
              type={PopoverItemType.default}
              title={"Block client"}
              onClick={() => {
                setPopoverAnchorEl(null);
                setShowBlockModal(true);
              }}
            />
          )}
          <PopoverItemSeparator />
          <PopoverItem
            type={PopoverItemType.default}
            title={"Report client"}
            onClick={reportTherapist}
          />
        </Box>
      </Popover>
      <ComplaintModal
        complaintFor={getValue(booking, "user.id")}
        open={showComplaintModal}
        onClose={() => {
          setShowComplaintModal(false);
          setPopoverAnchorEl(null);
        }}
        reportPro={false}
        refreshJobDetails={refreshJobDetails}
      />
      <BlockClientModal
        jobId={job.id}
        open={showBlockModal}
        onBlockSuccess={() => {
          if (refreshJobDetails) refreshJobDetails();
        }}
        onClose={() => {
          setShowBlockModal(false);
          setPopoverAnchorEl(null);
        }}
      />
    </Box>
  );
};

const styles = {
  wrapper: {
    display: "flex",
  },
  detail: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  detailText: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
  clickable: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "Museo",
  },
};

export default ClientDetails;
