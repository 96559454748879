import { Box, Typography } from "@material-ui/core";
import NoBookingsImage from "../../../images/no-bookings-placeholder.svg";
import { FontFamily, FontWeight } from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import React from "react";

interface NoItemsProps {
  placeholder: string;
  subtitle: string;
  actionText: string;
  onActionHandle: () => unknown;
}

export const NoItemsPlaceholder = ({
  placeholder,
  subtitle,
  actionText,
  onActionHandle,
}: NoItemsProps) => {
  return (
    <Box
      width="100%"
      height="65vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={"column"}
    >
      <Box>
        <img src={NoBookingsImage} alt={"no bookings"} />
      </Box>
      <Box>
        <Typography variant="h6" color="textPrimary">
          {placeholder}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary" align={"center"}>
          {subtitle}{" "}
          <Typography
            variant="body2"
            style={{
              fontWeight: FontWeight.Regular,
              fontFamily: FontFamily.Museo,
              cursor: "pointer",
              color: Colors.TurquoiseBlue,
            }}
            onClick={() => {
              onActionHandle();
            }}
          >
            {actionText}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
