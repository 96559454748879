import { Box, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import React from "react";
import { post } from "../../api/client";
import Button from "../../components/Button";
import TextField from "../../components/TextField";
import TextLink from "../../components/TextLink";
import { Colors } from "../../constants/colors";
import { parseApiError } from "../../helpers/error";
import { User } from "../../models";
import firebaseApp from "../../services/firebaseApp";
import { trackEvent } from "../../services/segment/track.service";
import { useAlertStore } from "../../stores/alert";
import { removeAdminAccessToken } from "../../helpers/accessToken";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../constants/paths";
import { getValue } from "../../utils/object";
import MfaLoginModal from "./MfaLoginModal";
import { getFireBaseToken } from "../../helpers/firebase";
import { resetPaymentStore } from "../../stores/booking";

interface Props {
  onLoggedIn: (user: User) => unknown;
  onForgotPassword: (email: string) => unknown;
  onCreateAccount: () => unknown;
  marginTop?: string | number;
  email?: string | null;
  onClose?: () => unknown;
  redirect?: boolean;
}

export default function LoginComponent({
  onLoggedIn,
  onForgotPassword,
  onCreateAccount,
  onClose,
  marginTop = "60px",
  email: providedEmail,
  redirect = true,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const { setErrorMessage } = useAlertStore();

  const [loading, setLoading] = React.useState(false);

  const [showTwoFaModal, setShowTwoFaModal] = React.useState(false);

  const onLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || !password) {
      return setErrorMessage("Please enter your email and password.");
    }
    removeAdminAccessToken();

    setLoading(true);

    const token = await getFireBaseToken();

    if (!token) {
      setLoading(false);
      return setErrorMessage(
        "We were not able to verify your request. Please refresh the page and try again."
      );
    }

    post(
      "api/v2/users/login",
      {
        email,
        password,
        channel: "web",
      },
      false,
      {
        "X-SESSION": token,
      }
    )
      .then((response) => {
        setLoading(false);
        resetPaymentStore(); // hotfix

        const user = response.data as User;

        const { accessToken, needMfa, mfaType, email, serviceId, mobile } = user;

        if (needMfa) {
          if (location.pathname === Paths.Login) {
            history.push(Paths.MfaLogin, {
              mfaType,
              email,
              serviceId,
              mobile,
            });
          } else {
            history.push(location.pathname, { mfaType, serviceId, mobile, email });
            setShowTwoFaModal(true);
          }

          return;
        }

        if (accessToken) {
          trackEvent("Logged In", {
            version: 2,
          });

          localStorage.setItem("token", accessToken);

          if (user.therapistprofile && redirect) {
            if (getValue(history, "location.state.jobId")) history.goBack();
            history.push(Paths.ProDashboardProfile);
          }

          onLoggedIn(user);
        }
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage(parseApiError(error));
      });
  };

  React.useEffect(() => {
    providedEmail && setEmail(providedEmail);
  }, [providedEmail]);

  return (
    <Box>
      {showTwoFaModal ? (
        <MfaLoginModal
          open={showTwoFaModal}
          onClose={() => setShowTwoFaModal(false)}
          closeLoginModal={onClose}
          onLoggedIn={onLoggedIn}
        />
      ) : (
        <>
          <Typography variant="h1" style={{ color: Colors.NightBlue, marginTop }}>
            Welcome back.
          </Typography>
          <Typography variant="h3" style={{ color: Colors.Dusk, marginTop: "24px" }}>
            Great to see you again! Enter your details below to login to your Blys account
          </Typography>

          <Box height="40px" />

          <form onSubmit={onLogin}>
            <TextField
              title="Email"
              placeholder="Enter email address"
              onChange={(text) => setEmail(text)}
              value={email}
              type="email"
            />

            <TextField
              title="Password"
              placeholder="Enter password"
              onChange={(text) => setPassword(text)}
              value={password}
              type="password"
              actionTitle="Forgot"
              onActionClicked={() => onForgotPassword(email)}
              isPassword={true}
            />
            <Box mt="20px">
              <Button title="Login" loading={loading} submit={true} />
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  display="flex"
                  flexDirection="row"
                  fontFamily="Museo"
                  fontWeight={500}
                  fontSize="16px"
                  color={Colors.BlueyGrey}
                  mt="29px"
                >
                  Don't have an account?&nbsp;
                  <TextLink title="Create account" onClick={onCreateAccount} />
                </Box>
              </Box>
            </Box>
          </form>
        </>
      )}
    </Box>
  );
}
