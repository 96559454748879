import React, { useState } from "react";
import { useMobile } from "../../../hooks/mobile";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Position,
  Spacing,
} from "../../v2/Styled/enum";
import SegmentedControl from "../../SegmentedControl";
import Divider from "../../Divider";

interface Props {
  title?: string;
  subtitle?: string | React.ReactComponentElement<any>;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  segments?: string[];
  selectedSegment?: number;
  setSelectedSegment?: (index: number) => void;
}

//this is sames as previous but it only aligns with the title of the back arrow
// not with all the content
export default function SectionHeader({
  title,
  subtitle,
  titleStyle,
  subtitleStyle,
  segments,
  selectedSegment,
  setSelectedSegment,
}: Props): JSX.Element {
  const isMobile = useMobile();
  return (
    <>
      {isMobile ? (
        <>
          <Box
            width="100%"
            bgcolor={Colors.White}
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
          >
            <Box width="374px">
              <Box
                display={Display.Flex}
                alignItems={AlignItems.center}
                position={Position.Relative}
              ></Box>
              <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S6}>
                <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S4}>
                  <Box>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Museo,
                        color: Colors.Indigo,
                        fontWeight: FontWeight.Bold,
                        fontSize: FontSize.F24,
                        ...titleStyle,
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Box width="198px">
                    {segments ? (
                      <SegmentedControl
                        segments={segments}
                        onSelectedIndex={(index) => setSelectedSegment?.(index)}
                        selectedSegmentIndex={selectedSegment ?? 0}
                        borderRadius={Spacing.S6}
                        height={Spacing.S8}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: FontFamily.Museo,
                      color: Colors.DarkSteelGrey,
                      fontWeight: FontWeight.Regular,
                      fontSize: FontSize.F14,
                      lineHeight: LineHeight.L24,
                      ...subtitleStyle,
                    }}
                  >
                    {subtitle}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            width="100%"
            bgcolor={Colors.White}
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
          >
            <Divider marginBottom={Spacing.S8} />
            <Box width="80%" margin="0 auto">
              <Box
                height={"124px"}
                display={Display.Flex}
                alignItems={AlignItems.center}
                position={Position.Relative}
              >
                <Box
                  display={Display.Flex}
                  flexDirection={FlexDirection.Column}
                  gridGap={Spacing.S6}
                >
                  <Box
                    display={Display.Flex}
                    flexDirection={FlexDirection.Row}
                    gridGap={Spacing.S6}
                  >
                    <Box>
                      <Typography
                        variant="h1"
                        style={{
                          fontFamily: FontFamily.Museo,
                          color: Colors.Indigo,
                          fontWeight: FontWeight.Bold,
                          fontSize: FontSize.F36,
                          lineHeight: LineHeight.L35,
                          ...titleStyle,
                        }}
                      >
                        {title}
                      </Typography>
                    </Box>
                    <Box width="222px">
                      {segments ? (
                        <SegmentedControl
                          segments={segments}
                          onSelectedIndex={(index) => setSelectedSegment?.(index)}
                          selectedSegmentIndex={selectedSegment ?? 0}
                          borderRadius={Spacing.S6}
                        />
                      ) : null}
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Museo,
                        color: Colors.DarkSteelGrey,
                        fontWeight: FontWeight.Medium,
                        fontSize: FontSize.F16,
                        lineHeight: LineHeight.L24,
                        ...subtitleStyle,
                      }}
                    >
                      {subtitle}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
