import React from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../constants/colors";
import Ratings from "../../../components/Ratings";
import { Text } from "../../../components/v2/Styled";
import { getFullUploadsUrl } from "../../../utils/url";
import { formatDate } from "../../../utils/date.util";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";
import { FontFamily, FontSize, FontWeight } from "../../../components/v2/Styled/enum";

interface ReviewCardProps {
  profileImage: string;
  userName: string;
  serviceType: string;
  rating: number;
  review: string;
  bookingId: string;
  createdAt: string;
  reviewProvided: boolean;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  profileImage,
  userName,
  serviceType,
  rating,
  review,
  createdAt,
  bookingId,
  reviewProvided,
}) => {
  const history = useHistory();
  return (
    <Box
      height={"184px"}
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #DCDFE6",
        width: "100%",
      }}
    >
      <Box padding={"16px"} style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Box style={{ display: "flex", gap: "8px" }}>
          <img
            alt="Profile "
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
            }}
            src={profileImage ? getFullUploadsUrl(profileImage) : ProfilePicturePlaceholder}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text
              font={FontFamily.Museo}
              color={Colors.Dusk}
              size={FontSize.F14}
              weight={FontWeight.Bold}
            >
              {userName}
            </Text>
            <Text size={FontSize.F12} weight={FontWeight.Medium} color={Colors.Grey}>
              {serviceType}
            </Text>
          </div>
        </Box>
        <Ratings rating={rating} />
        <Text size={FontSize.F12} weight={FontWeight.Medium} color={Colors.Grey}>
          <Text
            font={FontFamily.SFPro}
            size={FontSize.F16}
            weight={FontWeight.Regular}
            color={Colors.Dusk}
          >
            {review}
          </Text>
        </Text>
      </Box>{" "}
      <Box padding={"16px"} style={{ background: "#F8F9FA" }} height={"48px"}>
        <Text
          font={FontFamily.SFPro}
          size={FontSize.F12}
          weight={FontWeight.Medium}
          color={Colors.Grey}
        >
          {reviewProvided ? "Review provided" : "Review received"} on{" "}
          {formatDate({ date: createdAt, format: "DD MMM YYYY" })} for booking{" "}
          <span
            onClick={() => {
              history.push(`/bookings/${bookingId}`);
            }}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontWeight: 600,
              color: "#41506F",
            }}
          >
            #{bookingId}.
          </span>
        </Text>
      </Box>
    </Box>
  );
};

export default ReviewCard;
