import React from "react";
import { useMutation } from "@tanstack/react-query";

import { post } from "../../api/client";

export const useBlockClient = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => unknown;
  onError?: () => unknown;
}) =>
  useMutation(
    (data: { jobId: number | null | string; blockReason: string }) =>
      post(`therapists/${data.jobId}/blockClient`, { blockReason: data.blockReason }),
    {
      onSuccess: () => onSuccess(),
      onError: () => onError(),
    }
  );

export const useUnblockClient = () =>
  useMutation(({ jobId }: any) => {
    return post(`therapists/${jobId}/unblockClient`, { jobId });
  });

export const useUnblockClientWithId = () =>
  useMutation(({ clientId }: any) => {
    return post(`therapists/unblockClient/${clientId}`, { clientId });
  });
