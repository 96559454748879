import React from "react";
import { Box, Link } from "@material-ui/core";
import { UploadedFile } from "../../models";
import FileIcon from "../../images/file-icon.svg";
import { BASE_UPLOADS_URL } from "../../constants/common";
import { last } from "lodash";
import TextLink from "../../components/TextLink";

interface InsuranceFileRowProps {
  file: UploadedFile;
  onRemove: () => unknown;
  containsFullUrl?: boolean;
}

export default function ({ file, onRemove, containsFullUrl = false }: InsuranceFileRowProps) {
  const fileUrl = containsFullUrl ? file.filePath : `${BASE_UPLOADS_URL}/${file.filePath}`;
  return (
    <Box display="flex" flexDirection="row" maxWidth="400px" mt="9px" mb="9px" alignItems="center">
      <img src={FileIcon} />

      <Link
        style={{ color: "black", cursor: "pointer", flex: 1 }}
        onClick={() => {
          window.open(fileUrl, "_blank");
        }}
      >
        <Box fontFamily="Open Sans" fontWeight={400} fontSize="14px" lineHeight="21px" flex={1}>
          {last(file.filePath.split("/"))}
        </Box>
      </Link>

      <TextLink title="Remove" destructive onClick={onRemove} />
    </Box>
  );
}
