import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Divider, Typography } from "@mui/material";
import AddressAutocomplete from "../../AddressAutocompleteV2";
import { dropdownStylesCombined } from "../../Dropdown";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
} from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import RadioButton from "../../RadioButton";
import { SetLocationModal } from "../../Modals/MapModal/SetLocationModal";
import AddressLocationPin from "../../../images/map-pin-input-icon.svg";
import { useMobile, useTab } from "../../../hooks/mobile";
import { isMobile } from "react-device-detect";
import Dialog from "../../Dialog";
import CaretLeft from "../../../images/24_carat-left.png";

export const DistanceModal = ({
  selectedDistance,
  handleFilterApplied,
  setShowLocationFilter,
  setDistance,
}: {
  selectedDistance: any;
  handleFilterApplied: any;
  setShowLocationFilter: any;
  setDistance: any;
}) => {
  const distanceOptions = [
    {
      title: "20km",
      value: 20,
    },
    {
      title: "30km",
      value: 30,
    },
    {
      title: "50km",
      value: 50,
    },
    {
      title: "100km+",
      value: 100,
    },
  ];
  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: Spacing.S4,
              fontFamily: FontFamily.Museo,
              color: Colors.Dusk,
              fontWeight: FontWeight.Bold,
              fontSize: FontSize.F16,
              lineHeight: LineHeight.L24,
            }}
          >
            Distance
          </Typography>
        </Box>
        <Box display={"flex"} gridGap={Spacing.S6}>
          {distanceOptions.map((distance) => (
            <Box display={"flex"} key={distance.title}>
              <RadioButton
                style={{ height: 16, width: 16, marginRight: "8px" }}
                selected={distance.value === selectedDistance}
                onSelected={() => setDistance(distance.value)}
              />
              <Box
                color={Colors.Dusk}
                fontSize="16px"
                fontFamily="Museo"
                className="cursor-pointer"
                onClick={() => setDistance(distance.value)}
              >
                {distance.title}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={{ marginY: 2 }} />
    </>
  );
};

export const LocationFilterModal = ({
  filter,
  handleFilterApplied,
  setShowLocationFilter,
}: {
  filter: any;
  handleFilterApplied: any;
  setShowLocationFilter: any;
}) => {
  let location;
  if (filter && filter.location && typeof filter.location === "string") {
    location = JSON.parse(filter.location);
  }
  const [selectedDistance, setSelectedDistance] = useState(filter.distance || null);
  const [selectedAddress, setSelectedAddress] = useState(filter.location || null);
  const [fullAddress, setFullAddress] = useState<string | undefined>(location?.fullAddress || "");
  const [isMapSelected, setIsMapSelected] = useState(false);

  const handleClear = () => {
    filter.location = null;
    filter.distance = null;
    setFullAddress("");
    handleFilterApplied(["location", "distance"], [null, null]);
    setShowLocationFilter(false);
  };

  const applyFilters = () => {
    if (selectedAddress && selectedDistance) {
      handleFilterApplied(["location", "distance"], [selectedAddress, selectedDistance]);
    } else if (selectedAddress) {
      handleFilterApplied("location", selectedAddress);
    } else if (selectedDistance) {
      handleFilterApplied("distance", selectedDistance);
    }
    setShowLocationFilter(false);
  };
  const [open, setOpen] = useState(true);
  return (
    <>
      {isMobile ? (
        <>
          <Dialog open={open} fullScreen={true}>
            <Box bgcolor={Colors.White} paddingX={Spacing.S6}>
              <Box
                display={Display.Flex}
                flexDirection={FlexDirection.Column}
                height="154px"
                gridColumnGap={Spacing.S4}
              >
                <Box
                  height="52px"
                  display={Display.Flex}
                  justifyContent={JustifyContent.start}
                  alignItems={AlignItems.center}
                  onClick={() => {
                    setOpen(!open);
                    applyFilters();
                  }}
                >
                  <img src={CaretLeft} alt={CaretLeft} style={{ marginLeft: "-7px" }} />
                </Box>
                <Box height="73px">
                  <AddressAutocomplete
                    title={"Address"}
                    placeholder="Enter address here to search"
                    locationType={null}
                    value={fullAddress || ""}
                    onChange={(text) => setFullAddress(text)}
                    onSelectedAddress={(address) => {
                      setFullAddress(address.fullAddress);
                      setSelectedAddress(address);
                    }}
                    onMapSelected={() => {
                      setIsMapSelected(true);
                    }}
                    textFieldStyles={{
                      border: "none",
                      borderBottom: "2px solid #DCDFE6",
                      borderRadius: "0px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Dialog>
        </>
      ) : (
        <Box
          position="absolute"
          style={{ ...dropdownStylesCombined, marginTop: Spacing.S4 }}
          padding={Spacing.S6}
          minWidth={400}
        >
          {/* Location Section */}
          <AddressAutocomplete
            title={"Location"}
            placeholder="Search for suburb or address"
            locationType={null}
            value={fullAddress || ""}
            onChange={(text) => setFullAddress(text)}
            onSelectedAddress={(address) => {
              setFullAddress(address.fullAddress);
              setSelectedAddress(address);
            }}
            onMapSelected={() => {
              setIsMapSelected(true);
            }}
          />

          {/* Distance Section */}
          <DistanceModal
            selectedDistance={selectedDistance}
            handleFilterApplied={handleFilterApplied}
            setShowLocationFilter={setShowLocationFilter}
            setDistance={setSelectedDistance}
          />

          {/* Action Buttons */}
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="text"
              sx={{
                fontFamily: FontFamily.Museo,
                color: filter.location || filter.distance ? Colors.TextDanger : Colors.Grey,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
              }}
              onClick={handleClear}
            >
              Clear all
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: Colors.TurquoiseBlue,
                fontFamily: FontFamily.Museo,
                color: Colors.White,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
                "&:hover": {
                  backgroundColor: Colors.TurquoiseBlue,
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
              onClick={applyFilters}
            >
              Apply
            </Button>
          </Box>
        </Box>
      )}
      <SetLocationModal
        open={isMapSelected}
        onClose={() => {
          setIsMapSelected(false);
        }}
        setIsMapSelected={setIsMapSelected}
        handleAddressSelected={({ address }) => {
          setFullAddress(address.fullAddress || "");
          handleFilterApplied("location", address);
        }}
      />
    </>
  );
};

export default function LocationFilter({
  filter,
  handleFilterApplied,
  stylesProps,
  setCloseOthers,
  closeOthers,
}: {
  filter: any;
  handleFilterApplied: any;
  stylesProps?: React.CSSProperties;
  setCloseOthers?: any;
  closeOthers?: boolean | string;
}) {
  const [showLocationFilter, setShowLocationFilter] = useState(false);
  const isMobile = useMobile();
  const isTab = useTab();
  useEffect(() => {
    if (closeOthers !== "LocationFilter") {
      setShowLocationFilter(false);
    }
  }, [closeOthers]);

  const getLocationText = (loc: any) => {
    let location = loc;
    if (typeof location === "string") {
      location = JSON.parse(loc);
    }
    const sliceLength = (isMobile && 35) || (isTab && 15) || 35;
    if (location.fullAddress.length > sliceLength) {
      return location.fullAddress.slice(0, sliceLength) + "...";
    } else {
      return location.fullAddress;
    }
  };

  return (
    <Box position="relative">
      <Button
        variant={isMobile ? "text" : "outlined"}
        startIcon={
          isMobile ? undefined : (
            <img
              src={AddressLocationPin}
              color={Colors.Grey}
              style={{ color: Colors.Grey }}
              alt={"location pin"}
            />
          )
        }
        title={
          filter.location
            ? typeof filter.location === "string"
              ? JSON.parse(filter.location).fullAddress
              : filter.location.fullAddress
            : "Suburb"
        }
        sx={{
          height: Spacing.S11,
          width: "100%",
          fontFamily: FontFamily.Museo,
          fontSize: FontSize.F16,
          fontWeight: FontWeight.Medium,
          color: filter.location ? Colors.TurquoiseBlue : Colors.Grey,
          borderColor: Colors.LightBlueGrey,
          padding: "0px 24px",
          borderRadius: "100px",
          textTransform: "none",
          textAlign: "left",
          justifyContent: "left",
          "&:hover": {
            borderColor: Colors.LightBlueGrey,
          },
          "&:focus-visible": {
            borderColor: Colors.LightBlueGrey,
          },
          ...stylesProps,
        }}
        onClick={() => {
          setShowLocationFilter(!showLocationFilter);
          setCloseOthers && setCloseOthers("LocationFilter");
        }}
      >
        {filter.location
          ? getLocationText(filter.location)
          : isMobile
            ? "Enter suburb or address"
            : "Suburb"}
      </Button>
      {showLocationFilter && (
        <LocationFilterModal
          filter={filter}
          handleFilterApplied={handleFilterApplied}
          setShowLocationFilter={setShowLocationFilter}
        ></LocationFilterModal>
      )}
    </Box>
  );
}
