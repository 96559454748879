import React from "react";
import { Box } from "@mui/material";
import { Text } from "../../v2/Styled";
import * as Styled from "../../v2/Styled/enum";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";

interface Props {}

const StripeAddPaymentMethodFooter = ({}: Props): JSX.Element => {
  const isMobile = useMobile();
  return (
    <Box>
      <Text
        color={Colors.Grey}
        weight={Styled.FontWeight.Medium}
        lineHeight={Styled.LineHeight.L24}
        size={isMobile ? Styled.FontSize.F14 : Styled.FontSize.F16}
      >
        By providing your card information, you allow Blys to charge your card for future payments
        in accordance with their terms.
      </Text>
    </Box>
  );
};

export default StripeAddPaymentMethodFooter;
