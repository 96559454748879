import { post } from "../../api/client";
import { Box, CircularProgress, Button as MUIButton } from "@material-ui/core";
import braintree, { BraintreeError, PayPal } from "braintree-web";
import React from "react";
import Dialog from "../Dialog";
import "../../fonts/Museo.css";
import "../../styles/ApplePay.css";
import ModalHeader from "../ModalHeader";
import { PaymentMethod } from "../../models";
import { useMobile } from "../../hooks/mobile";
import ModalHeaderApp from "../ModalHeaderApp";
import { Colors } from "../../constants/colors";
import { useAlertStore } from "../../stores/alert";
import { useAccessToken } from "../../hooks/common";
import Snackbar, { SnackbarType } from "../Snackbar";
import { useBraintreeStore } from "../../stores/braintree";
import PayPalButtonImage from "../../images/paypal-button.png";

declare global {
  interface Window {
    grecaptcha: any;
  }
}
interface Props {
  open: boolean;
  onClose: () => unknown;
  onAddedNewCard: (method: PaymentMethod) => unknown;
  cardOnly?: boolean;
  canAddPaypal?: boolean;
  onlyShowPaypalOption?: boolean;
}

export default function AddNewPaypalModal({
  open,
  onClose,
  onAddedNewCard,
  cardOnly = false,
  canAddPaypal,
}: Props): JSX.Element {
  const { clientToken, fetchClientToken } = useBraintreeStore();
  const accessToken = useAccessToken();
  const isMobile = useMobile();

  const [adding, setAdding] = React.useState(false);

  const [paypalLoading, setPaypalLoading] = React.useState(true);
  const [paypalInstance, setPaypalInstance] = React.useState<PayPal | null>(null);

  const { setErrorMessage, errorMessage } = useAlertStore();

  React.useEffect(() => {
    if (!clientToken) {
      fetchClientToken();
    }
  }, []);

  React.useEffect(() => {
    if (clientToken && open) {
      // console.debug("clientToken: ", clientToken);
      braintree.client.create(
        {
          authorization: clientToken,
        },
        (error, clientInstance) => {
          // create paypal instance
          setPaypalLoading(true);

          braintree.paypal.create({ client: clientInstance }, (paypalErr, paypalInstance) => {
            setPaypalLoading(false);

            if (!paypalErr && paypalInstance) {
              setPaypalInstance(paypalInstance);
            }
          });
        }
      );
    }
  }, [open, clientToken]);

  const paypalCallback = (tokenizeErr: BraintreeError, payload: any) => {
    if (tokenizeErr) {
      console.debug("error: ", tokenizeErr);
      return;
    }

    if (payload) {
      const { nonce, details } = payload;
      const { email } = details;

      setAdding(true);

      post(
        "paymentmethods",
        {
          accessToken,
          nonce,
          email,
        },
        false
      )
        .then((response) => {
          setAdding(false);
          onAddedNewCard(response.data);
        })
        .catch((error) => {
          console.debug("add payment method error: ", error);

          setAdding(false);
        });
    }
  };

  const onPaypalClick = () => {
    paypalInstance?.tokenize({ flow: "vault" }, paypalCallback);
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullScreen={isMobile}
      onClose={onClose}
      disableEnforceFocus
    >
      <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
        {isMobile ? (
          <ModalHeaderApp
            title="Connect PayPal"
            subtitle="Payment methods will be stored to streamline future bookings"
          />
        ) : (
          <ModalHeader
            title="Connect PayPal"
            subtitle="Payment methods will be stored to streamline future bookings"
            onClose={onClose}
          />
        )}

        {paypalLoading ? (
          <CircularProgress style={{ color: Colors.NightBlue, margin: "42px" }} />
        ) : (
          <form id="my-sample-form" className="scale-down">
            <Box padding="40px" paddingBottom={isMobile ? "30px" : "40px"}>
              <div className="paypal" style={{ paddingBottom: "10px" }}>
                {!cardOnly && canAddPaypal && paypalInstance && (
                  <>
                    <MUIButton onClick={onPaypalClick}>
                      <img src={PayPalButtonImage} />
                    </MUIButton>
                  </>
                )}
              </div>
            </Box>
          </form>
        )}
      </Box>
      <Snackbar
        type={SnackbarType.error}
        message={errorMessage}
        open={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />
    </Dialog>
  );
}
