import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LandingWrapper from "../../components/LandingWrapper";
import LogoLink from "../../components/LogoLink";
import { useMobile } from "../../hooks/mobile";
import logo from "../../images/blys-logo.svg";
import SignUpComponent from "./SignUpComponent";
import pixelsService from "../../services/pixels/pixels.service";

export default function SignUp(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();

  useEffect(() => {
    pixelsService.trackPageView();
  }, []);

  return (
    <LandingWrapper>
      <Box width={isMobile ? undefined : "400px"} mb="100px">
        <LogoLink />

        <SignUpComponent
          onCreatedAccount={() => {
            history.push("/my-bookings");
          }}
          onLogin={() => {
            history.push("/login");
          }}
        />
      </Box>
    </LandingWrapper>
  );
}
