import { JobStatus } from "../constants/booking";
import { Booking } from "../models";
import { TipPerJob } from "../types/tip.types";

export const isTipReducedOnCompletedBooking = (
  jobs: {
    id: number;
    status: JobStatus;
  }[],
  tipsPerJob: TipPerJob[],
  oldTips: Booking["bookingTips"]
) => {
  return tipsPerJob.some((tip) => {
    // find the job
    const job = jobs.find((job) => job.id === tip.jobId);

    if (!job) {
      // job and tip are not related
      return true;
    }

    // check if the job is completed
    const isJobFinished = [JobStatus.finished, JobStatus.reviewLeft].includes(job.status);

    // check if the tip is reduced
    const oldTip = oldTips.find((oldTip) => oldTip.jobId === tip.jobId);

    if (!oldTip) {
      // adding new tip
      return false;
    }

    const isTipAmountReduced = tip.tipAmount < oldTip.amount;
    return isJobFinished && isTipAmountReduced;
  });
};
