import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import MapAreaView from "./MapAreaView";
import { Colors } from "../../constants/colors";
import TextField from "../TextField";
import Button, { ButtonSize, ButtonType } from "../Button";
import { useAlertStore } from "../../stores/alert";

import { Geocode, extractLocationData, getCurrentLocation } from "../../helpers/google";
import { SelectedAddress } from "../AddressAutocomplete";

interface Props {
  onSelectedAddress: (address: SelectedAddress) => unknown;
  setIsMapSelected: (val: boolean) => unknown;
}

const SelectLocationMap = ({ onSelectedAddress, setIsMapSelected }: Props) => {
  const { setErrorMessage } = useAlertStore();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [currentAddress, setCurrentAddress] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const [locationDetails, setLocationDetails] = useState<SelectedAddress>();

  const onLocationSelected = async (latitude: number, longitude: number) => {
    const res = await Geocode(latitude, longitude);
    if (res) {
      const accurateAddress = res[0];
      const locationData = (await extractLocationData(accurateAddress)) as SelectedAddress;
      if (locationData && locationData.fullAddress) {
        setCurrentAddress(locationData.fullAddress);
        setLocationDetails(locationData);
      }
    }
  };

  useEffect(() => {
    const updateCurrentLocation = async () => {
      const data = await getCurrentLocation();
      if (data) {
        onLocationSelected(data.lat, data.lng);
        setLatitude(parseFloat(data.lat));
        setLongitude(parseFloat(data.lng));
        setIsLocationEnabled(data.locationEnabled);
      }
    };
    updateCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveClick = () => {
    if (!locationDetails?.postcode) {
      setErrorMessage("Please select a valid location.");
      return;
    }
    onSelectedAddress(locationDetails);
    setIsMapSelected(false);
  };

  const getAddressFromLatLng = (latitude: number, longitude: number) => {
    setLatitude(latitude);
    setLongitude(longitude);
  };

  return (
    <Box width="100%">
      <Box width="100%" height="350px">
        {latitude && longitude ? (
          <MapAreaView
            id={"location-select-on-map"}
            latitude={latitude}
            longitude={longitude}
            onLocationSelected={onLocationSelected}
            setIsDragging={setIsDragging}
            isLocationEnabled={isLocationEnabled}
          />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress
              variant="indeterminate"
              style={{ color: Colors.Indigo }}
              size={50}
              thickness={4}
            />
          </Box>
        )}
      </Box>
      <Box
        fontSize="14px"
        fontWeight="500"
        fontFamily="Museo"
        color={Colors.Grey}
        textAlign="center"
        mt={2}
      >
        Move around the map to set your location
      </Box>

      <TextField
        inputTextColor={Colors.Dusk}
        multiline={true}
        readOnly={true}
        value={currentAddress}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.large}
          title="Cancel"
          width="50%"
          onClick={() => setIsMapSelected(false)}
        />

        <Box width="14.5px" />
        <Button
          size={ButtonSize.large}
          title="Save"
          width="50%"
          loading={false}
          onClick={handleOnSaveClick}
          disabled={isDragging || !currentAddress}
        />
      </Box>
    </Box>
  );
};

export default SelectLocationMap;
