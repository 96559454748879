import useSWR from "swr";
import { useRef, useState } from "react";
import { Box } from "@material-ui/core";

import { UploadedFile } from "../../../models";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import { deleteApi, post } from "../../../api/client";
import { useAlertStore } from "../../../stores/alert";
import { parseApiError } from "../../../helpers/error";
import { useAccessToken } from "../../../hooks/common";
import Button, { ButtonType } from "../../../components/Button";
import UploadedFileRow from "../../../components/ProDashboard/UploadedFileRow";
import ActionModal from "../../../components/ActionModal";
import { removeTherapistFile } from "../../../services/therapist/therapist.service";
import { allowUploadDocumentExtension, filterImageAndApplicationExtension } from "../../../helpers/services";

interface UploadIdentityDocumentsProps {
  fileOwnership: string;
}

export default function UploadDocuments({ fileOwnership }: UploadIdentityDocumentsProps) {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const { user } = useUserStore();
  const accessToken = useAccessToken();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const [removeConfirmVisible, setRemoveConfirmVisible] = useState(false);
  const [fileBeingHandled, setFileBeingHandled] = useState<UploadedFile | null>(null);
  const [removeLoading, setRemoveLoading] = useState(false);

  const { data: uploadedFilesData, mutate: uploadedFilesMutate } = useSWR(
    `therapists/me/files?accessToken=${accessToken}`
  );

  const handleConfirm = (uploadedFile: UploadedFile) => {
    if (user) {
      setRemoveLoading(true);
      removeTherapistFile(uploadedFile.id, user.id)
        .then((response) => {
          uploadedFilesMutate();
          setSuccessMessage("This file has been deleted");
        })
        .catch((error) => {
          setErrorMessage(parseApiError(error));
        })
        .finally(() => {
          setRemoveLoading(false);
          setRemoveConfirmVisible(false);
        });
    } else {
      setErrorMessage("Something went wrong. Please try again.");
      setRemoveConfirmVisible(false);
    }
  };

  const uploadedFiles = uploadedFilesData
    ? (uploadedFilesData as UploadedFile[]).filter((file) => file.type === fileOwnership)
    : [];
  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    event.stopPropagation();
    event.preventDefault();

    const file = event?.target?.files && event.target.files[0];
    if(!filterImageAndApplicationExtension(file)){
      setErrorMessage("Unsupported file type. Please upload a PNG, JPG, JPEG or PDF file.");
      return;
    }

    if (file) {
      const form = new FormData();
      form.append("image", file);

      user &&
        post(`therapists/${user.id}/file?fileOwnership=${fileOwnership}`, form, true, {
          "Content-Type": "multipart/form-data",
        })
          .then((response) => {
            uploadedFilesMutate();
            setSuccessMessage("This file has been uploaded");
          })
          .catch((error) => {
            setErrorMessage(parseApiError(error));
          });
    }
  }
  return (
    <Box>
      <Box
        mb="32px"
        mt="32px"
        fontSize="18px"
        fontWeight={600}
        lineHeight="27px"
        fontFamily="Museo"
        color={Colors.Dusk}
      >
        Upload documentation
      </Box>
      <Box
        mt="8px"
        fontSize="14px"
        fontWeight={400}
        lineHeight="21px"
        fontFamily="Open Sans"
        color={Colors.Dusk}
      >
        PDF, JPG and PNG files accepted. We recommend using a scanning app when photographing
        documents.
      </Box>

      {uploadedFiles.map((uploadedFile) => (
        <UploadedFileRow
          file={uploadedFile}
          onRemove={() => {
            setFileBeingHandled(uploadedFile);
            setRemoveConfirmVisible(true);
          }}
        />
      ))}

      <ActionModal
        open={removeConfirmVisible}
        title={"Remove file"}
        description={
          "Please confirm if you want to remove this file. This action can’t be undone. If you want to upload it again, you’ll need to reselect the file."
        }
        onCancel={() => {
          setRemoveConfirmVisible(false);
        }}
        onConfirm={() => {
          handleConfirm(fileBeingHandled as UploadedFile);
        }}
        confirmText="Confirm"
        cancelText="Back"
        isLoading={removeLoading}
      />

      <Box mt="37px" mb="32px">
        <Button
          title="Upload file"
          type={ButtonType.outlined}
          width="132px"
          onClick={() => {
            if (fileUploadRef.current) {
              fileUploadRef.current.click();
            }
          }}
        />
      </Box>

      <Box>
        <input
          type="file"
          ref={fileUploadRef}
          style={{ display: "none" }}
          id={`${fileOwnership}-upload-file`}
          accept={allowUploadDocumentExtension()}
          onChange={onFileInputChange}
        />
      </Box>
    </Box>
  );
}
