import React from "react";
import { Link } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {
  AlignItems,
  Display,
  FontFamily,
  FontSize,
  FontWeight,
  LetterSpacing,
  LineHeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import { Booking, Job } from "../../../models";
import { getValue } from "../../../utils/object";
import { DEFAULT_CURRENCY } from "../../../constants/booking";

interface Props {
  booking: Booking;
  job: Job;
  onClick: () => unknown;
}

const PayoutExpectedSummary = ({ booking, job, onClick }: Props) => {
  const displayProjectedPayout = getValue(job, "approxServiceFee.amount", "NNN");
  const currency = getValue(
    booking,
    "service.selectedCountry.currencySymbol",
    DEFAULT_CURRENCY.currencySymbol
  );

  return (
    <Box style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography
        style={{
          fontFamily: FontFamily.Museo,
          fontWeight: FontWeight.Bold,
          fontSize: FontSize.F16,
          color: Colors.NightBlue,
        }}
      >
        Total payout
      </Typography>
      <Box
        bgcolor={Colors.VeryLightGreen}
        paddingX={Spacing.S3}
        paddingY={Spacing.S1}
        display={Display.Flex}
        borderRadius={Spacing.S6}
        alignItems={AlignItems.center}
      >
        <Link
          onClick={onClick}
          style={{
            color: Colors.NightBlue,
            cursor: "pointer",
            fontFamily: FontFamily.Museo,
            fontWeight: FontWeight.Bold,
            fontSize: FontSize.F16,
            lineHeight: LineHeight.L24,
            letterSpacing: LetterSpacing.S05,
          }}
          underline={"always"}
        >
          Up to {currency}
          {displayProjectedPayout}
        </Link>
      </Box>
    </Box>
  );
};

export default PayoutExpectedSummary;
