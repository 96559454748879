import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Ratings from "../../../components/Ratings";
import { Colors } from "../../../constants/colors";
import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import { formatDate } from "../../../utils/date.util";
import { Text } from "../../../components/v2/Styled";
import { RecipientWithSummary } from "../../../models";
import {
  FontFamily,
  FontSize,
  FontWeight,
  TextDecoration,
} from "../../../components/v2/Styled/enum";
import RecipientBookingInfoCard from "./RecipientBookingInfoCard";

interface RecipientSummaryProps {
  recipient: RecipientWithSummary;
  ProfileImageComponent: React.ReactChild;
}

const RecipientSummary: React.FC<RecipientSummaryProps> = ({
  ProfileImageComponent,
  recipient,
}) => {
  const isMobile = useMobile();
  const [openTotalBooling, setOpenTotalBooking] = useState(false);
  const [openLast30Booking, setOpenLast30Booking] = useState(false);
  const lastBookingOn = getValue(recipient, "bookings[0].createdAt");
  const averageRating = Number(parseFloat(recipient.averageRating || "0").toFixed(1));
  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Box>
        {ProfileImageComponent}
        <Box style={{ display: "flex", marginTop: "16px", gap: "8px", flexDirection: "column" }}>
          <Text
            font={FontFamily.Museo}
            size={FontSize.F24}
            weight={FontWeight.Bold}
            //@ts-ignore
            color={"#000000"}
          >
            {recipient.firstName} {recipient.lastName}
          </Text>
          <Box>
            <Box style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <Ratings width="13px" height="13px" rating={averageRating} />
              <Text size={FontSize.F16} weight={FontWeight.SemiBold} color={Colors.Dusk}>
                {averageRating ? averageRating : ""}
              </Text>
            </Box>
            <Text color={Colors.BlueyGrey}>({recipient.totalRatings} Reviews)</Text>
          </Box>
        </Box>
      </Box>

      <Box
        style={{ display: "flex", gap: "12px", flexDirection: "column" }}
        width={"318px"}
        marginTop={isMobile ? "20px" : ""}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Text
            font={FontFamily.Museo}
            size={FontSize.F16}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            Created Date
          </Text>
          <Text
            font={FontFamily.Museo}
            size={FontSize.F16}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            {formatDate({ date: recipient.createdAt, format: "ddd, D MMM YYYY" })}
          </Text>
        </Box>
        <Box bgcolor={Colors.LightPeriwinkle} height="1px" width="100%" />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Text
            size={FontSize.F16}
            font={FontFamily.Museo}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            Last booking on
          </Text>
          <Text
            size={FontSize.F16}
            font={FontFamily.Museo}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            {lastBookingOn ? formatDate({ date: lastBookingOn, format: "ddd, D MMM YYYY" }) : "N/A"}
          </Text>
        </Box>
        <Box bgcolor={Colors.LightPeriwinkle} height="1px" width="100%" />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Text
            size={FontSize.F16}
            textDecoration={TextDecoration.underline}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
            font={FontFamily.Museo}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenTotalBooking(true);
            }}
          >
            Total bookings (All time)
          </Text>
          <Text
            size={FontSize.F16}
            font={FontFamily.Museo}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            {recipient.totalBookings}
          </Text>
        </Box>
        <Box bgcolor={Colors.LightPeriwinkle} height="1px" width="100%" />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Text
            font={FontFamily.Museo}
            size={FontSize.F16}
            style={{ textDecoration: "" }}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
            textDecoration={TextDecoration.underline}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenLast30Booking(true);
            }}
          >
            Total bookings (Last 30 days)
          </Text>
          <Text
            size={FontSize.F16}
            font={FontFamily.Museo}
            weight={FontWeight.Medium}
            color={Colors.Dusk}
          >
            {recipient.last30DaysBookings}
          </Text>
        </Box>
      </Box>
      <RecipientBookingInfoCard
        isOpen={openTotalBooling}
        onClose={() => {
          setOpenTotalBooking(false);
        }}
        title={"Total bookings (All time)"}
        description={{
          normalContent: "is the number of bookings completed since joining Blys.",
          boldContent: "Total bookings (All time) ",
        }}
      />
      <RecipientBookingInfoCard
        isOpen={openLast30Booking}
        onClose={() => {
          setOpenLast30Booking(false);
        }}
        title={"Total bookings (Last 30 days)"}
        description={{
          normalContent: " is the number of bookings completed in last 30 days.",
          boldContent: "Total bookings (Last 30 days)",
        }}
      />
    </Box>
  );
};

export default RecipientSummary;
