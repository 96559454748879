import { Box, ButtonBase } from "@material-ui/core";
import React from "react";
import { Colors } from "../constants/colors";

interface Props {
  segments: string[];
  onSelectedIndex: (index: number) => unknown;
  selectedSegmentIndex: number;
  borderRadius?: React.CSSProperties["borderRadius"];
  height?: React.CSSProperties["height"];
}

const segmentStyle = (borderRadius: React.CSSProperties["borderRadius"]) => ({
  flex: 1,
  m: "4px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderRadius: borderRadius || "4px",
});

const buttonStyle = {
  fontFamily: "Museo",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  flex: 1,
  height: "100%",
};

export default function SegmentedControl({
  segments,
  onSelectedIndex,
  selectedSegmentIndex,
  borderRadius,
  height,
}: Props) {
  return (
    <Box
      bgcolor={Colors.LightPeriwinkle}
      height={height || "45px"}
      width="100%"
      display="flex"
      flex={1}
      borderRadius={borderRadius || "8px"}
    >
      {segments.map((segment, index) => {
        if (selectedSegmentIndex === index) {
          return (
            <Box
              key={segment + index}
              color={Colors.Dusk}
              bgcolor="white"
              {...segmentStyle(borderRadius)}
            >
              <ButtonBase onClick={() => onSelectedIndex(index)} style={buttonStyle}>
                {segment}
              </ButtonBase>
            </Box>
          );
        } else {
          return (
            <Box color={Colors.BlueyGrey} {...segmentStyle(borderRadius)} m={0}>
              <ButtonBase onClick={() => onSelectedIndex(index)} style={buttonStyle}>
                {segment}
              </ButtonBase>
            </Box>
          );
        }
      })}
    </Box>
  );
}
